import React, { Component } from 'react'
import Login from 'Containers/Login'
// import Logout from 'Containers/Logout'
import { connect } from 'react-redux'
import { login } from 'Redux/reducers/auth'
import './Header.css'
import qlfLogo from 'Images/qlf.png'
import { changeRole } from 'Redux/actions'
import { localTh, ManageUser, ChangePwdUser } from 'Utils'
import { Dropdown } from 'semantic-ui-react'
import { Styles } from 'Components/CustomStyles'
import { Button, Modal } from 'semantic-ui-react'
import { logout } from 'Redux/reducers/auth'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { changePage } from 'Redux/actions'
// import { actions as userActions } from 'Redux/reducers/user'

// import jwt from 'jsonwebtoken'

const options = [
  { key: 'user', text: 'Account', icon: 'user' },
  { key: 'settings', text: 'Settings', icon: 'settings' },
  { key: 'sign-out', text: 'Signout', icon: 'sign out' }
]

class Header extends Component {
  state = {
    opacity: 0,
    modal: false,
    modalWarnning: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    const { auth } = this.props
    let checkLogin = auth.isAuthenticated ? 1 : 0
    if (checkLogin === 0 && window.location.hash !== '#/')
      window.location.href = '/'

    if (window.location.hash === '#/') {
      this.props.changePage('MAIN')
    } else {
      this.props.changePage('MENU')
    }

    this.setState({ opacity: auth.isAuthenticated ? 1 : 0 })
  }

  componentWillReceiveProps = nextProps => {
    const { auth } = this.props
    let checkLogin = auth.isAuthenticated ? 1 : 0
    if (checkLogin === 0 && window.location.hash !== '#/')
      window.location.href = '/'
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = event => {
    const scroll = window.pageYOffset
    const height = 80.0
    let opacity = (scroll * 1.0) / height
    this.setState({ opacity })
  }

  roleToString = role => {
    return localTh('roles', role) || ''
  }

  getLocalUser = cb => {
    import('jsonwebtoken').then(jwt => {
      const token = localStorage.getItem('id_token')
      var content = jwt.decode(token)
      this.setState({ localUser: content })
    })
  }

  getCurrentRole = roles => {
    let currole = localStorage.getItem('currentrole')
    if (
      !currole ||
      currole === null ||
      currole === undefined ||
      currole === ''
    ) {
      localStorage.setItem('currentrole', roles[0])
      currole = roles[0]
      this.setState({ modalWarnning: true })
    }
    if (!roles) return 'Guest'
    const { auth, role } = this.props
    if (!auth.isAuthenticated) {
      setTimeout(() => {
        this.props.changeRole('Guest')
      }, 200)
      return 'Guest'
    } else {
      if (role === 'Guest') {
        setTimeout(() => {
          this.props.changeRole(currole)
        }, 200)
        return currole
      } else {
        return role
      }
    }
  }

  onChangeRole = (e, { role }) => {
    this.props.changeRole(role)
    localStorage.setItem('currentrole', role)
  }

  onEditProfileClick = () => {
    this.setState({ modal: true })
  }

  onEditProfileClose = () => {
    this.setState({ modal: false, modalChangePwd: false })
    this.props.getTypeProject('584ccec0e1e111e889cf8170c54de9cb')
  }

  handleChange = value => {
    if (value === 'Account') {
      this.setState({ modal: true })
    } else if (value === 'Signout') {
      this.props.logout()
      window.location.href = '/'
    } else {
      this.setState({ modalChangePwd: true })
    }
  }

  onLogoutClick = () => {
    this.props.logout()
    window.location.href = '/'
  }

  onLoad = (e, { name }) => {
    window.open(name)
  }

  handleBackMain = () => {
    window.location.href = '/'
  }

  handleClose = () => {
    this.onLogoutClick()
    this.setState({modalWarnning: false})
  }

  render() {
    const { auth, page, role } = this.props
    const { opacity, localUser, modalWarnning } = this.state

    let user = null
    if (auth.isAuthenticated) {
      if (auth.user) {
        user = auth.user.user
      } else {
        if (localUser) user = localUser
      }
    }
    //let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated && !localUser) {
      this.getLocalUser()
    }
    
    let currentRole = {}
    if(user) {
      currentRole = this.getCurrentRole(user && user.roles)
    }
    return (
      <div
        className='header-container'
        style={{
          backgroundColor:
            'rgba(251, 65, 77, ' + (auth.isAuthenticated ? 1 : opacity) + ')',
          boxShadow:
            '0px 0px 18px -3px rgba(0, 0, 0, ' +
            (auth.isAuthenticated ? 1 : opacity) +
            ') ',
          transition: '.2s ease'
        }}
      >
        <div className='header-logo'>
          <img
            className='img'
            src={qlfLogo}
            alt='logo'
            onClick={this.handleBackMain}
          />
        </div>

        {user && (
          <span className='header-top-tight'>
            ผู้ใช้งาน :{' '}
            {user.title + ' ' + user.firstname + ' ' + user.lastname}
          </span>
        )}

        {user && (
          <Modal
            open={this.state.modal}
            onClose={this.onEditProfileClose}
            size='small'
            closeIcon
          >
            <Modal.Content>
              <ManageUser
                onCloseModal={this.onEditProfileClose}
                userID={user._id}
              />
            </Modal.Content>
          </Modal>
        )}
        {user && (
          <Modal
            open={this.state.modalChangePwd}
            onClose={this.onEditProfileClose}
            size='small'
            closeIcon
          >
            <Modal.Content>
              <ChangePwdUser
                onCloseModal={this.onEditProfileClose}
                userID={user._id}
              />
            </Modal.Content>
          </Modal>
        )}

        {user && (
          <Modal
            open={modalWarnning}
            onClose={this.handleClose}
            closeIcon
          // actions={['Snooze', { key: 'done', content: 'Done', positive: true }]}
          >
            <Modal.Header>กรุณาเข้าสู่ระบบใหม่อีกครั้ง</Modal.Header>
            <Modal.Content>
              เนื่องจากข้อมูลผู้ใช้งานไม่ครบถ้วน
            </Modal.Content>
            <Modal.Actions>
              <Button color='red' icon='remove' onClick={this.handleClose} content='ปิด'/>
            </Modal.Actions>
          </Modal>
        )}

        {user && page === 'MAIN' && (
          <Dropdown
            text={this.roleToString(currentRole)}
            className='header-top-role'
          >
            <Dropdown.Menu>
              {user.roles.map((r, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={this.onChangeRole}
                  role={r}
                  text={this.roleToString(r)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {user && page !== 'MAIN' && (
          <span className='header-top-tight'>
            {localTh('roles', role) + ' '}
          </span>
        )}
        {/* {user && <Dropdown
          text={this.roleToString(currentRole)}
          className="header-top-role">
          <Dropdown.Menu>
            {user.roles.map((r, i) => (
              <Dropdown.Item key={i} onClick={this.onChangeRole} role={r} text={this.roleToString(r)} />
            ))}
          </Dropdown.Menu>
        </Dropdown>} */}

        {user && (
          <Dropdown
            icon={null}
            pointing='top right'
            trigger={<Button className={Styles.outlineButton} icon='user' />}
          >
            <Dropdown.Menu>
              {options.map((r, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={this.handleChange.bind(this, r.text)}
                  text={localTh('manageUser', r.text)}
                  icon={r.key}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {!auth.isAuthenticated && <Login />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  message: state.auth.errorMessage,
  isLoginError: state.auth.isLoginError,
  role: state.role,
  page: state.page
})

const mapDispatchToProps = dispatch => ({
  login: (user, pass) => dispatch(login({ username: user, password: pass })),
  logout: () => dispatch(logout()),
  changeRole: role => dispatch(changeRole(role)),
  getTypeProject: id => dispatch(typeProject.fetchOne(id)),
  changePage: page => dispatch(changePage(page))
  // updateUser: (data, id) => dispatch(userActions.save(data, id, 'POST')),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)

import React, { Component, Fragment } from 'react';
import { Form, Button, Header, Message, Label, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { localTh } from 'Utils'
import { actions as typeProject } from 'Redux/reducers/typeproject'


class ListTypeProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      cid: undefined,
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      selected: null
    };
  }

  componentWillReceiveProps(nextProps) {
    this.props.getTypeProject('071f9ae0d12f11e89e8a73d5ec6ec9ef')
  }

  handleDeleteRow = (idx) => {
    let { data } = this.state;
    let { typeProject } = this.props;

    let _typeOffice = []
    if (typeProject) {
      if (Object.keys(data).length === 0) {
        _typeOffice = typeProject.typeOffice.slice()
      } else {
        _typeOffice = data.typeOffice.slice()
      }
    }

    _typeOffice.splice(idx, 1)

    let tmp = { ...data, typeOffice: _typeOffice }
    this.setState({ data: tmp, selected: _typeOffice.length - 1 })
  }

  handleEditRow = (idx, e, { name, value }) => {
    let { data } = this.state;
    let { typeProject } = this.props;

    let _typeOffice = []
    if (typeProject) {
      if (Object.keys(data).length === 0) {
        typeProject.typeOffice.forEach(item => {
          var tmp = { ...item }
          _typeOffice.push(tmp)
        })
      } else {
        data.typeOffice.forEach(item => {
          var tmp = { ...item }
          _typeOffice.push(tmp)
        })
      }
    }
    _typeOffice[idx][name] = value;

    let tmp = { ...data, typeOffice: _typeOffice }
    this.setState({ data: tmp })
  }

  handleAddRow = () => {
    let { data } = this.state;
    let { typeProject } = this.props;

    let _typeOffice = []
    if (typeProject) {
      if (Object.keys(data).length === 0) {
        _typeOffice = typeProject.typeOffice.slice()
      } else {
        _typeOffice = data.typeOffice.slice()
      }
    }

    _typeOffice.push({
      name: '', value: _typeOffice.length + 1
    });

    let tmp = { ...data, typeOffice: _typeOffice }
    this.setState({ data: tmp, selected: _typeOffice.length - 1 })

  }

  handleSubmit = () => {
    let { data } = this.state;
    let { typeProject } = this.props;

    if (typeProject) data = { ...typeProject, ...data };

    this.props.updateTypeProject(data, data._id)
    this.props.onClose()
  }

  handleSelectRow = (selected) => {
    if (selected === this.state.selected) selected = null
    this.setState({ selected })
  }

  render() {
    let { validate, data, selected } = this.state
    let { typeProject } = this.props;

    if (typeProject) data = { ...typeProject, ...data };

    return (
      <Fragment>
        <Header style={{ fontFamily: 'supermarket' }} content='รายชื่อประเภทโครงการ' />
        <Form error={validate.error}>
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content='ลำดับ/รหัสประเภท' width={3} textAlign='center' />
                <Table.HeaderCell content='คำอธิบาย' width={11} textAlign='center' />
                <Table.HeaderCell content='ลบ' width={2} textAlign='center' />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.typeOffice && data.typeOffice.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx)}>
                    {(selected === idx && <Table.Cell>
                      <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                    </Table.Cell>) || <Table.Cell textAlign='center'> {(idx + 1)}</Table.Cell>}
                    <Table.Cell content={item.name} />
                    <Table.Cell textAlign='center'><Button color='red' icon='close'
                      onClick={this.handleDeleteRow.bind(this, idx)} /></Table.Cell>
                  </Table.Row>
                  {selected === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={3}>
                        <Form.TextArea label={'คำอธิบาย ' + (idx + 1)} name='name' placeholder='(โปรดใส่คำอธิบาย)'
                          value={item.name} onChange={this.handleEditRow.bind(this, idx)} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell >
                  <Button style={{ fontFamily: 'supermarket' }} content='เพิ่ม' color='green' icon='plus' name='objective' onClick={this.handleAddRow} />
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Footer>
          </Table>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
            <Message.List>
              {/* <Message.Item >{'เลขบัตรประชาชน - ' + cid}</Message.Item> */}
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('organization', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>
          <Button onClick={this.handleSubmit} style={{ fontFamily: 'supermarket' }} color='blue' disabled={validate.error}>บันทึก</Button>
        </Form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  typeProject: state.typeproject.currentData
});

const mapDispatchToProps = dispatch => ({
  updateTypeProject: (data, id) => dispatch(typeProject.save(data, id, 'POST')),
  getTypeProject: (id) => dispatch(typeProject.fetchOne(id))
  // listOrganization: (id) => dispatch(organizationActions.fetchOne(id)),
  // updateForm: (data) => dispatch(formActions.update(data, PjmConstraints.PjmConstraints))
})


export default connect(mapStateToProps, mapDispatchToProps)(ListTypeProject);
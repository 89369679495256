import React, { Component, Fragment } from 'react'
import {
  Form,
  Header,
  Button,
  TextArea,
  Input,
  Table,
  Label,
  Message,
  Transition,
  Icon,
  Confirm
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
// import { constraints as ObjectiveConstaintsArrayR } from './RegisConstraints'
import { constraints as ObjectiveConstaintsArrayP } from './RegisConstraints'
import { constraints as ObjectiveConstaintsArrayO } from './RegisConstraints'
import { constraints as ObjectiveConstaints } from './RegisConstraints'
import { actions as projectActions } from 'Redux/reducers/projects'
import { HeaderContent } from 'Components/Header'
import { localTh } from 'Utils'
import { ValidateArray as validateArray } from 'Utils'
import jwt from 'jsonwebtoken'

let gettime = ''

class Objective extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedRow: null,
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      onSaveSuccess: false,
      openConfirm: false,
      indexDelete: ''
    }
  }

  componentDidMount () {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps (nextProps) {
    let { data } = this.state
    if (nextProps.currentProposal) {
      let tmp = { ...nextProps.currentProposal }
      if (!tmp.result) tmp.result = []
      this.setState({ data: { ...tmp, ...data } })
    }
  }

  handleSelectRow = (selectedRow, name) => {
    let {
      selectedProductRow,
      selectedResultRow,
      selectedObjectiveRow
    } = this.state

    if (name === 'product') {
      if (selectedRow === this.state.selectedProductRow)
        selectedProductRow = null
      else selectedProductRow = selectedRow
      selectedResultRow = null
      selectedObjectiveRow = null
    }
    if (name === 'result') {
      if (selectedRow === this.state.selectedResultRow) selectedResultRow = null
      else selectedResultRow = selectedRow
      selectedProductRow = null
      selectedObjectiveRow = null
    }
    if (name === 'objective') {
      if (selectedRow === this.state.selectedObjectiveRow)
        selectedObjectiveRow = null
      else selectedObjectiveRow = selectedRow
      selectedProductRow = null
      selectedResultRow = null
    }

    this.setState({
      modalAttachment: false,
      selectedProductRow,
      selectedResultRow,
      selectedObjectiveRow
    })
  }

  handleEditRow = (idx, field, e, { name, value }) => {
    let { data, validate } = this.state
    const { currentProposal } = this.props
    // if (this.props.currentProposal) data = { ...currentProposal, ...data }
    let tmp = {}
    let _product = []
    let _result = []
    let _objective = []

    if (field === 'product') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          currentProposal.product.forEach(item => {
            var tmp = { ...item }
            _product.push(tmp)
          })
        } else {
          data.product.forEach(item => {
            var tmp = { ...item }
            _product.push(tmp)
          })
        }
      }
      _product[idx][name] = value
      tmp = { ...data, product: _product }
    } else if (field === 'result') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          currentProposal.result.forEach(item => {
            var tmp = { ...item }
            _result.push(tmp)
          })
        } else {
          data.result.forEach(item => {
            var tmp = { ...item }
            _result.push(tmp)
          })
        }
      }
      _result[idx][name] = value
      tmp = { ...data, result: _result }
    } else if (field === 'objective') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          currentProposal.objective.forEach(item => {
            var tmp = { ...item }
            _objective.push(tmp)
          })
        } else {
          data.objective.forEach(item => {
            var tmp = { ...item }
            _objective.push(tmp)
          })
        }
      }
      _objective[idx][name] = value
      tmp = { ...data, objective: _objective }
    }

    if (!validate.attrs) validate.attrs = {}
    validate.error = false
    validate.attrs = {}

    this.setState({ data: tmp, validate })
  }

  handleAddRow = (e, { name }) => {
    let { data, validate } = this.state
    const { currentProposal } = this.props
    // if (this.props.currentProposal) data = { ...currentProposal, ...data }

    validate.error = false
    validate.attrs = {}
    let tmp = {}
    let _product = []
    let _result = []
    let _objective = []

    let indexP,
      indexR,
      indexO = null
    if (name === 'product') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          _product = currentProposal.product.slice()
        } else {
          _product = data.product.slice()
        }
      }
      _product.push({ product: '' })
      indexP = _product.length - 1
      tmp = { ...data, product: _product }
    } else if (name === 'result') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          _result = currentProposal.result.slice()
        } else {
          _result = data.result.slice()
        }
      }
      _result.push({ result: '' })
      tmp = { ...data, result: _result }
      indexR = _result.length - 1
    } else if (name === 'objective') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          _objective = currentProposal.objective.slice()
        } else {
          _objective = data.objective.slice()
        }
      }
      _objective.push({ objective: '' })
      tmp = { ...data, objective: _objective }
      indexO = _objective.length - 1
    }

    this.setState({
      data: tmp,
      selectedProductRow: indexP,
      selectedResultRow: indexR,
      selectedObjectiveRow: indexO,
      validate
    })
  }

  handleDeleteRow = () => {
    let { data, name, indexDelete, validate } = this.state
    const { currentProposal } = this.props
    // if (this.props.currentProposal) data = { ...currentProposal, ...data }

    validate.error = false
    validate.attrs = {}
    let tmp = {}
    let _product = []
    let _result = []
    let _objective = []

    if (name === 'product') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          _product = currentProposal.product.slice()
        } else {
          _product = data.product.slice()
        }
      }
      _product.splice(indexDelete, 1)
      tmp = { ...data, product: _product }
    } else if (name === 'result') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          _result = currentProposal.result.slice()
        } else {
          _result = data.result.slice()
        }
      }
      _result.splice(indexDelete, 1)
      tmp = { ...data, result: _result }
    } else if (name === 'objective') {
      if (currentProposal) {
        if (Object.keys(data).length === 0) {
          _objective = currentProposal.objective.slice()
        } else {
          _objective = data.objective.slice()
        }
      }
      _objective.splice(indexDelete, 1)
      tmp = { ...data, objective: _objective }
    }

    this.setState({
      data: tmp,
      selectedProductRow: null,
      selectedResultRow: null,
      selectedObjectiveRow: null,
      openConfirm: false
    })
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state
    const { currentProposal } = this.props
    if (this.props.currentProposal) data = { ...currentProposal, ...data }

    data[name] = value
    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false

    this.setState({ data, validate })
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  handleSubmit = condition => {
    let { data, validate } = this.state
    let { currentProposal, role, auth } = this.props
    if (this.props.currentProposal) data = { ...currentProposal, ...data }
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    })

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (role === 'Admin') {
      data['adminEdit'] = user._id
      data['adminEditTime'] = gettime
    }

    validate.attrs = {}
    validate.error = false
    validate['validateError'] = {}

    validate['validateError'] = validatejs(
      data,
      ObjectiveConstaints.ObjectiveConstaints
    )

    if (!data.result) data['result'] = []
    if (!data.product) data['product'] = []
    if (!data.objective) data['objective'] = []

    // validate array
    // let validateArrayErrorR = validateArray(data.result, ObjectiveConstaintsArrayR.ObjectiveConstaintsArrayR)
    let validateArrayErrorP = validateArray(
      data.product,
      ObjectiveConstaintsArrayP.ObjectiveConstaintsArrayP
    )
    let validateArrayErrorO = validateArray(
      data.objective,
      ObjectiveConstaintsArrayO.ObjectiveConstaintsArrayO
    )

    if (!validate['validateError']) validate['validateError'] = {}

    // if (Object.keys(validateArrayErrorR).length !== 0 && validateArrayErrorR.constructor === Object) {
    //   validate.error = true
    //   if (!validate['validateError']) validate['validateError'] = {}
    //   Object.keys(validateArrayErrorR).forEach(index => {
    //     validate['validateError']['result'] = validateArrayErrorR[index].result
    //     validate.attrs['result' + index] = true;
    //   })
    // }

    if (
      Object.keys(validateArrayErrorP).length !== 0 &&
      validateArrayErrorP.constructor === Object
    ) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayErrorP).forEach(index => {
        validate['validateError']['product'] =
          validateArrayErrorP[index].product
        validate.attrs['product' + index] = true
      })
    }

    if (
      Object.keys(validateArrayErrorO).length !== 0 &&
      validateArrayErrorO.constructor === Object
    ) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayErrorO).forEach(index => {
        validate['validateError']['objective'] =
          validateArrayErrorO[index].objective
        validate.attrs['objective' + index] = true
      })
    }

    // if(!validate['validateError'])validate['validateError']={}

    if (!data.result) data.result = []
    if (!data.product) data.product = []
    if (!data.objective) data.objective = []

    // if (data.result.length === 0) {
    //   validate.error = true
    //   validate['validateError']['result'] = []
    //   validate['validateError']['result'].push('โปรดระบุผลลัพธ์ข้อกำหนด')
    // }

    if (data.product.length === 0) {
      validate.error = true
      validate['validateError']['product'] = []
      validate['validateError']['product'].push('โปรดระบุผลผลิตข้อกำหนด')
    }

    if (data.objective.length === 0) {
      validate.error = true
      validate['validateError']['objective'] = []
      validate['validateError']['objective'].push('โปรดระบุวัตถุประสงค์')
    }

    // end validate array
    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      if (role === 'Admin') {
        data['adminEdit'] = user._id
        data['adminEditTime'] = gettime
      } else {
        data['timestamp'] = gettime
      }
      // if(JSON.parse(sessionStorage.getItem('attachSendProposal'))) {
      //   data['attachSendProposal'] = JSON.parse(sessionStorage.getItem('attachSendProposal'))
      //   sessionStorage.setItem('attachSendProposal', null)
      // }
      this.props.updateProposal(data, data._id)
      this.setState({ onSaveSuccess: true })
      setTimeout(() => {
        this.setState({ onSaveSuccess: false })
        if (condition === 'next') {
          this.props.history.push('/project/area/' + data._id)
        } else if (condition === 'back') {
          this.props.history.push('/project/information/' + data._id)
        }
      }, 3000)
      // if (this.props.history) this.props.history.push('/project/area/' + data._id);
    }
  }

  _renderFormField = content => {
    const { validate } = this.state
    let { currentProposal, role } = this.props

    let checkLockScreen =
      (currentProposal &&
        currentProposal.status !== 'ส่งข้อเสนอโครงการ' &&
        currentProposal.status !== 'กลั่นกรอง' &&
        role !== 'Admin') ||
      (currentProposal &&
        (currentProposal.status === 'ส่งข้อเสนอโครงการ' ||
          currentProposal.status === 'กลั่นกรอง') &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')

    if (currentProposal) {
      if (checkLockScreen) {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            readOnly
          />
        )
      } else {
        return <Form.Field {...content} error={validate.attrs[content.name]} />
      }
    } else {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  handleNextPage = () => {
    this.handleSubmit('next')
  }

  handleBackPage = () => {
    this.handleSubmit('back')
  }

  openConfirm = (idx, name) => {
    this.setState({ openConfirm: true, indexDelete: idx, name: name })
  }

  close = () => {
    this.setState({
      openConfirm: false,
      selectedProductRow: null,
      selectedResultRow: null,
      selectedObjectiveRow: null
    })
  }

  render () {
    let {
      data,
      validate,
      selectedProductRow,
      selectedResultRow,
      selectedObjectiveRow,
      onSaveSuccess
    } = this.state
    const { currentProposal, currentState, role } = this.props

    if (this.props.match.params.id) {
      data = { ...currentProposal, ...data }
    }

    let content = {
      purpose: { control: TextArea },
      goal: { control: TextArea },
      objective: { control: TextArea },
      evaluation: { control: TextArea }
    }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('proposal', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].control === Input) {
        content[key].value = data[key] || ''
      } else {
        content[key].value = data[key]
      }
    })

    let checkLockScreen =
      (currentProposal &&
        currentProposal.status !== 'ส่งข้อเสนอโครงการ' &&
        currentProposal.status !== 'กลั่นกรอง' &&
        role !== 'Admin') ||
      (currentProposal &&
        (currentProposal.status === 'ส่งข้อเสนอโครงการ' ||
          currentProposal.status === 'กลั่นกรอง') &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')

    return (
      <div>
        <Form onSubmit={this.handleSubmit} error={validate.error}>
          <HeaderContent
            title={data.nameTh}
            subtitle='เป้าประสงค์ของโครงการโครงการ'
          />
          {this._renderFormField(content.purpose)}
          {/* {this._renderFormField(content.goal)} */}
          {/* {this._renderFormField(content.objective)} */}
          {this._renderFormField(content.evaluation)}

          <Header
            as='h4'
            style={{ fontFamily: 'supermarket' }}
            content='วัตถุประสงค์ *'
            icon='list alternate outline'
          />
          <Table celled selectable color='olive'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content='ลำดับ' width={2} />
                <Table.HeaderCell content='วัตถุประสงค์ *' width={12} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.objective &&
                data.objective.map((item, idx) => (
                  <Fragment key={idx}>
                    <Table.Row
                      onClick={this.handleSelectRow.bind(
                        this,
                        idx,
                        'objective'
                      )}
                      error={validate.attrs['objective' + idx]}
                    >
                      {(selectedObjectiveRow === idx && (
                        <Table.Cell>
                          <Label
                            color='green'
                            ribbon
                            content={'ลำดับที่ ' + (idx + 1)}
                          />
                        </Table.Cell>
                      )) || (
                        <Table.Cell textAlign='center'> {idx + 1}</Table.Cell>
                      )}
                      <Table.Cell content={item.objective} />
                      <Table.Cell textAlign='center'>
                        <Button
                          type='button'
                          name='objective'
                          color='red'
                          icon='close'
                          onClick={this.openConfirm.bind(
                            this,
                            idx,
                            'objective'
                          )}
                          disabled={checkLockScreen}
                          // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {selectedObjectiveRow === idx && (
                      <Table.Row active>
                        <Table.Cell colSpan={4}>
                          <Form.TextArea
                            label={'วัตถุประสงค์ที่ ' + (idx + 1)}
                            name='objective'
                            value={item.objective}
                            onChange={this.handleEditRow.bind(
                              this,
                              idx,
                              'objective'
                            )}
                            readOnly={checkLockScreen}
                            // readOnly={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Fragment>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button
                    type='button'
                    content='เพิ่ม'
                    color='green'
                    icon='plus'
                    name='objective'
                    onClick={this.handleAddRow}
                    disabled={checkLockScreen}
                    // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Header
            as='h4'
            style={{ fontFamily: 'supermarket' }}
            content='ผลผลิตข้อกำหนด *'
            icon='list alternate outline'
          />
          <Table celled selectable color='olive'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content='ลำดับ' width={2} />
                <Table.HeaderCell content='ผลผลิตข้อกำหนด *' width={12} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.product &&
                data.product.map((item, idx) => (
                  <Fragment key={idx}>
                    <Table.Row
                      onClick={this.handleSelectRow.bind(this, idx, 'product')}
                      error={validate.attrs['product' + idx]}
                    >
                      {(selectedProductRow === idx && (
                        <Table.Cell>
                          <Label
                            color='green'
                            ribbon
                            content={'ลำดับที่ ' + (idx + 1)}
                          />
                        </Table.Cell>
                      )) || (
                        <Table.Cell textAlign='center'> {idx + 1}</Table.Cell>
                      )}
                      <Table.Cell content={item.product} />
                      <Table.Cell textAlign='center'>
                        <Button
                          type='button'
                          name={'product'}
                          color='red'
                          icon='close'
                          onClick={this.openConfirm.bind(this, idx, 'product')}
                          disabled={checkLockScreen}
                          // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {selectedProductRow === idx && (
                      <Table.Row active>
                        <Table.Cell colSpan={4}>
                          <Form.TextArea
                            label={'ผลผลิตที่ ' + (idx + 1)}
                            name='product'
                            value={item.product}
                            onChange={this.handleEditRow.bind(
                              this,
                              idx,
                              'product'
                            )}
                            readOnly={checkLockScreen}
                            // readOnly={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Fragment>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button
                    content='เพิ่ม'
                    color='green'
                    icon='plus'
                    name='product'
                    type='button'
                    onClick={this.handleAddRow}
                    disabled={checkLockScreen}
                    // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Header
            as='h4'
            style={{ fontFamily: 'supermarket' }}
            content='ผลลัพธ์ข้อกำหนด *'
            icon='list alternate outline'
          />
          <Table celled selectable color='olive'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content='ลำดับ' width={2} />
                <Table.HeaderCell content='ผลลัพธ์ข้อกำหนด *' width={12} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.result &&
                data.result.map((item, idx) => (
                  <Fragment key={idx}>
                    <Table.Row
                      onClick={this.handleSelectRow.bind(this, idx, 'result')}
                      error={validate.attrs['result' + idx]}
                    >
                      {(selectedResultRow === idx && (
                        <Table.Cell>
                          <Label
                            color='green'
                            ribbon
                            content={'ลำดับที่ ' + (idx + 1)}
                          />
                        </Table.Cell>
                      )) || (
                        <Table.Cell textAlign='center'>{idx + 1}</Table.Cell>
                      )}
                      <Table.Cell content={item.result} />
                      <Table.Cell textAlign='center'>
                        <Button
                          type='button'
                          name={'result'}
                          color='red'
                          icon='close'
                          onClick={this.openConfirm.bind(this, idx, 'result')}
                          disabled={checkLockScreen}
                          // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {selectedResultRow === idx && (
                      <Table.Row active>
                        <Table.Cell colSpan={4}>
                          <Form.TextArea
                            label={'ผลลัพธ์ที่ ' + (idx + 1)}
                            name='result'
                            value={item.result}
                            onChange={this.handleEditRow.bind(
                              this,
                              idx,
                              'result'
                            )}
                            readOnly={checkLockScreen}
                            // readOnly={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Fragment>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button
                    content='เพิ่ม'
                    color='green'
                    icon='plus'
                    name='result'
                    type='button'
                    onClick={this.handleAddRow}
                    disabled={checkLockScreen}
                    // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Transition
            visible={onSaveSuccess}
            animation='horizontal flip'
            duration={500}
          >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                <Icon name='check circle'></Icon>บันทึกสำเร็จ
              </Message.Header>
            </Message>
          </Transition>
          <Button
            color='blue'
            content='บันทึก'
            icon='save'
            type='submit'
            disabled={checkLockScreen}
            // disabled={validate.error || !data.result ||
            //   (currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') ||
            //   (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
            loading={currentState.isSaving}
            onClick={this.handleSubmit}
          />
          <Button
            className='button-back-bottom-right'
            type='button'
            color='blue'
            content='ย้อนกลับ'
            disabled={checkLockScreen}
            icon='step backward'
            onClick={this.handleBackPage}
            // disabled={checkLockScreen}
          />
          <Button
            className='button-bottom-right'
            type='button'
            color='blue'
            content='ถัดไป'
            disabled={checkLockScreen}
            icon='step forward'
            onClick={this.handleNextPage}
            // disabled={checkLockScreen}
          />
        </Form>
        <Confirm
          open={this.state.openConfirm}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.close}
          onConfirm={this.handleDeleteRow}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  currentState: state.projects,
  role: state.role,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Objective)

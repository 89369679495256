import React, { Component, Fragment } from 'react';
import { Form } from 'semantic-ui-react'
import ThaiAddress from 'react-thai-address';

class FormSearchAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressOptions: []
    };
  }

  handleSearchChange = (e, { searchQuery }) => {
    var queryAttrs = ['tumbon', 'city', 'province', 'zipcode'];
    var resultSize = 30;
    var result = {};
    var searchQueryArr = searchQuery.split(/\s+/g);
    searchQueryArr.forEach((searchStr) => {
      queryAttrs.forEach((attr) => {
        var _result = ThaiAddress.search({ [attr]: searchStr }, resultSize);
        _result.map((item) => ({
          'text': this.props.attr === 'tumbon' ? item['tumbon'] + '--' + item['city'] + '--' + item['province']+ '--' + item['zipcode'] :
            this.props.attr === 'city' ? item['city'] + '--' + item['province'] :
              item[this.props.attr],
          'value': JSON.stringify(item)
        }))
          .forEach((item) => {
            if (!result[item.text]) result[item.text] = item;
          })
      });
    });
    const addressOptions = Object.keys(result).map((item) => result[item])
    this.setState({ addressOptions });
  }

  handleOnChange = (e, { name, value, index }) => {
    if (this.props.onChange) this.props.onChange(e, {
      name,
      value: value === '' ? { [this.props.attr]: value } : JSON.parse(value),
      index,
      attr: this.props.attr
    });
  }

  render() {
    let { addressOptions } = this.state
    const { label, readOnly, index, attr } = this.props
    const value = this.props.value ? JSON.stringify(this.props.value) : '{}';
    let valueSt = '';
    if (this.props.value) {
      let item = this.props.value
      addressOptions = addressOptions.filter(item => item.value !== value)
      addressOptions.push({
        'text': item[attr],
        value: value
      });
      addressOptions.push({
        'text': '',
        value: ''
      });
      valueSt = item[attr];
    }
    return (
      <Fragment>
        {readOnly && (<p>{valueSt}</p>)}
        {!readOnly && (
          <Form.Dropdown placeholder='ที่อยู่' label={label}
            index={index}
            fluid search selection
            name={this.props.name}
            options={addressOptions}
            value={value}
            onChange={this.handleOnChange}
            onSearchChange={this.handleSearchChange}
          />
        )}
      </Fragment>
    )
  }
}

export default FormSearchAddress

var InfoConstraints = {
  tor_id: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  nameTh: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  askBudget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  yearBudget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
    numericality: {
      onlyInteger: true,
      strict: true,
      message: '^ต้องเป็นตัวเลขเท่านั้น'
    },
  },
  recipientType: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  recipientPerson: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  manager: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valueGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valuePlan: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  valueActivity: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  typeOffice: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  typeProject: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  startDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // otherBudgetDropdown: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },

}

var ProposerConstraints = {

  proposerTitle: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  proposerFName: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  proposerLName: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  proposerCid: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
    numericality: {
      onlyInteger: true,
      strict: true,
      message: '^ต้องเป็นตัวเลขเท่านั้น'
    },
  },
  proposerRank: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  proposerHomePhoneNumber: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
    numericality: {
      onlyInteger: true,
      strict: true,
      message: '^ต้องเป็นตัวเลขเท่านั้น'
    },
  },
  proposerPhoneNumber: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
    numericality: {
      onlyInteger: true,
      strict: true,
      message: '^ต้องเป็นตัวเลขเท่านั้น'
    },
  },
  proposerFax: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
    numericality: {
      onlyInteger: true,
      strict: true,
      message: '^ต้องเป็นตัวเลขเท่านั้น'
    },
  },
}

var AreaConstraints = {
  // projectArea: {
  //   presence: {
  //     allowEmpty: false,
  //     message:'^ต้องระบุ'
  //   },  
  // },
}

var ObjectiveConstaints = {
  purpose: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  evaluation: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var ObjectiveConstaintsArrayR = {
  // result: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
}

var ObjectiveConstaintsArrayP = {
  product: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var ObjectiveConstaintsArrayO = {
  objective: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

// var withdrawTimeConstraints = {
//   approveBuget: {
//     presence: {
//       allowEmpty: false,
//       message: '^ต้องระบุ'
//     },
//   },
// }

var PersonConstraints = {
  firstname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  title: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  haveOrganization: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  organization: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var PersonConConstraints = {
  firstname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  title: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  haveOrganization: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var OrganizationConstraints = {
  organizationName: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  }
}

var OrganizeCondiConstraints = {
  organizationName: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var InfoArrayConstraints = {
  typeTarget: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  targetGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  targetsCount: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  targetsUnit: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  targetExplan: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var WithdrawTimeArrayConstraints = {
  content: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // mainCom: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  submitTime: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  startDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

// var WithdrawTimeConstraints = {
//   content: {
//     presence: {
//       allowEmpty: false,
//       message: '^ต้องระบุ'
//     },
//   },
// }


let constraints = {
  InfoConstraints: InfoConstraints,
  ProposerConstraints: ProposerConstraints,
  AreaConstraints: AreaConstraints,
  ObjectiveConstaints: ObjectiveConstaints,
  PersonConstraints: PersonConstraints,
  PersonConConstraints: PersonConConstraints,
  OrganizationConstraints: OrganizationConstraints,
  OrganizeCondiConstraints: OrganizeCondiConstraints,
  ObjectiveConstaintsArrayR: ObjectiveConstaintsArrayR,
  ObjectiveConstaintsArrayP: ObjectiveConstaintsArrayP,
  ObjectiveConstaintsArrayO: ObjectiveConstaintsArrayO,
  InfoArrayConstraints: InfoArrayConstraints,
  // withdrawTimeConstraints: withdrawTimeConstraints,
  WithdrawTimeArrayConstraints: WithdrawTimeArrayConstraints
}


module.exports = { constraints }

var ChangePwdConstraints = {
  oldPwd:{
    presence: {
      allowEmpty: false,
      message:'^ต้องระบุ'
    },
  },
  pwd:{
    presence: {
      allowEmpty: false,
      message:'^ต้องระบุ'
    }
  },
  confirmPwd: {
      presence: {
        allowEmpty: false,
        message:'^ต้องระบุ'
      }
    },
}


  

let constraints = {
    ChangePwdConstraints: ChangePwdConstraints,
}


module.exports = { constraints }

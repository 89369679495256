import React, { Component, Fragment } from 'react'
import {
  Input,
  Header,
  Form,
  Tab,
  Segment,
  Button,
  Select,
  TextArea,
  Message,
  Checkbox,
  Modal
} from 'semantic-ui-react'
// import 'react-datepicker/dist/react-datepicker.css';
import jwt from 'jsonwebtoken'
import ComponentTable from './ComponentTable'
import validatejs from 'validate.js'
import { connect } from 'react-redux'
import { actions as scholarActions } from 'Redux/reducers/scholar'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as burnProjectAction } from 'Redux/reducers/burnprojects'
import { actions as userAction } from 'Redux/reducers/user'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { constraints as BorrowExConstraints } from './ConstraintsBorrow'
import { localTh } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'
import { FundingFields } from 'Utils'
import { generate } from 'Redux/reducers/counting'
import { GetServerTime as GetTime } from 'Utils'
import { constraints as borrowArrayConstraints } from './ConstraintsBorrow'
import { constraints as BorrowConditionsExConstraints } from './ConstraintsBorrow'
import { ValidateArray as validateArray } from 'Utils'
import { ComponentPerson } from 'Utils'
import { ComponentOrganization } from 'Utils'
import { ThaiDate } from 'Utils'
import { actions as userActions } from 'Redux/reducers/user'

let gettime,
  noBorrow,
  countNo,
  thisYear = ''
let checkNo = true
let checkProps = false
let checktypeInstitution = false

const WithdrawOptions = [
  { key: '1', text: 'รับเช็ค สั่งจ่ายในนาม', value: 'cheque' },
  { key: '2', text: 'โอนเงินเข้าบัญชี', value: 'bankAccount' }
]

const nohaveBurn = [
  { key: '0', text: 'ไม่มี', value: 'no' },
  { key: '1', text: 'มี', value: 'yes' }
]
let userOption = []

var bankOptions = [
  { key: '1', text: 'ธนาคารกรุงเทพ', value: 'ธนาคารกรุงเทพ' },
  { key: '2', text: 'ธนาคารออมสิน', value: 'ธนาคารออมสิน' },
  { key: '3', text: 'ธนาคารกรุงไทย', value: 'ธนาคารกรุงไทย' },
  { key: '4', text: 'ธนาคารกสิกร', value: 'ธนาคารกสิกร' },
  { key: '5', text: 'ธนาคารไทยพาณิชย์', value: 'ธนาคารไทยพาณิชย์' },
  { key: '6', text: 'ธนาคารกรุงศรีอยุธยา', value: 'ธนาคารกรุงศรีอยุธยา' },
  { key: '7', text: 'ธนาคารธนชาต', value: 'ธนาคารธนชาต' },
  { key: '8', text: 'ธนาคารทหารไทย', value: 'ธนาคารทหารไทย' },
  { key: '9', text: 'ธนาคารเกียรตินาคิน', value: 'ธนาคารเกียรตินาคิน' },
  { key: '10', text: 'ธนาคารซีไอเอ็มบีไทย', value: 'ธนาคารซีไอเอ็มบีไทย' },
  { key: '11', text: 'ธนาคารทิสโก้', value: 'ธนาคารทิสโก้' },
  { key: '12', text: 'ธนาคารยูโอบี', value: 'ธนาคารยูโอบี' },
  { key: '13', text: 'ธนาคารเพื่อการเกษตร', value: 'ธนาคารเพื่อการเกษตร' },
  {
    key: '14',
    text: 'ธนาคารอิสลามแห่งประเทศไทย',
    value: 'ธนาคารอิสลามแห่งประเทศไทย'
  },
  {
    key: '15',
    text: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
    value: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)'
  },
  {
    key: '16',
    text: 'ธนาคารไทยเครดิตเพื่อรายย่อย',
    value: 'ธนาคารไทยเครดิตเพื่อรายย่อย'
  },
  {
    key: '17',
    text: 'ธนาคารแลนด์ แอนด์ เฮาส์',
    value: 'ธนาคารแลนด์ แอนด์ เฮาส์'
  },
  { key: '18', text: 'ธนาคารไอซีบีซี (ไทย)', value: 'ธนาคารไอซีบีซี (ไทย)' },
  {
    key: '19',
    text: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย',
    value: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย'
  },
  {
    key: '20',
    text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
    value: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร'
  },
  {
    key: '21',
    text: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย',
    value: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย'
  },
  { key: '22', text: 'ธนาคารอาคารสงเคราะห์', value: 'ธนาคารอาคารสงเคราะห์' }
]

class AddBorrowEx extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      activeIndex: 0,
      tableRerender: false,
      checkYear: '',
      openModalOrg: false,
      openModalPerson: false,
      checkSwitchType: false
    }
  }

  componentDidMount = () => {
    let { data } = this.state
    data['remuneration'] = []
    data['document'] = []
    data['vehicle'] = []
    data['apartment'] = []
    data['food'] = []
    data['other'] = []
    checktypeInstitution = false
    this.setState({ data })

    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        let checkYear = new Date(new Date(value).getFullYear(), 8, 30).getTime()
        if (value > checkYear) {
          thisYear = new Date(new Date(value).getFullYear() + 544).getTime()
        } else {
          thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        }
      }
    })

    let loop = this.props.burnprojects.length
    if (loop > 0) {
      for (let i = 0; i < loop; i++) {
        this.props.burnprojects.splice(0, 1)
      }
    }

    if (Object.getOwnPropertyNames(this.props.counter.counter).length > 0) {
      Object.keys(this.props.counter.counter).forEach(key => {
        delete this.props.counter.counter[key]
      })
    }

    if (
      this.props.counter.counter[
        'borrow' + (new Date(gettime).getFullYear() + 543)
      ]
    ) {
      checkNo = false
      countNo = this.props.counter.counter[
        'borrow' + (new Date(gettime).getFullYear() + 543)
      ]
      checkProps = false
    } else {
      checkNo = true
      countNo = undefined
      checkProps = false
    }

    let { auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let query = {}

    this.props.listUser(query)
    this.props.getUserData(user._id)
    this.props.getListOffice()
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        if (value > checkYear) {
          thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        } else {
          thisYear = new Date((new Date(value)).getFullYear() + 543).getTime()
        }
      }
    })
  }

  componentWillReceiveProps = nextProps => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        let checkYear = new Date(new Date(value).getFullYear(), 8, 30).getTime()
        if (value > checkYear) {
          thisYear = new Date(new Date(value).getFullYear() + 544).getTime()
        } else {
          thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        }
      }
    })

    // if(!gettime && gettime === '' && !thisYear && thisYear === ''&& gettime === undefined && thisYear === undefined) this.getTimeFuction()

    if (checkNo) {
      countNo = nextProps.counter.counter['borrow' + thisYear]
      if (countNo !== undefined && countNo !== '') {
        checkProps = true
        noBorrow =
          nextProps.counter.counter['borrow' + thisYear]
            .toString()
            .padStart(4, '0') + thisYear.toString().replace('25', '')
        // noBorrow = 'CA' + nextProps.counter.counter['borrow' + thisYear] + '/' + thisYear
        this.handleSubmit()
      }
    } else {
      let emty = nextProps.counter.counter['borrow' + thisYear]
      if (emty !== undefined) {
        if (countNo !== nextProps.counter.counter['borrow' + thisYear]) {
          countNo = nextProps.counter.counter['borrow' + thisYear]
          checkProps = true
          // noBorrow =
          //   'CA' +
          //   nextProps.counter.counter['borrow' + thisYear] +
          //   '/' +
          //   thisYear
          noBorrow =
          nextProps.counter.counter['borrow' + thisYear]
            .toString()
            .padStart(4, '0') + thisYear.toString().replace('25', '')
          this.handleSubmit()
        }
      }
    }
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  handleChangeMoney = (e, { value }) => {
    this.setState({ money: value })
  }

  handleDayChange = (name, index, date) => {
    let { data, validate } = this.state
    if (!data.borrowDate) {
      data.borrowDate = gettime
    }
    data[name] = date._d.getTime()
    if (data.typeOfBorrow && name === 'startDate') {
      if (data.typeOfBorrow === 'supplies') {
        data['repayDate'] = this.increaseDate(data.startDate, 7)
      }
    } else if (data.typeOfBorrow && name === 'endDate') {
      if (data.typeOfBorrow !== 'supplies') {
        data['repayDate'] = this.increaseDate(data.endDate, 15)
      }
    }

    validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    this.setState({ data, validate })
  }

  handleAddRow = nameArray => {
    let { data, activeIndex, validate } = this.state
    data[nameArray].splice(data[nameArray].length, 0, { type: 'person' })
    validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    this.setState({
      data,
      activeIndex: activeIndex + 1,
      tableRerender: true,
      validate
    })
  }

  handleDeleteRow = (nameArray, index) => {
    let { data, validate } = this.state
    data[nameArray].splice(index, 1)
    let sum = 0
    data[nameArray].forEach(x => {
      if (x.total) {
        let nb = x.total.replace(/,/g, '')
        let cv = parseFloat(nb)
        sum += cv
        let sumMoney = sum.toString()
        data[nameArray][0]['sum'] = sumMoney
      }
    })
    validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    this.setState({ data, validate })
  }

  handleChangeNameRow = (nameArray, name, value, index) => {
    let { data, activeIndex, validate } = this.state

    validate.attrs = {}
    validate.error = false
    validate.validateError = {}

    data[nameArray][index]['namePerson'] = name
    data[nameArray][index]['content'] = value
    this.setState({ data, activeIndex: activeIndex + 1, tableRerender: true })
  }

  handleChangeRow = (nameArray, name, value, index, sumTotal) => {
    let { data, activeIndex, validate } = this.state
    let sum = 0

    if (name === 'type') {
      if (value === 'person') {
        data[nameArray][index]['number'] = ''
        data[nameArray][index]['money'] = ''
        data[nameArray][index]['total'] = ''
        data[nameArray][index]['unit'] = ''
        data[nameArray][index]['content'] = ''
      } else if (value === 'activity') {
        data[nameArray][index]['number'] = ''
        data[nameArray][index]['money'] = ''
        data[nameArray][index]['total'] = ''
        data[nameArray][index]['unit'] = ''
        data[nameArray][index]['content'] = ''
      }
    }

    if (name === 'total') {
      let money = value.replace('-', '')
      data[nameArray][index][name] = money ? money.toString() : '0'
    } else if (name === 'number') {
      let money = value.replace('-', '')
      data[nameArray][index][name] = money ? money.toString() : '0'
      if (
        data[nameArray][index]['money'] &&
        data[nameArray][index]['money'] > 0
      ) {
        data[nameArray][index]['total'] = (
          data[nameArray][index][name] * data[nameArray][index]['money']
        )
          .toFixed(2)
          .toString()
      }
    } else if (name === 'money') {
      let money = value.replace('-', '')
      data[nameArray][index][name] = money ? money.toString() : '0'
      if (
        data[nameArray][index]['number'] &&
        data[nameArray][index]['number'] > 0
      ) {
        data[nameArray][index]['total'] = (
          data[nameArray][index][name] * data[nameArray][index]['number']
        )
          .toFixed(2)
          .toString()
      }
    } else {
      data[nameArray][index][name] = value
    }

    validate.attrs = {}
    validate.error = false
    validate.validateError = {}

    if (data[nameArray]) {
      data[nameArray].forEach(x => {
        if (x.total) {
          let nb = x.total.replace(/,/g, '')
          let cv = parseFloat(nb, 10)
          sum += cv
          let sumMoney = sum.toFixed(2).toString()
          if (data[nameArray]) data[nameArray][0]['sum'] = sumMoney
        }
      })
    }
    let query = {
      _id: 'blank'
    }
    this.props.listPersonByOrganization(query)
    this.setState({ data, activeIndex: activeIndex + 1, tableRerender: true })
  }

  _renderFormField = content => {
    const { validate } = this.state
    let { currentProposal } = this.props

    if (currentProposal) {
      if (currentProposal.status === 'ทำเรื่องคืนเงิน') {
        if (content.control === Select) {
          return (
            <Form.Field {...content} error={validate.attrs[content.name]} />
          )
        } else if (content.control === Checkbox) {
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              checked={content.value}
            />
          )
        } else
          return (
            <Form.Field {...content} error={validate.attrs[content.name]} />
          )
      } else {
        if (content.control === Select) {
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              disabled
            />
          )
        } else {
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              readOnly
            />
          )
        }
      }
    } else {
      if (content.control === Select) {
        return <Form.Field {...content} error={validate.attrs[content.name]} />
      } else if (content.control === Checkbox) {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            checked={content.value}
          />
        )
      } else
        return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  handleSubmit = () => {
    let { data, validate } = this.state
    const { auth, burn } = this.props

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (data.remuneration) {
      data.remuneration.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }

    if (data.document) {
      data.document.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }

    if (data.vehicle) {
      data.vehicle.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }

    if (data.apartment) {
      data.apartment.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }
    if (data.food) {
      data.food.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }
    if (data.other) {
      data.other.forEach(x => {
        if (x.total === '0') {
          delete x.total
        }
      })
    }

    data['status'] = 'ยืมเงินทดรองจ่าย'
    if (data.haveBurnPJ === 'yes') {
      data.yearBudget = burn['yearBudget']
      data.groupsPlan = burn['groupsPlan']
      data.plan = burn['plan']
      data.activity = burn['activity']
      data.valueGroup = burn['valueGroup']
      data.valuePlan = burn['valuePlan']
      data.valueActivity = burn['valueActivity']
      data.codeBudget = burn['codeBudget']
    }
    if (data.transferMoney) {
      validate['validateError'] = validatejs(
        data,
        BorrowConditionsExConstraints.BorrowConditionsExConstraints
      )
    } else {
      validate['validateError'] = validatejs(
        data,
        BorrowExConstraints.BorrowExConstraints
      )
    }
    validate.attrs = {}
    validate.error = false

    let validateArrayErrorR = validateArray(
      data.remuneration,
      borrowArrayConstraints.borrowArrayConstraints
    )
    let validateArrayErrorD = validateArray(
      data.document,
      borrowArrayConstraints.borrowArrayConstraints
    )
    let validateArrayErrorV = validateArray(
      data.vehicle,
      borrowArrayConstraints.borrowArrayConstraints
    )
    let validateArrayErrorA = validateArray(
      data.apartment,
      borrowArrayConstraints.borrowArrayConstraints
    )
    let validateArrayErrorF = validateArray(
      data.food,
      borrowArrayConstraints.borrowArrayConstraints
    )
    let validateArrayErrorO = validateArray(
      data.other,
      borrowArrayConstraints.borrowArrayConstraints
    )

    if (!validate['validateError']) validate['validateError'] = {}
    if (
      Object.keys(validateArrayErrorR).length !== 0 &&
      validateArrayErrorR.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorR).forEach(index => {
        if (validateArrayErrorR[index].content) {
          if (!validate['validateError']['remuneration']) {
            validate['validateError']['remuneration'] = []
            validate['validateError']['remuneration'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorR[index].total) {
          if (!validate['validateError']['remunerationT']) {
            validate['validateError']['remunerationT'] = []
            validate['validateError']['remunerationT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
        validate.attrs['remuneration' + index] = true
      })
    }
    if (
      Object.keys(validateArrayErrorD).length !== 0 &&
      validateArrayErrorD.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorD).forEach(index => {
        if (validateArrayErrorD[index].content) {
          if (!validate['validateError']['document']) {
            validate['validateError']['document'] = []
            validate['validateError']['document'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorD[index].total) {
          if (!validate['validateError']['documentT']) {
            validate['validateError']['documentT'] = []
            validate['validateError']['documentT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
      })
    }

    if (
      Object.keys(validateArrayErrorV).length !== 0 &&
      validateArrayErrorV.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorV).forEach(index => {
        if (validateArrayErrorV[index].content) {
          if (!validate['validateError']['vehicle']) {
            validate['validateError']['vehicle'] = []
            validate['validateError']['vehicle'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorV[index].total) {
          if (!validate['validateError']['vehicleT']) {
            validate['validateError']['vehicleT'] = []
            validate['validateError']['vehicleT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
      })
    }

    if (
      Object.keys(validateArrayErrorA).length !== 0 &&
      validateArrayErrorA.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorA).forEach(index => {
        if (validateArrayErrorA[index].content) {
          if (!validate['validateError']['apartment']) {
            validate['validateError']['apartment'] = []
            validate['validateError']['apartment'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorA[index].total) {
          if (!validate['validateError']['apartmentT']) {
            validate['validateError']['apartmentT'] = []
            validate['validateError']['apartmentT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
      })
    }
    if (
      Object.keys(validateArrayErrorF).length !== 0 &&
      validateArrayErrorF.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorF).forEach(index => {
        if (validateArrayErrorF[index].content) {
          if (!validate['validateError']['food']) {
            validate['validateError']['food'] = []
            validate['validateError']['food'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorF[index].total) {
          if (!validate['validateError']['foodT']) {
            validate['validateError']['foodT'] = []
            validate['validateError']['foodT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
      })
    }
    if (
      Object.keys(validateArrayErrorO).length !== 0 &&
      validateArrayErrorO.constructor === Object
    ) {
      validate.error = true
      Object.keys(validateArrayErrorO).forEach(index => {
        if (validateArrayErrorO[index].content) {
          if (!validate['validateError']['other']) {
            validate['validateError']['other'] = []
            validate['validateError']['other'].push(
              'ต้องระบุชื่อบุคคล หรือชื่อรายการกิจกรรม '
            )
          }
        }
        if (validateArrayErrorO[index].total) {
          if (!validate['validateError']['otherT']) {
            validate['validateError']['otherT'] = []
            validate['validateError']['otherT'].push(
              'กิจกรรมต้องระบุจำนวน หรือ ระบุราคาต่อหน่วย บุคคลต้องระบุจำนวนเงิน'
            )
          }
        }
      })
    }

    if (
      data.remuneration.length === 0 &&
      data.document.length === 0 &&
      data.vehicle.length === 0 &&
      data.apartment.length === 0 &&
      data.food.length === 0 &&
      data.other.length === 0
    ) {
      validate['validateError']['noBorrowMoney'] = []
      validate['validateError']['noBorrowMoney'].push(
        'ต้องระบุ รายละเอียดการยืมเงินทดรองจ่าย อย่างน้อย 1 รายการ'
      )
    }

    if (
      Object.keys(validate['validateError']).length !== 0 &&
      validate['validateError'].constructor === Object
    ) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      validate['error'] = false
      validate['validateError'] = {}
      this.setState({ validate })

      if (countNo !== undefined && countNo !== '' && checkProps) {
        checkProps = false
        data.codeBorrow = noBorrow
        if (user) {
          data.otherAcademicID = user._id || ''
          data.otherAcademic =
            (user.title || '') +
            (user.firstname || '') +
            ' ' +
            (user.lastname || '')
        }
        let sum = 0
        data['listBorrow'] = []
        data['concludeMoney'] = []
        if (data.remuneration) {
          if (data.remuneration.length !== 0) {
            data.remuneration.forEach(item => {
              if (item.type === 'person') {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  namePerson: item.namePerson,
                  sum: item.total,
                  compensation: item.total
                })
              } else {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  sum: item.total,
                  compensation: item.total
                })
              }
            })
            let index = data.listBorrow.findIndex(
              x => x.listName === 'remuneration'
            )
            if (index === -1) {
              data.listBorrow.push({
                listName: 'remuneration',
                borrow: data.remuneration[0] ? data.remuneration[0].sum : '0',
                usedMoney: data.remuneration[0] ? data.remuneration[0].sum : '0'
              })
            } else {
              data.listBorrow.push({
                listName: 'remuneration',
                borrow: data.remuneration[0].sum,
                usedMoney: data.listBorrow[index].usedMoney
              })
            }
          } else {
            data.listBorrow.push({
              listName: 'remuneration',
              borrow: '0',
              usedMoney: ''
            })
          }
        }

        if (data.document) {
          if (data.document.length !== 0) {
            data.document.forEach(item => {
              if (item.type === 'person') {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  namePerson: item.namePerson,
                  sum: item.total,
                  document: item.total
                })
              } else {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  sum: item.total,
                  document: item.total
                })
              }
            })
            let index = data.listBorrow.findIndex(
              x => x.listName === 'document'
            )
            if (index === -1) {
              data.listBorrow.push({
                listName: 'document',
                borrow: data.document[0] ? data.document[0].sum : '0',
                usedMoney: data.document[0] ? data.document[0].sum : '0'
              })
            } else {
              data.listBorrow.push({
                listName: 'document',
                borrow: data.document[0].sum,
                usedMoney: data.listBorrow[index].usedMoney
              })
            }
          } else {
            data.listBorrow.push({
              listName: 'document',
              borrow: '0',
              usedMoney: ''
            })
          }
        }

        if (data.vehicle) {
          if (data.vehicle.length !== 0) {
            data.vehicle.forEach(item => {
              if (item.type === 'person') {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  namePerson: item.namePerson,
                  sum: item.total,
                  vehicle: item.total
                })
              } else {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  sum: item.total,
                  vehicle: item.total
                })
              }
            })
            let index = data.listBorrow.findIndex(x => x.listName === 'vehicle')
            if (index === -1) {
              data.listBorrow.push({
                listName: 'vehicle',
                borrow: data.vehicle[0] ? data.vehicle[0].sum : '0',
                usedMoney: data.vehicle[0] ? data.vehicle[0].sum : '0'
              })
            } else {
              data.listBorrow.push({
                listName: 'vehicle',
                borrow: data.vehicle[0].sum,
                usedMoney: data.listBorrow[index].usedMoney
              })
            }
          } else {
            data.listBorrow.push({
              listName: 'vehicle',
              borrow: '0',
              usedMoney: ''
            })
          }
        }

        if (data.apartment) {
          if (data.apartment.length !== 0) {
            data.apartment.forEach(item => {
              if (item.type === 'person') {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  namePerson: item.namePerson,
                  sum: item.total,
                  hotel: item.total
                })
              } else {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  sum: item.total,
                  hotel: item.total
                })
              }
            })
            let index = data.listBorrow.findIndex(
              x => x.listName === 'apartment'
            )
            if (index === -1) {
              data.listBorrow.push({
                listName: 'apartment',
                borrow: data.apartment[0] ? data.apartment[0].sum : '0',
                usedMoney: data.apartment[0] ? data.apartment[0].sum : '0'
              })
            } else {
              data.listBorrow.push({
                listName: 'apartment',
                borrow: data.apartment[0].sum,
                usedMoney: data.listBorrow[index].usedMoney
              })
            }
          } else {
            data.listBorrow.push({
              listName: 'apartment',
              borrow: '0',
              usedMoney: ''
            })
          }
        }

        if (data.food) {
          if (data.food.length !== 0) {
            data.food.forEach(item => {
              if (item.type === 'person') {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  namePerson: item.namePerson,
                  sum: item.total,
                  food: item.total
                })
              } else {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  sum: item.total,
                  food: item.total
                })
              }
            })
            let index = data.listBorrow.findIndex(x => x.listName === 'food')
            if (index === -1) {
              data.listBorrow.push({
                listName: 'food',
                borrow: data.food[0] ? data.food[0].sum : '0',
                usedMoney: data.food[0] ? data.food[0].sum : '0'
              })
            } else {
              data.listBorrow.push({
                listName: 'food',
                borrow: data.food[0].sum,
                usedMoney: data.listBorrow[index].usedMoney
              })
            }
          } else {
            data.listBorrow.push({
              listName: 'food',
              borrow: '0',
              usedMoney: ''
            })
          }
        }

        if (data.other) {
          if (data.other.length !== 0) {
            data.other.forEach(item => {
              if (item.type === 'person') {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  namePerson: item.namePerson,
                  sum: item.total,
                  other: item.total
                })
              } else {
                data.concludeMoney.push({
                  type: item.type,
                  content: item.content,
                  sum: item.total,
                  other: item.total
                })
              }
            })
            let index = data.listBorrow.findIndex(x => x.listName === 'other')
            if (index === -1) {
              data.listBorrow.push({
                listName: 'other',
                borrow: data.other[0] ? data.other[0].sum : '0',
                usedMoney: data.other[0] ? data.other[0].sum : '0'
              })
            } else {
              data.listBorrow.push({
                listName: 'other',
                borrow: data.other[0].sum,
                usedMoney: data.listBorrow[index].usedMoney
              })
            }
          } else {
            data.listBorrow.push({
              listName: 'other',
              borrow: '0',
              usedMoney: ''
            })
          }
        }
        let sumTotal = 0
        data.concludeMoney.forEach(item => {
          sumTotal += parseFloat(item.sum)
        })
        data.sumConclude = sumTotal.toFixed(2).toString()
        if (data.listBorrow) {
          data.listBorrow.forEach(x => {
            let nb = x.borrow.replace(/,/g, '')
            let cv = parseFloat(nb, 10)
            sum += cv
            data['sumBorrowMoney'] = sum.toFixed(2).toString()
          })
        }
        data.timeStamp = gettime
        data.recDate = gettime
        data.borrowDateText = ThaiDate('month', data.borrowDate)
        this.props.addBorrow(data)
        if (this.props.onRefresh) this.props.onRefresh()
        if (this.props.onCloseModalTor) this.props.onCloseModalTor()
      } else {
        this.props.genId('borrow' + thisYear)
      }
    }
  }

  increaseDate = (date, number) => {
    var day = new Date(date).getDate() + number
    var month = new Date(date).getMonth()
    var year = new Date(date).getFullYear()
    let resule = new Date(year, month, day).getTime()
    return resule
  }

  handleChange = (e, { name, value, checked }) => {
    let { data, validate, tmp } = this.state
    const { typeProject } = this.props
    if (name === 'burnProject') {
      data[name] = value
      this.props.getBurnProject(value)
    } else if (name === 'academicID') {
      data[name] = value
      userOption.forEach(item => {
        if (data.academicID === item.value) {
          data.academic = item.text

          let test = ''
          if (typeProject[0]) {
            for (let i = 0; i < item.office.length; i++) {
              let index = typeProject[0].typeOffice.findIndex(
                x => x.value === item.office[i]
              )
              if (i > 0) {
                test = test + ', ' + typeProject[0].typeOffice[index].name
              } else if (i === 0) {
                test = typeProject[0].typeOffice[index].name
              } else {
                test = ''
              }
            }
          }
          data.academicWorkGroup = test
          data['rank'] = item.workname
        }
      })
      let queryListBurn = {
        sort: { nameTh: 1 },
        limit: 20,
        skip: 0,
        query: {
          academicID: value
        }
      }
      this.props.listBurnProject(queryListBurn)
    }
    if (!data.borrowDate) {
      data.borrowDate = gettime
    }
    if (name === 'transferMoney' || name === 'sendtoERP') {
      data[name] = checked
    } else if (name === 'nosendtoERP') {
      data.sendtoERP = false
    } else if (name === 'haveBurnPJ') {
      data[name] = value
      if (data[name] === 'no') {
        data['burnProject'] = 'ไม่มี'
      } else if (data[name] === 'yes') {
        data['burnProject'] = ''
      }
    } else if (name === 'budget') {
      if (data.haveBurnPJ === 'no') {
        data['yearBudget'] = value.value.year
        data['groupsPlan'] = value.value.group
        data['plan'] = value.value.plan
        data['activity'] = value.value.activity
        data['valueGroup'] = value.value.valueGroup
        data['valuePlan'] = value.value.valuePlan
        data['valueActivity'] = value.value.valueActivity
        if (data.activity) {
          data.codeBudget =
            (parseInt(data.yearBudget) - 2500).toString() + data.valueActivity
        } else {
          data.codeBudget = undefined
        }
      }
    } else if (name === 'typeOfBorrow') {
      data[name] = value
      if (value === 'supplies') {
        if (data.startDate)
          data['repayDate'] = this.increaseDate(data.startDate, 7)
      } else {
        if (data.endDate)
          data['repayDate'] = this.increaseDate(data.endDate, 15)
      }
    } else if (name === 'noAccount') {
      var regx = /^[0-9]*$/
      const check = value.match(regx)
      if (check !== null) {
        data[name] = value
      } else if (value.length === 0) {
        data[name] = value
      }
    } else {
      data[name] = value
    }

    validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    this.setState({ data, validate, tmp })
  }

  handleNextProps = activeIndex => {
    let { tableRerender } = this.state

    if (activeIndex === 0) {
      this.setState({ activeIndex: 0 })
    } else if (tableRerender) {
      this.setState({ activeIndex: activeIndex - 1, tableRerender: false })
    }
  }

  onWithdrawNumberChange = e => {
    const { data, validate } = this.state

    let money = e.target.rawValue.replace('-', '')
    data[e.target.name] = money ? money.toString() : '0'
    // data[e.target.name] = parseInt(e.target.rawValue, 10)

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
    this.setState({ data, validate })
  }

  openModalOrg = () => {
    this.setState({ openModalOrg: true })
  }

  closeModalOrg = () => {
    this.setState({ openModalOrg: false })
  }

  openModalPerson = () => {
    this.setState({ openModalPerson: true })
  }

  closeModalPerson = () => {
    this.setState({ openModalPerson: false })
  }

  handleSearch = (e, { searchQuery }) => {
    let { auth } = this.props
    let { data } = this.state
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    let queryListBurn = {
      sort: { nameTh: 1 },
      limit: 20,
      skip: 0,
      query: {
        codeProject: { $regex: searchQuery },
        academicID: data.academicID || user._id
      }
    }
    if (searchQuery.length >= 2) {
      this.props.listBurnProject(queryListBurn)
    }
  }

  render () {
    const { validate, data, openModalPerson, openModalOrg } = this.state
    const { scholar, auth, burnprojects, burn, listUserOffice } = this.props

    userOption =
      listUserOffice.length > 0
        ? listUserOffice.map((item, idx) => ({
            key: idx,
            text:
              item.title === 'อื่นๆ'
                ? item.otherTitle + item.firstname + ' ' + item.lastname
                : item.title + ' ' + item.firstname + ' ' + item.lastname,
            value: item._id,
            office: item.typeInstitution,
            workname: item.workGroup
          }))
        : []

    let AID =
      listUserOffice.length > 0
        ? listUserOffice.findIndex(r => r._id === data.academicID)
        : -1

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    checktypeInstitution = false
    if (AID >= 0) {
      listUserOffice[AID].typeInstitution.forEach(itm => {
        if (itm === '01') {
          checktypeInstitution = true
        }
      })
    } else {
      checktypeInstitution = false
    }

    if (!checktypeInstitution) {
      data.haveBurnPJ = 'yes'
    }

    const typeForRefundDate = [
      {
        key: '0',
        text: 'เพื่อเป็นค่าใช้จ่ายในการเดินทางไปปฏิบัติงาน',
        value: 'travel'
      },
      {
        key: '1',
        text:
          'เพื่อเป็นค่าใช้จ่ายในการปฏิบัติงานเกี่ยวกันงาน โครงการ แยกงาน กิจกรรม หรือการพัฒนานวัฒกรรม',
        value: 'operate'
      },
      {
        key: '2',
        text: 'เพื่อจัดซื้อหรือจัดจ้างพัสดุที่จำเป็นต้องชำระเป็นเงินสด',
        value: 'supplies'
      },
      {
        key: '3',
        text: 'เพื่อเป็นค่าใช้จ่ายอื่นตามภาระหน้าที่ในการปฏิบัติงาน',
        value: 'other'
      }
    ]

    let startT = []
    let endT = []
    let h = 0
    let m = 0
    let hour = ''
    let minute = ''
    for (let i = 0; i < 48; i++) {
      if (i !== 0) {
        m += 30
        if (m === 60) {
          m = 0
          h += 1
        }
      }
      if (h < 10) {
        hour = '0' + h.toString()
      } else {
        hour = h.toString()
      }
      if (m === 0) {
        minute = '0' + m.toString()
      } else {
        minute = m.toString()
      }
      startT.push({
        key: i + 1,
        text: hour + '.' + minute + ' น.',
        value: hour + '.' + minute
      })
      endT.push({
        key: i + 1,
        text: hour + '.' + minute + ' น.',
        value: hour + '.' + minute
      })
    }

    let burnProjectOptions = []

    burnProjectOptions = burnprojects
      ? burnprojects.map((item, idx) => ({
          key: idx,
          text: item.codeProject,
          value: item._id
        }))
      : []

    let content = {
      objective: { control: TextArea },
      typeRecive: {
        control: Select,
        options: WithdrawOptions,
        width: 8,
        search: false
      },
      budget: {
        control: FundingFields,
        width: 16,
        delBudget: false,
        disabled: data.haveBurnPJ === 'yes'
      },
      nameAccount: { control: Input },
      namebank: { control: Input },
      branchBank: { control: Input },
      noAccount: { control: Input, maxLength: 15 },
      namePayer: { control: Input },
      codeDisburse: { control: Input, width: 8 },
      place: { control: Input, width: 6 },
      burnProject: {
        control: Select,
        options: burnProjectOptions,
        search: true,
        width: 6,
        disabled: data.haveBurnPJ === 'no'
      },
      startTime: { control: Select, options: startT, width: 4 },
      endTime: { control: Select, options: endT, width: 4 },
      transferMoney: { control: Checkbox },
      typeOfBorrow: { control: Select, width: 8, options: typeForRefundDate },
      haveBurnPJ: { control: Select, width: 4, options: nohaveBurn },
      academicID: {
        control: Select,
        options: userOption,
        width: 6,
        search: true
      },
      sendtoERP: { control: Checkbox }
    }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('Borrow', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].name === 'manager')
        content[key].onChangeManager = this.handleChangeManager
      if (content[key].name === 'burnProject') {
        content[key].onSearchChange = this.handleSearch
        content[key].value = data[key] || ''
      }
      if (content[key].control === Input) {
        if (content[key].money === 'money') {
          content[key].value = this.format(data[key] || '') || ''
        } else content[key].value = data[key] || ''
      } else if (content[key].control === Checkbox) {
        content[key].checked = data[key] || false
      } else if (content[key].control === FundingFields) {
        if (data.haveBurnPJ === 'yes') {
          content[key].value = {
            year: data.burnProject
              ? burn && burn['yearBudget']
              : data['yearBudget'],
            group: data.burnProject
              ? burn && burn['groupsPlan']
              : data['groupsPlan'],
            plan: data.burnProject ? burn && burn['plan'] : data['plan'],
            activity: data.burnProject
              ? burn && burn['activity']
              : data['activity'],
            valueGroup: data.burnProject
              ? burn && burn['valueGroup']
              : data['valueGroup'],
            valuePlan: data.burnProject
              ? burn && burn['valuePlan']
              : data['valuePlan'],
            valueActivity: data.burnProject
              ? burn && burn['valueActivity']
              : data['valueActivity'],
            time: gettime
          }
        } else if (data.haveBurnPJ === 'no') {
          content[key].value = {
            year: data['yearBudget'] || '',
            group: data['groupsPlan'] || '',
            plan: data['plan'] || '',
            activity: data['activity'] || '',
            valueGroup: data['valueGroup'] || '',
            valuePlan: data['valuePlan'] || '',
            valueActivity: data['valueActivity'] || '',
            time: gettime
          }
        }
      } else {
        content[key].value = data[key] || ''
      }
    })

    const panes = [
      {
        menuItem: 'ค่าตอบแทน',
        render: () => (
          <ComponentTable
            OnNextProps={this.handleNextProps}
            nameArray='remuneration'
            data={data.remuneration}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            validate={validate}
            onChangeName={this.handleChangeNameRow}
          />
        )
      },
      {
        menuItem: 'จัดส่งเอกสาร',
        render: () => (
          <ComponentTable
            OnNextProps={this.handleNextProps}
            nameArray='document'
            data={data.document}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            onChangeName={this.handleChangeNameRow}
            validate={validate}
          />
        )
      },
      {
        menuItem: 'พาหนะ',
        render: () => (
          <ComponentTable
            OnNextProps={this.handleNextProps}
            nameArray='vehicle'
            data={data.vehicle}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            onChangeName={this.handleChangeNameRow}
            validate={validate}
          />
        )
      },
      {
        menuItem: 'ที่พัก',
        render: () => (
          <ComponentTable
            OnNextProps={this.handleNextProps}
            nameArray='apartment'
            data={data.apartment}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            onChangeName={this.handleChangeNameRow}
            validate={validate}
          />
        )
      },
      {
        menuItem: 'อาหารและเครื่องดืม',
        render: () => (
          <ComponentTable
            OnNextProps={this.handleNextProps}
            nameArray='food'
            data={data.food}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            onChangeName={this.handleChangeNameRow}
            validate={validate}
          />
        )
      },
      {
        menuItem: 'ใช้จ่ายอื่นๆ',
        render: () => (
          <ComponentTable
            OnNextProps={this.handleNextProps}
            nameArray='other'
            data={data.other}
            onAddRow={this.handleAddRow}
            onChange={this.handleChangeRow}
            onDelete={this.handleDeleteRow}
            openPerson={this.openModalPerson}
            openOrg={this.openModalOrg}
            onChangeName={this.handleChangeNameRow}
            validate={validate}
          />
        )
      }
    ]

    return (
      <Fragment>
        <Form error={validate.error}>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='ยืมเงินทดรองจ่าย'
              as='h3'
              icon='write'
            />
            <Form.Group>
              <Form.Field width={3}>
                <label>{'วันที่ใช้เงิน'}</label>
                <DayPicker
                  ser={'startDate'}
                  name={'startDate'}
                  max={data.endDate || null}
                  onDayChange={this.handleDayChange}
                  data={data.startDate || ''}
                />
              </Form.Field>
              <Form.Field width={3}>
                <label>{'ถึงวันที่'}</label>
                <DayPicker
                  ser={'endDate'}
                  name={'endDate'}
                  min={data.startDate || null}
                  onDayChange={this.handleDayChange}
                  data={data.endDate || ''}
                />
              </Form.Field>
              {this._renderFormField(content.startTime)}
              {this._renderFormField(content.endTime)}
            </Form.Group>
            <Form.Group>
              {/* {this._renderFormField(content.codeDisburse)} */}
            </Form.Group>
            {this._renderFormField(content.academicID)}
            <Form.Group>
              {this._renderFormField(content.place)}
              {checktypeInstitution &&
                this._renderFormField(content.haveBurnPJ)}
              {this._renderFormField(content.burnProject)}
            </Form.Group>
            <Form.Group>{this._renderFormField(content.budget)}</Form.Group>
            <Header
              style={{ fontFamily: 'supermarket' }}
              as='h3'
              content={
                'รหัสงบประมาณ : ' +
                (data.codeBudget || (burn && burn.codeBudget) || '')
              }
            />
            {this._renderFormField(content.typeOfBorrow)}
            {this._renderFormField(content.objective)}
          </Segment>
          <Form.Group>
            <Form.Field>
              <label>{'วันที่ยืมเงิน *'}</label>
              <DayPicker
                ser={'borrowDate'}
                name={'borrowDate'}
                onDayChange={this.handleDayChange}
                data={data.borrowDate || ''}
              />
            </Form.Field>
          </Form.Group>
          <Header
            style={{ fontFamily: 'supermarket' }}
            content='รายละเอียดการยืมเงินทดรองจ่าย'
            icon='list'
            as='h4'
          />
          <Tab
            panes={panes}
            // activeIndex={this.state.activeIndex}
            onTabChange={(e, { activeIndex }) => {
              this.setState({
                activeIndex
              })
            }}
          />
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='รูปแบบการเบิกจ่ายเงิน'
              icon='money'
              as='h4'
            />
            {this._renderFormField(content.transferMoney)}
            {data.transferMoney && (
              <Fragment>
                <Form.Group widths='equal'>
                  <Form.Input
                    error={validate.attrs['nameAccount']}
                    label='ชื่อบัญชีผู้ยืม'
                    name='nameAccount'
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    error={validate.attrs['bank']}
                    control={Select}
                    label='ธนาคาร'
                    options={bankOptions}
                    name='bank'
                    onChange={this.handleChange}
                    selection
                    search={true}
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Input
                    error={validate.attrs['branch']}
                    label='สาขา'
                    name='branch'
                    onChange={this.handleChange}
                  />
                  {this._renderFormField(content.noAccount)}
                </Form.Group>
              </Fragment>
            )}
          </Segment>
          {/* <Form.Group>
            <Form.Checkbox
              checked={data.sendtoERP === true}
              onChange={this.handleChange}
              label='ส่งข้อมูลไปยังระบบบัญชี *'
              error={validate.attrs['sendtoERP']}
              name='sendtoERP'
            />
            <Form.Checkbox
              checked={data.sendtoERP === false}
              onChange={this.handleChange}
              label='ยังไม่ส่งข้อมูลไปยังระบบบัญชี *'
              error={validate.attrs['sendtoERP']}
              name='nosendtoERP'
            />
          </Form.Group> */}
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('Borrow', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Button
            style={{ fontFamily: 'supermarket' }}
            color='blue'
            content='บันทึก'
            icon='save'
            loading={scholar.isSaving}
            onClick={this.handleSubmit}
          />
        </Form>
        <Modal
          open={openModalPerson}
          closeOnDimmerClick={false}
          onClose={this.closeModalPerson}
          closeIcon
        >
          {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
          <Modal.Content>
            <ComponentPerson
              openModalPerson={openModalPerson}
              onCloseModalPerson={this.closeModalPerson}
            />
          </Modal.Content>
        </Modal>

        <Modal
          open={openModalOrg}
          closeOnDimmerClick={false}
          onClose={this.closeModalOrg}
          closeIcon
        >
          {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
          <Modal.Content>
            <ComponentOrganization
              openModalOrganization={openModalOrg}
              onCloseModalPerson={this.closeModalOrg}
            />
          </Modal.Content>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  scholar: state.scholar,
  auth: state.auth,
  counter: state.counter,
  role: state.role,
  burnprojects: state.burnprojects.data,
  burn: state.burnprojects.currentData,
  userData: state.user.currentData,
  typeProject: state.typeproject.data,
  listUserOffice: state.user.data
})

const mapDispatchToProps = dispatch => ({
  addBorrow: data => dispatch(scholarActions.save(data)),
  genId: name => dispatch(generate(name)),
  listPersonByOrganization: query =>
    dispatch(individualsActions.fetchList({ query: query })),
  listBurnProject: query => dispatch(burnProjectAction.fetchList({ ...query })),
  getBurnProject: id => dispatch(burnProjectAction.fetchOne(id)),
  getUserData: id => dispatch(userAction.fetchOne(id)),
  getListOffice: () =>
    dispatch(typeProject.fetchList({ query: { name: 'สังกัดสำนัก' } })),
  listUser: query =>
    dispatch(
      userActions.fetchList({
        query: query,
        projection: {
          title: 1,
          firstname: 1,
          lastname: 1,
          typeInstitution: 1,
          workGroup: 1
        }
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBorrowEx)

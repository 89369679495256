import React, { Component, Fragment } from 'react'
import {
  Table,
  Button,
  Modal,
  Header,
  Icon,
  Confirm,
  Form
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { actions as departmentActions } from 'Redux/reducers/department'
import { ComponentDepartment } from 'Utils'

class ListDepartment extends Component {
  constructor (props) {
    super(props)
    this.state = {
      query: {
        sort: { approve: 1 },
        limit: 10,
        skip: 0,
        query: {}
      },
      openModalOrganizations: false,
      confirmDelOrg: false,
      idOrg: null
    }
  }

  componentDidMount = () => {
    let query = {
      sort: { approve: 1 },
      limit: 10,
      skip: 0,
      query: {}
    }
    this.props.listOrganization(query)
    this.setState({ query })
    window.scrollTo(0, 0)
  }

  onSearch = (e, { value }) => {
    let query = {}
    if (value.length >= 3) {
      query = {
        sort: { approve: 1 },
        limit: 10,
        skip: 0,
        query: { organizationName: { $regex: value } }
      }
      this.props.listOrganization(query)
    } else if (value.length === 0) {
      query = {
        sort: { approve: 1 },
        limit: 10,
        skip: 0,
        query: {}
      }
      this.props.listOrganization(query)
    }
    this.setState({ query })
  }

  paging = skip => {
    let { query } = this.state
    query.skip = query.skip + skip
    this.props.listOrganization(query)
    this.setState({ query })
  }

  handleEditOrganization = idx => {
    let { organizations } = this.props
    // if (organizations) {
    //   this.props.getOrganization(organizations.data[idx]._id)
    // }
    this.setState({
      openModalOrganizations: true,
      checkOrganization: true,
      organizationsID: organizations.data[idx]._id
    })
  }

  handleListIndividual = idx => {
    let { organizations } = this.props
    this.props.history.push(
      '/admin/listindividuals/' + organizations.data[idx]._id
    )
  }

  handleListIndividualFreelance = () => {
    this.props.history.push('/admin/listindividuals/Freelance')
  }

  handleListIndividualHaveOrg = () => {
    this.props.history.push('/admin/listindividuals/HaveOrg')
  }

  handleAddOrganizations = () => {
    this.setState({ openModalOrganizations: true, checkOrganization: false })
  }

  close = () => {
    this.setState({
      openModalOrganizations: false,
      checkOrganization: false,
      confirmDelOrg: false,
      organizationsID: ''
    })
    let query = {
      sort: { approve: 1 },
      limit: 10,
      skip: 0,
      query: {}
    }
    this.props.listOrganization(query)
  }

  handleDeleteOrg = () => {
    const { idOrg } = this.state
    this.props.delOrganization(idOrg)
    this.setState({ confirmDelOrg: false })
  }

  handleConfirmDeleteOrg = item => {
    this.setState({ confirmDelOrg: true, idOrg: item })
  }

  render () {
    let {
      openModalOrganizations,
      checkOrganization,
      organizationsID
    } = this.state
    const { organizations } = this.props

    return (
      <Fragment>
        <Header as='h2'>
          <Icon name='university' />
          <Header.Content style={{ fontFamily: 'supermarket' }}>
            รายชื่อหน่วยงานที่บรรจุเข้าฐานข้อมูล กศส.
          </Header.Content>
        </Header>
        <Modal
          open={openModalOrganizations}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon
        >
          <Modal.Header style={{ fontFamily: 'supermarket' }}>
            ข้อมูล หน่วยงาน
          </Modal.Header>
          <Modal.Content>
            <ComponentDepartment
              checkOrganization={checkOrganization}
              onCloseModalPerson={this.close}
              lagalDepartment
              organizationsID={organizationsID}
            />
          </Modal.Content>
        </Modal>
          <Form.Input
            onChange={this.onSearch}
            icon='search'
            placeholder={'ค้นหาชื่อหน่วยงาน...'}
            width={8}
          />
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='center' width={1} content='สถานะ' />
              <Table.HeaderCell
                textAlign='center'
                width={4}
                content='ชื่อหน่วยงาน'
              />
              <Table.HeaderCell
                textAlign='center'
                width={3}
                content='ประเภทหน่วยงาน'
              />
              <Table.HeaderCell
                textAlign='center'
                width={7}
                content='ที่อยู่'
              />
              {/* <Table.HeaderCell textAlign='center' width={1} content='ลบ' /> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {organizations.data.map((item, idx) => (
              <Table.Row key={idx}>
                {!item.readed && (
                  <Table.Cell textAlign='center'>
                    <Icon name='warning circle' color='red' size='large' />
                  </Table.Cell>
                )}
                {item.readed === true && !item.approve && (
                  <Table.Cell textAlign='center' content='อ่านแล้ว'/>
                )}
                {item.readed === true && item.approve === true && (
                  <Table.Cell textAlign='center' content='อนุมัติแล้ว'/>
                )}
                <Table.Cell
                  onClick={this.handleEditOrganization.bind(this, idx)}
                >
                  {item.organizationName}
                </Table.Cell>
                <Table.Cell
                  onClick={this.handleEditOrganization.bind(this, idx)}
                >
                  {((item.governmentAgencies === true &&
                    'เป็นหน่วยงานภาครัฐ - ') ||
                    'เป็นหน่วยงานนอกภาครัฐ - ') +
                    ((item.legalEntity === true && 'เป็นนิติบุคคล') ||
                      'ไม่เป็นนิติบุคคล')}
                </Table.Cell>
                <Table.Cell
                  onClick={this.handleEditOrganization.bind(this, idx)}
                >
                  {item.organizationAdress &&
                    (item.organizationAdressNo || '') +
                      (item.organizationAdress.province === '' ? '' : ' จ. ') +
                      item.organizationAdress.province +
                      (item.organizationAdress.city === '' ? '' : ' อ. ') +
                      item.organizationAdress.city +
                      (item.organizationAdress.tumbon === '' ? '' : ' ต. ') +
                      item.organizationAdress.tumbon +
                      (item.organizationAdress.zipcode === '' || null
                        ? ''
                        : ' ' + item.organizationAdress.zipcode)}
                </Table.Cell>
                {/* <Table.Cell textAlign='center'><Button icon='check' color='green' onClick={this.handleEditOrganization.bind(this, idx)}></Button></Table.Cell> */}
                {/* <Table.Cell textAlign='center'>
                  <Button
                    icon='close'
                    color='red'
                    onClick={this.handleConfirmDeleteOrg.bind(this, item._id)}
                  />
                </Table.Cell> */}
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>
                <Button
                  color='green'
                  content='เพิ่มหน่วยงาน'
                  onClick={this.handleAddOrganizations}
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='3' textAlign='right'>
                {organizations.params && organizations.params.skip !== 0 && (
                  <Button
                    content='ย้อนกลับ'
                    onClick={this.paging.bind(null, -10)}
                  />
                )}
                {organizations.data &&
                  organizations.data.length >= 10 &&
                  organizations.data.length !== 0 && (
                  <Button
                    content='ถัดไป'
                    onClick={this.paging.bind(null, +10)}
                  />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Confirm
          content='ต้องการลบใช่หรือไม่'
          open={this.state.confirmDelOrg}
          onCancel={this.close}
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onConfirm={this.handleDeleteOrg}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  organizations: state.department,
  role: state.role
})

const mapDispatchToProps = dispatch => ({
  listOrganization: query => dispatch(departmentActions.fetchList(query)),
  getOrganization: id => dispatch(departmentActions.fetchOne(id)),
  delOrganization: id => dispatch(departmentActions.delete(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListDepartment)

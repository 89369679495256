import React, { Component, Fragment } from 'react';
import { Icon, Table } from 'semantic-ui-react';
// import PdfBorrowMoney from "./PdfTapBorrow/PdfBorrowMoney"
// import PdfReportCostEstimate from "./PdfTapBorrow/PdfReportCostEstimate"
// import PdfReportCostTotal from "./PdfTapBorrow/PdfReportCostTotal"
import { connect } from 'react-redux'
import { actions as burnProjectAction } from 'Redux/reducers/burnprojects'

class PrintoutTapBorrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }



  _onDownload = () => {
    const { typeFile, receipt, doc } = this.props
    if (typeFile === 19) {
      let url = 'https://wspro.eef.or.th/PMSServicesAPI/PageLoad.aspx?docNo=' + typeFile + '&id=' + receipt._id + '&db=dev'
      window.open(url)
    } else {
      let url = 'https://wspro.eef.or.th/PMSServicesAPI/PageLoad.aspx?docNo='+typeFile+'&id='+ doc._id + '&db=product'
      // // let url = 'http://wspro.eef.or.th/PMSServicesAPI/PageLoad.aspx?docNo='+typeFile+'&id='+match.params.id+'&db=dev'
      window.open(url)
      // if (typeFile === 12 || typeFile === 13) {
      //   if (doc.haveBurnPJ === 'yes') {
      //     this.props.listBurnProject({query:{_id:doc.burnProject}}).then(res => {
      //       if (res.length!==0&&res.ok === false && res.message === 'Not Found') { PdfBorrowMoney(typeFile, doc, null) }
      //       else {
      //         PdfBorrowMoney(typeFile, doc, res[0])
      //       }
      //     })
      //   }
      //   else {
      //     PdfBorrowMoney(typeFile, doc, null)
      //   }
      // }
      // if (typeFile === 17) {
      //   PdfReportCostEstimate(typeFile, doc)
      // }
      // if (typeFile === 18) {
      //   PdfReportCostTotal(typeFile, doc)
      // }
    }
  }


  render() {
    const { name } = this.props

    let content = <Fragment></Fragment>
    content = (
      <Table.Row >
        <Table.Cell content={name} />
        {/* {checkDownload &&  */}
        <Table.Cell textAlign='center' content={<Icon name='download' link onClick={this._onDownload} />} />
        {/* } */}
      </Table.Row>
    )

    return (
      <Fragment>{content}</Fragment>
    )
  }
}
const mapStateToProps = state => ({
  burnprojects: state.burnprojects.data,
});

const mapDispatchToProps = dispatch => ({
  listBurnProject: query => dispatch(burnProjectAction.fetchList({ ...query })),
  getBurnProject: id => dispatch(burnProjectAction.fetchOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(PrintoutTapBorrow)


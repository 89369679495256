import React, { Component } from 'react';
import { Segment, Progress } from 'semantic-ui-react'
import './Level.css'
import LevelCard from './LevelCard'
import { connect } from 'react-redux'

const ProposalFiles = [
  {
    id: 1,
    text: 'ส่งข้อเสนอโครงการ',
    files: [
      // { text: 'เอกสารแบบข้อเสนอโครงการ', link: '#' },
    ]
  },
  {
    id: 2,
    text: 'กลั่นกรอง',
    files: [
      // { text: 'ใบปะหน้า', link: '#' },
      // { text: 'เอกสารผู้ทรงคุณวุฒิที่ 1', link: '#' },
      // { text: 'เอกสารผู้ทรงคุณวุฒิที่ 2', link: '#' },
      // { text: 'เอกสารผู้ทรงคุณวุฒิที่ 3', link: '#' },
      // { text: 'เอกสารผู้ทรงคุณวุฒิที่ 4', link: '#' },
      // { text: 'เอกสารผู้ทรงคุณวุฒิที่ 5', link: '#' },
      // { text: 'เอกสารผู้ทรงคุณวุฒิที่ 6', link: '#' },
      // { text: 'เอกสารผู้ทรงคุณวุฒิที่ 7', link: '#' },
      // { text: 'สรุปความเห็นผู้ทรางคุณวุฒิ', link: '#' }
    ]
  },
  {
    id: 3,
    text: 'ทำสัญญาโครงการ',
    files: [
      // { text: 'หนังสือขอเปิดบัญชี', link: '#' },
      // { text: 'เอกสารข้อตกลง(กสศ)', link: '#' },
      // { text: 'เอกสารแนบท้าย(สสค)', link: '#' },
      // { text: 'กำหนดนำส่งและเงื่อนไขเบิกจ่าย', link: '#' },
      // { text: 'หนังสือขอเปิดบัญชี', link: '#' },
    ]
  },
  {
    id: 4,
    text: 'ประเมินความก้าวหน้าโครงการ',
    files: [
      // { text: 'แบบสรุปประกอบการอนุมัติ', link: '#' },
      // { text: 'แบบขอเบิกเงินงวด 1', link: '#' },
      // { text: 'แบบขอเบิกเงินงวด 2-3', link: '#' },
      // { text: 'แบบขอเบิกเงินงวดสุดท้าย', link: '#' },
      // { text: 'แบบรายงานความก้าวหน้างวด 3', link: '#' }
    ]
  },
  {
    id: 5,
    text: 'ปิดโครงการ',
    files: [
      // { text: 'แบบรายงานสรุปขอปิดโครงการ', link: '#' }
    ]
  },
  {
    id: 6,
    text: 'ยุติโครงการ',
    files: [
      // { text: 'แบบขออนุมัติยุติโครงการ', link: '#' }
    ]
  }
]

class Level extends Component {
  state = {}

  _nameToString = (data) => {
    if (!data) return '-'
    return (data.proposerTitle || '') + ' '
      + (data.proposerFName || '') + ' '
      + (data.proposerLName || ' ')
  }

  _titleToString = (data) => {
    if (!data) return '-'
    return (data.nameTh || '') + ' งบประมาณ ' + (data.yearBudget || '-')
  }

  _managerToString = (data) => {
    if (!data) return '-'
    return (data.managerName || '-')
  }

  _periodToString = (data) => {
    if (!data) return '-'
    return (data.period || '-') + ' เดือน'
  }

  _getList = () => {
    const { currentProposal, currentTor, type } = this.props
    if (!currentProposal && !currentTor) return []
    let result = []

    // tor
    if (currentTor && currentTor.attachTOR && type === 'tor') {
      result.push({
        id: 1,
        title: currentTor.status,
        items: [
          { text: 'เลขที่ข้อกำหนด', value: (currentTor.torCode || '-') },
        ]
      })
      currentTor.attachTOR.forEach(x => {
        // result[0].items.push({ text: x.typeOfAttach, link: '#' })
      });
    }

    // proposal
    let lastIndex = 0
    if (currentProposal && type !== 'tor') for (let data of ProposalFiles) {
      result.push({
        id: data.id,
        title: data.text,
        items: [
          ...[
            { text: 'เลขที่โครงการ', value: (currentProposal.codeProject || '-') },
          ],
        ]
      })

      // if (currentTor && currentTor.attachTOR && data.text === 'ส่งข้อเสนอโครงการ') {
      //   let tor = currentTor.attachTOR.filter(x => x.typeOfAttach === 'TOR')
      //   let topic = result.filter(x => x.title === data.text)
      //   tor.forEach(x => {
      //     topic[0].items.push({ text: x.typeOfAttach, fileName: x.fileName, link: '#' })
      //   });
      // }

      // if (currentProposal && currentProposal.attachSendProposal && data.text === 'ส่งข้อเสนอโครงการ') {
      //   let topic = result.filter(x => x.title === data.text)
      //   currentProposal.attachSendProposal.forEach(x => {
      //     topic[0].items.push({ text: x.typeOfAttach, fileName: x.fileName, link: '#' })
      //   });
      // }

      // if (currentProposal && currentProposal.attachSendProposal && data.text === 'กลั่นกรอง') {
      //   let topic = result.filter(x => x.title === data.text)
      //   currentProposal.attachSendProposal.forEach(x => {
      //     topic[0].items.push({ text: x.typeOfAttach, fileName: x.fileName, link: '#' })
      //   });
      // }

      // if (currentProposal && currentProposal.attachPromise && data.text === 'ทำสัญญาโครงการ') {
      //   let topic = result.filter(x => x.title === data.text)
      //   currentProposal.attachPromise.forEach(x => {
      //     topic[0].items.push({ text: x.typeOfAttach, fileName: x.fileName, link: '#' })
      //   });
      // }

      // if (currentProposal && currentProposal.attachProgress && data.text === 'ประเมินความก้าวหน้าโครงการ') {
      //   let topic = result.filter(x => x.title === data.text)
      //   currentProposal.attachProgress.forEach(x => {
      //     topic[0].items.push({ text: x.typeOfAttach, fileName: x.fileName, link: '#' })
      //   });
      // }

      // if (currentProposal && currentProposal.attachCloseProject && data.text === 'ปิดโครงการ') {
      //   let topic = result.filter(x => x.title === data.text)
      //   currentProposal.attachCloseProject.forEach(x => {
      //     topic[0].items.push({ text: x.typeOfAttach, fileName: x.fileName, link: '#' })
      //   });
      // }

      // if (currentProposal && currentProposal.attachCloseProject && data.text === 'ยุติโครงการ') {
      //   let topic = result.filter(x => x.title === data.text)
      //   currentProposal.attachCloseProject.forEach(x => {
      //     topic[0].items.push({ text: x.typeOfAttach, fileName: x.fileName, link: '#' })
      //   });
      // }

      if (currentProposal.status === data.text) {
        lastIndex = data.id
        break
      }
    }

    result.sort(function (a, b) { return b.id - a.id })

    if (result[0] && result[0].title === 'ยุติโครงการ') {
      result.splice(1, 1)
      result[0].id--
    }

    if (result.length > 0) {
      if (result[0].title !== 'ยุติโครงการ' && result[0].title !== 'ปิดโครงการ') {
        result[0].title += ' (กำลังดำเนินการ)'
      }
    }

    if (currentProposal && type !== 'tor') for (let data of ProposalFiles) {
      if (data.id > lastIndex && data.id !== 6) {
        result.unshift({
          id: data.id,
          title: data.text,
          unfinish: true,
          items: [
            ...[
              { text: 'เลขที่โครงการ', value: (currentProposal.codeProject || '-') },
            ],
          ]
        })
      }
    }

    let indexResult = result.findIndex(x => x.title === "ประเมินความก้าวหน้าโครงการ (กำลังดำเนินการ)");
    if (result[indexResult] && currentProposal.withdrawForm) {
      for (let i = 1; i <= currentProposal.withdrawForm.length; i++) {
        if (currentProposal.withdrawForm[i - 1].noDisburse) {
          result[indexResult].items.push({ text: 'เบิกเงินงวดที่' + i, value: 'บันทึกข้อมูลแล้ว' })
        }
      }
    }

    let indexResult2 = result.findIndex(x => x.title === "ประเมินความก้าวหน้าโครงการ");
    if (result[indexResult2] && currentProposal.withdrawForm) {
      for (let i = 1; i <= currentProposal.withdrawForm.length; i++) {
        if (currentProposal.withdrawForm[i - 1].noDisburse) {
          result[indexResult2].items.push({ text: 'เบิกเงินงวดที่' + i, value: 'บันทึกข้อมูลแล้ว' })
        }
      }
    }

    // if(currentProposal){
    //   if(!currentProposal.withDrawTime)currentProposal.withDrawTime=[]
    //   let indexResult = result.findIndex(x => x.id===5);
    //   let withDrawTimeLength = currentProposal.withDrawTime.length
    //   result[indexResult].id = 5 + withDrawTimeLength
    //   for(let i =1;i<=withDrawTimeLength;i++){
    //     if(currentProposal.withdrawForm[i-1].noDisburse){
    //       result.push(
    //         {
    //         id:4+i,
    //         title:'เบิกเงินงวดที่ '+i,         
    //         // unfinish: true,
    //         items: [
    //           ...[
    //             { text: 'เลขที่โครงการ', value: (currentProposal.codeProject || '-') },
    //           ],
    //         ]}
    //         )
    //     }else{
    //       result.push(
    //         {
    //         id:4+i,
    //         title:'เบิกเงินงวดที่ '+i,         
    //         unfinish: true,
    //         items: [
    //           ...[
    //             { text: 'เลขที่โครงการ', value: (currentProposal.codeProject || '-') },
    //           ],
    //         ]}
    //         )
    //     }

    //   }
    //   result.sort(function (a, b) { return b.id - a.id })
    // }

    return result
  }

  render() {
    const { currentProposal } = this.props
    const list = this._getList()
    const lastUpdate = currentProposal && currentProposal.timestamp && new Date(currentProposal.timestamp)
    let dateStr = '-'
    if (lastUpdate) {
      dateStr = lastUpdate.getDate() + '-'
      dateStr += (lastUpdate.getMonth() + 1) + '-'
      dateStr += lastUpdate.getFullYear()
    }


    let progress = list.filter(x => x.unfinish === true)
    let progressResult = (list.length - progress.length) === list.length ? list.length : (list.length - progress.length - 1)

    return (
      <Segment className="level-container">
        <div className="level-container-title first">{progressResult} จาก {list.length} ดำเนินการเสร็จสมบูรณ์</div>
        <Progress percent={(progressResult / list.length) * 100} color='orange' size="small" />
        <div className="level-container-title last">แก้ไขล่าสุดเมื่อ : {dateStr}</div>
        <div className="level-container-content">
          {list.map((d, i) => (<LevelCard key={i} data={d} unfinish={d.unfinish} currentProposal={currentProposal} />))}
        </div>
      </Segment>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  currentTor: state.tors.currentData,
  manager: state.manager
})

export default connect(mapStateToProps)(Level)


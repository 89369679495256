import { CrudReducerCreator, CrudActionCreator} from '../crud';

export const actions = new CrudActionCreator('/db/pm/tor','TOR',{
  json:true,
  headers: {
   'Content-Type': 'application/json'
  }
})

export default CrudReducerCreator(actions,'CRUD','RESET_DATA', true);

import validate from 'validate.js'

const validateArray = (arrayItems, itemConstraints) => {
  const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
    const error = validate(item, itemConstraints)
    if (error) errors[index] = error
    return errors
  }, {})
  return arrayItemErrors
}

export default validateArray
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Form, Header } from 'semantic-ui-react';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more'
import { actions as projectActions } from 'Redux/reducers/projects';
import { actions as allProjectActions } from 'Redux/reducers/allprojects';

HighchartsMore(Highcharts)

class GraphTargetGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: '2561'
    };
  }

  componentDidMount = () => {
    this.props.listProposal(this.state.year)
    this.props.listAllProposal()
  }

  format(input) {
    if (!input && input === 0) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1);
    }
  }

  handleChange = (e, { value }) => {
    this.props.listProposal(value)
    this.setState({ year: value })
  }

  render() {
    const { projects } = this.props
    let data = [
      { name: 'เด็กและเยาวชนด้อยโอกาส อายุระหว่างแรกเกิด - ๑๘ ปี', y: 0, format: 0 },
      { name: 'นักเรียน/นักศึกษา', y: 0, format: 0 },
      { name: 'ครู', y: 0, format: 0 },
      { name: 'สถานศึกษา', y: 0, format: 0 },
      { name: 'องค์กร', y: 0, format: 0 },
      { name: 'บุคลากรทางการศึกษาอื่นๆ', y: 0, format: 0 },
    ]
    projects.length > 0 && projects.forEach(item => {
      if (item.targetGroup) {
        item.targetGroup.forEach(row => {
          if (data.length === 0) {
            data.push({ name: row.targetGroup, y: 1 })
          } else {
            let index = data.findIndex(x => x.name === row.targetGroup)
            if (index === -1) {
              // data.push({ name: row.targetGroup, y: 1 })
              data[5].y++
              data[5].format++
            } else {
              data[index].y++
              data[index].format++
            }
          }
        })
      }
    })

    const optionss = {
      chart: {
        renderTo: 'container',
        polar: true,
        type: 'line'
      },

      title: {
        text: ' ',
        x: -80
      },

      pane: {
        size: '90%'
      },

      xAxis: {
        categories: ['เด็กและเยาวชนด้อยโอกาส อายุระหว่างแรกเกิด - ๑๘ ปี', 'นักเรียน/นักศึกษา', 'ครู',
          'สถานศึกษา', 'องค์กร', 'บุคลากรทางการศึกษาอื่นๆ'],
        tickmarkPlacement: 'on',
        lineWidth: 0
      },
      credits: {
        enabled: false
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
      },

      tooltip: {
        shared: true,
      },

      series: [
        {
          name: '2561',
          data: data,
        },
        // {
        //   name: '2562',
        //   data: [40, 10, 15, 50, 25, 45],
        // }
      ]
    };

    const options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.y}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.y} กลุ่ม คิดเป็น {point.percentage:.1f} %',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          }
        }
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        layout: "vertical",
        x: 20,
        y: 100,
        itemMarginTop: 5,
        itemMarginBottom: 5,
      },
      credits: {
        enabled: false
      },
      series: [{
        colorByPoint: true,
        data: data
      }]
    };

    return (
      <Form>
        <Header as='h1' style={{ fontFamily: 'supermarket' }}
          content='จำนวนกลุ่มเป้าหมายทั้งหมดที่มีในโครงการ' textAlign='center'
          subheader='' />
        <HighchartsReact highcharts={Highcharts} options={options} />
        <HighchartsReact highcharts={Highcharts} options={optionss} />
      </Form>
    )
  }

}
const mapStateToProps = state => ({
  projects: state.projects.data,
  projectAll: state.allprojects.data,
  borrow: state.scholar.data,
  search: state.search,
  auth: state.auth,
  role: state.role,
  counter: state.counter
})

const mapDispatchToProps = dispatch => ({
  listProposal: (year) => dispatch(projectActions.fetchList({
    query: { delete: { $ne: true } },
    projection: { yearBudget: 1, projectGroupArea: 1, targetGroup: 1 }
  })),
  listAllProposal: () => dispatch(allProjectActions.fetchList({
    aggregate: [{ $match: { delete: { $ne: true } } },
    { $group: { _id: '$yearBudget' } },
    { $sort: { _id: 1 } },
    ]
  })),
})

export default connect(mapStateToProps, mapDispatchToProps)(GraphTargetGroup)
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Form, Header, Segment, Menu, Button } from 'semantic-ui-react';
import { actions as projectActions } from 'Redux/reducers/projects';
import { actions as allProjectActions } from 'Redux/reducers/countproject';
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { DatePicker as DayPicker } from 'Utils'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ThaiDate } from 'Utils'

const thaiMount = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค.',
  'เม.ย.',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
]

class TableProgressMoney_split extends Component {
  constructor(props) {
    super(props);
    this.state = {
      next: 0,
      query: {
        // sort: { noAgreement: 1 },
        query: { delete: { $ne: true } },
        limit: 10000,
        skip: 0,
        projection: { nameTh: 1, managerName: 1, budget: 1, withdrawForm: 1, academic: 1, codeProject: 1, noAgreement: 1, status: 1, withDrawTime: 1 }
      },
      length: 0,
      nameTh: '',
      recipientOrganizeName: '',
      recipientPersonName: '',
      managerName: '',
      startDate: 0,
      endDate: 0,
      academic: ''
    };
  }

  componentDidMount = () => {
    let { query } = this.state
    this.props.listProposal(query)
    this.props.allProject(query.query)
    this.props.listBudget()
    this.props.getOffice()
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.countProjects) {
      this.setState({ length: nextProps.countProjects / 10000 })
    }
  }

  format(input) {
    if (!input && input === 0) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1);
    }
  }

  handleSkipNext = () => {
    let { next, length, query } = this.state
    if (next < length - 1) {
      next = next + 1
      query.skip = 10000 * next
      this.props.listProposal(query)
      this.setState({ next, query })
    }
  }

  handleSkipBack = () => {
    let { next, query } = this.state
    if (next > 0) {
      next = next - 1
      query.skip = 10000 * next
      this.props.listProposal(query)
      this.setState({ next, query })
    }
  }

  handleSkipSelect = (e, { name }) => {
    let { next, query } = this.state
    next = parseInt(name)
    query.skip = 10000 * next
    this.props.listProposal(query)
    this.setState({ next, query })
  }

  handleDayChange = (name, index, date) => {
    let { query, startDate, endDate, startRecDate, endRecDate } = this.state;
    if (name === 'startDate') {
      startDate = date._d.getTime();
      query.query.startDate = { $gte: startDate }
      this.setState({ query, startDate })
    } else if (name === 'endDate') {
      endDate = date._d.getTime();
      query.query.endDate = { $lte: endDate }
      this.setState({ query, endDate })
    } else if (name === 'startRecDate') {
      startRecDate = date._d.getTime();
      query.query.recDate = { $gte: startRecDate }
      this.setState({ query, startRecDate })
    } else if (name === 'endRecDate') {
      endRecDate = date._d.getTime();
      query.query.recDate = { $lte: endRecDate }
      this.setState({ query, endRecDate })
    }
  }

  handleChange = (e, { name, value }) => {
    let { query, nameTh, recipientOrganizeName, recipientPersonName, managerName, startDate, endDate, academic } = this.state;

    if (name === 'budget' || name === 'recipientType' || name === 'typeOffice' || name === 'yearBudget' || name === 'status') {
      query.query[name] = value;
      if (query.query.recipientType === 'บุคคล') delete query.query.recipientOrganizeName
      this.setState({ query })
    } else if (name === 'nameTh') {
      nameTh = value
      query.query.nameTh = { $regex: value }
      this.setState({ query, nameTh })
    } else if (name === 'managerName') {
      managerName = value
      query.query.managerName = { $regex: value }
      this.setState({ query, managerName })
    } else if (name === 'recipientOrganizeName') {
      recipientOrganizeName = value
      query.query.recipientOrganizeName = { $regex: value }
      this.setState({ query, recipientOrganizeName })
    } else if (name === 'recipientPersonName') {
      recipientPersonName = value
      query.query.recipientPersonName = { $regex: value }
      this.setState({ query, recipientPersonName })
    } else if (name === 'startDate') {
      startDate = value
      query.query.startDate = { $gte: value }
      this.setState({ query, startDate })
    } else if (name === 'endDate') {
      endDate = value
      query.query.endDate = { $lte: value }
      this.setState({ query, endDate })
    } else if (name === 'academic') {
      academic = value
      query.query.academic = { $regex: value }
      this.setState({ query, academic })
    }
  }

  handleSearch = () => {
    this.props.listProposal(this.state.query)
    this.props.allProject(this.state.query.query)
  }

  handleReset = () => {
    let { query } = this.state
    query = {
      // sort: { noAgreement: 1 },
      query: { delete: { $ne: true } },
      limit: 10000,
      skip: 0,
      projection: { nameTh: 1, academic: 1, codeProject: 1, noAgreement: 1, managerName: 1, budget: 1, withdrawForm: 1, status: 1, withDrawTime: 1 }
    }
    this.props.listProposal(query)
    this.props.allProject(query.query)
    this.setState({
      query, nameTh: '',
      recipientOrganizeName: '',
      recipientPersonName: '',
      managerName: '',
      startDate: 0,
      endDate: 0,
      academic: '',
      next: 0,
      startRecDate: 0,
      endRecDate: 0
    })
  }

  thaiDate = (data) => {
    if (data.toString().length > 0) {
      var day = ((new Date(data)).getDate());
      var month = ((new Date(data)).getMonth());
      var year = ((new Date(data)).getFullYear() + 543);
      return day + " " + thaiMount[month] + " " + year
    } else {
      return 'ยังไม่ระบุ'
    }
  }

  render() {
    let { projectsIn, fundings, typeOffice } = this.props
    const { next, length, query, recipientOrganizeName, recipientPersonName, startDate, endDate, academic, startRecDate, endRecDate, nameTh } = this.state
    let footer = []
    for (let i = 0; i < length; i++) {
      footer.push({})
    }

    const yearOptions = fundings.filter(item => item.used === true)
      .map((item, idx) => ({
        name: item.year,
        key: item._id,
        value: item.year,
        text: item.year
      }));

    const typeProject = [
      { key: 0, text: 'องค์กร', value: 'องค์กร' },
      { key: 1, text: 'บุคคล', value: 'บุคคล' },
      { key: 2, text: 'คณะบุคคล', value: 'คณะบุคคล' }
    ]

    let typeOfficeOption = typeOffice[0] ? typeOffice[0].typeOffice.map((item, idx) => ({
      key: idx,
      text: item.name,
      value: item.value
    })) : []

    projectsIn &&
      projectsIn.forEach((item, idx) => {
        let text = ''
        if (item.screen && item.screen.length > 0) {
          item.screen.forEach((i, index) => {
            if (i.nameProfessional && i.nameProfessional !== '') {
              if (index !== item.screen.length - 1)
                text += index + 1 + '. ' + i.nameProfessional + ',\r\n'
              else text += index + 1 + '. ' + i.nameProfessional
            }
          })
        }
        item.screentext = text
        let textList = ''
        let moneyList = ''
        if (item.withDrawTime && item.withDrawTime.length > 0) {
          item.withDrawTime.forEach((i, index) => {
            if (i.submitTime) {
              if (index !== item.withDrawTime.length - 1) {
                textList +=
                  index + 1 + '. ' + ThaiDate('month', i.submitTime) + '\r\n'
                moneyList += 'งวดที่ ' + (index + 1) + ' จำนวน ' + this.format(i.sum.toString()) + ' บาท\r\n'
              } else {
                textList += index + 1 + '. ' + ThaiDate('month', i.submitTime)
                moneyList += 'งวดที่ ' + (index + 1) + ' จำนวน ' + this.format(i.sum.toString()) + ' บาท'
              }
            }
          })
        }
        item.withDrawTimetext = textList
        item.withDrawTimeMonry = moneyList
      })

    return (
      <Segment>
        <Header as='h1' style={{ fontFamily: 'supermarket' }}
          content='รายละเอียดโครงการ' textAlign='center'
          subheader='' />

        <Form >
          <Form.Group >
            <Form.Select label='ปีงบประมาณ' options={yearOptions} width={4} name='yearBudget' onChange={this.handleChange} value={query.query.yearBudget} />
            <Form.Input label='ชื่อโครงการ' width={8} name='nameTh' onChange={this.handleChange} value={nameTh} />
            <Form.Input label='ชื่อผู้รับผิดชอบโครงการ' width={4} name='managerName' onChange={this.handleChange} value={nameTh} />
          </Form.Group>
          <Form.Group>
            <Form.Select label='ประเภทผู้รับทุน' options={typeProject} width={4} name='recipientType' onChange={this.handleChange} value={query.query.recipientType} />
            <Form.Input label='ชื่อองค์กรรับทุน' width={5} name='recipientOrganizeName' onChange={this.handleChange}
              disabled={query.query.recipientType === 'บุคคล'} value={recipientOrganizeName} />
            <Form.Input label='ชื่อผู้รับทุน' width={4} name='recipientPersonName' onChange={this.handleChange} value={recipientPersonName} />
          </Form.Group>
          <Form.Group>
            {/* <Form.Select name='status' value={query.query.status} options={statusProject} onChange={this.handleChange} width={4} label='สถานะโครงการ' /> */}
            <Form.Select label='สังกัด/สำนัก' width={4} options={typeOfficeOption} name='typeOffice' onChange={this.handleChange} value={query.query.typeOffice} />
            <Form.Input label='นักวิชาการ/ผู้ปฏิบัติงาน' width={5} name='academic' onChange={this.handleChange} value={academic} />
            <Form.Field style={{ fontFamily: 'supermarket' }}>
              <label>{'วันที่เริ่มต้นโครงการ'}</label>
              <DayPicker ser={'startDate'} name={'startDate'}
                max={endDate || ''}
                onDayChange={this.handleDayChange}
                data={startDate || ''}
              />
            </Form.Field >
            <Form.Field style={{ fontFamily: 'supermarket' }} width={3}>
              <label>{'วันที่สิ้นสุดโครงการ'}</label>
              <DayPicker ser={'endDate'} name={'endDate'}
                min={startDate || ''}
                onDayChange={this.handleDayChange}
                data={endDate || ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field style={{ fontFamily: 'supermarket' }} >
              <label>{'วันที่สร้างโครงการ ตั้งแต่'}</label>
              <DayPicker ser={'startRecDate'} name={'startRecDate'}
                max={endRecDate || ''}
                onDayChange={this.handleDayChange}
                data={startRecDate || ''}
              />
            </Form.Field>
            <Form.Field style={{ fontFamily: 'supermarket' }} >
              <label>{'ถึงวันที่'}</label>
              <DayPicker ser={'endRecDate'} name={'endRecDate'}
                min={startRecDate || ''}
                onDayChange={this.handleDayChange}
                data={endRecDate || ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field style={{ 'textAlign': 'center' }}>
            <Button content='ล้างการค้นหา' icon='sync' size='big' onClick={this.handleReset} style={{ fontFamily: 'supermarket' }} />
            <Button color='green' content='ค้นหา' icon='search' size='big' onClick={this.handleSearch} style={{ fontFamily: 'supermarket' }} />
          </Form.Field>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename="reportPMStablexls"
            sheet="tablexls"
            buttonText="Download as XLS" />
          <Segment style={{ "overflow": "auto scroll", "width": "100%", "height": "500px" }}>
            <pre>
              <table id="table-to-xls" border="1px black" >
                <thead>
                  <tr>
                    <th>ลำดับ</th>
                    <th>รหัสโครงการ</th>
                    <th>เลขที่สัญญา</th>
                    <th>ชื่อโครงการ</th>
                    <th>ชื่อนักวิชาการ</th>
                    <th>สถานะโครงการ</th>
                    <th>งบประมาณ (บาท)</th>
                    <th>จำนวนงวด</th>
                    <th>วันที่นำส่งผลงาน</th>
                    <th>วันที่ต้องเบิกในระบบ งวดที่ 1 </th>
                    <th>วันที่เบิกในระบบ งวดที่ 1 </th>
                    <th>จำนวนเงินที่เบิกในระบบ งวดที่ 1 (บาท)</th>
                    <th>วันที่ต้องเบิกในระบบ งวดที่ 2 </th>
                    <th>วันที่เบิกในระบบ งวดที่ 2 </th>
                    <th>จำนวนเงินที่เบิกในระบบ งวดที่ 2 (บาท)</th>
                    <th>วันที่ต้องเบิกในระบบ งวดที่ 3 </th>
                    <th>วันที่เบิกในระบบ งวดที่ 3 </th>
                    <th>จำนวนเงินที่เบิกในระบบ งวดที่ 3 (บาท)</th>
                    <th>วันที่ต้องเบิกในระบบ งวดที่ 4 </th>
                    <th>วันที่เบิกในระบบ งวดที่ 4 </th>
                    <th>จำนวนเงินที่เบิกในระบบ งวดที่ 4 (บาท)</th>
                    <th>วันที่ต้องเบิกในระบบ งวดที่ 5 </th>
                    <th>วันที่เบิกในระบบ งวดที่ 5 </th>
                    <th>จำนวนเงินที่เบิกในระบบ งวดที่ 5 (บาท)</th>
                  </tr>
                </thead>
                <tbody>
                  {projectsIn && projectsIn.map((item, idx) => (
                    // <Fragment key={idx}>
                    <tr key={idx}>
                      <td>{((next) * 10000) + idx + 1}</td>
                      <td>{item.codeProject}</td>
                      <td>{item.noAgreement}</td>
                      <td>{item.nameTh}</td>
                      <td>{item.academic}</td>
                      <td>{item.status}</td>
                      <td align="right">{this.format((item.budget || 0).toString())}</td>
                      <td>{item.withDrawTime ? item.withDrawTime.length : 'ยังไม่ได้ระบุ'}</td>
                      <td>{item.withDrawTimetext}</td>
                      <td align="center">{(item.withDrawTime && item.withDrawTime[0] ? this.thaiDate(item.withDrawTime[0].submitTime || '') : '')}</td>
                      <td align="center">{(item.withdrawForm && item.withdrawForm[0] ? this.thaiDate(item.withdrawForm[0].sendWorkTime || '') : '')}</td>
                      <td align="center">{(item.withdrawForm && item.withdrawForm[0] ? (item.withdrawForm[0].withdrawPeriodAmount ?
                        this.format(item.withdrawForm[0].withdrawPeriodAmount.toString()) + " บาท " : 'ยังไม่ได้ระบุ') : '-')}</td>
                      <td align="center">{(item.withDrawTime && item.withDrawTime[1] ? this.thaiDate(item.withDrawTime[1].submitTime || '') : '')}</td>
                      <td align="center">{(item.withdrawForm && item.withdrawForm[1] ? this.thaiDate(item.withdrawForm[1].sendWorkTime || '') : '')}</td>
                      <td align="center">{(item.withdrawForm && item.withdrawForm[1] ? (item.withdrawForm[1].withdrawPeriodAmount ?
                        this.format(item.withdrawForm[1].withdrawPeriodAmount.toString()) + " บาท " : 'ยังไม่ได้ระบุ') : '-')}</td>
                      <td align="center">{(item.withDrawTime && item.withDrawTime[2] ? this.thaiDate(item.withDrawTime[2].submitTime || '') : '')}</td>
                      <td align="center">{(item.withdrawForm && item.withdrawForm[2] ? this.thaiDate(item.withdrawForm[2].sendWorkTime || '') : '')}</td>
                      <td align="center">{(item.withdrawForm && item.withdrawForm[2] ? (item.withdrawForm[2].withdrawPeriodAmount ?
                        this.format(item.withdrawForm[2].withdrawPeriodAmount.toString()) + " บาท " : 'ยังไม่ได้ระบุ') : '-')}</td>
                      <td align="center">{(item.withDrawTime && item.withDrawTime[3] ? this.thaiDate(item.withDrawTime[3].submitTime || '') : '')}</td>
                      <td align="center">{(item.withdrawForm && item.withdrawForm[3] ? this.thaiDate(item.withdrawForm[3].sendWorkTime || '') : '')}</td>
                      <td align="center">{(item.withdrawForm && item.withdrawForm[3] ? (item.withdrawForm[3].withdrawPeriodAmount ?
                        this.format(item.withdrawForm[3].withdrawPeriodAmount.toString()) + " บาท " : 'ยังไม่ได้ระบุ') : '-')}</td>
                      <td align="center">{(item.withDrawTime && item.withDrawTime[4] ? this.thaiDate(item.withDrawTime[4].submitTime || '') : '')}</td>
                      <td align="center">{(item.withdrawForm && item.withdrawForm[4] ? this.thaiDate(item.withdrawForm[4].sendWorkTime || '') : '')}</td>
                      <td align="center">{(item.withdrawForm && item.withdrawForm[4] ? (item.withdrawForm[4].withdrawPeriodAmount ?
                        this.format(item.withdrawForm[4].withdrawPeriodAmount.toString()) + " บาท " : 'ยังไม่ได้ระบุ') : '-')}</td>
                    </tr>
                    // </Fragment>
                  ))}
                </tbody>
              </table>
            </pre>
          </Segment>
          <Menu pagination >
            <Menu.Item as='a' content='<' onClick={this.handleSkipBack} />
            {footer.map((item, idx) => (
              <Fragment key={idx}>
                <Menu.Item as='a' content={idx + 1} active={idx === next} name={idx.toString()} onClick={this.handleSkipSelect} />
              </Fragment>
            ))}
            <Menu.Item as='a' content='>' onClick={this.handleSkipNext} />
          </Menu>
        </Form>
      </Segment>
    )
  }

}
const mapStateToProps = state => ({
  projectsIn: state.projects.data,
  countProjects: state.countproject.data,
  auth: state.auth,
  role: state.role,
  fundings: state.fundings.data,
  typeOffice: state.typeproject.data
})

const mapDispatchToProps = dispatch => ({
  allProject: (query) => dispatch(allProjectActions.fetchList({ count: query })),
  listProposal: (query) => dispatch(projectActions.fetchList(query)),
  listBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  getOffice: () => dispatch(typeProject.fetchList({ query: { name: 'สังกัดสำนัก' } })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TableProgressMoney_split)
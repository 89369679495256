import React, { Component, Fragment } from 'react';
import {
  Form,
  Header,
  Message,
  Input,
  Button,
  Select,
  TextArea,
  Table,
  Label,
  Icon,
  Transition,
  Segment,
  Confirm,
  Modal
} from 'semantic-ui-react'
import Cleave from 'cleave.js/react';
import validatejs from 'validate.js'
import jwt from 'jsonwebtoken'
import { localTh } from 'Utils'
import { FormSearch } from 'Utils'
import { formatcomma } from 'Utils'
import { constraints as BillConstraints } from './BillConstraints'
import { constraints as BillArrayConstraints } from './BillConstraints'
import { constraints as BillConditionConstraints } from './BillConstraints'
import { ValidateArray as validateArray } from 'Utils'
import { actions as receiptsActions } from 'Redux/reducers/receipts'
import { actions as scholarActions } from 'Redux/reducers/scholar'
import { connect } from 'react-redux'
import { ComponentPerson } from 'Utils'
import { GetServerTime as GetTime } from 'Utils'

let gettime = ''

const titleOptions = [
  { value: 'นาย', text: 'นาย' },
  { value: 'นาง', text: 'นาง' },
  { value: 'นางสาว', text: 'นางสาว' },
  { value: 'อื่นๆ', text: 'อื่นๆ(ระบุ)' },
]


class Bill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      selectedRow: null,
      onSaveSuccess: false,
      openModalPerson: false,
      isLoad: false,
      modalAddReceipt: false
    };
  }

  componentDidMount = () => {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });


    if (match.params.id) {
      let query = {
        borrow_id: match.params.id
      }
      this.props.listReceipts(query)
    }

  }

  componentWillReceiveProps = (nextProps) => {
    let { isLoad } = this.state
    let { receipts,borrow } = nextProps


    if (receipts.length === 0 && isLoad) {
      let query = {
        borrow_id: this.props.match.params.id
      }
      this.props.listReceipts(query)
      this.setState({ isLoad: true })
    }
    if (borrow) {
      let tmp = { ...borrow }
      if (receipts[0] && !isLoad) {
        tmp['receipt_id'] = receipts[0]._id
        this.props.updateBorrow(tmp, borrow._id)
        this.setState({ isLoad: true })
      }
    }
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }


  _renderFormField = (content) => {
    const { validate } = this.state;
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else
      return <Form.Field {...content} error={validate.attrs[content.name]} />
  }

  handleAddRow = () => {
    const { match, receipts } = this.props
    let { data } = this.state

    if (match.params.id) {
      data = { ...receipts[0], ...data }
    }

    if (!data.listBill) data['listBill'] = []
    data.listBill.push({ listBill: '', billMoney: '' })

    this.setState({ data, selectedRow: data.listBill.length - 1 })
  }

  handleDeleteRow = () => {
    const { match, receipts } = this.props
    let { data, indexDelete, validate } = this.state;
    let result = 0
    
    if (match.params.id) {
      data = { ...receipts[0], ...data }
    }


    validate.validateError = {}
    validate.attrs = {}
    validate.error = false

    if (data.listBill) {
      data.listBill.splice(indexDelete, 1)
      data['listBill'].forEach(r => (
        result += r.billMoney
      ));
      data['sumListBill'] = result
      this.setState({ data, openConfirmDel: false, selectedRow: null })
    }



  }

  handleConfirmDelete = (idx) => {
    this.setState({ indexDelete: idx, openConfirmDel: true })
  }

  handleChange = (e, { name, value }) => {
    const { receipts } = this.props
    let { data, validate } = this.state

    if (receipts[0]) {
      data = { ...receipts[0], ...data }
    }

    validate.validateError = {}
    validate.attrs = {}
    validate.error = false

    data[name] = value

    this.setState({ data })
  }

  handleSelectRow = (selectedRow) => {
    if (selectedRow === this.state.selectedRow) selectedRow = null;
    this.setState({ selectedRow })
  }

  summary = (array) => {
    let result = 0
    var nf = new Intl.NumberFormat()
    if (array) {
      array.forEach(r => (
        result += r.billMoney
      ));
    }
    return nf.format(result)
  }

  onMoneyChange = (idx, e) => {
    const { receipts } = this.props
    let { data, validate } = this.state
    let result = 0

    if (receipts[0]){
      data = { ...receipts[0], ...data }
    }

    validate.validateError = {}
    validate.attrs = {}
    validate.error = false

    data['listBill'][idx]['billMoney'] = e.target.rawValue.replace('-', '')
    if (data['listBill']) {
      data['listBill'].forEach(r => (
        result += r.billMoney
      ));
    }
    data['sumListBill'] = result

    this.setState({ data, validate })
  }



  handleEditRow = (idx, e, { name, value }) => {
    const { receipts } = this.props
    let { data, validate } = this.state

    if (receipts[0]) {
      data = { ...receipts[0], ...data }
    }
    validate.validateError = {}
    validate.attrs = {}
    validate.error = false

    data['listBill'][idx][name] = value

    this.setState({ data, validate })
  }

  validateCid = (value) => {
    if (value === null) return '^กรุณากรอกเลขบัตรประชาชน'
    else if (value.length !== 13) return '^ต้องมี 13 หลัก'
    else {
      var num = value.replace(/-/g, '');
      let digits = num.split("")
      // .map((st) => parseInt(st));
      if (digits[0] > 0 || digits[0] < 9) {
        let result = 0;
        for (var i = 0; i < 12; i++) {
          result += digits[i] * (13 - i)
        }
        result = (11 - (result % 11)) % 10;
        if (result !== parseInt(digits[12], 10)) {
          return '^เลขบัตรประชาชนไม่ถูกต้อง'
        }
      } else if (value[0] === 'P') return null
    }
  }

  handleSubmit = () => {
    const { auth,receipts } = this.props
    let { data, validate } = this.state;
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    });
    if (receipts[0]) {
      data = { ...receipts[0], ...data }
    }

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    validate.validateError = {}
    validate.attrs = {}
    validate.error = false

    if (!data.listBill) data.listBill = []

    validate.attrs = {};
    if(data.title==='อื่นๆ'){
      validate['validateError'] = validatejs(data, BillConditionConstraints.BillConditionConstraints)
    }else{
      validate['validateError'] = validatejs(data, BillConstraints.BillConstraints)
    }
    if (validate['validateError'] === undefined) {
      validate['validateError'] = {}
    }

    if (data.cid) {
      if (data.cid.length > 0) {
        if (data.cid.length !== 13) {
          validate['validateError'].cid = ['กรุณาใส่ให้ครบ 13 หลัก']
        } else {
          var regx = /^[0-9]*$/
          const check = data.cid.match(regx)
          if (check !== null) {
            let validCID = this.validateCid(data.cid)
            if (validCID !== undefined) {
              validate['validateError'].cid = [validCID]
            }
          } else {
            validate['validateError'].cid = ['กรุณาใส่เลขบัตรเป็นตัวเลข']
          }
        }
      }
    }
    // validate array
    if (!validate['validateError']) validate['validateError'] = {}

    let validateArrayError = validateArray(data.listBill, BillArrayConstraints.BillArrayConstraints)
    if (Object.keys(validateArrayError).length !== 0 && validateArrayError.constructor === Object) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayError).forEach(index => {
        if (validateArrayError[index].listBill) {
          validate['validateError']['listBill'] = validateArrayError[index].listBill
        }
        if (validateArrayError[index].billMoney) {
          validate['validateError']['billMoney'] = validateArrayError[index].billMoney
        }
        validate.attrs['listBill' + index] = true;
      })
    }
    if (data.listBill.length === 0) {
      validate.error = true
      validate['validateError']['listBill'] = []
      validate['validateError']['listBill'].push('โปรดระบุรายการ')
    }

    // end validate array
    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      validate['error'] = false
      data['academicFirstname'] = user.firstname
      data['academicLastname'] = user.lastname
      data['academicId'] = user._id
      data['timestamp'] = gettime
      this.setState({ validate });
      this.props.updateReceipt(data, data._id)
      this.setState({ onSaveSuccess: true });
      setTimeout(() => {
        this.setState({ onSaveSuccess: false });
      }, 3000)
    }
  }

  onCidChange = (e) => {
    const { match, receipts } = this.props
    let { data, validate } = this.state

    if (match.params.id) {
      data = { ...receipts[0], ...data }
    }


    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    data['cid'] = e.target.rawValue

    this.setState({ data, validate })
  }

  close = () => {
    this.setState({ openConfirmDel: false, modalAttachment: false, selectedRow: false })
  }

  _onUpload = () => {
    this.setState({ modalAttachment: true })
  }


  openModalPerson = () => {
    this.setState({ openModalPerson: true })
  }

  closeModalPerson = () => {
    this.setState({ openModalPerson: false })
  }

  createReceipt = () => {
    const { borrow } = this.props

    let data = {}
    data["borrow_id"] = borrow._id
    this.props.addReceipt(data)
  }



  render() {
    const { receipts } = this.props
    let { data, validate, selectedRow, onSaveSuccess, openModalPerson } = this.state

    if (receipts) {
      data = { ...receipts[0], ...data }
    }

    if (!data.sumListBill) data.sumListBill = '0'

    let content = {
      'title': { control: Select, options: titleOptions },
      'titleOther': { control: Input, disabled: data.title !== 'อื่นๆ' },
      'firstname': { control: Input },
      'lastname': { control: Input },
      'billAddressNo': { control: TextArea },
      'billAddress': { control: FormSearch },
      'projectName': { control: Input },
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('bill', key);
      content[key].name = key;
      content[key].onChange = this.handleChange;
      if (content[key].control === FormSearch) {
        content[key].value = data[key];
      } else {
        content[key].value = data[key] || '';
      }
    });

    return (
      <Fragment>
        {receipts.length === 0 &&
          <Segment className="ui placeholder segment" >
            <div className="ui icon header">
              <Icon name='file alternate' />
              <h2 style={{ fontFamily: 'supermarket' }}>
                {/* ไม่มีสร้างใบสำคัญรับเงิน */}
              </h2>
            </div>
            <Button color='blue' style={{ fontFamily: 'supermarket' }} onClick={this.createReceipt}>สร้างใบสำคัญรับเงิน</Button>
          </Segment>
        }
        {receipts.length !== 0 &&
          <Segment>
            <Header icon='money' style={{ fontFamily: 'supermarket' }} content='ใบสำคัญรับเงิน' />
            <Form error={validate.error}>
              <Form.Group widths='equal'>
                {this._renderFormField(content.title)}
                {this._renderFormField(content.titleOther)}
              </Form.Group>
              <Form.Group widths='equal'>
                {this._renderFormField(content.firstname)}
                {this._renderFormField(content.lastname)}
              </Form.Group>
              <Form.Group >
                <Form.Field width='8' error={validate.attrs['cid']}>
                  <label>{'เลขประจำตัวประชาชน'}</label>
                  <Cleave name='cid'
                    maxLength='17'
                    options={{
                      delimiters: [' '],
                      blocks: [1, 4, 5, 2, 1],
                      uppercase: true
                    }}
                    onChange={this.onCidChange.bind(this)}
                    value={data.cid || ''} />
                </Form.Field>
                <Form.Field width={3}>
                  <label>เพิ่มบุคลากร</label>
                  <Button content='เพิ่ม' color='green' icon='plus' onClick={this.openModalPerson} />
                </Form.Field>
              </Form.Group>
              <Form.Group widths='equal'>
                {this._renderFormField(content.billAddressNo)}
              </Form.Group>
              <Form.Group widths='equal'>
                {this._renderFormField(content.billAddress)}
                {this._renderFormField(content.projectName)}
              </Form.Group>
              <Header icon='tasks' style={{ fontFamily: 'supermarket' }} content='รายการ' />
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell content='ลำดับ' textAlign='center' width={2} />
                    <Table.HeaderCell content='รายการ' textAlign='center' />
                    <Table.HeaderCell content='จำนวนเงิน (บาท)' textAlign='center' width={3} />
                    <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.listBill && data.listBill.map((item, idx) => (
                    <Fragment key={idx}>
                      <Table.Row error={validate.attrs['listBill' + idx] || validate.attrs['billMoney' + idx]} onClick={this.handleSelectRow.bind(this, idx)}>
                        {(selectedRow === idx && <Table.Cell>
                          <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                        </Table.Cell>) || <Table.Cell textAlign='center'>
                            {idx + 1}
                          </Table.Cell>}
                        <Table.Cell textAlign='center' content={item.listBill} />
                        <Table.Cell textAlign='center' content={formatcomma(item.billMoney)} />
                        <Table.Cell textAlign='center'>
                          <Button
                            type='button'
                            name={idx} color='red'
                            icon='close'
                            onClick={this.handleConfirmDelete.bind(this, idx)}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {selectedRow === idx && (
                        <Table.Row active>
                          <Table.Cell colSpan={5}>
                            <Form.TextArea label='รายการ' name='listBill'
                              value={item.listBill} onChange={this.handleEditRow.bind(this, idx)} />
                            <Form.Group widths='equal'>
                              <Form.Field>
                                <label>{'จำนวนเงิน'}</label>
                                <Cleave name='billMoney'
                                  maxLength='16'
                                  options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand'
                                  }}
                                  onChange={this.onMoneyChange.bind(this, idx)}
                                  value={item.billMoney || ''} />
                              </Form.Field>
                            </Form.Group>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  ))}
                </Table.Body>
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell colSpan='1' textAlign='center'>
                      <Button type='button' color='green' content='เพิ่ม'
                        onClick={this.handleAddRow}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan='4' textAlign='center'>
                      {'รวมเป็นเงินทั้งสิ้น(ปิดโครงการ) : ' + formatcomma(data.sumListBill) + '  บาท'}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
              <Message error>
                <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
                <Message.List>
                  {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                    <Fragment key={idx}>
                      {validate.validateError[key].map((item, idx) => (
                        <Message.Item key={idx}> {localTh('bill', key)} - {item} </Message.Item>
                      ))}
                    </Fragment>
                  ))}
                </Message.List>
              </Message>
              <Button onClick={this.handleSubmit} style={{ fontFamily: 'supermarket' }} color='blue' disabled={validate.error}>บันทึก</Button>
            </Form>
            <Modal
              open={openModalPerson}
              closeOnDimmerClick={false}
              onClose={this.closeModalPerson}
              closeIcon>
              <Modal.Header >เพิ่ม บุคลากร</Modal.Header>
              <Modal.Content>
                <ComponentPerson openModalPerson={openModalPerson} onCloseModalPerson={this.closeModalPerson} />
              </Modal.Content>
            </Modal>
            <Confirm open={this.state.openConfirmDel}
              content='ต้องการลบใช่หรือไม่'
              cancelButton='ยกเลิก'
              confirmButton="ยืนยัน"
              onCancel={this.close}
              onConfirm={this.handleDeleteRow} />
            <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
              <Message positive>
                <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
              </Message>
            </Transition>
          </Segment>
        }

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  receipt: state.receipts.currentData,
  receipts: state.receipts.data,
  borrow: state.scholar.currentData,
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  addReceipt: (data) => dispatch(receiptsActions.save(data)),
  getReceipt: (id) => dispatch(receiptsActions.fetchOne(id)),
  listReceipts: (query) => dispatch(receiptsActions.fetchList({ query: query })),
  updateReceipt: (data, id) => dispatch(receiptsActions.save(data, id, 'POST')),
  updateBorrow: (data, id) => dispatch(scholarActions.save(data, id, 'POST')),
  getBorrow: (id) => dispatch(scholarActions.fetchOne(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Bill);

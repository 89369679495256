import React, { Component, Fragment } from 'react';
import {
  Header,
  Form,
  Table,
  Label,
  Button,
  Message,
  Transition,
  Icon,
  Modal,
  Segment
} from 'semantic-ui-react';
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react';
import { connect } from 'react-redux'
import { actions as scholarActions } from 'Redux/reducers/scholar'
import { localTh } from 'Utils'
import { PrintoutComponent } from 'Utils'
import { GetServerTime as GetTime } from 'Utils'
import { constraints as borrowMoneyConstraints } from './ConstraintsBorrow'
import { ValidateArray as validateArray } from 'Utils'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import jwt from 'jsonwebtoken'
import { DatePicker as DayPicker } from 'Utils'
import { ComponentPerson, ComponentOrganization } from 'Utils'

let gettime = ''

let individualsName

class TotalRefund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      selectedRow: null,
      borrowCheck: false,
      validate: { error: false, validateError: {}, attrs: {} },
      modalPrintout: false,
      onSaveSuccess: false,
      openModalOrg: false,
      openModalPerson: false
    };
  }

  format = (input) => {
    if (!input) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      // remove leading zero

      num = num.replace(/^0+/, '')
      num = num.replace(/^\./, '0.')

      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      input = input.substring(0, input.length - 1);
    }
  }

  componentDidMount = () => {
    const { match } = this.props
    if (match.params.id) this.props.getBorrow(match.params.id)

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });
  }

  componentWillReceiveProps = (nextProps) => {
    const { borrow } = nextProps
    let { data } = this.state

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });

    if (this.props.borrow !== nextProps.borrow) {
      if (this.props.match.params.id) this.props.getBorrow(this.props.match.params.id)
    }

    data = { ...borrow, ...data }
    if (!data.concludeMoney) {
      data.concludeMoney = []
    }

    if (!data.refundDate) {
      data.refundDate = gettime
    }

    this.setState({ data, borrowCheck: false })

  }

  handleChangeMoney = (value) => {
    this.setState({ money: value })
  }

  handleSelectRow = (selectedRow) => {
    if (selectedRow === this.state.selectedRow) selectedRow = null;
    this.setState({ selectedRow, modalAttachment: false })
  }

  handleSelectConclude = (selectedConclude) => {
    let { data } = this.state;
    const { match, borrow } = this.props
    if (match.params.id) {
      data = { ...borrow, ...data }
    }
    if (selectedConclude === this.state.selectedConclude) selectedConclude = null;
    else {
      if (data.concludeMoney[selectedConclude].namePerson) {
        let query = {
          '_id': data.concludeMoney[selectedConclude].namePerson
        }
        this.props.listPersonByOrganization(query)
      }
    }
    this.setState({ selectedConclude, modalAttachment: false })
  }

  handleChangeRow = (idx, e) => {
    let { data } = this.state;
    const { borrow } = this.props

    if (!borrow.refundDate) {
      borrow.refundDate = gettime
    }

    let sumTotal = ''

    let _data = []
    if (borrow !== null) {
      if (Object.keys(data).length === 0) {
        borrow.listBorrow.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      } else {
        data.listBorrow.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      }
    }

    let sum = 0
    if (!data['sumUsedMoney']) data['sumUsedMoney'] = '0'
    let money = e.target.rawValue.replace('-', '')
    _data[idx][e.target.name] = money ? money.toString() : '0'

    if (_data) {
      _data.forEach(x => {
        if (x.usedMoney) {
          let nb = x.usedMoney.replace(/,/g, '')
          let cv = parseFloat(nb)
          sum += cv
          let sumMoney = sum.toFixed(2).toString()
          sumTotal = sumMoney
        }
      });
    }

    let tmp = { ...data, listBorrow: _data, sumUsedMoney: sumTotal }

    this.setState({ data: tmp })
  }

  handleSubmit = () => {
    let { data, validate } = this.state;
    const { match, borrow } = this.props
    if (match.params.id) {
      data = { ...borrow, ...data }
    }
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    });
    validate.attrs = {};
    validate['validateError'] = {}
    var borrowMoneyRefund = {
      refundDate: {
        presence: {
          allowEmpty: false,
          message: '^ต้องระบุ'
        },
      },
    }
    validate['validateError'] = validatejs(data, borrowMoneyRefund)

    let validateArrayErrorR = validateArray(data.concludeMoney, borrowMoneyConstraints.borrowMoneyConstraints)
    if (!validate['validateError']) validate['validateError'] = {}
    if (Object.keys(validateArrayErrorR).length !== 0 && validateArrayErrorR.constructor === Object) {
      validate.error = true
      Object.keys(validateArrayErrorR).forEach(index => {
        if (validate['validateError']['conclude']) {
          validate['validateError']['conclude'] = validateArrayErrorR[index].content
        }
        validate.attrs['conclude' + index] = true;
      })
    }
    if (Object.keys(validate['validateError']).length !== 0) {
      validate.error = true
      this.setState({ validate });
    } else {
      validate['error'] = false
      data.timeStamp = gettime
      // data.refundDate = gettime
      data['status'] = 'ทำเรื่องขอคืนเงิน'
      this.props.updateBorrow(data, data._id)
      this.setState({ onSaveSuccess: true, validate });
      setTimeout(() => {
        this.setState({ onSaveSuccess: false });
      }, 3000)
      this.setState({ validate });

      // this.props.history.push('/List/scholar');
    }
  }

  _onDownload = () => {
    this.setState({ modalPrintout: true, modalAttachment: false })
  }

  handleCloseModal = () => {
    this.setState({ modalSendEmail: false, modalAttachment: false, modalAddGroupFinance: false, modalPrintout: false })
  }

  handleChangeConclude = (idx, e, { name, value }) => {
    let { data } = this.state
    const { borrow } = this.props
    let _data = []
    if (borrow !== null) {
      if (Object.keys(data).length === 0) {
        borrow.concludeMoney.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      } else {
        data.concludeMoney.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      }
    }
    _data[idx][name] = value

    if (name === 'namePerson') {
      individualsName.map(r => {
        if (value === r.id) _data[idx]['content'] = r.name
        return r
      })
    }
    let tmp = { ...data, concludeMoney: _data }

    this.setState({ data: tmp })
  }

  onMoneyChange = (idx, e) => {
    let { data } = this.state
    const { borrow } = this.props

    let _data = []
    let _listBorrow = []
    if (borrow !== null) {
      if (Object.keys(data).length === 0) {
        borrow.listBorrow.forEach(item => {
          var tmp = { ...item }
          _listBorrow.push(tmp)
        })
      } else {
        data.listBorrow.forEach(item => {
          var tmp = { ...item }
          _listBorrow.push(tmp)
        })
      }

      if (Object.keys(data).length === 0) {
        borrow.concludeMoney.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      } else {
        data.concludeMoney.forEach(item => {
          var tmp = { ...item }
          _data.push(tmp)
        })
      }
    }

    let money = e.target.rawValue.replace('-', '')

    _data[idx][e.target.name] = money ? money.toString() : '0'

    _data[idx]['sum'] = (parseFloat(_data[idx]['compensation'] || '0') + parseFloat(_data[idx]['vehicle'] || '0') +
    parseFloat(_data[idx]['hotel'] || '0') + parseFloat(_data[idx]['food'] || '0') +
      parseFloat(_data[idx]['other'] || '0') + parseFloat(_data[idx]['document'] || '0')).toFixed(2).toString()

    let remuneration = 0
    let document = 0
    let vehicle = 0
    let apartment = 0
    let food = 0
    let other = 0

    _data.forEach(item => {
      if (item.compensation) remuneration += parseFloat(item['compensation'])
      if (item.document) document += parseFloat(item['document'])
      if (item.vehicle) vehicle += parseFloat(item['vehicle'])
      if (item.hotel) apartment += parseFloat(item['hotel'])
      if (item.food) food += parseFloat(item['food'])
      if (item.other) other += parseFloat(item['other'])
    })
    let index = 0
    index = _listBorrow.findIndex(x => x.listName === 'remuneration')
    if (index === -1) {
      _listBorrow.push({
        listName: 'remuneration', borrow: '0', usedMoney: remuneration.toString()
      })
    } else {
      _listBorrow[index].usedMoney = remuneration.toString()
    }

    index = _listBorrow.findIndex(x => x.listName === 'document')
    if (index === -1) {
      _listBorrow.push({
        listName: 'document', borrow: '0', usedMoney: document.toString()
      })
    } else {
      _listBorrow[index].usedMoney = document.toString()
    }

    index = _listBorrow.findIndex(x => x.listName === 'vehicle')
    if (index === -1) {
      _listBorrow.push({
        listName: 'vehicle', borrow: '0', usedMoney: vehicle.toString()
      })
    } else {
      _listBorrow[index].usedMoney = vehicle.toString()
    }

    index = _listBorrow.findIndex(x => x.listName === 'apartment')
    if (index === -1) {
      _listBorrow.push({
        listName: 'apartment', borrow: '0', usedMoney: apartment.toString()
      })
    } else {
      _listBorrow[index].usedMoney = apartment.toString()
    }

    index = _listBorrow.findIndex(x => x.listName === 'food')
    if (index === -1) {
      _listBorrow.push({
        listName: 'food', borrow: '0', usedMoney: food.toString()
      })
    } else {
      _listBorrow[index].usedMoney = food.toString()
    }

    index = _listBorrow.findIndex(x => x.listName === 'other')
    if (index === -1) {
      _listBorrow.push({
        listName: 'other', borrow: '0', usedMoney: other.toString()
      })
    } else {
      _listBorrow[index].usedMoney = other.toString()
    }

    let Total = ''
    let sum = 0
    _listBorrow.forEach(x => {
      if (x.usedMoney) {
        let nb = x.usedMoney.replace(/,/g, '')
        let cv = parseFloat(nb, 10)
        sum += cv
        Total = sum.toFixed(2).toString()
      }
    });

    let sumTotal = 0
    _data.forEach(item => (
      sumTotal += parseFloat(item.sum)
    ))
    let tmp = {
      ...data, concludeMoney: _data,
      sumConclude: sumTotal.toFixed(2).toString(), listBorrow: _listBorrow,
      sumUsedMoney: Total
    }
    this.setState({ data: tmp })
  }

  handleAddConclude = () => {
    let { data } = this.state
    const { borrow } = this.props

    let _borrowgroup = []
    if (borrow) {
      if (Object.keys(data).length === 0) {
        _borrowgroup = borrow.concludeMoney.slice()
      } else {
        _borrowgroup = data.concludeMoney.slice()
      }
    }

    if (_borrowgroup) {
      _borrowgroup.splice(_borrowgroup.length, 0, {
        content: '', sum: '0', type: 'activity',
        compensation: '0', vehicle: '0',
        hotel: '0', food: '0', other: '0',
      })
    } else {
      _borrowgroup.splice(0, 0, {
        content: '', sum: '0', type: 'activity',
        compensation: '0', vehicle: '0',
        hotel: '0', food: '0', other: '0',
      })
    }
    let tmp = { ...data, concludeMoney: _borrowgroup }
    this.setState({ data: tmp, selectedConclude: (data.concludeMoney.length) })
  }

  handleDeleteConclude = () => {
    let { data, countResult } = this.state
    const { borrow } = this.props

    let _borrowgroup = []
    if (borrow) {
      if (Object.keys(data).length === 0) {
        _borrowgroup = borrow.concludeMoney.slice()
      } else {
        _borrowgroup = data.concludeMoney.slice()
      }
    }

    _borrowgroup.splice(countResult, 1)
    let sumTotal = 0
    _borrowgroup.forEach(item => (
      sumTotal += parseFloat(item.sum)
    ))
    let tmp = { ...data, concludeMoney: _borrowgroup, sumConclude: sumTotal.toFixed(2).toString() }
    this.setState({ data: tmp, modalDeleteConclude: false, selectedConclude: false })
  }

  handleSelectedConcludeOpen = (idx) => {
    this.setState({ modalDeleteConclude: true, countResult: idx })
  }

  handleSelectedConcludeClose = () => {
    this.setState({ modalDeleteConclude: false })
  }

  handleChangeSearch = (e) => {
    if (e.target.value.length === 3) {
      let query = {
        '$or': [
          { 'firstname': { $regex: e.target.value } },
          { 'lastname': { $regex: e.target.value } },
        ]
      }
      this.props.listPersonByOrganization(query)
    }
  }

  openModalPerson = () => {
    this.setState({ openModalPerson: true })
  }

  closeModalPerson = () => {
    this.setState({ openModalPerson: false })
  }

  openModalOrg = () => {
    this.setState({ openModalOrg: true })
  }

  closeModalOrg = () => {
    this.setState({ openModalOrg: false })
  }

  handleDayChange = (name, index, date) => {
    let { data } = this.state
    const { borrow } = this.props
    if (borrow) {
      data = { ...borrow, ...data }
    }
    data[name] = date._d.getTime()
    if (data.typeOfBorrow && name === 'startDate') {
      if (data.typeOfBorrow === 'supplies') {
        data['repayDate'] = this._increaseDate(data.startDate, 7)
      }
    } else if (data.typeOfBorrow && name === 'endDate') {
      if (data.typeOfBorrow !== 'supplies') {
        data['repayDate'] = this._increaseDate(data.endDate, 15)
      }
    }
    this.setState({ data })
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  render() {
    let { selectedRow, data, modalPrintout, onSaveSuccess, selectedConclude, validate, openModalPerson, openModalOrg } = this.state
    const { borrow, match, individuals, auth, role } = this.props
    if (borrow) {
      const listBorrow = data && data.listBorrow && data.listBorrow.slice()
      data = { ...borrow, ...data }
      data.listBorrow = listBorrow
    }

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    const typeList = [
      { key: '0', text: 'บุคคล', value: 'person' },
      { key: '1', text: 'กิจกรรม', value: 'activity' },
    ]

    let individualsList = individuals.data && individuals.data ?
      individuals.data.map((item, idx) => ({
        key: idx,
        text: item.title === 'อื่นๆ' ? item.otherTitle + '  ' + item.firstname + ' ' + item.lastname : item.title + '  ' + item.firstname + ' ' + item.lastname,
        value: item._id
      })) : []

    individualsName = individuals.data.map((item, idx) => ({
      id: item._id,
      name: item.title === 'อื่นๆ' ? item.otherTitle + '  ' + item.firstname + ' ' + item.lastname : item.title + '  ' + item.firstname + ' ' + item.lastname,
    }))

    return (
      <Fragment>
        <Segment>
          <PrintoutComponent
            modalPrintout={modalPrintout}
            numberOfFile={['รายละเอียดการยืมเงินทดรองจ่าย']}
            typeFile={[13]}
            match={match}
            onClose={this.handleCloseModal} />
          <Form>
            <Form.Group>
              <Form.Field width={3} disabled={(data.academicID !== user._id && data.otherAcademicID !== user._id) && role !== 'Admin'} >
                <label>{'วันที่ยืมเงิน *'}</label>
                <DayPicker ser={'borrowDate'} name={'borrowDate'}
                  onDayChange={this.handleDayChange}
                  data={data.borrowDate || ''} />
              </Form.Field>
              <Form.Field width={3} disabled={(data.academicID !== user._id && data.otherAcademicID !== user._id) && role !== 'Admin'} >
                <label>{'วันที่คืนเงิน *'}</label>
                <DayPicker ser={'refundDate'} name={'refundDate'}
                  onDayChange={this.handleDayChange}
                  data={data.refundDate || ''} />
              </Form.Field>
            </Form.Group>
          </Form>
          <Header icon='file alternate' style={{ fontFamily: 'supermarket' }} content='สรุปค่าใช้จ่าย' as='h3' />
          <Table selectable celled>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell content='ลำดับ' width={1} />
                <Table.HeaderCell content='รายการ' width={9} />
                <Table.HeaderCell content='รวม' width={4} />
                <Table.HeaderCell content='ลบ' width={2} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data && data.concludeMoney && data.concludeMoney.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectConclude.bind(this, idx)} textAlign='center'>
                    {(selectedConclude === idx && <Table.Cell>
                      <Label color="green" ribbon content={(idx + 1)} />
                    </Table.Cell>) || <Table.Cell content={idx + 1} />}
                    <Table.Cell width={8} content={item.content} />
                    <Table.Cell content={this.format(item.sum)} />
                    <Table.Cell >
                      <Button icon='close' color='red' onClick={this.handleSelectedConcludeOpen.bind(this, idx)} />
                    </Table.Cell>
                  </Table.Row>
                  {selectedConclude === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={4}>
                        <Form>
                          <Form.Group>
                            <Form.Select name='type' label='ประเภทรายการ *' options={typeList} value={item.type}
                              onChange={this.handleChangeConclude.bind(this, idx)} width={4} />
                            {item.type === 'activity' && <Form.Input maxLength="100" name='content' value={item.content || ''} label='ชื่อรายการ *'
                              onChange={this.handleChangeConclude.bind(this, idx)} width={8} />}
                            {item.type === 'person' && <Form.Select name='namePerson' value={item.namePerson || item.content || ''} label='ชื่อบุคคล * (กรอก 3 ตัวอักษรเพื่อค้นหารายชื่อ)'
                              // {...DropdownExtensions} 
                              onChange={this.handleChangeConclude.bind(this, idx)}
                              width={8} options={individualsList} search={true} placeholder={'กรุณากรอกอย่างน้อย 3 ตัวอักษร'}
                              onSearchChange={this.handleChangeSearch} />}
                            <Form.Field >
                              <label>เพิ่มบุคคากร</label>
                              <Button content='เพิ่ม' color='green' icon='plus' onClick={this.openModalPerson} />
                            </Form.Field>
                            <Form.Field >
                              <label>เพิ่มองค์กร</label>
                              <Button content='เพิ่ม' color='green' icon='plus' onClick={this.openModalOrg} />
                            </Form.Field>
                          </Form.Group>
                          <Form.Group >
                            <Form.Field width={5} >
                              <label>{'ค่าตอบแทน'}</label>
                              <Cleave name='compensation'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.compensation || ''} />
                            </Form.Field>
                            <Form.Field width={5} >
                              <label>{'ค่าเอกสาร'}</label>
                              <Cleave name='document'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.document || ''} />
                            </Form.Field>
                            <Form.Field width={5} >
                              <label>{'ค่าพาหนะ'}</label>
                              <Cleave name='vehicle'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.vehicle || ''} />
                            </Form.Field>
                          </Form.Group>
                          <Form.Group >
                            <Form.Field width={5} >
                              <label>{'ค่าที่พัก'}</label>
                              <Cleave name='hotel'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.hotel || ''} />
                            </Form.Field>
                            <Form.Field width={5} >
                              <label>{'ค่าอาหาร'}</label>
                              <Cleave name='food'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.food || ''} />
                            </Form.Field>
                            <Form.Field width={5} >
                              <label>{'อื่นๆ'}</label>
                              <Cleave name='other'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.other || ''} />
                            </Form.Field>
                          </Form.Group>
                        </Form>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell>
                  <Button content='เพิ่ม' color='green' onClick={this.handleAddConclude} />
                </Table.HeaderCell>
                <Table.HeaderCell content='รวมทั้งหมด (บาท) :' textAlign='right' />
                <Table.HeaderCell colSpan='2' content={(this.format(data.sumConclude) || '0') + ' บาท'} />
              </Table.Row>
            </Table.Footer>
          </Table>
          <Header icon='file alternate outline' style={{ fontFamily: 'supermarket' }} content='รายละเอียดการยืมเงินทดรองจ่าย' as='h3' />
          <Table selectable celled>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell content='ลำดับ' width={1} />
                <Table.HeaderCell content='รายการ' width={7} />
                <Table.HeaderCell content='จำนวนเงินที่ยืม' width={4} />
                <Table.HeaderCell content='จำนวนเงินที่ใช้' width={4} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data && data.listBorrow && data.listBorrow.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx)} textAlign='center'>
                    {(selectedRow === idx && <Table.Cell>
                      <Label color="green" ribbon content={(idx + 1)} />
                    </Table.Cell>) || <Table.Cell content={idx + 1} />}
                    <Table.Cell width={8} content={localTh('refund', item.listName)} />
                    {/* <Table.Cell content={item.unit} /> */}
                    <Table.Cell content={this.format(item.borrow) || '0'} />
                    {/* <Table.Cell content={item.money} /> */}
                    <Table.Cell content={this.format(item.usedMoney) || '0'} />
                  </Table.Row>
                  {selectedRow === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={6}>
                        <Form>
                          <Form.Field width='8'>
                            <label>{'จำนวนเงินที่ใช้'}</label>
                            <Cleave name='usedMoney'
                              maxLength='16'
                              options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand'
                              }}
                              onChange={this.handleChangeRow.bind(this, idx)}
                              value={item.usedMoney || ''} />
                          </Form.Field>
                        </Form>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan='2' content='รวมเป็นเงิน (บาท) :' textAlign='right' />
                <Table.HeaderCell content={borrow ? this.format(borrow.sumBorrowMoney) : '0'} textAlign='center' />
                <Table.HeaderCell content={data ? this.format(data.sumUsedMoney ? data.sumUsedMoney : '0') : '0'} textAlign='center' />
              </Table.Row>
            </Table.Footer>
          </Table>
          <Button color='blue' content='บันทึก' icon='save' onClick={this.handleSubmit} />
          <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
            </Message>
          </Transition>
          {validate.error && <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
            <Message.List>
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('Borrow', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>}

          <Modal
            open={openModalOrg}
            closeOnDimmerClick={false}
            onClose={this.closeModalOrg}
            closeIcon>
            {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
            <Modal.Content>
              <ComponentOrganization openModalOrganization={openModalOrg} onCloseModalPerson={this.closeModalOrg} />
            </Modal.Content>
          </Modal>

          <Modal
            open={openModalPerson}
            closeOnDimmerClick={false}
            onClose={this.closeModalPerson}
            closeIcon>
            {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
            <Modal.Content>
              <ComponentPerson openModalPerson={openModalPerson} onCloseModalPerson={this.closeModalPerson} />
            </Modal.Content>
          </Modal>
          <Modal
            open={this.state.modalDeleteConclude}
            onClose={this.handleSelectedConcludeClose}
            closeOnDimmerClick={this.state.closeOnDimmerConclude}
            size='small'
            closeIcon>
            {/* <Header content={' คำเตือน '} as='h2' icon='warning' /> */}
            <Modal.Content style={{ fontFamily: 'supermarket' }} content='ท่านกำลังลบรายการสรุปค่าใช้ ต้องการดำเนินการต่อหรือไม่ ?' as='h3' />
            <Modal.Actions>
              <Button content='ยกเลิก' onClick={this.handleSelectedConcludeClose} />
              <Button content='ยืนยัน' color='blue' onClick={this.handleDeleteConclude} />
            </Modal.Actions>
          </Modal>
        </Segment>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  scholar: state.scholar,
  borrow: state.scholar.currentData,
  individuals: state.individuals,
  auth: state.auth,
  role: state.role
});

const mapDispatchToProps = dispatch => ({
  addBorrow: (data) => dispatch(scholarActions.save(data)),
  updateBorrow: (data, id) => dispatch(scholarActions.save(data, id, 'POST')),
  getBorrow: (id) => dispatch(scholarActions.fetchOne(id)),
  getPerson: (id) => dispatch(individualsActions.fetchOne(id)),
  listPersonByOrganization: (query) => dispatch(individualsActions.fetchList({ query: query })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TotalRefund);
import React, { Component } from 'react'
import './Search.css'
import { Button, Input, Dropdown, Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTorOpen: false
    };
  }

  handleOpenModal = () => this.setState({ modalTorOpen: true })
  handleCloseModalAddTOR = () => this.setState({ modalTorOpen: false })

  render() {
    const isOpen = this.props.sidebar.isOpen
    return (
      <div className={"search-filter " + (isOpen ? '' : 'full')}>
        <div className={"left " + (isOpen ? '' : 'full')}>
          <Dropdown text='Sort by' icon='sort' floating labeled button className='icon'>
            <Dropdown.Menu>
              <Dropdown.Item label={{ color: 'red', empty: true, circular: true }} text='Important' />
              <Dropdown.Item label={{ color: 'blue', empty: true, circular: true }} text='Announcement' />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown text='Filter by' icon='filter' floating labeled button className='icon'>
            <Dropdown.Menu>
              <Dropdown.Item label={{ color: 'red', empty: true, circular: true }} text='Important' />
              <Dropdown.Item label={{ color: 'blue', empty: true, circular: true }} text='Announcement' />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown text='More' icon='globe' floating labeled button className='icon'>
            <Dropdown.Menu>
              <Dropdown.Item label={{ color: 'red', empty: true, circular: true }} text='Important' />
              <Dropdown.Item label={{ color: 'blue', empty: true, circular: true }} text='Announcement' />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={"right " + (isOpen ? '' : 'full')}>
          <Menu secondary>
            <Menu.Item fitted>
              <Input icon='search' placeholder='Search...' />
            </Menu.Item>
            <Menu.Item fitted>
              <Button icon='th' />
            </Menu.Item>
          </Menu>
          <Menu secondary className="search-create-new">
            <Menu.Item fitted>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sidebar: state.sidebar
})

export default connect(mapStateToProps)(Search)

import React, { Component, Fragment } from 'react';
import { Confirm } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { generate } from 'Redux/reducers/counting'

class SendProposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: ''
    };
  }

  onConfirm = () => {
    // let number = ''
    // var promise1 = new Promise(function (resolve, reject) {
    //   this.props.genId('noAgreement-' + this.props.year)((receive) => {
    //     number = receive
    //     resolve(number)
    //   })
    // });

    // promise1.then(function (value) {
    //   number = value
    // });

    // this.props.genId('noAgreement-' + this.props.year)

    this.setState({ test: 'check' })
    this.props.confirm()
  }

  render() {
    let { status } = this.props
    return (
      <Fragment>
        <Confirm
          open={status}
          header='คำเตือน'
          content='เมื่อกดยืนยันแล้ว โครงการนี้ จะไม่สามารถกลับไปแก้ไขข้อมูลที่อยู่ในขั้นตอน ส่งข้อเสนอโครงการได้'
          onCancel={this.props.cancel}
          onConfirm={this.onConfirm}
          cancelButton='ยกเลิก'
          confirmButton="ยืนยัน"
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  counter: state.counter,
});

const mapDispatchToProps = dispatch => ({
  genId: (name) => dispatch(generate(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SendProposal);
import React, { Component, Fragment } from 'react'
import {
  Form,
  Input,
  Select,
  Table,
  Button,
  Header,
  Label,
  Message,
  Checkbox,
  Segment,
  Transition,
  Icon,
  Modal,
  Confirm,
  TextArea
} from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css'
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react'
import moment from 'moment'
import 'moment-precise-range-plugin'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
import { HeaderContent } from 'Components/Header'
import { constraints as InfoConstraints } from './RegisConstraints'
import { constraints as InfoArrayConstraints } from './RegisConstraints'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as organizationsActions } from 'Redux/reducers/organizations'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as individuals_wActions } from 'Redux/reducers/individuals_w'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { changeManager } from 'Redux/reducers/manager'
import ComponentSetBudget from './ComponentSetBudget'
import { PersonDropdown } from 'Utils'
import { ComponentPerson } from 'Utils'
import { ComponentOrganization } from 'Utils'
import { FundingFields } from 'Utils'
import { AttachedComponent } from 'Utils'
import { ValidateArray as validateArray } from 'Utils'
import { localTh, ListTypeProject } from 'Utils'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { formatcomma, ListSeriesProject } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'
import jwt from 'jsonwebtoken'

let gettime,
  thisYear = ''
let personReceiveOptions = {}

const organize = [
  { key: '1', value: 'องค์กร', text: 'องค์กร' },
  { key: '2', value: 'บุคคล', text: 'บุคคล' },
  { key: '3', value: 'คณะบุคคล', text: 'คณะบุคคล' }
]

const contract = [
  { key: '1', text: 'โครงการเดี่ยว', value: 'โครงการเดี่ยว' },
  { key: '2', text: 'โครงการเชิงรุก', value: 'โครงการเชิงรุก' }
]

const otherBudget = [
  { key: '1', text: 'งบประมาน', value: 'งบประมาน' },
  { key: '2', text: 'สิ่งของอื่นๆ', value: 'สิ่งของอื่นๆ' },
  { key: '3', text: 'ไม่มี', value: 'ไม่มี' }
]

let organization

export class Information extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileUpload: [],
      tmp: '',
      Oganize: 'องค์กร',
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      checkReadOnly: false,
      torsList: [],
      updateSearch: false,
      modalAttachment: false,
      enterKey: false,
      onSaveSuccess: false,
      open: false,
      openConfirm: false,
      indexDelete: '',
      indexDeleteCondition: '',
      isLoadOrganize: false,
      openConfirmDelCon: false
    }
  }

  componentDidMount() {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        let checkYear = new Date(new Date(value).getFullYear(), 8, 30).getTime()
        if (value > checkYear) {
          thisYear = new Date(new Date(value).getFullYear() + 544).getTime()
        } else {
          thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        }
      }
    })
    // let query = {}
    // this.props.listOrganizations(query)
    this.props.listBudget()
    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
    this.props.getSeriesProject()
    this.props.queryTors({ status: 'อนุมัติแล้ว' })
    this.props.getTypeProject('584ccec0e1e111e889cf8170c54de9cb')
  }

  componentWillReceiveProps = nextProps => {
    let { currentProposal, match, tors } = nextProps

    let { data, isLoadOrganize } = this.state
    let isLoad = false
    let tmp = { ...nextProps.currentProposal }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        let checkYear = new Date(new Date(value).getFullYear(), 8, 30).getTime()
        if (value > checkYear) {
          thisYear = new Date(new Date(value).getFullYear() + 544).getTime()
        } else {
          thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        }
      }
    })

    if (currentProposal && match.params.id) {
      let found = false

      if (
        tors.data.length > 1 &&
        !found &&
        !this.state.updateSearch &&
        !isLoad
      ) {
        this.props.queryTors({ _id: tmp.tor_id })
      }

      if (!currentProposal.numberOfGroupBudget) {
        currentProposal.numberOfGroupBudget = 1
      }

      tors.data.map(r => {
        if (tmp.tor_id === r._id) {
          if (r.targets) {
            found = true
            let _targetGroup = []
            let _endDate,
              _startDate = ''
            if (!tmp.targetGroup) {
              _targetGroup = r.targets
            } else {
              _targetGroup = tmp.targetGroup
            }
            if (!tmp.endDate) {
              _endDate = r.endDate
            } else {
              _endDate = tmp.endDate
            }
            if (!tmp.startDate) {
              _startDate = r.startDate
            } else {
              _startDate = tmp.startDate
            }

            let clone = {
              ...data,
              targetGroup: _targetGroup,
              startDate: _startDate,
              endDate: _endDate,
              recipientOrganize: tmp.recipientOrganize,
              recipientOrganizeName: tmp.recipientOrganizeName
            }
            this.setState({ data: clone })
          }
        }
        return r
      })

      if (tmp.focusPoint && !isLoad) {
        this.props.changeManager(tmp.focusPoint)
      }

      if (!isLoadOrganize) {
        if (currentProposal.recipientType === 'องค์กร' || currentProposal.recipientType === 'คณะบุคคล') {
          if (currentProposal.recipientOrganize) {
            let query = { _id: currentProposal.recipientOrganize }
            if (currentProposal.recipientOrganize.length > 3) {
              this.props.listOrganizations(query)
              this.props.listPersonByOrganization(
                currentProposal.recipientOrganize
              )
              this.props.listPersonReceive(currentProposal.recipientOrganize)
            }
          }
        }

        else if (currentProposal.recipientType === 'บุคคล') {
          if (currentProposal.manager.length > 3) {
            let query = { _id: currentProposal.manager }
            let query1 = { _id: currentProposal.recipientPerson }
            this.props.listPersonFreelance(query)
            this.props.listPersonFreelanceReceive(query1)
          }
        }
        this.setState({ isLoadOrganize: true })
      }
      if (!data.operationTime) {
        let sd = moment(currentProposal.startDate).format('YYYY-MM-DD')
        let ed = moment(currentProposal.endDate).format('YYYY-MM-DD')

        // var diff = moment.preciseDiff(sd, ed);
        var diff = moment.preciseDiff(sd + ' 00:00:00', ed + ' 24:00:00')

        let diffyth
        let diffmth
        let diffdth

        let cy = diff.indexOf('years')

        if (cy !== -1) {
          diffyth = diff.replace('years', 'ปี')
        } else {
          diffyth = diff.replace('year', 'ปี')
        }

        let cl = diff.indexOf('months')

        if (cl !== -1) {
          diffmth = diffyth.replace('months', 'เดือน')
        } else {
          diffmth = diffyth.replace('month', 'เดือน')
        }

        let cd = diff.indexOf('days')

        if (cd !== -1) {
          diffdth = diffmth.replace('days', 'วัน')
        } else {
          diffdth = diffmth.replace('day', 'วัน')
        }

        data['operationTime'] = diffdth
        if (nextProps.currentProposal) {
          let pj = { ...nextProps.currentProposal }
          if (!pj.condition) pj.condition = []
          this.setState({ data: { ...pj, ...data } })
        }
      }
    }
  }

  handleSearch = (e, { name }) => {
    let { data } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    if (name === 'recipientOrganize') {
      if (e.target.value.length >= 3) {
        let query = {
          $or: [{ organizationName: { $regex: e.target.value } }]
        }
        this.props.listOrganizations(query)
      }
    } else if (name === 'manager') {
      if (data.recipientType === 'บุคคล') {
        if (e.target.value.length >= 3) {
          let test = e.target.value.split(' ')
          let qurey = {
            firstname: { $regex: test[0] },
            lastname: { $regex: test[1] || '' }
          }
          this.props.listPersonFreelance(qurey)
        }
      }
    } else if (name === 'recipientPerson') {
      if (data.recipientType === 'บุคคล') {
        if (e.target.value.length >= 3) {
          let test = e.target.value.split(' ')
          let qurey = {
            firstname: { $regex: test[0] },
            lastname: { $regex: test[1] || '' }
            // role: { $in: ["ผู้รับผิดชอบโครงการ"] }
          }
          this.props.listPersonFreelanceReceive(qurey)
        }
      }
    }
  }

  handleChange = (e, { name, value, checked, manager }) => {
    let { tors, currentProposal } = this.props
    let { data, validate, tmp } = this.state
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    validate.attrs = {}
    validate.error = false
    validate.validateError = {}

    if (name === 'funding') {
      data['yearBudget'] = value.value.year
      data['groupsPlan'] = value.value.group
      data['plan'] = value.value.plan
      data['activity'] = value.value.activity
      data['valueGroup'] = value.value.valueGroup
      data['valuePlan'] = value.value.valuePlan
      data['valueActivity'] = value.value.valueActivity
      data['codeBudget'] =
        parseInt(data['yearBudget'] - 2500) + data.valueActivity
      this.setState({ data, validate, tmp })
    } else if (name === 'funding1') {
      data['yearBudget1'] = value.value.year
      data['groupsPlan1'] = value.value.group
      data['plan1'] = value.value.plan
      data['activity1'] = value.value.activity
      data['valueGroup1'] = value.value.valueGroup
      data['valuePlan1'] = value.value.valuePlan
      data['valueActivity1'] = value.value.valueActivity
      data['codeBudget1'] =
        parseInt(data['yearBudget1']) - 2500 + value.value.valueActivity
      this.setState({ data, validate, tmp })
    } else if (name === 'funding2') {
      data['yearBudget2'] = value.value.year
      data['groupsPlan2'] = value.value.group
      data['plan2'] = value.value.plan
      data['activity2'] = value.value.activity
      data['valueGroup2'] = value.value.valueGroup
      data['valuePlan2'] = value.value.valuePlan
      data['valueActivity2'] = value.value.valueActivity
      data['codeBudget2'] =
        parseInt(data['yearBudget2']) - 2500 + value.value.valueActivity
      this.setState({ data, validate, tmp })
    } else if (name === 'funding3') {
      data['yearBudget3'] = value.value.year
      data['groupsPlan3'] = value.value.group
      data['plan3'] = value.value.plan
      data['activity3'] = value.value.activity
      data['valueGroup3'] = value.value.valueGroup
      data['valuePlan3'] = value.value.valuePlan
      data['valueActivity3'] = value.value.valueActivity
      data['codeBudget3'] =
        parseInt(data['yearBudget3']) - 2500 + value.value.valueActivity
      this.setState({ data, validate, tmp })
    } else if (name === 'funding4') {
      data['yearBudget4'] = value.value.year
      data['groupsPlan4'] = value.value.group
      data['plan4'] = value.value.plan
      data['activity4'] = value.value.activity
      data['valueGroup4'] = value.value.valueGroup
      data['valuePlan4'] = value.value.valuePlan
      data['valueActivity4'] = value.value.valueActivity
      data['codeBudget4'] =
        parseInt(data['yearBudget4']) - 2500 + value.value.valueActivity
      this.setState({ data, validate, tmp })
    } else if (name === 'recipientOrganize') {
      data[name] = value
      organization.map(r => {
        if (value === r.id) data['recipientOrganizeName'] = r.name
        return r
      })
      // if(JSON.parse(sessionStorage.getItem('attachSendProposal'))) {
      //   data['attachSendProposal'] = JSON.parse(sessionStorage.getItem('attachSendProposal'))
      //   sessionStorage.setItem('attachSendProposal', null)
      // }
      this.setState({ data, validate, tmp })
      this.props.updateProposal(data, data._id)
      this.props.listPersonByOrganization(value)
      this.props.listPersonReceive(value)
    } else if (name === 'recipientPerson') {
      data[name] = value
      personReceiveOptions.map(r => {
        if (value === r.value) data['recipientPersonName'] = r.text
        return r
      })
      this.setState({ data, validate, tmp })
    } else if (name === 'manager') {
      data[name] = value
      manager.map(r => {
        if (value === r.id) data['managerName'] = r.name
        return r
      })
      this.setState({ data, validate, tmp })
    } else if (name === 'recipientType') {
      data[name] = value

      if (value === 'บุคคล') {
        if (data.manager) {
          delete data.manager
        }
        delete data.recipientPerson
        delete data.recipientOrganize
        delete data.recipientOrganizeName
        // if (value.length > 3) {
        //   let qurey = {
        //     'haveOrganization': 'no',
        //   }
        //   this.props.listPersonFreelance(qurey);
        // }
      }
      this.setState({ data, validate, tmp }, () => {
        this.props.resetPersonReceive()
        this.props.resetPersonFreelance()
        this.props.resetOrganizations()
      })
    } else if (name === 'sameNameTor') {
      if (checked) {
        let index = tors.data.findIndex(x => x._id === data.tor_id)
        let tor = tors.data
        data['nameTh'] = tor[index].nameTh
        this.setState({ checkReadOnly: true })
      } else {
        data['nameTh'] = ''
        this.setState({ checkReadOnly: false })
      }
      this.setState({ data, validate, tmp })
    } else {
      data[name] = value
      this.setState({ data, validate, tmp })
    }

    // validate['validateError'] = validatejs(data, InfoConstraints.InfoConstraints)
  }

  handleSubmit = condition => {
    let { data, validate } = this.state
    // let { currentProposal, role } = this.props;
    let { currentProposal, auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    if (this.props.match.params.id) {
      data = { ...currentProposal, ...data }
    }

    if (data.askBudget === '0') delete data.askBudget
    if (data.targetGroup) {
      data.targetGroup.forEach(x => {
        if (x.targetsCount === '0') {
          delete x.targetsCount
        }
      })
    }
    data['status'] = 'ส่งข้อเสนอโครงการ'
    // if(role !== 'Admin') data['status'] = 'ส่งข้อเสนอโครงการ'
    if (role === 'Admin') {
      data['adminEdit'] = user._id
      data['adminEditTime'] = gettime
    }

    validate.attrs = {}
    validate['validateError'] = validatejs(
      data,
      InfoConstraints.InfoConstraints
    )
    // validate array
    if (!validate['validateError']) validate['validateError'] = {}

    let validateArrayError = validateArray(
      data.targetGroup,
      InfoArrayConstraints.InfoArrayConstraints
    )
    if (
      Object.keys(validateArrayError).length !== 0 &&
      validateArrayError.constructor === Object
    ) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayError).forEach(index => {
        if (validateArrayError[index].targetGroup) {
          validate['validateError']['targetGroup'] =
            validateArrayError[index].targetGroup
        }
        if (validateArrayError[index].targetsCount) {
          validate['validateError']['targetsCount'] =
            validateArrayError[index].targetsCount
        }
        if (validateArrayError[index].targetsUnit) {
          validate['validateError']['targetsUnit'] =
            validateArrayError[index].targetsUnit
        }
        if (validateArrayError[index].targetExplan) {
          validate['validateError']['targetExplan'] =
            validateArrayError[index].targetExplan
        }
        validate.attrs['targetGroup' + index] = true
      })
    }
    if (data.targetGroup.length === 0) {
      validate.error = true
      validate['validateError']['targetGroup'] = []
      validate['validateError']['targetGroup'].push('โปรดระบุกลุ่มเป้าหมาย')
    }

    if (data.typeProject === 5) {
      let validateSeries = {
        seqConnect: {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
      }
      if (data.seqConnect !== '1') {
        validateSeries.seriesConnect = {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
      } else {
        validateSeries.endDate_connect = {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
        validateSeries.startDate_connect = {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
        validateSeries.askBudget_connect = {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
      }
      let validConnect = validatejs(data, validateSeries)

      if (validConnect) {
        if (!validate['validateError']) validate['validateError'] = []
        if (validConnect.seriesConnect)
          validate['validateError']['seriesConnect'] = [' โปรดระบุ']
        if (validConnect.seqConnect)
          validate['validateError']['seqConnect'] = [' โปรดระบุ']
        if (validConnect.endDate_connect)
          validate['validateError']['endDate_connect'] = [' โปรดระบุ']
        if (validConnect.startDate_connect)
          validate['validateError']['startDate_connect'] = [' โปรดระบุ']
        if (validConnect.askBudget_connect)
          validate['validateError']['askBudget_connect'] = [' โปรดระบุ']
      }
    }

    // end validate array
    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      validate['error'] = false
      this.setState({ validate })
      if (this.props.match.params.id) {
        if (role === 'Admin') {
          data['adminEdit'] = user._id
          data['adminEditTime'] = gettime
        } else {
          data['timestamp'] = gettime
        }
        // if(JSON.parse(sessionStorage.getItem('attachSendProposal'))) {
        //   data['attachSendProposal'] = JSON.parse(sessionStorage.getItem('attachSendProposal'))
        //   sessionStorage.setItem('attachSendProposal', null)
        // }
        this.props.updateProposal(data, data._id)
        this.props.getTor(data.tor_id)
        this.setState({ onSaveSuccess: true })
        setTimeout(() => {
          this.setState({ onSaveSuccess: false })
          if (condition === 'next') {
            this.props.history.push('/project/objective/' + data._id)
          }
        }, 3000)
      } else {
        this.props.addProposal(data)
      }
    }
  }

  _renderFormField = content => {
    const { validate } = this.state
    let { currentProposal, role } = this.props

    if (currentProposal) {
      if (
        (currentProposal &&
          currentProposal.status !== 'ส่งข้อเสนอโครงการ' &&
          currentProposal.status !== 'กลั่นกรอง' &&
          role !== 'Admin') ||
        (currentProposal &&
          (currentProposal.status === 'ส่งข้อเสนอโครงการ' ||
            currentProposal.status === 'กลั่นกรอง') &&
          role !== 'Admin' &&
          role !== 'Academic' &&
          role !== 'Co-Academic')
      ) {
        if (content.control === Select) {
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              disabled
            />
          )
        }
        if (content.control === FundingFields) {
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              onKeyPress={this.handleKeyPress}
              disabled
            />
          )
        }
        if (content.control === PersonDropdown) {
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              onKeyPress={this.handleKeyPress}
              disabled
            />
          )
        } else {
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              readOnly
            />
          )
        }
      } else {
        if (content.control === Select) {
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              onKeyPress={this.handleKeyPress}
            />
          )
        } else
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              onKeyPress={this.handleKeyPress}
            />
          )
      }
    }
  }

  handleSelectRow = selectedRow => {
    // let { currentProposal, role } = this.props

    // if ((currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')) {
    //   selectedRow = null
    // }
    if (selectedRow === this.state.selectedRow) selectedRow = null
    this.setState({ selectedRow, modalAttachment: false })
  }

  handleEditRow = (idx, e, { name, value }) => {
    let { data, validate } = this.state
    const { currentProposal } = this.props

    let _targetGroup = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        currentProposal.targetGroup.forEach(item => {
          var tmp = { ...item }
          _targetGroup.push(tmp)
        })
      } else {
        data.targetGroup.forEach(item => {
          var tmp = { ...item }
          _targetGroup.push(tmp)
        })
      }
    }

    if (name === 'typeTarget') {
      if (value !== 'อื่นๆ') _targetGroup[idx]['targetGroup'] = value
      else _targetGroup[idx]['targetGroup'] = ''
    } else {
      _targetGroup[idx][name] = value
    }

    // validate array
    validate.error = false
    validate.attrs = {}
    validate.validateError = {}
    // end validate array
    let tmp = { ...data, targetGroup: _targetGroup }

    this.setState({ data: tmp, validate })
  }

  handleAddRow = () => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }

    validate.error = false
    validate.attrs = {}

    let _targetGroup = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _targetGroup = currentProposal.targetGroup.slice()
      } else {
        _targetGroup = data.targetGroup.slice()
      }
    }

    // if (!data.targetGroup) data['targetGroup'] = [];
    _targetGroup.push({
      targetGroup: '',
      detail: '',
      targetsCount: '',
      targetExplan: '',
      targetsUnit: ''
    })

    let tmp = { ...data, targetGroup: _targetGroup }

    this.setState({ data: tmp, selectedRow: _targetGroup.length - 1, validate })
  }

  handleDeleteRow = () => {
    let { data, indexDelete, validate } = this.state
    let { currentProposal } = this.props

    validate.error = false
    validate.attrs = {}

    let _targetGroup = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _targetGroup = currentProposal.targetGroup.slice()
      } else {
        _targetGroup = data.targetGroup.slice()
      }
    }

    // if (data.targetGroup) {
    // data.targetGroup.splice(indexDelete, 1)
    _targetGroup.splice(indexDelete, 1)

    let tmp = { ...data, targetGroup: _targetGroup }

    this.setState({ data: tmp, openConfirm: false, selectedRow: null })
    // }
  }

  handleDayChange = (name, index, date) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    data[name] = date._d.getTime()

    let sd = moment(data.startDate).format('YYYY-MM-DD')
    let ed = moment(data.endDate).format('YYYY-MM-DD')

    // var diff = moment.preciseDiff(sd, ed);
    var diff = moment.preciseDiff(sd + ' 00:00:00', ed + ' 24:00:00')

    let diffyth
    let diffmth
    let diffdth

    let cy = diff.indexOf('years')

    if (cy !== -1) {
      diffyth = diff.replace('years', 'ปี')
    } else {
      diffyth = diff.replace('year', 'ปี')
    }

    let cl = diff.indexOf('months')

    if (cl !== -1) {
      diffmth = diffyth.replace('months', 'เดือน')
    } else {
      diffmth = diffyth.replace('month', 'เดือน')
    }

    let cd = diff.indexOf('days')

    if (cd !== -1) {
      diffdth = diffmth.replace('days', 'วัน')
    } else {
      diffdth = diffmth.replace('day', 'วัน')
    }

    data['operationTime'] = diffdth

    let sd_connect = moment(data.startDate_connect).format('YYYY-MM-DD')
    let ed_connect = moment(data.endDate_connect).format('YYYY-MM-DD')
    var diff_connect = moment.preciseDiff(
      sd_connect + ' 00:00:00',
      ed_connect + ' 24:00:00'
    )

    let diffyth_connect
    let diffmth_connect
    let diffdth_connect

    let cy_connect = diff_connect.indexOf('years')

    if (cy_connect !== -1) {
      diffyth_connect = diff_connect.replace('years', 'ปี')
    } else {
      diffyth_connect = diff_connect.replace('year', 'ปี')
    }

    let cl_connect = diff_connect.indexOf('months')

    if (cl_connect !== -1) {
      diffmth_connect = diffyth_connect.replace('months', 'เดือน')
    } else {
      diffmth_connect = diffyth_connect.replace('month', 'เดือน')
    }

    let cd_connect = diff_connect.indexOf('days')

    if (cd_connect !== -1) {
      diffdth_connect = diffmth_connect.replace('days', 'วัน')
    } else {
      diffdth_connect = diffmth_connect.replace('day', 'วัน')
    }

    data['operationTime_connect'] = diffdth_connect

    this.setState({ data, validate })
  }

  handleTorSearchChange = (e, { searchQuery }) => {
    if (searchQuery.length === 3) {
      var query = { nameTh: { $regex: '^' + searchQuery } }
      this.props.queryTors(query)
      this.setState({ updateSearch: true })
    } else {
      if (searchQuery.length < 3) {
        this.setState({ updateSearch: false })
      }
    }
  }

  handleSendEmail = () => {
    this.setState({ modalSendEmail: true })
  }

  handleCloseModal = () => {
    this.setState({
      modalSendEmail: false,
      modalAttachment: false,
      modalAddGroupFinance: false
    })
  }

  _onUpload = () => {
    const { data } = this.state
    this.props.getTor(data.tor_id)
    this.setState({ modalAttachment: true, modalPrintout: false })
  }

  closeSendEmailModal = () => {
    this.setState({ modalSendEmail: false })
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  handleNextPage = () => {
    this.handleSubmit('next')
  }

  openConfirm = (e, { name }) => {
    this.setState({ openConfirm: true, indexDelete: name })
  }

  close = () =>
    this.setState({
      openModalPerson: false,
      openModalOrganization: false,
      openConfirm: false,
      selectedRow: null,
      typeProjectModal: false,
      openConfirmDelCon: false,
      seriesProjectModal: false
    })

  handleComponentPerson = () => {
    this.setState({ openModalPerson: true })
  }

  handleComponentOrganization = () => {
    this.setState({ openModalOrganization: true })
  }

  onMoneyChange = e => {
    let { currentProposal } = this.props
    let { data, validate } = this.state

    if (currentProposal) data = { ...currentProposal, ...data }

    let money = e.target.rawValue.replace('-', '')

    if (currentProposal.edit) {
      data['beforeEditMoney'] = currentProposal.askBudget
      data[e.target.name] = money ? money.toString() : '0'
    } else {
      data[e.target.name] = money ? money.toString() : '0'
    }
    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false
    this.setState({ data, validate })
  }

  onMoneyTargetsChange = (idx, e) => {
    let { currentProposal } = this.props
    let { data, validate } = this.state

    if (currentProposal) data = { ...currentProposal, ...data }

    let money = e.target.rawValue.replace('-', '')

    data['targetGroup'][idx][e.target.name] = money ? money.toString() : '0'
    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false

    this.setState({ data, validate })
  }

  handleAddRowCondition = () => {
    let { data } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }
    let _condition = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _condition = currentProposal.condition.slice()
      } else {
        _condition = data.condition.slice()
      }
    }

    _condition.push('')

    let tmp = { ...data, condition: _condition }
    this.setState({ data: tmp, selectCondition: _condition.length - 1 })
  }

  handleSelectRowCondition = selectCondition => {
    if (selectCondition === this.state.selectCondition) selectCondition = null
    this.setState({ selectCondition, modalAttachment: false })
  }

  handleChangeCondition = (e, { index, value }) => {
    let { data } = this.state
    let { currentProposal } = this.props
    data = { ...currentProposal, ...data }

    let _condition = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _condition = currentProposal.condition.slice()
      } else {
        _condition = data.condition.slice()
      }
    }

    _condition[index] = value

    let tmp = { ...data, condition: _condition }

    this.setState({ data: tmp })
  }

  handleOpenModalCon = (e, { index }) => {
    this.setState({ openConfirmDelCon: true, indexDeleteCondition: index })
  }

  handleDeleteConditions = e => {
    let { data, validate, indexDeleteCondition } = this.state
    let { currentProposal } = this.props

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    // if (currentProposal) data = { ...currentProposal, ...data }

    let _conditions = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _conditions = currentProposal.condition.slice()
      } else {
        _conditions = data.condition.slice()
      }
    }

    _conditions.splice(indexDeleteCondition, 1)
    let tmp = { ...data, condition: _conditions }

    this.setState({
      data: tmp,
      openConfirmDelCon: false,
      selectCondition: null
    })
  }

  handleListOrganization = () => {
    const { currentProposal } = this.props
    this.props.history.push('/project/listorganization/' + currentProposal._id)
  }

  handleModalTypeProject = () => {
    this.setState({ typeProjectModal: true })
  }

  handleModalSeriesProject = () => {
    this.setState({ seriesProjectModal: true })
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  render() {
    let {
      currentProposal,
      currentState,
      tors,
      organizations,
      role,
      typeProject,
      personReceive,
      auth
    } = this.props
    let {
      selectedRow,
      data,
      validate,
      modalAttachment,
      onSaveSuccess,
      openModalPerson,
      openModalOrganization,
      selectCondition,
      openConfirmDelCon
    } = this.state

    // data.askBudget = data.askBudget ? Math.abs(data.askBudget):0;

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    // let typeOfficeOption = [
    //   { key: '0', text: 'สำนักบริหารกลาง', value: '01' },
    //   { key: '1', text: 'สำนักพัฒนานโยบาย กลยุทธ์ และงบประมาณ', value: '02' },
    //   { key: '2', text: 'สำนักบริหารเงินอุดหนุนผู้ขาดแคลนทุนทรัพย์', value: '03' },
    //   { key: '3', text: 'สำนักพัฒนา นวัตกรรมการเรียนรู้เชิงพื้นที่', value: '04' },
    //   { key: '4', text: 'สำนักพัฒนาคุณภาพครู นักเรียนครูและสถานศึกษา', value: '05' },
    //   { key: '5', text: 'สถาบันวิจัยพัฒนาองค์ความรู้แบะเทคโนโลยีสารสนเทศเพื่อความเสมอภาคทางการศึกษา', value: '06' },
    //   { key: '6', text: 'สำนักส่งเสริมการมีส่วนร่มนวัตกรรมและทุนการศึกษา', value: '07' },
    // ]

    let typeOffice =
      typeProject.data.length > 0
        ? typeProject.data.filter(item => item.name === 'สังกัดสำนัก')
        : []
    let typeOfficeOption = []
    let officeUser = user.typeInstitution ? user.typeInstitution : []
    officeUser.forEach((item, idx) => {
      let name =
        typeOffice.length > 0
          ? typeOffice[0].typeOffice.filter(row => row.value === item)
          : []
      typeOfficeOption[idx] = {
        key: idx,
        text: name[0] ? name[0].name : '',
        value: item
      }
    })

    let typeProjectOption =
      typeProject.currentData && typeProject.currentData
        ? typeProject.currentData.type.map((item, idx) => ({
          key: idx,
          text: item.name,
          value: item.value
        }))
        : []

    let torOption = tors.data.map((item, idx) => ({
      key: item._id,
      value: item._id,
      text: item.nameTh
    }))

    if (!this.state.updateSearch && !data['tor_id']) {
      torOption = []
    }

    let typeSeriesProject =
      typeProject.data.length > 0
        ? typeProject.data.filter(
          item => item.type === 'ชุดโครงการ' && item.year === thisYear
        )
        : []

    let typeSeriesProjectOption =
      typeSeriesProject.length > 0
        ? typeSeriesProject[0].list.map((item, idx) => ({
          key: idx,
          text: item.name,
          value: item.code
        }))
        : []

    personReceiveOptions = personReceive.data.map((item, idx) => ({
      key: idx,
      text:
        item.title === 'อื่นๆ'
          ? item.otherTitle + item.firstname + ' ' + item.lastname
          : item.title + item.firstname + ' ' + item.lastname,
      value: item._id
    }))

    let organizationsOptions = organizations.data.map((item, idx) => ({
      text: item.organizationName,
      value: item._id
    }))

    organization = organizations.data.map((item, idx) => ({
      id: item._id,
      name: item.organizationName
    }))

    let optionGroup5 = [
      { key: 0, value: 1, text: 1 },
      { key: 1, value: 2, text: 2 },
      { key: 2, value: 3, text: 3 },
      { key: 3, value: 4, text: 4 },
      { key: 4, value: 5, text: 5 }
    ]

    let content = {
      tor_id: {
        control: Select,
        onSearchChange: this.handleTorSearchChange,
        options: torOption,
        search: true,
        selection: true
      },
      sameNameTor: { control: Checkbox },
      nameTh: { control: Input },
      askBudget: { control: Input, width: 4, money: 'money', maxLength: '16' },
      otherBudgetItem: { control: Input, width: 8 },
      otherBudgetDropdown: { control: Select, options: otherBudget },
      typeApprove: { control: Select, options: contract },
      recipientType: { control: Select, options: organize, width: 8 },
      recipientOrganize: {
        control: Select,
        width: 8,
        options: organizationsOptions,
        disabled: data.recipientType === 'บุคคล',
        search: true,
        selection: true
      },
      manager: {
        control: PersonDropdown,
        width: 8,
        search: true,
        selection: true
      },
      recipientPerson: {
        control: Select,
        width: 8,
        options: personReceiveOptions,
        search: true,
        selection: true
      },
      funding: { control: FundingFields, width: 16, delBudget: false },
      funding1: { control: FundingFields, width: 16 },
      funding2: { control: FundingFields, width: 16 },
      funding3: { control: FundingFields, width: 16 },
      funding4: { control: FundingFields, width: 16 },
      typeOffice: { control: Select, options: typeOfficeOption, width: 8 },
      typeProject: { control: Select, options: typeProjectOption, width: 5 },
      seriesProject: {
        control: Select,
        options: typeSeriesProjectOption,
        width: 13
      },
      numberOfGroupBudget: { control: Select, options: optionGroup5, width: 8 }
    }

    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('proposal', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].control === Input) {
        content[key].value = data[key] || ''
      } else if (content[key].name === 'funding') {
        content[key].value = {
          year: data['yearBudget'],
          group: data['groupsPlan'],
          plan: data['plan'],
          activity: data['activity'],
          valueGroup: data['valueGroup'],
          valuePlan: data['valuePlan'],
          valueActivity: data['valueActivity']
        }
      } else if (content[key].name === 'funding1') {
        content[key].value = {
          year: data['yearBudget1'],
          group: data['groupsPlan1'],
          plan: data['plan1'],
          activity: data['activity1'],
          valueGroup: data['valueGroup1'],
          valuePlan: data['valuePlan1'],
          valueActivity: data['valueActivity1']
        }
      } else if (content[key].name === 'funding2') {
        content[key].value = {
          year: data['yearBudget2'],
          group: data['groupsPlan2'],
          plan: data['plan2'],
          activity: data['activity2'],
          valueGroup: data['valueGroup2'],
          valuePlan: data['valuePlan2'],
          valueActivity: data['valueActivity2']
        }
      } else if (content[key].name === 'funding3') {
        content[key].value = {
          year: data['yearBudget3'],
          group: data['groupsPlan3'],
          plan: data['plan3'],
          activity: data['activity3'],
          valueGroup: data['valueGroup3'],
          valuePlan: data['valuePlan3'],
          valueActivity: data['valueActivity3']
        }
      } else if (content[key].name === 'funding4') {
        content[key].value = {
          year: data['yearBudget4'],
          group: data['groupsPlan4'],
          plan: data['plan4'],
          activity: data['activity4'],
          valueGroup: data['valueGroup4'],
          valuePlan: data['valuePlan4'],
          valueActivity: data['valueActivity4']
        }
      } else if (
        key === 'manager' ||
        key === 'recipientOrganize' ||
        key === 'recipientPerson'
      ) {
        content[key].onSearchChange = this.handleSearch
        content[key].value = data[key] || ''
        content[key].onChange = this.handleChange
      } else {
        content[key].value = data[key] || ''
      }
    })
    const informationOptions = [{ text: 'TOR', value: 'TOR' }]

    const optionsTarget = [
      {
        key: '0',
        text: 'เด็กและเยาวชนด้อยโอกาส อายุระหว่างแรกเกิด - ๑๘ ปี',
        value: 'เด็กและเยาวชนด้อยโอกาส อายุระหว่างแรกเกิด - ๑๘ ปี'
      },
      { key: '1', text: 'นักเรียน/นักศึกษา', value: 'นักเรียน/นักศึกษา' },
      { key: '2', text: 'ครู', value: 'ครู' },
      { key: '3', text: 'สถานศึกษา', value: 'สถานศึกษา' },
      { key: '4', text: 'องค์กร', value: 'องค์กร' },
      {
        key: '5',
        text: 'บุคลากรทางการศึกษาอื่นๆ',
        value: 'บุคลากรทางการศึกษาอื่นๆ'
      }
    ]

    let checkLockScreen =
      (currentProposal &&
        currentProposal.status !== 'ส่งข้อเสนอโครงการ' &&
        currentProposal.status !== 'กลั่นกรอง' &&
        role !== 'Admin') ||
      (currentProposal &&
        (currentProposal.status === 'ส่งข้อเสนอโครงการ' ||
          currentProposal.status === 'กลั่นกรอง') &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <HeaderContent title={data.nameTh} subtitle='ข้อมูลพื้นฐานโครงการ' />
        <AttachedComponent
          currentProposal={tors.currentData ? tors.currentData : data}
          namePage='attachTOR'
          namePageReadOnly='attachTOR'
          modalAttachment={modalAttachment}
          onHandleUpload={this.handleUploaded}
          onHandleRemove={this.handleAttachedFileRemove}
          options={informationOptions}
          onClose={this.handleCloseModal}
          checkStatus={true}
        />
        <Form error={validate.error}>
          <Form.Input
            name='nameTh'
            label='ชื่อโครงการ *'
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            value={data.nameTh || ''}
            readOnly={checkLockScreen}
          ></Form.Input>
          <Form.Group>{this._renderFormField(content.typeOffice)}</Form.Group>
          <Form.Group>
            {data &&
              data.typeProject === 3 &&
              this._renderFormField(content.seriesProject)}
            {data && data.typeProject === 3 && (
              <Form.Field width={3}>
                <label>เพิ่มชุดโครงการ</label>
                <Button
                  content='เพิ่ม'
                  color='green'
                  icon='plus'
                  onClick={this.handleModalSeriesProject}
                  disabled={role !== 'Admin'}
                />
              </Form.Field>
            )}
          </Form.Group>
          {data.typeProject === 5 && data.seqConnect === '1' && (
            <Form.Group>
              <Form.Field>
                <label>{'วันที่เริ่มต้น (ทุนต่อเนื่อง)'}</label>
                <DayPicker
                  ser={'startDate_connect'}
                  name={'startDate_connect'}
                  max={data.endDate_connect}
                  onDayChange={this.handleDayChange}
                  data={data.startDate_connect || ''}
                />
              </Form.Field>
              <Form.Field>
                <label>{'วันที่สิ้นสุด (ทุนต่อเนื่อง)'}</label>
                <DayPicker
                  ser={'endDate_connect'}
                  name={'endDate_connect'}
                  min={data.startDate_connect}
                  onDayChange={this.handleDayChange}
                  data={data.endDate_connect || ''}
                />
              </Form.Field>
              <Header style={{ fontFamily: 'supermarket', marginLeft: 20 }}>
                ระยะเวลาดำเนินการ (ทุนต่อเนื่อง) :{' '}
                {data['operationTime_connect']
                  ? data['operationTime_connect']
                  : 'ยังไม่ได้ระบุเวลาเริ่มต้น/สิ้นสุด'}
              </Header>
            </Form.Group>
          )}
          {data.typeProject === 5 && data.seqConnect === '1' && (
            <Form.Group>
              <Form.Field error={validate.attrs['askBudget_connect']} width={8}>
                <label>{'งบประมาณ (ทุนต่อเนื่อง)'}</label>
                <Cleave
                  name='askBudget_connect'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.askBudget_connect || ''}
                />
              </Form.Field>
            </Form.Group>
          )}
          {tors.data.length > 0 && (
            <Form.Group>
              <Form.Field disabled={checkLockScreen}>
                <label>{'วันที่เริ่มต้น'}</label>
                <DayPicker
                  ser={'startDate'}
                  name={'startDate'}
                  // min={tors.data[0].startDate || ''}
                  // max={data.endDate || tors.data[0].endDate}
                  max={data.endDate}
                  onDayChange={this.handleDayChange}
                  data={data.startDate || ''}
                />
              </Form.Field>
              <Form.Field disabled={checkLockScreen}>
                <label>{'วันที่สิ้นสุด'}</label>
                <DayPicker
                  ser={'endDate'}
                  name={'endDate'}
                  // min={data.startDate || tors.data[0].startDate}
                  // max={tors.data[0].endDate || ''}
                  min={data.startDate}
                  onDayChange={this.handleDayChange}
                  data={data.endDate || ''}
                />
              </Form.Field>
              <Header style={{ fontFamily: 'supermarket', marginLeft: 20 }}>
                ระยะเวลาดำเนินการ :{' '}
                {data['operationTime']
                  ? data['operationTime']
                  : 'ยังไม่ได้ระบุเวลาเริ่มต้น/สิ้นสุด'}
              </Header>
            </Form.Group>
          )}
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='หมวดงบประมาณโครงการ'
              as='h4'
              icon='money'
            />
            <Form.Group>{this._renderFormField(content.funding)}</Form.Group>
            <Form.Group widths='equal'>
              <Form.Field error={validate.attrs['askBudget']}>
                <label>{'งบประมาณ *'}</label>
                <Cleave
                  name='askBudget'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.askBudget || ''}
                  disabled={checkLockScreen}
                />
              </Form.Field>
              {this._renderFormField(content.otherBudgetDropdown)}
            </Form.Group>
            <Form.Group>
              {data.otherBudgetDropdown === 'งบประมาน' && (
                <Form.Field width='8'>
                  <label>{'งบประมาณจากแหล่งอื่น'}</label>
                  <Cleave
                    name='otherBudget'
                    maxLength='16'
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand'
                    }}
                    onChange={this.onMoneyChange.bind(this)}
                    value={data.otherBudget || ''}
                    disabled={checkLockScreen}
                  />
                </Form.Field>
              )}
              {data.otherBudgetDropdown === 'สิ่งของอื่นๆ' && (
                <Form.Field width='16'>
                  <label>{'สิ่งของอื่นๆจากแหล่งอื่น'}</label>
                  <TextArea
                    name='otherBudgetItem'
                    value={data.otherBudgetItem}
                    onChange={this.handleChange}
                    readOnly={checkLockScreen}
                  />
                </Form.Field>
              )}
            </Form.Group>
          </Segment>
          {data.numberOfGroupBudget > 1 && (
            <Segment>
              <Header
                style={{ fontFamily: 'supermarket' }}
                content={'หมวดงบประมาณโครงการ 2'}
                as='h4'
                icon='money'
              />
              <Form.Group widths='equal'>
                {this._renderFormField(content.funding1)}
              </Form.Group>
              <ComponentSetBudget
                askBudget={data.askBudget1}
                otherBudgetDropdown={data.otherBudgetDropdown1}
                nameAskBudget='askBudget1'
                nameotherDropdown='otherBudgetDropdown1'
                nameOtherBudget='otherBudget1'
                nameOtherBudgetItem='otherBudgetItem1'
                otherBudget={data.otherBudget1}
                otherBudgetItem={data.otherBudgetItem1}
                onDatatoChange={this.handleChange}
                onMoneyToChange={this.onMoneyChange}
              />
            </Segment>
          )}
          {data.numberOfGroupBudget > 2 && (
            <Segment>
              <Header
                style={{ fontFamily: 'supermarket' }}
                content={'หมวดงบประมาณโครงการ 3'}
                as='h4'
                icon='money'
              />
              <Form.Group widths='equal'>
                {this._renderFormField(content.funding2)}
              </Form.Group>
              <ComponentSetBudget
                askBudget={data.askBudget2}
                otherBudgetDropdown={data.otherBudgetDropdown2}
                nameAskBudget='askBudget2'
                nameotherDropdown='otherBudgetDropdown2'
                nameOtherBudget='otherBudget2'
                nameOtherBudgetItem='otherBudgetItem2'
                otherBudget={data.otherBudget2}
                otherBudgetItem={data.otherBudgetItem2}
                onDatatoChange={this.handleChange}
                onMoneyToChange={this.onMoneyChange}
              />
            </Segment>
          )}
          {data.numberOfGroupBudget > 3 && (
            <Segment>
              <Header
                style={{ fontFamily: 'supermarket' }}
                content={'หมวดงบประมาณโครงการ 4'}
                as='h4'
                icon='money'
              />
              <Form.Group widths='equal'>
                {this._renderFormField(content.funding3)}
              </Form.Group>
              <ComponentSetBudget
                askBudget={data.askBudget3}
                otherBudgetDropdown={data.otherBudgetDropdown3}
                nameAskBudget='askBudget3'
                nameotherDropdown='otherBudgetDropdown3'
                nameOtherBudget='otherBudget3'
                nameOtherBudgetItem='otherBudgetItem3'
                otherBudget={data.otherBudget3}
                otherBudgetItem={data.otherBudgetItem3}
                onDatatoChange={this.handleChange}
                onMoneyToChange={this.onMoneyChange}
              />
            </Segment>
          )}
          {data.numberOfGroupBudget > 4 && (
            <Segment>
              <Header
                style={{ fontFamily: 'supermarket' }}
                content={'หมวดงบประมาณโครงการ 5'}
                as='h4'
                icon='money'
              />
              <Form.Group widths='equal'>
                {this._renderFormField(content.funding4)}
              </Form.Group>
              <ComponentSetBudget
                askBudget={data.askBudget4}
                otherBudgetDropdown={data.otherBudgetDropdown4}
                nameAskBudget='askBudget4'
                nameotherDropdown='otherBudgetDropdown4'
                nameOtherBudget='otherBudget4'
                nameOtherBudgetItem='otherBudgetItem4'
                otherBudget={data.otherBudget4}
                otherBudgetItem={data.otherBudgetItem4}
                onDatatoChange={this.handleChange}
                onMoneyToChange={this.onMoneyChange}
              />
            </Segment>
          )}
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='ภาคีร่วมดำเนินงาน'
              as='h4'
              icon='user'
            />
            <Form.Group>
              {this._renderFormField(content.recipientType)}
              {this._renderFormField(content.recipientOrganize)}
            </Form.Group>
            <Form.Group>
              {this._renderFormField(content.recipientPerson)}
              {this._renderFormField(content.manager)}
            </Form.Group>
            <Button
              type='button'
              color='green'
              content='บุคลากร'
              icon='plus'
              onClick={this.handleComponentPerson}
            />
            <Button
              type='button'
              color='green'
              content='องค์กร'
              icon='plus'
              onClick={this.handleComponentOrganization}
            />
            <Button
              type='button'
              color='green'
              content='รายชื่อองค์กร'
              icon='list'
              onClick={this.handleListOrganization}
            />
          </Segment>
          <Modal
            open={openModalPerson}
            closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon
          >
            {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
            <Modal.Content>
              <ComponentPerson
                openModalPerson={openModalPerson}
                onCloseModalPerson={this.close}
              />
            </Modal.Content>
          </Modal>
          <Modal
            open={openModalOrganization}
            closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon
          >
            {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
            <Modal.Content>
              <ComponentOrganization
                openModalOrganization={openModalOrganization}
                onCloseModalPerson={this.close}
              />
            </Modal.Content>
          </Modal>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='กลุ่มเป้าหมายของโครงการ'
              icon='target'
              as='h5'
            />
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    content='กลุ่มเป้าหมาย *'
                    textAlign='center'
                    width={6}
                  />
                  <Table.HeaderCell
                    content='รายละเอียด'
                    textAlign='center'
                    width={5}
                  />
                  <Table.HeaderCell
                    content='จำนวน *'
                    textAlign='center'
                    width={3}
                  />
                  <Table.HeaderCell content='ลบ' textAlign='center' width={2} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.targetGroup &&
                  data.targetGroup.map((item, idx) => (
                    <Fragment key={idx}>
                      <Table.Row
                        onClick={this.handleSelectRow.bind(this, idx)}
                        error={validate.attrs['targetGroup' + idx]}
                      >
                        {(selectedRow === idx && (
                          <Table.Cell>
                            <Label
                              color='green'
                              ribbon
                              content={item.targetGroup}
                            />
                          </Table.Cell>
                        )) || (
                            <Table.Cell textAlign='center'>
                              {item.targetGroup}
                            </Table.Cell>
                          )}
                        <Table.Cell textAlign='center' content={item.detail} />
                        <Table.Cell
                          content={
                            formatcomma(item.targetsCount)
                              ? formatcomma(item.targetsCount) +
                              ' ' +
                              (item.targetsUnit || '')
                              : ' '
                          }
                          textAlign='center'
                        />
                        <Table.Cell textAlign='center'>
                          <Button
                            // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                            disabled={checkLockScreen}
                            type='button'
                            name={idx}
                            color='red'
                            icon='close'
                            onClick={this.openConfirm}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {selectedRow === idx && (
                        <Table.Row active>
                          <Table.Cell colSpan={4}>
                            <Form.Group widths='equal'>
                              <Form.Select
                                options={optionsTarget}
                                // readOnly={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                                disabled={checkLockScreen}
                                label='กลุ่มเป้าหมาย'
                                name='typeTarget'
                                value={item.typeTarget}
                                onChange={this.handleEditRow.bind(this, idx)}
                              />
                              <Form.Input
                                // readOnly={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                                readOnly={checkLockScreen}
                                maxLength='100'
                                label='กลุ่มเป้าหมาย (อื่นๆ โปรดระบุ)'
                                name='targetGroup'
                                value={item.targetGroup}
                                onChange={this.handleEditRow.bind(this, idx)}
                              />
                            </Form.Group>
                            <Form.Group widths='equal'>
                              <Form.Input
                                //  readOnly={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                                readOnly={checkLockScreen}
                                maxLength='500'
                                label='รายละเอียด'
                                name='detail'
                                value={item.detail}
                                onChange={this.handleEditRow.bind(this, idx)}
                              />
                              <Form.Field>
                                <label>{'จำนวน'}</label>
                                <Cleave
                                  name='targetsCount'
                                  maxLength='16'
                                  options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand'
                                  }}
                                  onChange={this.onMoneyTargetsChange.bind(
                                    this,
                                    idx
                                  )}
                                  value={item.targetsCount || ''}
                                  readOnly={checkLockScreen}
                                // readOnly={(data && data.status === 'อนุมัติแล้ว' && role !== 'Admin') || (data && data.status === 'ยังไม่อนุมัติ' && role !== 'Admin' && role !== 'Academic')}
                                />
                              </Form.Field>
                              <Form.Input
                                label='หน่วย'
                                name='targetsUnit'
                                readOnly={checkLockScreen}
                                value={item.targetsUnit || ''}
                                onChange={this.handleEditRow.bind(this, idx)}
                              />
                            </Form.Group>
                            <Form.TextArea
                              label={'ข้อความของกลุ่มเป้าหมายที่ ' + (idx + 1)}
                              name='targetExplan'
                              readOnly={checkLockScreen}
                              value={item.targetExplan}
                              onChange={this.handleEditRow.bind(this, idx)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  ))}
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>
                    <Button
                      // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                      disabled={checkLockScreen}
                      color='green'
                      content='เพิ่ม'
                      onClick={this.handleAddRow}
                      type='button'
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='เงื่อนไขและแนวทางอื่นๆ'
              as='h5'
              icon='comment alternate outline'
            />

            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    content='ลำดับ'
                    width={1}
                    textAlign='center'
                  />
                  <Table.HeaderCell
                    content='เงื่อนไขและแนวทาง'
                    width={13}
                    textAlign='center'
                  />
                  <Table.HeaderCell content='ลบ' width={2} textAlign='center' />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.condition &&
                  data.condition.map((item, idx) => (
                    <Fragment key={idx}>
                      <Table.Row
                        onClick={this.handleSelectRowCondition.bind(this, idx)}
                      >
                        {(selectCondition === idx && (
                          <Table.Cell>
                            <Label
                              color='green'
                              ribbon
                              content={'ลำดับที่  ' + (idx + 1)}
                            />
                          </Table.Cell>
                        )) || (
                            <Table.Cell
                              content={idx + 1}
                              textAlign='center'
                              width={1}
                            />
                          )}
                        <Table.Cell content={item} />
                        <Table.Cell textAlign='center'>
                          {' '}
                          <Button
                            color='red'
                            icon='close'
                            index={idx}
                            onClick={this.handleOpenModalCon}
                            disabled={checkLockScreen}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {selectCondition === idx && (
                        <Table.Row active>
                          <Table.Cell colSpan={3}>
                            <Form.TextArea
                              readOnly={checkLockScreen}
                              label='เงื่อนไขและแนวทาง'
                              index={idx}
                              onChange={this.handleChangeCondition}
                              value={item}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  ))}
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan='3'>
                    {
                      <Button
                        type='button'
                        color='green'
                        content='เพิ่ม'
                        onClick={this.handleAddRowCondition}
                        disabled={validate.error || checkLockScreen}
                      />
                    }
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Transition
            visible={onSaveSuccess}
            animation='horizontal flip'
            duration={500}
          >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                <Icon name='check circle'></Icon>บันทึกสำเร็จ
              </Message.Header>
            </Message>
          </Transition>
          <Button
            type='submit'
            color='blue'
            content='บันทึก'
            icon='save'
            // disabled={validate.error || (currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
            disabled={checkLockScreen}
            loading={currentState.isSaving}
            onClick={this.handleSubmit}
          />
          <Button
            className='button-bottom-right'
            type='button'
            color='blue'
            content='ถัดไป'
            disabled={checkLockScreen}
            icon='step forward'
            onClick={this.handleNextPage}
          // disabled={checkLockScreen}
          />
        </Form>
        <Modal
          open={this.state.typeProjectModal}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon
        >
          <Modal.Content>
            <ListTypeProject onClose={this.close} />
          </Modal.Content>
        </Modal>
        <Modal
          style={{ marginTop: 50 }}
          open={this.state.seriesProjectModal}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon
        >
          <Modal.Content>
            <ListSeriesProject onClose={this.close} year={thisYear} />
          </Modal.Content>
        </Modal>
        <Confirm
          open={this.state.openConfirm}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.close}
          onConfirm={this.handleDeleteRow}
        />
        <Confirm
          open={openConfirmDelCon}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.close}
          onConfirm={this.handleDeleteConditions}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  form: state.proposalForm,
  currentState: state.projects,
  tors: state.tors,
  organizations: state.organizations,
  role: state.role,
  typeProject: state.typeproject,
  personReceive: state.individuals,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  queryTors: query => dispatch(torActions.fetchList({ query: query })),
  listPersonReceive: id =>
    dispatch(individualsActions.fetchList({ query: { organization: id } })),
  resetPersonReceive: () => dispatch(individualsActions.resetList()),
  resetPersonFreelance: () => dispatch(individuals_wActions.resetList()),
  resetOrganizations: () => dispatch(organizationsActions.resetList()),
  listOrganizations: query =>
    dispatch(organizationsActions.fetchList({ query: query })),
  listPersonByOrganization: id =>
    dispatch(individuals_wActions.fetchList({ query: { organization: id } })),
  listBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  addProposal: data => dispatch(projectActions.save(data)),
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id)),
  getTor: id => dispatch(torActions.fetchOne(id)),
  changeManager: data => dispatch(changeManager(data)),
  listPersonFreelanceReceive: query =>
    dispatch(individualsActions.fetchList({ query: query, limit: 30 })),
  listPersonFreelance: query =>
    dispatch(individuals_wActions.fetchList({ query: query, limit: 30 })),
  getTypeProject: id => dispatch(typeProject.fetchOne(id)),
  getSeriesProject: () => dispatch(typeProject.fetchList())
})

export default connect(mapStateToProps, mapDispatchToProps)(Information)

var PageProgressConstraints = {
  // approveBudget:{
  //   presence: {
  //     allowEmpty: false,
  //     message:'^ต้องระบุ'
  //   }
  // },
  // approveAlreadyWithdraw: {
  //   presence: {
  //     allowEmpty: false,
  //     message:'^ต้องระบุ'
  //   }
  // },
  // approveAlreadyWithdrawAmount: {
  //   presence: {
  //     allowEmpty: false,
  //     message:'^ต้องระบุ'
  //   },
  //   numericality: {
  //     onlyInteger: true,
  //     strict: true,
  //     message: '^ต้องเป็นตัวเลขเท่านั้น'
  //   },
  // },
  approveRateProgress: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  approveProblem: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  approveManage: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  approveMoneyProblem: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // approveRateInterest: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  approveUseMoney: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  approveWithdraw: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  approveDeadline: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // approveWithdrawAmount: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  // approveUseMoneyAmount: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
}


var WithdrawFormConstraints = {
  // codeDisburse: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  // withdrawCostOfOperation: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  sendWorkTime: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // withdrawCompensationManager: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  // withdrawCompensationProject: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  // withdrawAmount: {
  //   presence: {
  //     allowEmpty: false,
  //     message:'^ต้องระบุ'
  //   },
  // },

  // withdrawTaxDeduction: {
  //   presence: {
  //     allowEmpty: false,
  //     message:'^ต้องระบุ'
  //   },
  // },
  // withdrawPayment: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
}

var WithdrawFormConditionConstraints = {
  codeDisburse: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  withdrawCostOfOperation: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // withdrawServiceFee: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  withdrawCompensationManager: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  // withdrawCompensationProject: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   },
  // },
  withdrawPayChequeBy: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  sendWorkTime: {
    presence: {
      allowEmpty: false,
      message:'^ต้องระบุ'
    },
  },
  withdrawPayment: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var ProgressProjectConstraints = {
  progressWithdrawProblem: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  progressWithdrawFixProblem: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}

var ExtendTimeConstraints = {
  reasonExtend: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  commentExtend: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  startDateExtend: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  endDateExtend: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
}



let constraints = {
  PageProgressConstraints: PageProgressConstraints,
  WithdrawFormConstraints: WithdrawFormConstraints,
  ProgressProjectConstraints: ProgressProjectConstraints,
  WithdrawFormConditionConstraints: WithdrawFormConditionConstraints,
  ExtendTimeConstraints: ExtendTimeConstraints,
}


module.exports = { constraints }

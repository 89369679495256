import { combineReducers } from 'redux'

import page from './page'
import todos from './todos'
import projects from './projects'
import oldprojects from './oldprojects'
import tors from './tors'
import allprojects from './allprojects'
import alltors from './alltors'
import organizations from './organizations'
import organizations_w from './organizations_w'
import individuals from './individuals'
import fundings from './fundings'
import emails from './emails'
import scholar from './scholar'
import search from './search'
import sidebar from './sidebar'
import user from './user'
import user_1 from './user_1'
import receipts from './receipts'
import typeproject from './typeproject'
import burnprojects from './burnprojects'
import individuals_w from './individuals_w'
import individuals_ap from './individuals_ap'
import shareuser from './shareuser'
import professional_b from './professional_b'
import professional_burn from './professional_burn'
import bankgroup from './bankgroup'
import department from './department'
import natualPerson from './natualPerson'
import departmentCheck from './departmentCheck'
import natualPersonCheck from './natualPersonCheck'
import { authReducers as auth } from './auth'
import { managerReducers as manager } from './manager'
import { counterReducers as counter } from './counting'
import countproject from './countproject'
import countborrow from './countscholar'
import send_PO_AX from './send_PO_AX'
import send_vendor_AX from './send_vendor_AX'

import role from './role'

export default combineReducers({
  page,
  todos,
  projects,
  tors,
  organizations,
  fundings,
  scholar,
  individuals,
  search,
  auth,
  manager,
  emails,
  alltors,
  allprojects,
  sidebar,
  user,
  role,
  counter,
  oldprojects,
  receipts,
  burnprojects,
  typeproject,
  individuals_w,
  individuals_ap,
  organizations_w,
  shareuser,
  user_1,
  professional_b,
  professional_burn,
  bankgroup,
  natualPerson,
  department,
  departmentCheck,
  natualPersonCheck,
  countproject,
  countborrow,
  send_PO_AX,
  send_vendor_AX
});




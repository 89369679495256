import React, { Component } from 'react';
import DayPicker from 'react-datepicker';
import PropTypes from 'prop-types'
// import ExampleCustomInput from './CustomInput'
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'semantic-ui-react';
import { GetServerTime as GetTime } from 'Utils'

const thaiMount = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค.',
  'เม.ย.',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
]

let gettime = undefined

class ExampleCustomInput extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string
  }

  render() {
    return (
      <Button
        type='button'
        color='blue'
        className="example-custom-input"
        onClick={this.props.onClick}
        content={this.props.placeholder} />
    )
  }
}

export default class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment()
    };
    this.handleDayChange = this.handleDayChange.bind(this);
  }

  handleDayChange = (date) => {
    const { name, index } = this.props
    if (this.props.onDayChange) this.props.onDayChange(name, index, date)
  }

  getTimeFuction = () => {
    new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })
  }

  render() {
    const { data, ser, max, min } = this.props
    let date
    let dataselect = new Date()
    if (data.toString().length > 1) {
      var day = ((new Date(data)).getDate());
      var month = ((new Date(data)).getMonth());
      var fullyear = ((new Date(data)).getFullYear() + 543);
      var year = ((new Date(data)).getFullYear());
      date = day + " " + thaiMount[month] + " " + fullyear
      dataselect = year + '-' + (month + 1) + '-' + day
    }

    if(!gettime) {
      // this.getTimeFuction()
    }

    let defaultdate_null = null
    if (max && gettime) {
      if (max < gettime) {
        let date_max = new Date(max)
        date_max.setDate(date_max.getDate() + 1);
        var dayx = ((date_max).getDate());
        var monthx = ((date_max).getMonth());
        // var fullyearx = ((new Date(max)).getFullYear() + 543);
        var yearx = ((date_max).getFullYear());
        defaultdate_null = yearx + '-' + (monthx + 1) + '-' + (dayx)
        // defaultdate_null = new Date(max)
      }
    }

    if (min && gettime) {
      if (min > gettime) {
        let date_min = new Date(min)
        date_min.setDate(date_min.getDate() - 1);
        var dayn = ((date_min).getDate());
        var monthn = ((date_min).getMonth());
        // var fullyearn = ((new Date(min)).getFullYear() + 543);
        var yearn = ((date_min).getFullYear());
        defaultdate_null = yearn + '-' + (monthn + 1) + '-' + (dayn)
        // defaultdate_null = new Date(min)
      }
    }

    return (
      <DayPicker
        customInput={<ExampleCustomInput label={ser} />}
        maxDate={((max && moment(max)) || moment('2100-12-31'))}
        minDate={((min && moment(min)) || moment('1997-01-01'))}
        showMonthDropdown
        showYearDropdown
        defaultValue={''}
        dropdownMode="select"
        dateFormat="DD-MM-YYYY"
        onChange={this.handleDayChange}
        selected={data.toString().length > 1 ? moment(dataselect) : defaultdate_null ? moment(defaultdate_null) : null}
        placeholderText={date || 'เลือกวันที่'} />
    )
  }
}

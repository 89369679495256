import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
// import Bubble from 'Components/Bubble'
import { Router } from 'Containers/Router'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import reducers from './redux/reducers'
import registerServiceWorker from './registerServiceWorker'
import 'semantic-ui-css/semantic.min.css';

const Bubble = React.lazy(() => import('Components/Bubble'));

const store = createStore(reducers, applyMiddleware(thunk))

if (caches) {
  caches.keys().then((names) => {
    // Delete all the cache files
    console.log('name', names)
    if (names.length > 0) {
      names.forEach(name => {
        caches.delete(name);
      })
    }
  });
}

const withAnimate =
  <div className="animate-bubble" >
    <Suspense fallback={<div>Loading...</div>}>
      <Bubble />
      <Bubble bottom="0" left="110vh" size="150vh" />
    </Suspense>
    <Router />
  </div >

ReactDOM.render(
  <Provider store={store}>
    {withAnimate}
  </Provider>,
  document.getElementById('root')
)

registerServiceWorker()

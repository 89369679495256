import React, { Component, Fragment } from 'react';
import { Form, Table, Segment, Icon, Button, Checkbox, Transition, Message } from 'semantic-ui-react';
import { connect } from 'react-redux'
import { actions as usersActions } from 'Redux/reducers/user'
import { actions as shareuserActions } from 'Redux/reducers/shareuser'


class ShareUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      username: '',
      isLoad: false,
      isAdd: false,
      onSaveNotSuccess: false
    };
  }

  getLocalUser = (cb) => {
    import('jsonwebtoken').then((jwt) => {
      const token = localStorage.getItem("id_token")
      var content = jwt.decode(token)
      console.log(content)
      this.setState({ 'localUser': content })
    });
  }

  componentDidMount = () => {
    this.props.listUser()

  }

  createShare = () => {
    const { projectShare } = this.props
    const { localUser } = this.state

    let tmp = {
      projectShareId: projectShare._id,
      mainUserId:localUser._id,
      listUsers: []
    }
    this.props.addShare(tmp)
  }

  handleChange = (e, { name, value }) => {
    this.setState({ username: value })
  }

  handleAddUser = () => {
    const { shareuser, users } = this.props
    let { data, username } = this.state

    if (shareuser) data = { ...shareuser.data[0], ...data }

    let checkLoop = false
    let userFilter = {}

    for (let key in users) {
      if (users[key]._id === username) {
        userFilter = users[key]
      }
    }

    if (data['listUsers']) {
      data['listUsers'].forEach(x => {
        if (x._id === userFilter._id) {
          checkLoop = true
        }
      });
    }

    if (userFilter._id !== data['mainUserId'] && !checkLoop) {
      data['listUsers'].push({
        _id: userFilter._id,
        title: userFilter.title,
        firstname: userFilter.firstname,
        lastname: userFilter.lastname,
        user: userFilter.user,
        email: userFilter.email,
        tel: userFilter.tel,
        checkboxOn: true
      })
    }
    if (checkLoop) {
      this.setState({ onSaveNotSuccess: true });
      setTimeout(() => {
        this.setState({ onSaveNotSuccess: false });
      }, 5000)
    }
    this.props.updateShare(data, data._id)
  }

  handleDelUser = (idx) => {
    const { shareuser } = this.props
    let { data } = this.state

    if (shareuser) data = { ...shareuser.data[0], ...data }

    data.listUsers.splice(idx, 1)

    this.props.updateShare(data, data._id)


  }

  handleOnOffShare = (idx, id, e, { name, checked }) => {
    const { shareuser } = this.props
    let { data } = this.state

    if (shareuser) data = { ...shareuser.data[0], ...data }

    data.listUsers[idx][name] = checked

    this.props.updateShare(data, data._id)
  }

  render() {
    const { users, auth, shareuser } = this.props
    let { localUser, username, onSaveNotSuccess } = this.state
    let user = null;
    if (auth.user) {
      user = auth.user.user
    } else {
      if (localUser) user = localUser
    }
    if (!user && auth.isAuthenticated && !localUser) {
      this.getLocalUser()
    }

    let personKssOptions = users && users.map((item, idx) => ({
      key: idx,
      text: item.title === 'อื่นๆ' ? item.otherTitle + '  ' + item.firstname + ' ' + item.lastname : item.title + '  ' + item.firstname + ' ' + item.lastname,
      value: item._id
    }))

    return (
      <Fragment>
        {shareuser && shareuser.data.length === 0 &&
          <Segment className="ui placeholder segment" >
            <div className="ui icon header">
              <Icon name='share square' />
              <h2 style={{ fontFamily: 'supermarket' }}>
                {/* ไม่มีสร้างใบสำคัญรับเงิน */}
              </h2>
            </div>
            <Button color='blue' style={{ fontFamily: 'supermarket' }} onClick={this.createShare}>เปิดใช้งานการแชร์โครงการ</Button>
          </Segment>
        }
        {shareuser && shareuser.data.length !== 0 &&
          <Form>
            <Form.Group>
              <Form.Select
                name='username'
                width='5'
                options={personKssOptions}
                placeholder='Username'
                search
                onChange={this.handleChange}
                value={username}
              />
              <Form.Button onClick={this.handleAddUser}><p style={{ fontFamily: 'supermarket' }}>เพิ่ม</p></Form.Button>
            </Form.Group>
          </Form>
        }
        {shareuser && shareuser.data.length !== 0 &&
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>เปิด/ปิด</Table.HeaderCell>
                <Table.HeaderCell>ชื่อ-นามสกุล</Table.HeaderCell>
                <Table.HeaderCell>เบอร์โทรศัพท์</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell>ลบ</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {shareuser.data[0].listUsers.map((item, idx) =>
                <Table.Row key={idx}>
                  <Table.Cell><Checkbox
                    name='checkboxOn'
                    toggle
                    onChange={this.handleOnOffShare.bind(this, idx, item._id)}
                    checked={item.checkboxOn}
                  />
                  </Table.Cell>
                  <Table.Cell>{item.title + item.firstname + ' ' + item.lastname}</Table.Cell>
                  <Table.Cell>{item.tel ? item.tel : '-'}</Table.Cell>
                  <Table.Cell>{item.email}</Table.Cell>
                  <Table.Cell><Button icon='close' onClick={this.handleDelUser.bind(this, idx)} /></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        }
        <Transition visible={onSaveNotSuccess} animation='horizontal flip' duration={500} >
          <Message negative>
            <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='close'></Icon>เพิ่มชื่อไม่สำเร็จ ชื่อที่เลือกมีอยู่แล้ว</Message.Header>
          </Message>
        </Transition>
      </Fragment>
    )
  }
}


const mapStateToProps = state => ({
  projects: state.projects,
  auth: state.auth,
  role: state.role,
  users: state.user.data,
  shareuser: state.shareuser
})

const mapDispatchToProps = dispatch => ({
  addShare: (data) => dispatch(shareuserActions.save(data)),
  updateShare: (data, id) => dispatch(shareuserActions.save(data, id, 'POST')),
  listUser: () => dispatch(usersActions.fetchList()),
  getShare: (id) => dispatch(shareuserActions.fetchOne(id)),
  getlistShare: (query) => dispatch(shareuserActions.fetchList({ query })),

})

export default connect(mapStateToProps, mapDispatchToProps)(ShareUsers)
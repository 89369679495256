import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  Dropdown,
  Form
} from 'semantic-ui-react'
import { actions as fundingsActions } from 'Redux/reducers/fundings'


class FundingFields extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      value: {},
      disabledGselected: true,
      disabledYselected: true,
      disabledPselected: true,
      search: true,
      lastValue: '',
      lastName: '',
      lastYear: '',
    }
  }

  componentDidMount() {
    this.props.listYearBudget()
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps
    if (value) {
      let obj = {
        ...this.state
      }
      if (value) {
        obj.value = value
        if (value.year) obj.disabledYselected = false
        if (value.group) obj.disabledGselected = false
        if (value.plan) obj.disabledPselected = false
      }
      this.setState({
        ...obj
      });
    }
    if (this.props.readOnly) {
      this.setState({
        disabledYear: true,
        disabledGselected: true,
        disabledYselected: true,
        disabledPselected: true,
        search: false,
      })
    }
  }


  handleChange = (e, { name, value, options }) => {
    const { fundings } = this.props
    let obj = {
      ...this.state
    }

    obj['value'][name] = value;

    let current = fundings.filter(item => item.year === obj.value.year)[0]
    const obgroups = this.mapArrayBudget(current ?
      current.list
        .filter(item => item.group)
        .map(item => ({ name: item.valueGroup, value: item.group }))
      : [{ name: '', value: '' }])

    const obplans = this.mapArrayBudget(current ?
      current.list
        .filter(item => item.valuePlan && item.valueGroup === obj.value.valueGroup)
        .map(item => ({ name: item.valuePlan, value: item.plan }))
      : [{ name: '', value: '' }])

    const obactivities = this.mapArrayBudget(current ?
      current.list
        .filter(item => item.valueActivity && item.valuePlan === obj.value.valuePlan)
        .map(item => ({ name: item.valueActivity, value: item.activity }))
      : [])

    if (name === 'year') {
      let selectedYear = options.filter(item => item.value === value)
      if (selectedYear.length === 1) {
        obj['value']['plan'] = null
        obj['value']['valuePlan'] = null
        obj['value']['plan'] = null
        obj['value']['valuePlan'] = null
        obj['value']['activity'] = null
        obj['value']['valueActivity'] = null
        obj['value']['version'] = '1'
        this.setState({ ...obj, lastYear: value, disabledYselected: false });
      }
    } else {
      if (name === 'valueGroup') {
        const groupvalue = obgroups.filter(item => item.name === value)
        if (groupvalue.length === 1) obj['value']['group'] = groupvalue[0].value
        else obj['value']['group'] = null
        obj['value']['plan'] = null
        obj['value']['valuePlan'] = null
        obj['value']['activity'] = null
        obj['value']['valueActivity'] = null
        this.setState({ ...obj, disabledGselected: false })
      }
      if (name === 'valuePlan') {
        const planvalue = obplans.filter(item => item.name === value)
        if (planvalue.length === 1) obj['value']['plan'] = planvalue[0].value
        else obj['value']['plan'] = null
        obj['value']['activity'] = null
        obj['value']['valueActivity'] = null
        this.setState({ ...obj, disabledPselected: false })
      }
      if (name === 'valueActivity') {
        const activityvalue = obactivities.filter(item => item.name === value)
        if (activityvalue.length === 1) obj['value']['activity'] = activityvalue[0].value
        else obj['value']['activity'] = null
        this.setState({ ...obj })
      }
      if (name === 'version') {
        obj['value']['version'] = value
        this.setState({ ...obj })
      }
    }
    this.setState({ lastValue: value, lastName: name })
    if (this.props.onChange) this.props.onChange(e, {
      name: this.props.name,
      value: { ...obj }
    });
  }

  mapArrayBudget = (data) => {
    let result = []
    if (data.length > 0) {
      result[0] = data[0]
      for (let i = 1; i < data.length; i++) {
        let check = false
        for (let j = 0; j < result.length; j++) {
          if (data[i].name === result[j].name) check = true
        }
        if (!check) result.push(data[i])
      }
    }
    return result
  }

  render() {
    let { fundings } = this.props
    const {
      value,
      // disabledYear,
      // disabledGselected,
      // disabledYselected,
      // disabledPselected,
      search,
    } = this.state

    const yearOptions = this.mapArrayBudget(fundings.filter(item => item.used === true)
      .map((item, idx) => ({
        name: item.year,
        key: item._id,
        value: item.year,
        text: item.year
      }))
    );

    // let finalVersion = ''
    // let listVersion = fundings.filter(item => item.year === '2561' || item.year === '2562')

    // for (let i = 0; i < listVersion.length; i++) {
    //   if (i > 0) {
    //     let a = parseInt(listVersion[(i - 1)].version)
    //     let b = parseInt(listVersion[i].version)
    //     if (a < b) finalVersion = listVersion[i].version
    //     else finalVersion = listVersion[(i - 1)].version
    //   } else finalVersion = listVersion[i].version
    // }

    let current = fundings.filter(item => (item.year === value.year))[0]

    let groups = current && value.year ?
      this.mapArrayBudget(current.list
        .filter(item => item.valueGroup)
        .map((item, idx) => ({
          key: idx,
          name: item.valueGroup,
          value: item.valueGroup,
          text: (item.valueGroup || 'ไม่มีรหัส') + ' : ' + item.group
        })))
        .sort((a, b) => a.value.localeCompare(b.value))
      : []

    let plans = current && value.valueGroup ?
      this.mapArrayBudget(current.list
        .filter(item => item.valuePlan && item.valueGroup === value.valueGroup)
        .map((item, idx) => ({
          key: idx,
          name: item.valuePlan,
          value: item.valuePlan,
          text: (item.valuePlan || 'ไม่มีรหัส') + ' : ' + item.plan
        })))
        .sort((a, b) => a.value.localeCompare(b.value))
      : []

    let activities = current && value.valuePlan ?
      this.mapArrayBudget(current.list
        .filter(item => item.valueActivity && item.valuePlan === value.valuePlan)
        .map((item, idx) => ({
          key: idx,
          name: item.valueActivity,
          value: item.valueActivity,
          text: (item.valueActivity || 'ไม่มีรหัส') + ' : ' + item.activity
        })))
        .sort((a, b) => a.value.localeCompare(b.value))
      : []

    return (
      <Fragment>
        <Form.Group>
          <Form.Field control={Dropdown} width={8}
            // {...DropdownExtensions}
            label='* ปีงบประมาณ *'
            options={yearOptions}
            placeholder='ปีงบประมาณ'
            value={value.year}
            name='year'
            search={true}
            selection
            onChange={this.handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Field control={Dropdown} width={16}
            // {...DropdownExtensions}
            label='* กลุ่มแผนงาน *'
            options={groups}
            search={search}
            selection
            value={value.valueGroup}
            name='valueGroup'
            onChange={this.handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Field control={Dropdown} width={16}
            // {...DropdownExtensions}
            label='* แผนงาน *'
            options={plans}
            search={search}
            selection
            value={value.valuePlan}
            name='valuePlan'
            onChange={this.handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Field control={Dropdown} width={16}
            // {...DropdownExtensions}
            label='* กลุ่มกิจกรรม *'
            options={activities}
            search={search}
            selection
            value={value.valueActivity}
            name='valueActivity'
            onChange={this.handleChange} />
        </Form.Group>
      </Fragment >
    )
  }
}

const mapState = state => ({
  fundings: state.fundings.data,
  //current:state.fundings.currentData,
})

const mapAction = dispatch => ({
  listYearBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  getDoc: (id) => dispatch(fundingsActions.fetchOne(id)),
  updateDoc: (data, id) => dispatch(fundingsActions.save(data, id, 'POST')),
  deleteDoc: (id) => dispatch(fundingsActions.delete(id)),
  addDoc: (data) => dispatch(fundingsActions.save(data))
})

export default connect(mapState, mapAction)(FundingFields)
import React, { Component, Fragment } from 'react'
import {
  Header,
  Form,
  Button,
  Select,
  Input,
  Checkbox,
  Message,
  Segment,
  TextArea,
  Transition,
  Icon,
  Confirm
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
import { actions as projectActions } from 'Redux/reducers/projects'
import { constraints as PageProgressConstraints } from './ProgressConstraints'
import { localTh } from 'Utils'
import { ThaiDate } from 'Utils'

import { DatePicker as DayPicker } from 'Utils'
import { formatcomma } from 'Utils'

let gettime = ''

const RateProgressOptions = [
  { key: 'y', text: 'เป็นไปตามแผน', value: 'y_plan' },
  { key: 'n', text: 'ไม่เป็นไปตามแผน', value: 'n_plan' }
]

const ProblemOptions = [
  { key: 'y', text: 'มี', value: 'have' },
  { key: 'n', text: 'ไม่มี', value: 'nohave' }
]

const ManageOptions = [
  { key: 'y', text: 'มี', value: 'have' },
  { key: 'n', text: 'ไม่มี', value: 'nohave' }
]

const MoneyOptions = [
  { key: 'y', text: 'มี', value: 'have' },
  { key: 'n', text: 'ไม่มี', value: 'nohave' }
]

const RateInterestOptions = [
  { key: 'a', text: 'สูง', value: 'very' },
  { key: 'b', text: 'ปานกลาง', value: 'mid' },
  { key: 'c', text: 'ต่ำ', value: 'low' }
]

const UseMoneyOptions = [
  { key: 'y', text: 'เงินคงเหลือ', value: 'have' },
  { key: 'n', text: 'ใช้เงินเกิน', value: 'nohave' }
]
const WithdrawOptions = [
  { key: 'y', text: 'ไม่เต็มวงเงิน', value: 'have' },
  { key: 'n', text: 'เต็มวงเงิน', value: 'nohave' },
  { key: 'c', text: 'ไม่เบิก', value: 'cancel' }
]

const DeadliWithdrawOptions = [
  { key: 'a', text: 'เป็นไปตามกำหนด', value: 'Scheduled' },
  { key: 'b', text: 'เบิกจ่ายก่อนกำหนด', value: 'preScheduled' },
  { key: 'c', text: 'เบิกล่าช้ากว่ากำหนด', value: 'delayScheduled' }
]

class PageProgress extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fileUpload: [],
      type: 'single',
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      saved: false,
      onSaveSuccess: false,
      modalEditTime: false,
      validateSP: false,
      onSaveSuccessSP: false
    }
  }

  componentDidMount () {
    const { match, currentProposal } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    if (!currentProposal['progressPeriod']) {
      currentProposal['progressPeriod'] = []
    }

    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    })
  }

  componentWillReceiveProps (nextProps) {
    let { currentProposal } = nextProps
    let { data } = this.state
    if (!currentProposal['progressPeriod']) {
      currentProposal['progressPeriod'] = []
    }

    let countPeriod = currentProposal.withDrawTime.length

    if (
      currentProposal.progressPeriod &&
      currentProposal.progressPeriod.length === 0
    ) {
      for (let i = 0; i < countPeriod; i++) {
        currentProposal.progressPeriod.push({})
      }
    }

    if (nextProps.currentProposal) {
      let tmp = { ...nextProps.currentProposal }
      this.setState({ data: { ...data, ...tmp } })
    }
  }

  handleChange = (e, { name, value, checked, idx }) => {
    let { data, validate } = this.state
    const { index, special } = this.props
    if (special) {
      if (
        name === 'approveReportProgress' ||
        name === 'approveReportMoney' ||
        name === 'approveCdData' ||
        name === 'approveReportOther' ||
        name === 'progressResult' + idx
      ) {
        data.progressPeriodSP[index][name] = checked
      } else {
        data.progressPeriodSP[index][name] = value
      }
      validate['validateError'] = validatejs(
        data.progressPeriodSP[index],
        PageProgressConstraints.PageProgressConstraints
      )
      validate.attrs = {}
      validate.error = false
      this.setState({ data, validate })
    } else {
      if (
        name === 'approveReportProgress' ||
        name === 'approveReportMoney' ||
        name === 'approveCdData' ||
        name === 'approveReportOther' ||
        name === 'progressResult' + idx
      ) {
        data.progressPeriod[index][name] = checked
      } else {
        data.progressPeriod[index][name] = value
      }
      validate['validateError'] = validatejs(
        data.progressPeriod[index],
        PageProgressConstraints.PageProgressConstraints
      )
      validate.attrs = {}
      validate.error = false

      this.setState({ data, validate })
    }
  }

  handleSubmit = () => {
    let { data, validate } = this.state
    let { currentProposal, index, special } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    })
    if (this.props.currentProposal) data = { ...currentProposal, ...data }

    if (special) {
      validate.attrs = {}
      validate['validateError'] = validatejs(
        data.progressPeriodSP[index],
        PageProgressConstraints.PageProgressConstraints
      )
      if (validate['validateError']) {
        validate['error'] = true
        Object.keys(validate['validateError']).forEach(item => {
          validate.attrs[item] = true
        })
        this.setState({ validate })
      } else {
        validate['error'] = false
        this.setState({ validate, onSaveSuccess: true })
        setTimeout(() => {
          this.setState({ onSaveSuccess: false })
        }, 3000)
        data['timestamp'] = gettime
        this.props.updateProposal(data, data._id)
      }
    } else {
      validate.attrs = {}
      validate['validateError'] = validatejs(
        data.progressPeriod[index],
        PageProgressConstraints.PageProgressConstraints
      )
      if (validate['validateError']) {
        validate['error'] = true
        Object.keys(validate['validateError']).forEach(item => {
          validate.attrs[item] = true
        })
        this.setState({ validate })
      } else {
        validate['error'] = false
        this.setState({ validate, onSaveSuccess: true })
        setTimeout(() => {
          this.setState({ onSaveSuccess: false })
        }, 3000)
        data['timestamp'] = gettime
        this.props.updateProposal(data, data._id)
      }
    }
  }

  handleDayChangeRow = (name, index, date) => {
    let { data, validate } = this.state
    let { currentProposal, special } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    if (special) {
      data.progressPeriodSP[index][name] = date._d.getTime()
    } else {
      data.progressPeriod[index][name] = date._d.getTime()
    }

    if (!validate.attrs) validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
    this.setState({ data, validate })
  }

  _renderFormField = content => {
    const { validate } = this.state
    const { currentProposal, role } = this.props

    if (
      (currentProposal &&
        currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
        role !== 'Admin') ||
      (currentProposal &&
        currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')
    ) {
      if (content.control === Select) {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            disabled
          />
        )
      } else
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            readOnly
          />
        )
    } else if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else
      return <Form.Field {...content} error={validate.attrs[content.name]} />
  }

  handleNextPage = () => {
    const { currentProposal } = this.props
    this.props.history.push('/project/tabWithdrawForm/' + currentProposal._id)
  }

  handleBackPage = () => {
    const { currentProposal } = this.props
    this.props.history.push('/project/pagepromise/' + currentProposal._id)
  }

  onMoneyChange = e => {
    let { data, validate } = this.state
    let { currentProposal, index, special } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    let money = e.target.rawValue.replace('-', '')

    if (special) {
      data.progressPeriodSP[index][e.target.name] = money
        ? money.toString()
        : '0'
    } else {
      data.progressPeriod[index][e.target.name] = money ? money.toString() : '0'
    }
    // validate array
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    this.setState({ data, validate })
  }

  openModal = () => {
    this.setState({ modalEditTime: true })
  }

  handleCancel = () => {
    this.setState({ modalEditTime: false })
  }

  handleChangeSelected = (e, { name, value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    data[name] = value

    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false
    this.setState({ data, validate, validateSP: false })
  }

  handleConfirm = () => {
    let { data } = this.state
    let { currentProposal } = this.props
    if (this.props.currentProposal) data = { ...currentProposal, ...data }

    let check = false

    if (data.manyOfEdit === undefined || data.indexOfEdit === undefined) {
      check = true
    }

    if (check) {
      this.setState({ validateSP: true, modalEditTime: false })
    } else {
      data.withdrawFormSP = []
      data.progressPeriodSP = []
      for (let i = 0; i < data.manyOfEdit; i++) {
        data.withdrawFormSP[i] = {}
        data.progressPeriodSP[i] = {}
      }

      data.confirmOFEditTime = true
      this.setState({
        validateSP: false,
        onSaveSuccessSP: true,
        modalEditTime: false
      })
      setTimeout(() => {
        this.setState({ onSaveSuccessSP: false })
      }, 3000)
      data['timestamp'] = gettime
      this.props.updateProposal(data, data._id)
    }
  }

  render () {
    let {
      validate,
      data,
      onSaveSuccess,
      onSaveSuccessSP,
      validateSP
    } = this.state
    const {
      currentProposal,
      currentState,
      index,
      role,
      special,
      indexSP
    } = this.props

    data = { ...currentProposal, ...data }
    if (!data.progressPeriod) {
      data['progressPeriod'] = []
    }

    let content = {
      approveBudget: { control: Input },
      approveBudgetOther: { control: Input },
      approveAlreadyWithdraw: { control: Input },
      approveAlreadyWithdrawAmount: { control: Input },
      approveRateProgress: { control: Select, options: RateProgressOptions },
      approveRateProgressDesc: { control: TextArea },
      approveProblem: { control: Select, options: ProblemOptions },
      approveProblemDesc: { control: TextArea },
      approveManage: { control: Select, options: ManageOptions },
      approveManageDesc: { control: TextArea },
      approveMoneyProblem: { control: Select, options: MoneyOptions, width: 8 },
      approveMoneyProblemDesc: { control: TextArea, width: 8 },
      approveRateInterest: { control: Select, options: RateInterestOptions },
      approveRateInterestDesc: { control: TextArea },
      approveUseMoney: { control: Select, options: UseMoneyOptions },
      approveUseMoneyAmount: { control: Input, money: 'money' },
      approveWithdraw: { control: Select, options: WithdrawOptions },
      approveWithdrawAmount: { control: Input, money: 'money' },
      approveDeadline: {
        control: Select,
        options: DeadliWithdrawOptions,
        width: 8
      },
      approveDeadlineDesc: { control: TextArea, width: 8 },
      approveDate: { control: Input },
      approveDesc: { control: Input },
      approveCommentSsk: { control: TextArea },
      approveReportProgress: { control: Checkbox },
      approveReportMoney: { control: Checkbox },
      approveCdData: { control: Checkbox },
      approveReportOther: { control: Checkbox },
      approveReportProgressAmount: { control: Input },
      approveCdDataAmout: { control: Input },
      approveReportOtherSpecify: { control: Input },
      approveReportNoReportProgress: {
        control: Input,
        money: 'money',
        width: 4
      },
      approveReportNoCD: { control: Input, money: 'money', width: 4 }
    }

    if (special) {
      Object.keys(content).forEach(key => {
        content[key]['label'] = localTh('proposal', key)
        content[key].name = key
        content[key].onChange = this.handleChange
        if (content[key].control === Input) {
          if (data.progressPeriodSP && data.progressPeriodSP.length > 0) {
            content[key].value = data.progressPeriodSP[index][key] || ''
          }
        } else if (content[key].control === Checkbox) {
          if (data.progressPeriodSP && data.progressPeriodSP.length > 0) {
            content[key].checked = data.progressPeriodSP[index][key] || false
          }
        } else {
          if (data.progressPeriodSP && data.progressPeriodSP.length > 0) {
            content[key].value = data.progressPeriodSP[index][key] || ''
          }
        }
      })
    } else {
      Object.keys(content).forEach(key => {
        content[key]['label'] = localTh('proposal', key)
        content[key].name = key
        content[key].onChange = this.handleChange
        if (content[key].control === Input) {
          if (data.progressPeriod && data.progressPeriod.length > 0) {
            content[key].value = data.progressPeriod[index][key] || ''
          }
        } else if (content[key].control === Checkbox) {
          if (data.progressPeriod && data.progressPeriod.length > 0) {
            content[key].checked = data.progressPeriod[index][key] || false
          }
        } else {
          if (data.progressPeriod && data.progressPeriod.length > 0) {
            content[key].value = data.progressPeriod[index][key] || ''
          }
        }
      })
    }

    if (gettime) {
      if (special) {
        // if (!data.progressPeriodSP[index].sendWorkTime) data.progressPeriodSP[index].sendWorkTime = gettime
      } else {
        // if (!data.progressPeriod[index].sendWorkTime) data.progressPeriod[index].sendWorkTime = gettime
      }
    } else {
      // this.getTimeFuction()
    }

    return (
      <Fragment>
        <Form error={validate.error}>
          {/* <Form.Group >
            <Form.Field width={4} disabled={data && data.confirmOFEditTime && role !== 'Admin'}>
              <Header style={{ fontFamily: 'supermarket' }}
                as='h5' content='จำนวนระยะที่ต้องการแบ่งจ่าย' />
              <Form.Group>
                {data.withDrawTime && data.withDrawTime.map((item, idx) => (
                  <Form.Checkbox radio
                    key={idx}
                    label={'งวดที่ ' + (idx + 1)}
                    name='indexOfEdit'
                    value={idx}
                    checked={data.indexOfEdit === idx}
                    onChange={this.handleChangeSelected} />
                ))}
              </Form.Group>
            </Form.Field>
            <Form.Field width={4} disabled={data && data.confirmOFEditTime && role !== 'Admin'}>
              <Header style={{ fontFamily: 'supermarket' }}
                as='h5' content='งวดงานงวดเงินที่ต้องการแบ่งจ่ายเงินเป็นระยะ' />
              <Form.Group>
                <Form.Checkbox radio
                  label={'1 ระยะ'}
                  name='manyOfEdit'
                  value={1}
                  checked={data.manyOfEdit === 1}
                  onChange={this.handleChangeSelected} />
                <Form.Checkbox radio
                  label={'2 ระยะ'}
                  name='manyOfEdit'
                  value={2}
                  checked={data.manyOfEdit === 2}
                  onChange={this.handleChangeSelected} />
                <Form.Checkbox radio
                  label={'3 ระยะ'}
                  name='manyOfEdit'
                  value={3}
                  checked={data.manyOfEdit === 3}
                  onChange={this.handleChangeSelected} />
              </Form.Group>
            </Form.Field>
            <Form.Field disabled={data && data.confirmOFEditTime && role !== 'Admin'}>
              <label>{''}</label>
              <Button content='ยืนยัน' color='blue' onClick={this.openModal} />
            </Form.Field>
          </Form.Group> */}
          <Transition
            visible={validateSP}
            animation='horizontal flip'
            duration={500}
          >
            <Message error>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                กรอกข้อมูลไม่ครบถ้วน
              </Message.Header>
              <Message.List>
                <Message.Item>
                  {' '}
                  งวดงานงวดเงินและจำนวนระยะที่ต้องการแบ่งจ่าย -
                  โปรดระบุให้ครบถ้วน{' '}
                </Message.Item>
              </Message.List>
            </Message>
          </Transition>
          <Transition
            visible={onSaveSuccessSP}
            animation='horizontal flip'
            duration={500}
          >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                <Icon name='check circle'></Icon>บันทึกสำเร็จ
              </Message.Header>
            </Message>
          </Transition>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='การประเมินความก้าวหน้า'
              as='h5'
              icon='write'
            />
            <Form.Field>
              <label>ผลงานที่นำส่ง</label>
            </Form.Field>
            {currentProposal.withDrawTime && (
              <p>
                <li>
                  {!special && currentProposal.withDrawTime[index].content}
                  {special && currentProposal.withDrawTime[indexSP].content}
                </li>
              </p>
            )}
            <Form.Group widths='equal'>
              <Form.Field
                disabled={
                  (currentProposal &&
                    currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                    role !== 'Admin') ||
                  (currentProposal &&
                    currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
                    role !== 'Admin' &&
                    role !== 'Academic' &&
                    role !== 'Co-Academic')
                }
                width={8}
              >
                <label>{'กำหนดการเบิกจ่ายตามงวด'}</label>
                {!special && (
                  <DayPicker
                    ser={'sendWorkTime'}
                    name={'sendWorkTime'}
                    onDayChange={this.handleDayChangeRow}
                    data={
                      (data.progressPeriod[index] &&
                        data.progressPeriod[index].sendWorkTime) ||
                      ''
                    }
                    index={index}
                  />
                )}
                {special && (
                  <DayPicker
                    ser={'sendWorkTime'}
                    name={'sendWorkTime'}
                    onDayChange={this.handleDayChangeRow}
                    data={
                      (data.progressPeriodSP[index] &&
                        data.progressPeriodSP[index].sendWorkTime) ||
                      ''
                    }
                    index={index}
                  />
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.approveRateProgress)}
              {this._renderFormField(content.approveRateInterest)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.approveRateProgressDesc)}
              {this._renderFormField(content.approveRateInterestDesc)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.approveManage)}
              {this._renderFormField(content.approveProblem)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.approveManageDesc)}
              {this._renderFormField(content.approveProblemDesc)}
            </Form.Group>
            <Header
              style={{ fontFamily: 'supermarket' }}
              as='h5'
              content='ส่งมอบงาน'
            />
            <Form.Group inline>
              <Form.Field width={8}>
                {this._renderFormField(content.approveReportProgress)}
                <Form.Field>
                  <label>{'จำนวน(ชุด)'}</label>
                  <Cleave
                    name='approveReportNoReportProgress'
                    maxLength='16'
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand'
                    }}
                    onChange={this.onMoneyChange.bind(this)}
                    value={
                      (!special &&
                        data.progressPeriod[index] &&
                        data.progressPeriod[index]
                          .approveReportNoReportProgress) ||
                      (special &&
                        data.progressPeriodSP[index] &&
                        data.progressPeriodSP[index]
                          .approveReportNoReportProgress) ||
                      ''
                    }
                    readOnly={
                      (currentProposal &&
                        currentProposal.status !==
                          'ประเมินความก้าวหน้าโครงการ' &&
                        role !== 'Admin') ||
                      (currentProposal &&
                        currentProposal.status ===
                          'ประเมินความก้าวหน้าโครงการ' &&
                        role !== 'Admin' &&
                        role !== 'Academic' &&
                        role !== 'Co-Academic')
                    }
                  />
                </Form.Field>
              </Form.Field>
              <Form.Field width={8}>
                {this._renderFormField(content.approveCdData)}
                <Form.Field>
                  <label>{'จำนวน(ชุด)'}</label>
                  <Cleave
                    name='approveReportNoCD'
                    maxLength='16'
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand'
                    }}
                    onChange={this.onMoneyChange.bind(this)}
                    value={
                      (!special &&
                        data.progressPeriod[index] &&
                        data.progressPeriod[index].approveReportNoCD) ||
                      (special &&
                        data.progressPeriodSP[index] &&
                        data.progressPeriodSP[index].approveReportNoCD) ||
                      ''
                    }
                    readOnly={
                      (currentProposal &&
                        currentProposal.status !==
                          'ประเมินความก้าวหน้าโครงการ' &&
                        role !== 'Admin') ||
                      (currentProposal &&
                        currentProposal.status ===
                          'ประเมินความก้าวหน้าโครงการ' &&
                        role !== 'Admin' &&
                        role !== 'Academic' &&
                        role !== 'Co-Academic')
                    }
                  />
                </Form.Field>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={8}>
                {this._renderFormField(content.approveReportMoney)}
              </Form.Field>
              {this._renderFormField(content.approveReportOther)}
              <Form.Input
                name='approveReportOtherReport'
                onChange={this.handleChange}
                value={
                  (!special &&
                    data.progressPeriod[index] &&
                    data.progressPeriod[index].approveReportOtherReport) ||
                  (special &&
                    data.progressPeriodSP[index] &&
                    data.progressPeriodSP[index].approveReportOtherReport) ||
                  ''
                }
                width={8}
              />
            </Form.Group>
            {this._renderFormField(content.approveCommentSsk)}
          </Segment>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='การเงินรายงวด'
              as='h5'
              icon='money'
            />
            <Form.Group widths='equal'>
              <Form.Field>
                <Header
                  style={{ fontFamily: 'supermarket' }}
                  as='h5'
                  content={'งบประมาณ : ' + formatcomma(data.askBudget) + ' บาท'}
                />
              </Form.Field>
              <Form.Field>
                <Header
                  style={{ fontFamily: 'supermarket' }}
                  as='h5'
                  content={
                    data.otherBudgetDropdown
                      ? (data.otherBudgetDropdown === 'งบประมาน'
                          ? 'งบประมาณจากแหล่งอื่น : '
                          : 'สิ่งของอื่นๆจากแหล่งอื่นได้แก่ : ') +
                        (data.otherBudgetDropdown === 'งบประมาน'
                          ? (formatcomma(
                              data.otherBudget ? data.otherBudget : ''
                            ) || 0) + ' บาท'
                          : data.otherBudgetItem)
                      : 'การสมทบจากแหล่งอื่น : ไม่มี'
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field>
                <Header
                  style={{ fontFamily: 'supermarket' }}
                  as='h5'
                  content={
                    'งบประมาณที่ได้รับ : ' +
                    formatcomma(data.budget ? data.budget : '0') +
                    ' บาท'
                  }
                />
              </Form.Field>
              <Form.Field>
                {!special && (
                  <Header
                    style={{ fontFamily: 'supermarket' }}
                    as='h5'
                    content={
                      'กำหนดส่งผลงาน : ' +
                      (ThaiDate('month', data.withDrawTime[index].submitTime) ||
                        'ยังไม่กำหนด')
                    }
                  />
                )}
                {special && (
                  <Header
                    style={{ fontFamily: 'supermarket' }}
                    as='h5'
                    content={
                      'กำหนดส่งผลงาน : ' +
                      (ThaiDate(
                        'month',
                        data.withDrawTime[indexSP].submitTime
                      ) || 'ยังไม่กำหนด')
                    }
                  />
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.approveUseMoney)}
              <Form.Field error={validate.attrs['approveUseMoneyAmount']}>
                <label>{'จำนวน(บาท)'}</label>
                <Cleave
                  name='approveUseMoneyAmount'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={
                    (!special &&
                      data.progressPeriod[index] &&
                      data.progressPeriod[index].approveUseMoneyAmount) ||
                    (special &&
                      data.progressPeriodSP[index] &&
                      data.progressPeriodSP[index].approveUseMoneyAmount) ||
                    ''
                  }
                  readOnly={
                    (currentProposal &&
                      currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin' &&
                      role !== 'Academic' &&
                      role !== 'Co-Academic')
                  }
                />
              </Form.Field>
              {/* {this._renderFormField(content.approveUseMoneyAmount)} */}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.approveWithdraw)}
              <Form.Field error={validate.attrs['approveWithdrawAmount']}>
                <label>{'จำนวน(บาท)'}</label>
                <Cleave
                  name='approveWithdrawAmount'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={
                    (!special &&
                      data.progressPeriod[index] &&
                      data.progressPeriod[index].approveWithdrawAmount) ||
                    (special &&
                      data.progressPeriodSP[index] &&
                      data.progressPeriodSP[index].approveWithdrawAmount) ||
                    ''
                  }
                  readOnly={
                    (currentProposal &&
                      currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin' &&
                      role !== 'Academic' &&
                      role !== 'Co-Academic')
                  }
                />
              </Form.Field>
              {/* {this._renderFormField(content.approveWithdrawAmount)} */}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.approveMoneyProblem)}
              {this._renderFormField(content.approveDeadline)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.approveMoneyProblemDesc)}
              {this._renderFormField(content.approveDeadlineDesc)}
            </Form.Group>
          </Segment>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Transition
            visible={onSaveSuccess}
            animation='horizontal flip'
            duration={500}
          >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                <Icon name='check circle'></Icon>บันทึกสำเร็จ
              </Message.Header>
            </Message>
          </Transition>
          <Button
            color='blue'
            content='บันทึก'
            icon='save'
            type='submit'
            disabled={
              (currentProposal &&
                currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                role !== 'Admin') ||
              (currentProposal &&
                currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
                role !== 'Admin' &&
                role !== 'Academic' &&
                role !== 'Co-Academic')
            }
            loading={currentState.isSaving}
            onClick={this.handleSubmit}
          />
          {/* <Button
            className='button-back-bottom-right'
            type='button'
            color='blue'
            content='ย้อนกลับ'
            icon='step backward'
            disabled={validate.error}
            onClick={this.handleBackPage}
          />
          <Button
            className='button-bottom-right'
            type='button'
            color='blue'
            content='ถัดไป'
            icon='step forward'
            disabled={validate.error}
            onClick={this.handleNextPage}
          /> */}
        </Form>
        <Confirm
          open={this.state.modalEditTime}
          header='คำเตือน'
          content='เมื่อกดยืนยันแล้ว งวดงานงวดเงินและระยะจ่ายเงินที่ท่านเลือกจะไม่สามารถเปลี่ยนแปลงหรือแก้ไขได้ หากต้องการเปลี่ยนแปลงให้ติดต่อผู้ดูระบบเท่านั้น'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  currentState: state.projects,
  role: state.role
})

const mapDispatchToProps = dispatch => ({
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(PageProgress)

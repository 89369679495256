import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import './LevelCardDescription.css'
import { actions as torsActions } from 'Redux/reducers/tors'

class LevelCardDescription extends Component {
  constructor(props) {
    super(props);
    this.linkRef = null;
    this.setLinkRef = element => {
      this.linkRef = element;
    };
    this.state = {
      isExists: false
    };
  }

  handleDownload = (name, index) => {
    const { data, currentProposal, tor } = this.props

    let bucket

    if (name === 'TOR') {
      bucket = tor._id
    } else {
      bucket = currentProposal._id
    }

    var url = '/minio/download/' + bucket + '/' + data[index].fileName;
    var exts = data[index].fileName.split('.');
    var typeDict = {
      'pdf': 'application/pdf',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'jpg': 'image/jpg'
    }

    var type = typeDict[exts[exts.length - 1].toLowerCase()] || 'text/plain'


    var request = new XMLHttpRequest();
    request.open("GET", url);
    request.setRequestHeader("Authorization", 'JWT ' + localStorage.getItem('id_token'));
    request.responseType = 'blob';
    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        done(request.response);
      }
    }

    var done = function (blob) {
      var _blob = new Blob([blob], { type });
      var url = URL.createObjectURL(_blob);
      window.open(url, '_blank');
    }
    request.send();
  }


  render() {
    const { data } = this.props
    if (!data) return null
    return (
      <Grid>
        {data.map((data, i) => (
          <Grid.Row key={i} className="level-card-content-grid-row">
            <Grid.Column width={6} textAlign="right" className="level-card-content-grid-column">
              {data.link ? 'ดาวน์โหลด' : data.text}:
            </Grid.Column>
            <Grid.Column width={10} textAlign="left" className="level-card-content-grid-column padding">
              {data.link ? <div
                onClick={this.handleDownload.bind(this, data.text, i)}
                style={{ cursor: 'pointer', color: 'blue' }}>{data.text}</div> : data.value + ' ' + (data.unit || '')}
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  tor: state.tors.currentData,
});

const mapDispatchToProps = dispatch => ({
  getTor: (id) => dispatch(torsActions.fetchOne(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LevelCardDescription);

import React, { Component, Fragment } from 'react'
import {
  Form,
  TextArea,
  Button,
  Input,
  Checkbox,
  Header,
  Select,
  Transition,
  Icon,
  Message,
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import { GetServerTime as GetTime } from 'Utils'
import { connect } from 'react-redux'
import { constraints as ExtendTimeConstraints } from './ProgressConstraints'
import { actions as projectActions } from 'Redux/reducers/projects'
import { localTh } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'

let gettime = ''

class ExtendForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validate: { error: false, validateError: null, attrs: {} },
      modalAttachment: false,
      onSaveSuccess: false
    }
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    })
  }


  componentDidMount() {
    let { data } = this.state
    data = { ...this.props.dataRow, ...data }
    this.setState(data)
  }

  componentWillReceiveProps(nextProps) {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });
  }

  _renderFormField = (content) => {
    const { validate } = this.state
    let { role, dataRow } = this.props
    if ((dataRow && dataRow.status !== 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin') || (dataRow && dataRow.status === 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin' && role !== 'Academic' && role !== 'Co-Academic')) {
      if (content.control === Select) {
        return (<Form.Field {...content} error={validate.attrs[content.name]} disabled />)
      } else return (<Form.Field {...content} error={validate.attrs[content.name]} readOnly />)
    } else {
      if (content.control === Select) {
        return (<Form.Field {...content} error={validate.attrs[content.name]} />)
      } else return (<Form.Field {...content} error={validate.attrs[content.name]} />)
    }

  }

  handleChange = (e, { name, value, checked }) => {
    let { data, validate } = this.state;
    let { dataRow, index } = this.props;
    data = { ...dataRow, ...data }

    data.extendTimeForm[index][name] = checked || value;

    validate['validateError'] = validatejs(data.extendTimeForm[index], ExtendTimeConstraints.ExtendTimeConstraints)
    validate.attrs = {};
    validate.error = false;

    this.setState({ data, validate });
  }

  handleSubmit = () => {
    let { data } = this.state;
    let { dataRow, index } = this.props;
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    });
    data = { ...dataRow, ...data }
    const result = validatejs(data.extendTimeForm[index], ExtendTimeConstraints.ExtendTimeConstraints)
    if (result) {
      let attrs = {}
      Object.keys(result).forEach((item) => attrs[item] = true)
      this.setState({ validate: { error: true, validateError: result, attrs } })
    } else {
      this.setState({ validate: { error: false, validateError: result, attrs: {} }, onSaveSuccess: true })
      if (this.props.dataRow) {
        data = { ...this.props.dataRow, ...data }
        setTimeout(() => {
          this.setState({ onSaveSuccess: false });
        }, 3000)
        data['timestamp'] = gettime
        this.props.updateProposal(data, data._id)
      }
    }
  }

  handleDayChangeRow = (name, idx, date) => {
    let { data } = this.state;
    let { dataRow, index } = this.props;
    data = { ...dataRow, ...data }

    data.extendTimeForm[index][name] = date._d.getTime()

    const result = validatejs(data.extendTimeForm[index], ExtendTimeConstraints.ExtendTimeConstraints)
    if (result) {
      let attrs = {}
      Object.keys(result).forEach((item) => attrs[item] = true)
      this.setState({ validate: { error: true, validateError: result, attrs } })
    }

    this.setState({ data })
  }

  render() {
    let { currentState, role, dataRow, index } = this.props
    let { data, validate, onSaveSuccess } = this.state
    data = { ...dataRow, ...data }

    const content = {
      'reasonExtend': { control: TextArea, row: 5 },
      'attachmentExtend': { control: Checkbox },
      'otherattachmentExtend': { control: Checkbox },
      'otherattachmentExtendText': { control: Input },
      'commentExtend': { control: TextArea, row: 5 },
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('proposal', key);
      content[key].name = key;
      content[key].onChange = this.handleChange;
      if (content[key].control === Input) {
        content[key].value = data.extendTimeForm[index][key] || ''
      } else if (content[key].control === Checkbox) {
        content[key].checked = data.extendTimeForm[index][key] || false
      } else {
        content[key].value = data.extendTimeForm[index][key] || ''
      }
    })

    if (gettime) {
      // if (!data.extendTimeForm[index].startDateExtende) data.extendTimeForm[index].startDateExtend = gettime
    } else {
      // this.getTimeFuction()
    }

    return (
      <div>
        <Form error={validate.error}>
          <Header style={{ fontFamily: 'supermarket' }} content='ขยายเวลาดำเนินโครงการ' as='h5' icon='clock outline' />
          <Form.Group>
            <Form.Field disabled={(dataRow && dataRow.status !== 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin') || (dataRow && dataRow.status === 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin' && role !== 'Academic' && role !== 'Co-Academic')} >
              <label>{'ขยายเวลาตั้งแต่วันที่'}</label>
              <DayPicker ser={'startDateExtend'} name={'startDateExtend'}
                onDayChange={this.handleDayChangeRow}
                data={data.extendTimeForm[index].startDateExtend || ''}
                max={data.extendTimeForm[index].endDateExtend} />
            </Form.Field>
            <Form.Field disabled={(dataRow && dataRow.status !== 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin') || (dataRow && dataRow.status === 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin' && role !== 'Academic' && role !== 'Co-Academic')} >
              <label>{'ถึงวันที่'}</label>
              <DayPicker ser={'endDateExtend'} name={'endDateExtend'}
                onDayChange={this.handleDayChangeRow}
                data={data.extendTimeForm[index].endDateExtend || ''}
                min={data.extendTimeForm[index].startDateExtend} />
            </Form.Field>
          </Form.Group>
          {this._renderFormField(content.reasonExtend)}
          {this._renderFormField(content.attachmentExtend)}
          <Form.Group>
            {this._renderFormField(content.otherattachmentExtend)}
            {this._renderFormField(content.otherattachmentExtendText)}
          </Form.Group>
          {this._renderFormField(content.commentExtend)}
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
            <Message.List>
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('proposal', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>
          {<Button color='blue' content='บันทึก' icon='save'
            type='submit'
            disabled={(dataRow && dataRow.status !== 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin') || (dataRow && dataRow.status === 'ประเมินความก้าวหน้าโครงการ' && role !== 'Admin' && role !== 'Academic' && role !== 'Co-Academic')}
            loading={currentState.isSaving}
            onClick={this.handleSubmit} />}
        </Form>
        <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
          </Message>
        </Transition>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  currentState: state.projects,
  role: state.role
})

const mapDispatchToProps = dispatch => ({
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: (id) => dispatch(projectActions.fetchOne(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExtendForm)
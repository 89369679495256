import React, { Component } from 'react';
import { Grid, Header, Divider, Menu, Icon } from 'semantic-ui-react'
import './HeaderContent.css'
import { connect } from 'react-redux'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as torActions } from 'Redux/reducers/tors'


class HeaderContent extends Component {

  componentDidMount = () => {

    let tor_id = localStorage.getItem("tor_id")
    this.props.getTor(tor_id)
  }

  render() {
    const { title, tor, name, onEditProposal, onUpload, onDownload } = this.props
    return (
      <div>
        {/* <p>123 PK <Button onClick={this.gen} content='genId'/></p> */}
        <Grid columns={2}>
          <Grid.Column width={14} verticalAlign="middle">
            {name !== 'TOR' && name !== 'borrow' && name !== 'burn' &&
              <Header as='h3' className="headercontent-title" style={{ fontFamily: 'supermarket', fontWeight: 'normal' }}><b>โครงการ : </b> {title}</Header>
            }
            {name !== 'TOR' && name !== 'borrow' && name !== 'burn' &&
              <Header as='h5' className="headercontent-title" style={{ fontFamily: 'supermarket', fontWeight: 'normal' }}><b>ข้อกำหนด : </b> {tor && tor.nameTh}</Header>
            }
            {name === 'TOR' && name !== 'borrow' && name !== 'burn' &&
              <Header as='h3' className="headercontent-title" style={{ fontFamily: 'supermarket', fontWeight: 'normal' }}><b>ข้อกำหนด : </b> {tor && tor.nameTh}</Header>
            }
            {name === 'TOR' && name !== 'borrow' && name !== 'burn' &&
              <Header as='h5' className="headercontent-title" style={{ fontFamily: 'supermarket', fontWeight: 'normal' }}>{title}</Header>
            }
            {name !== 'TOR' && name !== 'borrow' && name === 'burn' &&
              <Header as='h3' className="headercontent-title" style={{ fontFamily: 'supermarket', fontWeight: 'normal' }}><b>โครงการ : </b> {title}</Header>
            }
            {/* {subtitle && <Header.Subheader className="headercontent-subtitle">{subtitle}</Header.Subheader>} */}
          </Grid.Column>
          {(onEditProposal || onDownload || onUpload) && <Grid.Column width={2} verticalAlign="middle">
            <Menu floated='right' className="headercontent-menu">
              {onDownload && <Menu.Item as='a' icon onClick={onDownload} style={{ fontFamily: 'supermarket' }}>
                <Icon name='arrow alternate circle down outline' />พิมพ์</Menu.Item>}
              {onUpload && <Menu.Item as='a' fitted icon onClick={onUpload} style={{ fontFamily: 'supermarket' }}>
                <Icon name='attach' />ไฟล์แนบ</Menu.Item>}
              {/* {onSendEmail && <Menu.Item as='a' fitted icon onClick={onSendEmail}>
                <Icon name='send' />ส่ง Email</Menu.Item>} */}
              {onEditProposal && <Menu.Item as='a' fitted icon onClick={onEditProposal}>
                <Icon name='edit' />แก้ไขข้อเสนอ</Menu.Item>}
            </Menu>
          </Grid.Column>}
        </Grid>
        <Divider />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tor: state.tors.currentData,
  project: state.projects.currentData,
  projects: state.projects,
  counter: state.counter
})

const mapDispatchToProps = dispatch => ({
  getProposal: (id) => dispatch(projectActions.fetchOne(id)),
  getTor: (id) => dispatch(torActions.fetchOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContent)



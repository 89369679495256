import React, { Component, Fragment } from 'react';
import { Table, Button, Message, Confirm, Segment, Menu, Form, Input, Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
import { actions as borrowActions } from 'Redux/reducers/scholar'

let gettime = ''

const filterOptions = [
  { key: '1', text: 'ทั้งหมด', value: 'ทั้งหมด' },
  { key: '2', text: 'ส่งข้อเสนอโครงการ', value: 'ส่งข้อเสนอโครงการ' },
  { key: '3', text: 'กลั่นกรอง', value: 'กลั่นกรอง' },
  { key: '4', text: 'ทำสัญญาโครงการ', value: 'ทำสัญญาโครงการ' },
  { key: '5', text: 'ประเมินความก้าวหน้าโครงการ', value: 'ประเมินความก้าวหน้าโครงการ' },
  { key: '6', text: 'ปิดโครงการ', value: 'ปิดโครงการ' },
  { key: '7', text: 'ยุติโครงการ', value: 'ยุติโครงการ' },
]

class ListBorrowDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      openConfirmCopy: false,
      query: {},
      search: {},
      nameSearch: 'name',
      dataSaveing: false
    };
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  componentDidMount = () => {
    const { auth, role } = this.props

    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      gettime = value
    });

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    let query = {}
    if (role === "Admin") {
      query = {
        sort: { nameTh: 1 },
        limit: 10,
        skip: 0,
        query: {
          delete: true
        }
      }
    } else {
      query = {
        sort: { nameTh: 1 },
        limit: 10,
        skip: 0,
        query: {
          $or: [
            { academicID: user._id },
            { otherAcademicID: user._id }
          ],
          delete: true
        }
      }
    }


    if (Object.getOwnPropertyNames(this.props.counter.counter).length > 0) {
      Object.keys(this.props.counter.counter).forEach((key) => {
        delete this.props.counter.counter[key]
      })
    }

    this.props.fetchTOR(query)
    this.setState({ query: query })
  }

  componentWillReceiveProps = (nextProps) => {
    let { dataSaveing } = this.state
    let { isSaving } = nextProps

    if (isSaving) {
      this.setState({ dataSaveing: true })
    } else {
      if (dataSaveing) {
        this.setState({ dataSaveing: false })
        this.saveDataSuccess()
      }
    }

  }

  copyProject = () => {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    let query = {}
    if (role === "Admin") {
      query = {
        sort: { nameTh: 1 },
        limit: 10,
        skip: 0,
        query: {
          delete: true
        }
      }
    } else {
      query = {
        sort: { nameTh: 1 },
        limit: 10,
        skip: 0,
        query: {
          $or: [
            { academicID: user._id, },
            { otherAcademicID: user._id, }
          ],
          delete: true
        }
      }
    }

    let { data } = this.state
    delete data.delete
    this.props.updateTOR(data, data._id)
    this.props.fetchTOR(query)
    this.setState({ openConfirmCopy: false })
  }

  saveDataSuccess = () => {
    this.props.onCloseModalProject()
  }

  handleOpenConfirmCopy = (data) => {
    this.setState({ data: data, openConfirmCopy: true })
  }

  paging = (skip) => {
    let { query } = this.state
    query.skip = query.skip + skip
    this.props.fetchTOR(query)
    this.setState({ query })
  }

  close = () => {
    this.setState({ openConfirmCopy: false })
  }

  onTextChange = (e, { name, value }) => {
    let { query } = this.state

    if (name === 'name') {
      let search = query.query

      if (value.match(/^[0-9]{2}/) !== null) {
        search['codeProject'] = {}
        search['codeProject']['$regex'] = value
        delete search['nameTh']
      } else {
        search['nameTh'] = {}
        search['nameTh']['$regex'] = value
        delete search['codeProject']
      }
      if (value.length >= 3) {
        this.props.searchTOR(search)
        this.setState({ search: search })
      } else if (value.length === 0) {
        search = query.query
        this.props.searchTOR(search)
      }
    } else {
      let search = query.query
      search = { delete: true }
      search['managerName'] = {}
      search['managerName']['$regex'] = value
      if (value.length >= 3) {
        this.props.searchTOR(search)
        this.setState({ search: search })
      } else if (value.length === 0) {
        search = query.query
        this.props.searchTOR(search)
      }
    }
  }


  handleFilter = (e, { value }) => {
    const { query } = this.state

    let { search } = this.state
    search = query.query
    search = { delete: true }
    if (value === 'ทั้งหมด') {
      if (search.status) {
        delete search.status
      }
    } else {
      search['status'] = value
    }
    this.props.searchTOR(search)
    this.setState({ status: value, })
  }

  handleChangeSearch = () => {

    if (this.state.nameSearch === 'name') {
      this.setState({ nameSearch: 'managerName' })
    } else {
      this.setState({ nameSearch: 'name' })
    }
  }


  render() {
    const { projects } = this.props
    let { nameSearch, dataSaveing, data } = this.state
    console.log(projects)

    return (
      <Fragment>
        <Dimmer active={dataSaveing}>
          <Loader size='large'>กำลังทำการบันทึกข้อมูล</Loader>
        </Dimmer>
        <Message color='yellow'>
          <Message.Header style={{ fontFamily: 'supermarket' }}>หมายเหตุ*</Message.Header>
          <p>
            * ผู้ใช้งานสามารถ คืนข้อมูลข้อกำหนดที่เคยลบไปแล้วกลับมาใช้งานได้
            </p>
          <p>
            * เลข Running Number ต่างๆ ในข้อกำหนด จะเป็นเลขเดิม
            </p>
          <p>
            * สถานะข้อกำหนดจะเหมือนเดิม
            </p>
        </Message>
        <Segment>
          <Menu secondary >
            <Menu.Item fitted >
              <Input style={{ fontFamily: 'supermarket' }} name={nameSearch} onChange={this.onTextChange} icon='search' placeholder={nameSearch === 'name' ? 'ชื่อหรือรหัสข้อกำหนด..' : 'ผู้รับผิดชอบข้อกำหนด..'} />
            </Menu.Item>
            <Menu.Item fitted>
              <Button style={{ fontFamily: 'supermarket' }} content={nameSearch === 'name' ? 'ชื่อหรือรหัสข้อกำหนด' : 'ผู้รับผิดชอบข้อกำหนด'} fluid color='grey' icon='retweet' onClick={this.handleChangeSearch} />
            </Menu.Item>
            <Menu.Item fitted>
              <Form>
                <Dropdown
                  placeholder='กรองข้อมูล'
                  icon='filter'
                  labeled
                  button
                  className='icon search-dropdown'
                  onChange={this.handleFilter}
                  options={filterOptions} />
              </Form>
            </Menu.Item>
          </Menu>
        </Segment>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>เลขที่ทดรองจ่าย</Table.HeaderCell>
              <Table.HeaderCell>ชื่อนักวิชาการ</Table.HeaderCell>
              <Table.HeaderCell>สถานะ</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>คืนข้อมูล</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {projects && projects.data.map((item, idx) =>
              <Table.Row key={idx}>
                <Table.Cell>{item.codeBorrow}</Table.Cell>
                <Table.Cell>{item.academic}</Table.Cell>
                <Table.Cell>{item.status}</Table.Cell>
                <Table.Cell textAlign='center'>
                  <Button icon='redo' color='blue' onClick={this.handleOpenConfirmCopy.bind(this, item)} />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          {projects.data.length !== 0 &&
            <Table.Footer fullWidth >
              <Table.Row>
                <Table.HeaderCell colSpan='5' textAlign='right'>
                  {projects.params && projects.params.skip !== 0 &&
                    <Button style={{ fontFamily: 'supermarket' }} content='ย้อนกลับ' onClick={this.paging.bind(null, -10)} />
                  }
                  {projects && projects.data.length >= 10 && projects.data.length !== 0 &&
                    <Button style={{ fontFamily: 'supermarket' }} content='ถัดไป' onClick={this.paging.bind(null, +10)} />
                  }
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          }
        </Table>
        <Confirm open={this.state.openConfirmCopy}
          content={'ต้องการคืนทดรองจ่าย ' + (data && data.nameTh) + ' ที่ลบไปแล้ว ใช่หรือไม่'}
          cancelButton='ยกเลิก'
          confirmButton="ยืนยัน"
          onCancel={this.close}
          onConfirm={this.copyProject} />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  selectedTor: state.tors.currentData,
  projects: state.scholar,
  role: state.role,
  counter: state.counter,
  isSaving: state.scholar.isSaving,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  fetchTOR: (query) => dispatch(borrowActions.fetchList({ ...query })),
  searchTOR: (query) => dispatch(borrowActions.fetchList({
    query: query,
    sort: { nameTh: 1 },
    limit: 10,
    skip: 0,
  })),
  updateTOR: (data, id) => dispatch(borrowActions.save(data, id, 'POST')),
  listTor: (query) => dispatch(borrowActions.fetchList({ ...query })),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListBorrowDelete);
import React, { Component } from 'react';
import { Label } from 'semantic-ui-react'
import './LevelCard.css'
import LevelCardDescription from './LevelCardDescription'

export default class LevelCard extends Component {
  render() {
    const { unfinish, data, currentProposal } = this.props

    if (!data) return null
    let color = 'grey'
    if (unfinish !== true) {
      if (data && data.title.split(' ').pop() === '(กำลังดำเนินการ)') color = 'orange'
      else color = 'green'
    }
    return (
      <div className={"level-card-container _" + color}>
        <Label className={"level-card-circle _" + color} circular>{data.id}</Label>
        <div className={"level-card-header _" + color} title={data.title || ''}>{data.title || ''}</div>
        <div className="level-card-content">
          <LevelCardDescription data={data.items} currentProposal={currentProposal} />
        </div>
      </div >
    )
  }
}

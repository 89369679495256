const initialState = {
  isFetching: false,
  counter:{}
}

function requestCounter(name) {
  return {
    type:'COUNTER_REQUEST',    
    name
  }
}

function counterError(mesg) {
  return {
    type:'COUNTER_ERROR',
    message:mesg
  }
}

function receivedCounter(data) {
  return {
    type:'COUNTER_RECEIVED',
    data
  }
}

export function generate(name) {
  const token = localStorage.getItem('id_token')
  let config = {
    method: 'GET',
    headers: { 
     'Content-Type':'application/json', 
     'Authorization':'JWT '+token
    }
  }

  return dispatch => {
    dispatch(requestCounter(name))
    return fetch('/db/pm/sequence/sequence/'+name, config)
      .then(response => response.json().then(data => ({ data, response })))
      .then(({ data, response }) =>  {
        if (!response.ok) {
          dispatch(counterError(data.message))
        // return Promise.reject(user)
        } else {
          dispatch(receivedCounter(data))
        } 
      })
      .catch(err => console.log("Error: ", err))
  }
}

export const counterReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'COUNTER_TOKEN_REQUIRED':
      return {
        ...state,
        isFetching:false,
      }
    case 'COUNTER_REQUEST':
      return {
        ...state,
        isFetching:true,
      }
    case 'COUNTER_RECEIVED':
      var counter = { ...state.counter }
      counter[action.data.name] = action.data.runningNumber 
      return {
        ...state,
        isFetching:false,
        counter
      }
    case 'COUNTER_ERROR':
      return {
        ...state,
        errorMessage:action.message
      }
    default:
      return state
  }
}





import React, { Component, Suspense } from 'react'
import './Project.css'
import Sidebar from "Containers/Sidebar"
// import Paper from 'Components/Paper'
import { Search, SearchResult } from 'Containers/Search'
import { routes } from 'Containers/Router'
import { Route, HashRouter, Switch } from 'react-router-dom'


const LevelMenu = React.lazy(() => import('Containers/LevelMenu'));
const Paper = React.lazy(() => import('Components/Paper'));

const Wrapper = ({ match, component, history, path }) => {
  const children = React.cloneElement(component, { match, history })
  const excludeLevelMenu = ['/torinfo/:id?', '/menu/:id?', '/torform/:id?','/listorganization/:id?','/listindividuals/:id?','/bill/:id?','/torflow/:id?']
  if (excludeLevelMenu.indexOf(path.toLowerCase()) > -1) {
    return (
      <Suspense fallback={<div>Loading</div>}>
        <Paper match={match} history={history}>{children}</Paper>
      </Suspense>
    )
  }
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Paper history={history} match={match}>
        <div className="wrapper-container">
          <div className="left">
            <Suspense fallback={<div>Loading</div>}>
              <LevelMenu history={history} match={match} />
            </Suspense>
          </div>
          <div className="right">
            {children}
          </div>
        </div>
      </Paper>
    </Suspense>
  )
}

class Project extends Component {
  render() {
    const { path } = this.props.match

    return (
      <Sidebar {...this.props}>
        <HashRouter>
          <Switch>
              <Route
                path='/project/search'
                component={() => <div>
                  <Search {...this.props} />
                  <SearchResult {...this.props} />
                </div>
                } />
              {routes.map((r, i) => <Route key={i}
                exact={r.exact || false}
                path={path + r.path}
                component={() => {
                  return <Wrapper {...this.props} path={r.path} component={r.component} />
                }} />)}

          </Switch>
        </HashRouter>
      </Sidebar>
    )
  }
}

export default Project

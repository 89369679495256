var EmailConstraints = {
  to:{
    presence: {
      allowEmpty: false,
      message:'^ต้องระบุ'
    },
    email: true
  },
  subject:{
    presence: {
      allowEmpty: false,
      message:'^ต้องระบุ'
    }
  },
  text: {
      presence: {
        allowEmpty: false,
        message:'^ต้องระบุ'
      }
    },
}


  

let constraints = {
  EmailConstraints: EmailConstraints,
}


module.exports = { constraints }

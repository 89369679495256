var BillConstraints = {
  firstname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  title: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  projectName: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
}

var BillConditionConstraints = {
  firstname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  titleOther: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  projectName: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
}

var BillArrayConstraints = {
  listBill: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    },
  },
  billMoney: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  }
}



let constraints = {
  BillConstraints: BillConstraints,
  BillArrayConstraints: BillArrayConstraints,
  BillConditionConstraints:BillConditionConstraints
}


module.exports = { constraints }

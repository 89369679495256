import React, { Component, Fragment } from 'react';
import {
  Tab,
  Menu,
  Modal,
  Header,
  Button,
  Icon
} from 'semantic-ui-react';

import BorrowMoney from './BorrowMoney'
import TotalRefund from './TotalRefund'
import Bill from './Bill'
import { connect } from 'react-redux'
import { actions as scholarActions } from 'Redux/reducers/scholar'
import { actions as receiptsActions } from 'Redux/reducers/receipts'
import { HeaderContent } from 'Components/Header'
import { PrintoutComponentTapBorrow } from 'Utils'
import { AttachedComponent } from 'Utils'


const receiptOptions = [
  { key: '1', text: 'ใบสำคัญรับเงิน', value: 'receipt' },
]

class TapBorrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPrintout: false,
      data: {},
      isLoad:false,
      alertReceipt:false
    };
  }


  componentDidMount = () => {
    const { match } = this.props
    this.props.getBorrow(match.params.id)
  }

  componentWillReceiveProps = (nextProps)=>{
    const { match } = this.props
    let { isLoad } = this.state
    if(!isLoad){
      let query = {
        borrow_id:match.params.id
      }
      this.props.listReceipts(query)
      this.setState({isLoad:true})
    }
  }

  handleUploaded = ({ uploaded }) => {
    let { receipts } = this.props
    let receipt = receipts[0]
    if(receipt){
      let findArray = receipt[uploaded.name] && receipt[uploaded.name].find(x => x.fileName === uploaded.typeOfAttach + '_' + uploaded.file.name)
      if (!findArray) {
        if (!receipt[uploaded.name]) receipt[uploaded.name] = [];
        receipt[uploaded.name].push({ 'fileName': uploaded.typeOfAttach + '_' + uploaded.file.name, 'typeOfAttach': uploaded.typeOfAttach });
      } else {
        this.setState({ alert: true });
      }
      this.props.updateReceipt(receipt, receipt._id);
    }else{
      this.setState({ alertReceipt: true });
    }
  }

  _onDownload = () => {
    this.setState({ modalPrintout: true, modalAttachment: false, modalSummary: false })
  }

  _onUpload = () => {
    const { receipts } = this.props
    let receipt = receipts[0]
    if(receipt){
    this.setState({ modalPrintout: false, modalAttachment: true, modalSummary: false,alertReceipt:false })
    }else{
    this.setState({ modalPrintout: false, modalAttachment: false, modalSummary: false,alertReceipt:true })
    }
  }

  handleCloseModal = () => {
    this.setState({ modalSendEmail: false, modalAttachment: false, modalAddGroupFinance: false, modalPrintout: false, modalSummary: false })
  }

  handleAttachedFileRemove = (name) => {
    let { borrow } = this.props;
    borrow['receipt'].splice(name.index, 1);
    this.props.updateBorrow(borrow, borrow._id);
  }

  closeAlert = () => {
    this.setState({ alert: false,alertReceipt:false })
  }

  render() {
    const { match,receipts, borrow } = this.props
    const { modalAttachment, modalPrintout, alert,alertReceipt } = this.state

    const panes = [
      { menuItem: <Menu.Item key='1' style={{ fontFamily: 'supermarket' }}>ยืมเงินทดรองจ่าย</Menu.Item>, render: () => <BorrowMoney match={this.props.match} /> },
      { menuItem: <Menu.Item key='2' style={{ fontFamily: 'supermarket' }}>คืนเงินทดรองจ่าย</Menu.Item>, render: () => <TotalRefund match={this.props.match} /> },
      { menuItem: <Menu.Item key='3' style={{ fontFamily: 'supermarket' }}>ใบสำคัญรับเงิน</Menu.Item>, render: () => <Bill match={this.props.match} history={this.props.history} /> }
    ]
    
    return (
      <Fragment>
        <HeaderContent
          title='ยืมเงินทดรองจ่าย'
          name='borrow'
          onDownload={this._onDownload}
          onUpload={this._onUpload}
        />
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        <AttachedComponent
          currentProposal={receipts && receipts[0]}
          namePage='receipt'
          namePageReadOnly='receipt'
          modalAttachment={modalAttachment}
          onHandleUpload={this.handleUploaded}
          onHandleRemove={this.handleAttachedFileRemove}
          options={receiptOptions}
          onClose={this.handleCloseModal}
          checkStatus={true}
        />
        <PrintoutComponentTapBorrow
          modalPrintout={modalPrintout}
          numberOfFile={['ยืมเงินทดรองจ่ายส่วนที่ 1', 'ยืมเงินทดรองจ่ายส่วนที่ 1 และ 2', 'ประมาณการค่าใช้จ่าย', 'สรุปค่าใช้จ่าย', 'ใบสำคัญรับเงิน']}
          typeFile={[12, 13, 17, 18, 19]}
          receipt={receipts && receipts[0]}
          borrow={borrow}
          match={match}
          onClose={this.handleCloseModal} />
        <Modal
          open={alert}
          onClose={this.closeAlert}
          size='small'
        >
          <Header icon='browser' style={{ fontFamily: 'supermarket' }} content='ผิดพลาด' />
          <Modal.Content>
            <h3 style={{ fontFamily: 'supermarket' }}>ไฟล์ซ้ำ</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color='grey' onClick={this.closeAlert}>
              <Icon name='checkmark' /> ตกลง
          </Button>
          </Modal.Actions>
        </Modal>
        
        <Modal
          open={alertReceipt}
          onClose={this.closeAlert}
          size='small'
        >
          <Header icon='browser' style={{ fontFamily: 'supermarket' }} content='ผิดพลาด' />
          <Modal.Content>
            <h3 style={{ fontFamily: 'supermarket' }}>ต้องสร้างใบสำคัญรับเงินก่อน</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color='grey' onClick={this.closeAlert}>
              <Icon name='checkmark' /> ตกลง
          </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  scholar: state.scholar,
  borrow: state.scholar.currentData,
  auth: state.auth,
  receipt:state.receipts.currentData,
  receipts:state.receipts.data
});

const mapDispatchToProps = dispatch => ({
  addBorrow: (data) => dispatch(scholarActions.save(data)),
  updateBorrow: (data, id) => dispatch(scholarActions.save(data, id, 'POST')),
  updateReceipt: (data, id) => dispatch(receiptsActions.save(data, id, 'POST')),
  getBorrow: (id) => dispatch(scholarActions.fetchOne(id)),
  listReceipts: (query) => dispatch(receiptsActions.fetchList({query:query})),
})

export default connect(mapStateToProps, mapDispatchToProps)(TapBorrow)

import React, { Component, Fragment } from 'react'
import {
  Form,
  TextArea,
  Button,
  Input,
  Checkbox,
  Segment,
  Header,
  Select,
  Transition,
  Icon,
  Message,
  Confirm
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react'
import { GetServerTime as GetTime } from 'Utils'
import { HeaderContent } from 'Components/Header'
import { connect } from 'react-redux'
import { constraints as Constraints } from './EndProjectConstraints'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { generate } from 'Redux/reducers/counting'

import { localTh } from 'Utils'
import { AttachedComponent } from 'Utils'
import { PrintoutComponent } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'

let gettime,
  noBook,
  currentNoBook,
  thisYear = ''
let checkNobook = true

const attachmentEndForm = {
  withdrawLastTime: {
    control: Checkbox,
    label: 'มีการเบิกเงินงวดสุดท้าย',
    name: 'withdrawLastTime'
  },
  reportProceedAndResearch: {
    control: Checkbox,
    label: 'แนบรายงานการดำเนินงาน/รายงานวิจัยฉบับสมบูรณ์ จำนวน',
    name: 'reportProceedAndResearch'
  },
  amountReport: { control: Input, width: 2, name: 'amountReport' },
  reportFinance: {
    control: Checkbox,
    label: 'แนบรายงานการเงินโครงการ/รายงานการเงินเพื่อปิดโครงการ',
    name: 'reportFinance'
  },
  deposit: {
    control: Checkbox,
    label: 'แนบสมุดบัญชีเงินฝากธนาคาร',
    name: 'deposit'
  },
  refund: {
    control: Checkbox,
    label: 'แนบเอกสารการโอนเงินคืนแก่ กสศ.',
    name: 'refund'
  },
  ohter: { control: Checkbox, label: 'แนบเอกสารอื่น ๆ (ระบุ)', name: 'ohter' },
  noteOhter: { control: Input, width: 6, name: 'noteOhter' }
}

const options = [
  { key: '1', text: 'มี', value: 'มี' },
  { key: '2', text: 'ไม่มี', value: 'ไม่มี' }
]

const content = {
  Takemoney: { control: Input, money: 'money' },
  takeTime: { control: Input, width: 8 },
  interest: { control: Input, money: 'money' },
  refund: { control: Input, money: 'money' },
  ActualUsemoney: { control: Input, money: 'money' },
  endFormConclusion: { control: TextArea, rows: 5 },
  endFormApprove: { control: Checkbox },
  endFormNote: { control: TextArea },
  endFormApprover: { control: Input },
  extendTime: { control: Select, options: options, width: 8 },
  descriptionWithdraw: { control: TextArea, row: 5 },
  comment: { control: TextArea, row: 5 }
}

class EndForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      choiceAttachment: '',
      validate: { error: false, validateError: null, attrs: {} },
      modalAttachment: false,
      onSaveSuccess: false,
      confirmClose: false
    }
  }

  componentDidMount() {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    this.props.listProject()
    this.props.listTor()
    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }

    currentNoBook = undefined
    noBook = undefined
    checkNobook = false
    if (this.props.counter.counter['noBookInside-' + thisYear]) {
      currentNoBook = this.props.counter.counter['noBookInside-' + thisYear]
    }
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    let { currentProposal, counter } = nextProps
    if (currentProposal) {
      let tmp = { ...currentProposal }
      if (!tmp.attachmentEndForm) {
        tmp.attachmentEndForm = {}
      }
      let { data } = this.state
      this.setState({ data: { ...tmp, ...data } })
    }

    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    // if (!thisYear || thisYear === '') this.getTimeFuction()

    checkNobook = false
    if (counter.counter['noBookInside-' + thisYear]) {
      if (currentNoBook) {
        if (currentNoBook !== counter.counter['noBookInside-' + thisYear]) {
          noBook = counter.counter['noBookInside-' + thisYear]
          checkNobook = true
          currentNoBook = counter.counter['noBookInside-' + thisYear]
          this.handleSubmit()
        }
      } else {
        currentNoBook = counter.counter['noBookInside-' + thisYear]
        noBook = counter.counter['noBookInside-' + thisYear]
        checkNobook = true
        this.handleSubmit()
      }
    }

    // if (currentProposal && currentProposal.status === 'ประเมินความก้าวหน้าโครงการ') {
    //   if (!currentProposal.noBookEndProject) {
    //     currentProposal.noBookEndProject = 'check'
    //     this.props.genId('noBookInside' + currentProposal.yearBudget)
    //   } else if (currentProposal.noBookEndProject === 'check') {
    //     noBook['noBookEndProject'] = counter.counter['noBookInside' + currentProposal.yearBudget]
    //   }
    // }
  }

  handleChangeAttachment = (e, { name, checked, value }) => {
    let { data } = this.state
    data.attachmentEndForm[name] = checked || value
    //  data.attachmentEndForm[name] = value
    this.setState({ data })
  }

  _renderFormField = content => {
    const { validate } = this.state
    let { currentProposal, role } = this.props

    if (
      (currentProposal &&
        currentProposal.status !== 'ยุติโครงการ' &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic') ||
      (currentProposal &&
        currentProposal.status === 'ยุติโครงการ' &&
        role !== 'Admin')
    ) {
      if (content.control === Select) {
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            disabled
          />
        )
      } else
        return (
          <Form.Field
            {...content}
            error={validate.attrs[content.name]}
            readOnly
          />
        )
    } else {
      if (content.control === Select) {
        return <Form.Field {...content} error={validate.attrs[content.name]} />
      } else
        return <Form.Field {...content} error={validate.attrs[content.name]} />
    }
  }

  format = input => {
    if (!input) {
      input = ''
    }
    var num = input.replace(/,/g, '')
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.')
        num[0] = num[0]
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        if (num[1].length > 2) {
          num[1] = num[1].substring(0, num[1].length - 1)
        }
        input = num[0] + '.' + num[1]
        return input
      } else {
        input = num
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        return input
      }
    } else {
      input = input.substring(0, input.length - 1)
    }
  }

  handleChange = (e, { name, value, checked }) => {
    let { data, validate } = this.state
    data[name] = checked || value

    // if (!data['noBookEndProject']) {
    //   if (counter.counter['noBookInside' + ((new Date(gettime)).getFullYear() + 543)]) {
    //     data['noBookEndProject'] = 'กสศ. น. 19/' +
    //       counter.counter['noBookInside' + ((new Date(gettime)).getFullYear() + 543)].toString().padStart(5, '0') +
    //       '/' + ((new Date(gettime)).getFullYear() + 543)
    //   } else {
    //     this.props.genId('noBookInside' + ((new Date(gettime)).getFullYear() + 543))
    //   }
    // }

    validate['validateError'] = validatejs(data, Constraints.EndFormConstraints)
    validate.attrs = {}
    validate.error = false

    this.setState({ data, validate })
  }

  handleDayChangeRow = (name, index, date) => {
    let { data } = this.state
    // let { currentProposal } = this.props
    // if (currentProposal) {
    //   data = { ...currentProposal, ...data }
    // }
    data[name] = date._d.getTime()
    this.setState({ data })
  }

  _onUpload = () => {
    this.setState({ modalAttachment: true })
  }

  handleUploaded = ({ uploaded }) => {
    let { currentProposal } = this.props
    if (!currentProposal[uploaded.name]) currentProposal[uploaded.name] = []
    currentProposal[uploaded.name].push({
      fileName: uploaded.typeOfAttach + '_' + uploaded.file.name,
      typeOfAttach: uploaded.typeOfAttach
    })
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  handleSubmit = () => {
    let { data, validate, confirmClose } = this.state
    let { currentProposal, currentState } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })
    if (currentProposal) {
      data = { ...currentProposal, ...data }
      if (!currentProposal.endFormApprove && data.endFormApprove && !confirmClose) {
        this.setState({confirmClose: true})
      } else {
        const result = validatejs(data, Constraints.EndFormConstraints)
        if (result) {
          let attrs = {}
          Object.keys(result).forEach(item => (attrs[item] = true))
          this.setState({
            validate: { error: true, validateError: result, attrs }
          })
        } else {
          if (!data.noBookEndProject) {
            if (noBook) {
              if (data['endFormApprove']) {
                data['status'] = 'ปิดโครงการ'
              } else {
                data['status'] = 'ประเมินความก้าวหน้าโครงการ'
              }
              data.noBookEndProject =
                'กสศ. น. 19/' +
                noBook.toString().padStart(5, '0') +
                '/' +
                thisYear
              data['timestamp'] = gettime
              validate['error'] = false
              this.props.updateProposal(data, data._id)
            } else {
              this.props.genId('noBookInside-' + thisYear)
            }
          } else if (!checkNobook) {
            checkNobook = true
            data['timestamp'] = gettime
            if (data['endFormApprove']) {
              data['status'] = 'ปิดโครงการ'
            } else {
              data['status'] = 'ประเมินความก้าวหน้าโครงการ'
            }
            validate['error'] = false
            this.props.updateProposal(data, data._id)
          }
          if (!currentState.isSaving) {
            this.setState({ validate, onSaveSuccess: true, confirmClose: false })
            setTimeout(() => {
              this.setState({ onSaveSuccess: false })
            }, 3000)
          }
        }
      }
    }
  }

  _onUpload = () => {
    this.setState({ modalAttachment: true })
  }

  handleUploaded = ({ uploaded }) => {
    let { currentProposal } = this.props
    if (!currentProposal[uploaded.name]) currentProposal[uploaded.name] = []
    currentProposal[uploaded.name].push({
      fileName: uploaded.typeOfAttach + '_' + uploaded.file.name,
      typeOfAttach: uploaded.typeOfAttach
    })
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  handleAttachedFileRemove = name => {
    let { currentProposal } = this.props
    currentProposal['attachEndForm'].splice(name.index, 1)
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  _onDownload = () => {
    this.setState({ modalPrintout: true, modalAttachment: false })
  }

  handleCloseModal = () => {
    this.setState({
      modalSendEmail: false,
      modalAttachment: false,
      modalAddGroupFinance: false,
      modalPrintout: false,
      confirmClose: false
    })
  }

  onMoneyChange = e => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    let money = e.target.rawValue.replace('-', '')

    // if(e.target.name ==='refund'){
    //   money = parseFloat(e.target.rawValue)
    // }else{
    //   money = Math.abs(parseFloat(e.target.rawValue))
    // }
    data[e.target.name] = money ? money.toString() : '0'
    // validate array
    if (!validate.attrs) validate.attrs = {}
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    this.setState({ data, validate })
  }

  render() {
    const { currentProposal, currentState, match, role } = this.props
    let {
      data,
      validate,
      modalAttachment,
      modalPrintout,
      onSaveSuccess
    } = this.state

    if (!currentProposal)
      return (
        <div>
          <HeaderContent
            subtitle='แบบรายงานขอปิดโครงการ'
            onUpload={this._onUpload}
          />
        </div>
      )

    data = { ...currentProposal, ...data }
    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('proposal', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].control === Input) {
        content[key].value = data[key] || ''
      } else if (content[key].control === Checkbox) {
        content[key].checked = data[key] || false
      } else {
        content[key].value = data[key] || ''
      }
    })

    var EndFormOptions = [
      {
        text: 'รายงานการดำเนินการ/วิจัย(Full)',
        value: 'รายงานการดำเนินการ/วิจัย(Full)'
      },
      { text: 'รายงานการเงิน', value: 'รายงานการเงิน' },
      { text: 'สมุดบัญชีเงินฝากธนาคาร', value: 'สมุดบัญชีเงินฝากธนาคาร' },
      {
        text: 'เอกสารการโอนเงินคืนแก่ กสศ',
        value: 'เอกสารการโอนเงินคืนแก่ กสศ'
      },
      { text: 'อื่นๆ', value: 'อื่นๆ' }
    ]

    return (
      <div>
        <HeaderContent subtitle='แบบรายงานขอปิดโครงการ' title={data.nameTh} />
        <AttachedComponent
          currentProposal={currentProposal}
          namePage='attachEndForm'
          modalAttachment={modalAttachment}
          onHandleUpload={this.handleUploaded}
          onHandleRemove={this.handleAttachedFileRemove}
          options={EndFormOptions}
          onClose={this.handleCloseModal}
          checkStatus={
            currentProposal && currentProposal.status === 'ปิดโครงการ'
          }
        />
        <PrintoutComponent
          modalPrintout={modalPrintout}
          numberOfFile={['แบบรายงานสรุปเพื่อขออนุมัติปิดโครงการ']}
          typeFile={[10]}
          match={match}
          onClose={this.handleCloseModal}
        />
        <Form error={validate.error}>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              as='h5'
              content='ข้อมูลการเงิน'
              icon='money'
            />
            <Form.Group widths='equal'>
              <Form.Field error={validate.attrs['Takemoney']}>
                <label>{'จำนวนเงินเบิกจ่ายเงินจาก กสศ. *'}</label>
                <Cleave
                  name='Takemoney'
                  readOnly={
                    (currentProposal &&
                      currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin' &&
                      role !== 'Academic' &&
                      role !== 'Co-Academic')
                  }
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.Takemoney || ''}
                />
              </Form.Field>
              <Form.Field error={validate.attrs['interest']}>
                <label>{'ดอกเบี้ย *'}</label>
                <Cleave
                  name='interest'
                  readOnly={
                    (currentProposal &&
                      currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin' &&
                      role !== 'Academic' &&
                      role !== 'Co-Academic')
                  }
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.interest || ''}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field error={validate.attrs['refund']}>
                <label>{'เงินคืน/เบิกเพิ่ม *'}</label>
                <Cleave
                  name='refund'
                  readOnly={
                    (currentProposal &&
                      currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin' &&
                      role !== 'Academic' &&
                      role !== 'Co-Academic')
                  }
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.refund || ''}
                />
              </Form.Field>
              <Form.Field error={validate.attrs['ActualUsemoney']}>
                <label>{'รวมใช้จ่ายจริง *'}</label>
                <Cleave
                  name='ActualUsemoney'
                  readOnly={
                    (currentProposal &&
                      currentProposal.status !== 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin') ||
                    (currentProposal &&
                      currentProposal.status === 'ประเมินความก้าวหน้าโครงการ' &&
                      role !== 'Admin' &&
                      role !== 'Academic' &&
                      role !== 'Co-Academic')
                  }
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.ActualUsemoney || ''}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              {/* {this._renderFormField(content.takeTime)} */}
              {this._renderFormField(content.extendTime)}
            </Form.Group>
            {data && data.extendTime === 'มี' && (
              <Form.Group>
                <Form.Field width={4}>
                  <label>{'ขยายเวลา (เริ่มต้น)'}</label>
                  <DayPicker
                    ser={'extendStartDate'}
                    name={'extendStartDate'}
                    onDayChange={this.handleDayChangeRow}
                    data={(currentProposal && data.extendStartDate) || ''}
                  />
                </Form.Field>
                <Form.Field width={4}>
                  <label>{'ขยายเวลา (สิ้นสุด)'}</label>
                  <DayPicker
                    ser={'extendEndDate'}
                    name={'extendEndDate'}
                    onDayChange={this.handleDayChangeRow}
                    data={(currentProposal && data.extendEndDate) || ''}
                  />
                </Form.Field>
              </Form.Group>
            )}
          </Segment>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              as='h5'
              content='การใช้จ่ายเงิน'
              icon='briefcase'
            />
            <Form.Field
              {...attachmentEndForm.withdrawLastTime}
              checked={
                (data.attachmentEndForm &&
                  data.attachmentEndForm.withdrawLastTime) ||
                false
              }
              onChange={this.handleChangeAttachment}
              readOnly={
                (currentProposal &&
                  currentProposal.status !== 'ยุติโครงการ' &&
                  role !== 'Admin' &&
                  role !== 'Academic' &&
                  role !== 'Co-Academic') ||
                (currentProposal &&
                  currentProposal.status === 'ยุติโครงการ' &&
                  role !== 'Admin')
              }
            />
            {this._renderFormField(content.descriptionWithdraw)}
          </Segment>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              as='h5'
              content='รายละเอียดปิดโครงการ'
              icon='file'
            />
            {this._renderFormField(content.endFormConclusion)}
            {/* <Form.Group>
              <Form.Field {...attachmentEndForm.reportProceedAndResearch}
                onChange={this.handleChangeAttachment || false}
                checked={data.attachmentEndForm && data.attachmentEndForm.reportProceedAndResearch}
                readOnly={(currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ยุติโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin')} />
              <Form.Field {...attachmentEndForm.amountReport}
                value={(data.attachmentEndForm && data.attachmentEndForm.amountReport) || ''}
                onChange={this.handleChangeAttachment}
                readOnly={(currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ยุติโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin')} />
              <label>เล่ม</label>
            </Form.Group>
            <Form.Field {...attachmentEndForm.reportFinance}
              checked={(data.attachmentEndForm && data.attachmentEndForm.reportFinance) || false}
              onChange={this.handleChangeAttachment}
              readOnly={(currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ยุติโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin')} />
            <Form.Field {...attachmentEndForm.deposit}
              checked={(data.attachmentEndForm && data.attachmentEndForm.deposit) || false}
              onChange={this.handleChangeAttachment}
              readOnly={(currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ยุติโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin')} />
            <Form.Field {...attachmentEndForm.refund}
              checked={(data.attachmentEndForm && data.attachmentEndForm.refund) || false}
              onChange={this.handleChangeAttachment}
              readOnly={(currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ยุติโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin')} />
            <Form.Group>
              <Form.Field {...attachmentEndForm.ohter}
                checked={(data.attachmentEndForm && data.attachmentEndForm.ohter) || false}
                onChange={this.handleChangeAttachment}
                readOnly={(currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ยุติโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin')} />
              <Form.Field {...attachmentEndForm.noteOhter}
                value={(data.attachmentEndForm && data.attachmentEndForm.noteOhter) || ''}
                onChange={this.handleChangeAttachment}
                readOnly={(currentProposal && currentProposal.status === 'ปิดโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status !== 'ยุติโครงการ' && role !== 'Admin' && role !== 'Academic') || (currentProposal && currentProposal.status === 'ยุติโครงการ' && role !== 'Admin')} />
            </Form.Group> */}
            {this._renderFormField(content.comment)}
            {/* {this._renderFormField(content.endFormApprover)} */}
          </Segment>
          {/* <Segment>
            <Header style={{ fontFamily: 'supermarket' }} as='h5' content='เสนอผู้จัดการ กสศ. เพื่ออนุมัติปิดโครงการ' icon='edit' />
            {this._renderFormField(content.endFormApprove)}
            {this._renderFormField(content.endFormNote)}
          </Segment> */}
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          {<Form.Checkbox
            onChange={this.handleChange}
            checked={data.endFormApprove}
            label={'อนุมัติปิดโครงการ'}
            name='endFormApprove'
            disabled={(role !== 'Admin' && currentProposal && currentProposal.endFormApprove)}
          />}
          {
            <Button
              color='blue'
              content='บันทึก'
              icon='save'
              type='submit'
              disabled={
                (currentProposal &&
                  currentProposal.status !== 'ยุติโครงการ' &&
                  role !== 'Admin' &&
                  role !== 'Academic' &&
                  role !== 'Co-Academic') ||
                (currentProposal &&
                  currentProposal.status === 'ยุติโครงการ' &&
                  role !== 'Admin')
              }
              loading={currentState.isSaving}
              onClick={this.handleSubmit}
            />
          }
        </Form>
        <Transition
          visible={onSaveSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle'></Icon>บันทึกสำเร็จ
            </Message.Header>
          </Message>
        </Transition>
        <Confirm
          open={this.state.confirmClose}
          content='หากยืนยันการปิดโครงการแล้ว ท่านจะไม่สามารถย้อนกลับไปทำการเบิกเงินงวดได้ ท่านยืนยันใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.handleCloseModal}
          onConfirm={this.handleSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  form: state.proposalForm,
  currentState: state.projects,
  role: state.role,
  counter: state.counter
})

const mapDispatchToProps = dispatch => ({
  listProject: () => dispatch(torActions.fetchList()),
  listTor: () =>
    dispatch(torActions.fetchList({ sort: { nameTh: 1 }, limit: 5, skip: 0 })),
  addProposal: data => dispatch(projectActions.save(data)),
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id)),
  genId: name => dispatch(generate(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(EndForm)

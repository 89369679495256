import React, { Component, Fragment } from 'react'
import {
  Form,
  Button,
  Segment,
  Header,
  Message,
  Transition,
  Icon,
  Confirm
} from 'semantic-ui-react'
import { FormSearchAddress } from 'Utils'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
import { actions as projectActions } from 'Redux/reducers/projects'
import { localTh } from 'Utils'
import { HeaderContent } from 'Components/Header'
import jwt from 'jsonwebtoken'

let gettime = ''

class Area extends Component {
  constructor (props) {
    super(props)
    this.state = {
      readOnly: false,
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      onSaveSuccess: false,
      openConfirm: false,
      indexDelete: ''
    }
  }

  componentDidMount () {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.currentProposal) {
      let tmp = { ...nextProps.currentProposal }
      let { data } = this.state;
      this.setState({ data: { ...tmp, ...data } });
    }
    // if(this.props.currentProposal===nextProps.currentProposal){
    //   console.log('2')
    //   this.props.getProposal(match.params.id);
    // }
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  handleSubmit = condition => {
    let { data, validate } = this.state
    let { currentProposal, auth, role } = this.props
    if (this.props.currentProposal) data = { ...currentProposal, ...data }
    validate.attrs = {}

    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    })

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (!validate['validateError']) validate['validateError'] = {}

    if (!data.projectGroupArea) data.projectGroupArea = []

    if (data.projectGroupArea.length === 0) {
      validate.error = true
      validate['validateError']['projectGroupArea'] = []
      validate['validateError']['projectGroupArea'].push(
        'โปรดระบุพื้นที่ดำเนินการอย่างน้อย 1 พื้นที่'
      )
    }

    for (let i = 0; i < data.projectGroupArea.length; i++) {
      for (let key in data.projectGroupArea[i]) {
        if (data.projectGroupArea[i][key] === '') {
          validate.error = true
          validate['validateError']['projectGroupArea'] = []
          validate['validateError']['projectGroupArea'].push(
            'โปรดระบุพื้นที่ดำเนินการให้ครบถ้วน'
          )
        }
      }
    }

    if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      validate['error'] = false
      this.setState({ validate })
      if (role === 'Admin') {
        data['adminEdit'] = user._id
        data['adminEditTime'] = gettime
      } else {
        data['timestamp'] = gettime
      }

      this.setState({ onSaveSuccess: true })
      setTimeout(() => {
        this.setState({ onSaveSuccess: false })
        if (condition === 'next') {
          this.props.history.push('/project/pageScreen/' + data._id)
        } else if (condition === 'back') {
          this.props.history.push('/project/objective/' + data._id)
        }
      }, 3000)
      // if(JSON.parse(sessionStorage.getItem('attachSendProposal'))) {
      //   data['attachSendProposal'] = JSON.parse(sessionStorage.getItem('attachSendProposal'))
      //   sessionStorage.setItem('attachSendProposal', null)
      // }
      this.props.updateProposal(data, data._id)
      // if (this.props.history) this.props.history.push('/project/withdrawTime/' + data._id);
    }
  }

  handleChangeArea = (e, { attr, value, index }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (this.props.currentProposal) data = { ...currentProposal, ...data }

    let _Area = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        currentProposal.projectGroupArea.forEach(item => {
          var tmp = { ...item }
          _Area.push(tmp)
        })
      } else {
        data.projectGroupArea.forEach(item => {
          var tmp = { ...item }
          _Area.push(tmp)
        })
      }
    }

    validate.error = false
    validate['validateError'] = {}

    if (value[attr] === '') {
      _Area[index][attr] = value[attr]
    } else {
      _Area[index] = value
    }
    if (attr === 'city') {
      delete _Area[index].tumbon
    } else if (attr === 'province') {
      delete _Area[index].tumbon
      delete _Area[index].city
    }

    let tmp = { ...data, projectGroupArea: _Area }

    this.setState({ data: tmp, validate })
  }

  handleAddArea = () => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }

    if (!data.projectGroupArea) data.projectGroupArea = []

    let _Area = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _Area = currentProposal.projectGroupArea.slice()
      } else {
        _Area = data.projectGroupArea.slice()
      }
    }

    validate.error = false
    validate['validateError'] = {}

    _Area.push({
      tumbon: '',
      city: '',
      province: '',
      zipcode: ''
    })

    let tmp = { ...data, projectGroupArea: _Area }

    this.setState({ data: tmp, validate })
  }

  handleDeleteArea = () => {
    let { data, validate, indexDelete } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }

    let _Area = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _Area = currentProposal.projectGroupArea.slice()
      } else {
        _Area = data.projectGroupArea.slice()
      }
    }

    // if (data.projectGroupArea) {
    _Area.splice(indexDelete, 1)
    validate.error = false
    validate['validateError'] = {}

    let tmp = { ...data, projectGroupArea: _Area }

    this.setState({ data: tmp, openConfirm: false, validate })
    // }
  }

  handleNextPage = () => {
    this.handleSubmit('next')
  }

  handleBackPage = () => {
    this.handleSubmit('back')
  }

  openConfirm = (e, { index }) => {
    this.setState({ openConfirm: true, indexDelete: index })
  }

  close = () => this.setState({ openConfirm: false })

  render () {
    let { validate, data, onSaveSuccess } = this.state
    const { currentProposal, currentState, role, match } = this.props

    if (match.params.id) {
      data = { ...currentProposal, ...data }
    }

    let checkLockScreen =
      (currentProposal &&
        currentProposal.status !== 'ส่งข้อเสนอโครงการ' &&
        currentProposal.status !== 'กลั่นกรอง' &&
        role !== 'Admin') ||
      (currentProposal &&
        (currentProposal.status === 'ส่งข้อเสนอโครงการ' ||
          currentProposal.status === 'กลั่นกรอง') &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')

    return (
      <div style={{ paddingBottom: 220 }}>
        <HeaderContent
          title={data.nameTh}
          subtitle='พื้นที่ดำเนินการของโครงการ'
        />
        <Form error={validate.error}>
          {data &&
            data.projectGroupArea &&
            data.projectGroupArea.map((item, idx) => (
              <Segment key={idx}>
                {!checkLockScreen && (
                  <Button
                    style={{ position: 'absolute', top: 10, right: 10 }}
                    color='red'
                    type='button'
                    icon='close'
                    onClick={this.openConfirm}
                    index={idx}
                    disabled={checkLockScreen}
                    // disabled={(data && data.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (data && data.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                  />
                )}
                <Header
                  as='h3'
                  style={{ marginTop: 0, fontFamily: 'supermarket' }}
                >
                  พื้นที่ดำเนินการ {idx + 1}
                </Header>
                <Form.Group>
                  <Form.Field
                    width={16}
                    control={FormSearchAddress}
                    label={'ตำบล/แขวง (พิมตัวอักษรเพื่อค้นหา)'}
                    attr='tumbon'
                    index={idx}
                    onChange={this.handleChangeArea}
                    value={item || 'กรุณากรอกพื้นที่ดำเนินการ'}
                    readOnly={checkLockScreen}
                    // readOnly={(data && data.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (data && data.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                  />
                  <Form.Field
                    width={16}
                    control={FormSearchAddress}
                    label={'อำเภอ/เขต (พิมตัวอักษรเพื่อค้นหา)'}
                    attr='city'
                    index={idx}
                    onChange={this.handleChangeArea}
                    value={item || 'กรุณากรอกพื้นที่ดำเนินการ'}
                    readOnly={checkLockScreen}
                    // readOnly={(data && data.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (data && data.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                  />
                  <Form.Field
                    width={16}
                    control={FormSearchAddress}
                    label={'จังหวัด (พิมตัวอักษรเพื่อค้นหา)'}
                    attr='province'
                    index={idx}
                    onChange={this.handleChangeArea}
                    value={item || 'กรุณากรอกพื้นที่ดำเนินการ'}
                    readOnly={checkLockScreen}
                    // readOnly={(data && data.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (data && data.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                  />
                </Form.Group>
              </Segment>
            ))}
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Transition
            visible={onSaveSuccess}
            animation='horizontal flip'
            duration={500}
          >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                <Icon name='check circle' />
                บันทึกสำเร็จ
              </Message.Header>
            </Message>
          </Transition>
          <Button
            disabled={checkLockScreen}
            color='green'
            type='button'
            content='เพิ่มพื้นที่ดำเนินการ'
            onClick={this.handleAddArea}
          />
          <Button
            color='blue'
            content='บันทึก'
            icon='save'
            type='submit'
            loading={currentState.isSaving}
            onClick={this.handleSubmit}
            disabled={checkLockScreen}
          />
          <Button
            className='button-back-bottom-right'
            type='button'
            color='blue'
            content='ย้อนกลับ'
            disabled={checkLockScreen}
            icon='step backward'
            onClick={this.handleBackPage}
            // disabled={checkLockScreen}
          />
          <Button
            className='button-bottom-right'
            type='button'
            color='blue'
            content='ถัดไป'
            disabled={checkLockScreen}
            icon='step forward'
            onClick={this.handleNextPage}
            // disabled={checkLockScreen}
          />
        </Form>
        <Confirm
          open={this.state.openConfirm}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.close}
          onConfirm={this.handleDeleteArea}
        />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  currentState: state.projects,
  role: state.role,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Area)

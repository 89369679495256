import React, { Component } from 'react'
import {
  Transition,
  Icon,
  Message,
  Tab,
  Menu
} from 'semantic-ui-react'
import { HeaderContent } from 'Components/Header'
import { connect } from 'react-redux'
import { actions as projectActions } from 'Redux/reducers/projects'
import ExtendForm from './ExtendForm'


class TabExtendTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validate: { error: false, validateError: null, attrs: {} },
      modalAttachment: false,
      onSaveSuccess: false
    }
  }

  componentDidMount() {
    const { match } = this.props
    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentProposal) {
      let tmp = { ...nextProps.currentProposal }

      if (!tmp.extendTimeForm) {
        tmp.extendTimeForm = [{}, {}, {}, {}, {}]
      }

      let { data } = this.state
      this.setState({ data: { ...tmp, ...data } })
    }
  }

  render() {
    const { currentProposal } = this.props
    let { data, onSaveSuccess } = this.state
    data = { ...currentProposal, ...data }

    let panes = []
    if (data.extendTimeForm) {
      panes.push({
        menuItem: (
          <Menu.Item key={0} content={'ครั้งที่ 1'} />
        ),
        render: () => <Tab.Pane><ExtendForm dataRow={data} index={0} /></Tab.Pane>,
      })
      // data.extendTimeForm.forEach((item, idx) => {
      //   panes.push({
      //     menuItem: (
      //       <Menu.Item key={idx} content={'ครั้งที่ ' + (idx + 1)} />
      //     ),
      //     render: () => <Tab.Pane><ExtendForm dataRow={data} index={idx} /></Tab.Pane>,
      //   })
      // })
    }

    return (
      <div>
        <HeaderContent
          subtitle='แบบรายงานขอปิดโครงการ'
          title={data.nameTh} />
        <Tab panes={panes} />
        <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
          </Message>
        </Transition>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  currentState: state.projects,
  role: state.role
})

const mapDispatchToProps = dispatch => ({
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: (id) => dispatch(projectActions.fetchOne(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TabExtendTime)
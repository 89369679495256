import React, { Component, Fragment } from 'react'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { connect } from 'react-redux'
import { Table, Header, Button, Modal, Form, Label, Transition, Icon, Message } from 'semantic-ui-react'

class PagePlanBudget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
      item: {},
      data: {},
      checkValue: { valuePlan: '', plan: '' },
      obj: { valuePlan: '', plan: '' },
      dataChange: false,
      onSaveSuccess: false,
      error: false
    }
  }

  componentDidMount() {
    const { obj } = this.props
    if (obj._id) {
      this.props.getDoc(obj._id)
    } else {
      const id = localStorage.getItem("_id");
      this.props.getDoc(id)
    }
  }

  componentWillReceiveProps(nextProps) {
    let { data } = this.state
    let tmp = { ...nextProps.fundings }
    if (nextProps.fundings) {
      this.setState({ data: { ...tmp, ...data } })
    }
  }

  handleSelectRow = (item, selected) => {
    let { checkValue } = this.state
    checkValue.valuePlan = item.name
    checkValue.plan = item.value
    if (selected === this.state.selected) selected = null
    this.setState({ checkValue, selected })
  }

  handleSelected = (selected) => {
    const { dataChange } = this.state
    if (!dataChange) {
      this.props.onSelected('valuePlan', selected.name, 'activity')
      localStorage.setItem("valuePlan", selected.name);
    } else {
      this.setState({ item: selected })
      this.handleSelectedWithChangeOpen()
    }
  }

  handleSelectedBy = (item) => {
    this.props.onSelected('valuePlan', item.name, 'activity')
    localStorage.setItem("valuePlan", item.name);
  }

  handleSelectedWithChangeNext = () => {
    const { item } = this.state
    this.setState({ modalDataChange: false, closeOnDimmerDataChange: true, dataChange: false, })
    this.handleSelectedBy(item)
  }

  handleSelectedWithChangeOpen = () => {
    this.setState({ modalDataChange: true, closeOnDimmerDataChange: false })
  }

  handleSelectedWithChangeClose = () => {
    this.setState({ modalDataChange: false, closeOnDimmerDataChange: true })
  }

  handleChangeExplain = (e, { name, value }) => {
    let { data, checkValue } = this.state
    const { fundings } = this.props
    if (fundings) {
      data = { ...fundings, ...data }
    }
    data.list.forEach(item => {
      if (item.valuePlan === name) {
        item.plan = value
      }
    })
    checkValue.plan = value
    this.setState({ data, checkValue, dataChange: true })
  }

  handleAddRow = () => {
    let { obj } = this.state
    obj['plan'] = 'รหัสแผนงาน'
    this.setState({ modal: true, closeOnDimmerClick: false })
  }

  handleConfirmModalAdd = () => {
    let { obj, data } = this.state
    const { fundings } = this.props
    if (fundings) {
      data = { ...fundings, ...data }
    }
    var regx = /^[0-9]*$/
    const year = localStorage.getItem("year");
    const valueGroup = localStorage.getItem("valueGroup");
    const check = obj.valuePlan.match(regx)
    if (check !== null && obj.valuePlan.length === 2) {
      const groups = data && data.list ?
        this.mapArrayBudget(data.list
          .filter(item => item.valuePlan && item.valueGroup === valueGroup)
          .map((item, idx) => ({
            name: item.valuePlan,
            value: item.plan
          }))) : []
      const validate = groups.filter(item => item.name === valueGroup + obj.valuePlan)
      if (validate.length > 0) {
        this.setState({ error: true })
        setTimeout(() => {
          this.setState({ error: false });
        }, 3000)
      } else {
        let s = { 'year': year, 'valueGroup': valueGroup, 'valuePlan': valueGroup + obj.valuePlan, 'plan': obj.plan };
        data.list.push(s);
        this.setState({ modal: false, closeOnDimmerClick: true, dataChange: true })
      }
    } else {
      this.setState({ error: true })
      setTimeout(() => {
        this.setState({ error: false });
      }, 3000)
    }
  }

  handleCloseModalAdd = () => {
    this.setState({ modal: false, closeOnDimmerClick: true })
  }

  handleChange = (e, { name, value }) => {
    let { obj } = this.state
    if (name === 'valuePlan') {
      if (value.length < 3) {
        obj[name] = value
      }
    } else obj[name] = value
    this.setState(obj)
  }

  mapArrayBudget = (data) => {
    let result = []
    if (data.length > 0) {
      result[0] = data[0]
      for (let i = 1; i < data.length; i++) {
        let check = false
        for (let j = 0; j < result.length; j++) {
          if (data[i].name === result[j].name) check = true
        }
        if (!check) result.push(data[i])
      }
    }
    return result
  }

  handleOpenModalSave = () => {
    let { data } = this.state
    const { fundings } = this.props
    if (fundings) {
      data = { ...fundings, ...data }
    }
    this.props.updateDoc(data, data._id);
    this.setState({ onSaveSuccess: true, dataChange: false })
    setTimeout(() => {
      this.setState({ onSaveSuccess: false });
    }, 3000)
  }

  render() {
    let { data, selected, checkValue, obj, dataChange, onSaveSuccess, error } = this.state
    const { fundings } = this.props
    if (fundings) {
      data = { ...fundings, ...data }
    }
    const valueGroup = localStorage.getItem("valueGroup");
    const plans = data && data.list ?
      this.mapArrayBudget(data.list
        .filter(item => item.valuePlan && item.valueGroup === valueGroup)
        .map((item, idx) => ({
          name: item.valuePlan,
          value: item.plan
        }))) : []

    return (
      <Fragment >
        <Header content={'แผนหมวดงบประมาณของปี : ' + data.year + ' แผน : ' + valueGroup} />
        <Table selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content='รหัสแผนงาน' textAlign='center' width={3} />
              <Table.HeaderCell content='คำอธิบาย' textAlign='center' width={13} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {plans.length > 0 && plans.map((row, idx) => (
              <Fragment key={idx}>
                <Table.Row onClick={this.handleSelectRow.bind(this, row, idx)} >
                  {(selected === idx && < Table.Cell >
                    <Label color="green" ribbon size='big' content={'รหัสแผนงาน : ' + row.name} />
                  </Table.Cell>) || < Table.Cell textAlign='center' content={'รหัสแผน : ' + row.name} />}
                  <Table.Cell content={row.value} />
                </Table.Row>
                {selected === idx && (
                  <Table.Row active>
                    <Table.Cell colSpan='2'>
                      <Form>
                        <Form.Group>
                          <Form.Field width={2}>
                            <label>รหัสแผนงาน</label>
                            <Header content={' ' + checkValue.valuePlan} as='h4' style={{ 'marginTop': '15px' }} />
                          </Form.Field>
                          <Form.Input label='คำอธิบาย' value={checkValue.plan} width={14}
                            name={checkValue.valuePlan} onChange={this.handleChangeExplain} />
                        </Form.Group>
                        <Form.Group>
                          <Form.Field width={2} />
                          <Button content='เลือกแผน' color='blue' icon='step forward'
                            onClick={this.handleSelected.bind(this, row)} />
                        </Form.Group>
                      </Form>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Fragment>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>
                <Button content='เพิ่มแผนงาน' color='blue' icon='plus' onClick={this.handleAddRow} /></Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
          </Message>
        </Transition>
        <Button content='บันทึก' color='green' icon='save'  disabled={!dataChange}
          onClick={this.handleOpenModalSave} />
        <Modal
          open={this.state.modal}
          onClose={this.handleCloseModalAdd}
          closeOnDimmerClick={this.state.closeOnDimmerClick}
          size='small'
          closeIcon>
          <Modal.Header content={'เพิ่มแผน ของปีงบประมาณ :' + data.year} as='h3' />
          <Modal.Content>
            <Form>
              <Form.Group>
                <Form.Field width={2}>
                  <label>รหัสแผนงาน</label>
                  <Header content={valueGroup + ' +'} as='h4' style={{ 'marginTop': '15px' }} />
                </Form.Field>
                <Form.Input label='รหัสแผนงาน 2 หลัก' width={4} value={obj.valuePlan} name='valuePlan' onChange={this.handleChange} />
                <Form.Input label='คำอธิบาย' width={10} value={obj.plan} name='plan' onChange={this.handleChange} />
              </Form.Group>
              <Transition visible={error} animation='horizontal flip' duration={500} >
                <Message negative>
                  <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='close'></Icon>รหัสแผนงาน ผิดพลาด</Message.Header>
                </Message>
              </Transition>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button content='ยินยัน' color='blue' icon='check' onClick={this.handleConfirmModalAdd} />
            <Button content='ยกเลิก' color='red' icon='close' onClick={this.handleCloseModalAdd} />
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.modalDataChange}
          onClose={this.handleSelectedWithChangeClose}
          closeOnDimmerClick={this.state.closeOnDimmerDataChange}
          size='small'
          closeIcon>
          <Header content={' คำเตือน '} as='h2' icon='warning' />
          <Modal.Content content='ท่านได้เปลี่ยนคำอธิบายแผนงานแต่ยังไม่บันทึก ต้องการดำเนินการต่อหรือไม่ ?' as='h3' />
          <Modal.Actions>
            <Button content='ใช่' color='blue' icon='check' onClick={this.handleSelectedWithChangeNext} />
            <Button content='ไม่' color='red' icon='close' onClick={this.handleSelectedWithChangeClose} />
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}

const mapState = state => ({
  fundings: state.fundings.currentData,
})

const mapAction = dispatch => ({
  updateDoc: (data, id) => dispatch(fundingsActions.save(data, id, 'POST')),
  getDoc: (id) => dispatch(fundingsActions.fetchOne(id)),
})

export default connect(mapState, mapAction)(PagePlanBudget)

var ScreenContraints = {
  screenFeedback: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  // screensuggestion: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุ'
  //   }
  // },
}


var ScreenArrayContraints = {
  nameProfessional: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
}




let constraints = {
  ScreenContraints: ScreenContraints,
  ScreenArrayContraints: ScreenArrayContraints
}


module.exports = { constraints }

var TorConstraints = {
  startDate: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  nameTh: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  budgetLimit: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
  timeProcess: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุ'
    }
  },
}

var TorArrayConstraints = {
  targetGroup: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุให้ครบถ้วน'
    }
  },
  targetsCount: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุให้ครบถ้วน'
    }
  },
  targetsUnit: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุให้ครบถ้วน'
    }
  },
  targetExplan: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุให้ครบถ้วน'
    }
  },
}

var TorArrayConstraintsP = {
  product: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุให้ครบถ้วน'
    }
  },
}

var TorArrayConstraintsR = {
  // result: {
  //   presence: {
  //     allowEmpty: false,
  //     message: '^ต้องระบุให้ครบถ้วน'
  //   }
  // },
}

var TorArrayConstraintsO = {
  objective: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุให้ครบถ้วน'
    }
  },
}

var TorArrayConstraintsS = {
  scope: {
    presence: {
      allowEmpty: false,
      message: '^ต้องระบุให้ครบถ้วน'
    }
  },
}

export const constraints = {
  TorConstraints: TorConstraints,
  TorArrayConstraints: TorArrayConstraints,
  TorArrayConstraintsP: TorArrayConstraintsP,
  TorArrayConstraintsR: TorArrayConstraintsR,
  TorArrayConstraintsO: TorArrayConstraintsO,
  TorArrayConstraintsS: TorArrayConstraintsS
}

const thaiDate = {
  month: {
    '0': 'มกราคม',
    '1': 'กุมภาพันธ์',
    '2': 'มีนาคม',
    '3': 'เมษายน',
    '4': 'พฤษภาคม',
    '5': 'มิถุนายน',
    '6': 'กรกฎาคม',
    '7': 'สิงหาคม',
    '8': 'กันยายน',
    '9': 'ตุลาคม',
    '10': 'พฤศจิกายน',
    '11': 'ธันวาคม',
  }
}

export default function (context, name) {
  if (thaiDate[context] && name) {
    let date = new Date(name)
    var day = date.getDate()
    var month = thaiDate[context][date.getMonth()] ? thaiDate[context][date.getMonth()] : date.getMonth()
    var year = date.getFullYear() + 543
    return day + " " + month + " " + year
  }
  return name;
}
import React, { Component, Suspense } from 'react'
import './Main.css'
// import Card from './Card'
import logo from 'Images/qlf.png'
import { connect } from 'react-redux'
import { changePage } from 'Redux/actions'
import { Button } from 'semantic-ui-react';
import jwt from 'jsonwebtoken'

const cards = [
  { title: 'โครงการ', link: '/project/search', detail: 'สร้างข้อกำหนด จัดการโครงการ ' },
  { title: 'ทดรองจ่าย', link: '/borrow/searchborrow', detail: 'สร้างหัวข้อยืมเงินทดรองจ่ายและ คืนเงินทดรองจ่าย' },
  // { title: 'ข้อมูลหน่วยงาน', link: '/lagaldepartment/listdepartment', detail: 'ข้อมูลหน่วยงาน', name: 'law' },
  // { title: 'เงินอุดหนุน', link: '/', detail: 'เงินอุดหนุน' },
  { title: 'ผู้ดูแลระบบ', link: '/admin/fundings', detail: 'จัดการระบบ เพิ่มหมวดงบประมาณ เพิ่มบุคคลากร' },
  { title: 'ภาพรวมระบบ', link: '/graph/search', detail: 'ข้อมูลจำนวนโครงการทั้งหมด และกราฟแสงดงข้อมูลภาพรวม งบประมาณ จำนวนโครงการ รายชื่อบุคลากรสำนักต่างๆ' }
]

const Card = React.lazy(() => import('./Card'));

class Main extends Component {

  componentDidMount = () => {
    this.props.changePage('MAIN')
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  filter = () => {
    const { role, auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    let results = cards.slice()
    if (role === 'Academic' || role === 'Co-Academic') {
      if (user.user === 'nikorn') results = [cards[0], cards[1], cards[3]]
      else results = [cards[0], cards[1]]
      // delete results.splice(results.length - 1, 1)
    } else if (role === 'Contract') {
      if (user.user === 'jantana') results = [cards[0], cards[3]]
      else results = [cards[0], cards[3]]
    } else if (role === 'Staff') {
      results = [cards[1]]
    } else if (role === 'Law') {
      results = [cards[2]]
    } else if (role === 'auditor') {
      results = [cards[0]]
    }
    return results
  }

  handleLoad = () => {
    window.open('https://drive.google.com/file/d/1PNbjnEcSuJNOa88v1xutBy9w_nmXkJUJ/view?usp=sharing')
  }

  handleBackMain = () => {
    console.log('got')
    this.props.history.push('/')
  }

  render() {
    return (
      <div className="main-container">
        <div className="main-header">
          <h1 className="title">project management 1.9.4 </h1>
          <div className="main-subheader">
            <div className="img-container" onClick={this.handleBackMain}>
              <img className="img" src={logo} alt="logo" />
            </div>
            <div className="sub-title">ระบบบริหารจัดการโครงการ <Button content='คู่มือการใช้งาน'
              onClick={this.handleLoad} inverted color='blue' /></div>
          </div>
        </div>
        <br />
        <br />
        <Suspense fallback={<div>Loading...</div>}>
          <div className='main-card'>
            {this.filter().map((c, i) => <Card key={i} data={c} />)}
          </div>
        </Suspense>
        <div ></div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  organizations: state.department,
  role: state.role,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  changePage: (page) => dispatch(changePage(page)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)

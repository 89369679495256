import React, { Component, Fragment } from 'react'
import {
  Header,
  Form,
  Button,
  Segment,
  Message,
  Modal,
  Transition,
  Icon
} from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css'
import { GetServerTime as GetTime } from 'Utils'
import { HeaderContent } from 'Components/Header'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as userActions } from 'Redux/reducers/user'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as individuals_wActions } from 'Redux/reducers/individuals_w'
import { actions as individuals_apActions } from 'Redux/reducers/individuals_ap'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { actions as bankgroupActions } from 'Redux/reducers/bankgroup'
import { generate } from 'Redux/reducers/counting'
import { localTh } from 'Utils'
import { connect } from 'react-redux'
import { AttachedComponent } from 'Utils'
import { PrintoutComponent } from 'Utils'
import { ComponentOrganization, ComponentPerson } from 'Utils'
import jwt from 'jsonwebtoken'

let gettime = ''

let APOptions = []

var PromiseOptions = [
  { key: '1', text: 'ธนาคารกรุงเทพ', value: 'ธนาคารกรุงเทพ' },
  { key: '2', text: 'ธนาคารออมสิน', value: 'ธนาคารออมสิน' },
  { key: '3', text: 'ธนาคารกรุงไทย', value: 'ธนาคารกรุงไทย' },
  { key: '4', text: 'ธนาคารกสิกร', value: 'ธนาคารกสิกร' },
  { key: '5', text: 'ธนาคารไทยพาณิชย์', value: 'ธนาคารไทยพาณิชย์' },
  { key: '6', text: 'ธนาคารกรุงศรีอยุธยา', value: 'ธนาคารกรุงศรีอยุธยา' },
  { key: '7', text: 'ธนาคารธนชาต', value: 'ธนาคารธนชาต' },
  { key: '8', text: 'ธนาคารทหารไทย', value: 'ธนาคารทหารไทย' },
  { key: '9', text: 'ธนาคารเกียรตินาคิน', value: 'ธนาคารเกียรตินาคิน' },
  { key: '10', text: 'ธนาคารซีไอเอ็มบีไทย', value: 'ธนาคารซีไอเอ็มบีไทย' },
  { key: '11', text: 'ธนาคารทิสโก้', value: 'ธนาคารทิสโก้' },
  { key: '12', text: 'ธนาคารยูโอบี', value: 'ธนาคารยูโอบี' },
  { key: '13', text: 'ธนาคารเพื่อการเกษตร', value: 'ธนาคารเพื่อการเกษตร' },
  {
    key: '14',
    text: 'ธนาคารอิสลามแห่งประเทศไทย',
    value: 'ธนาคารอิสลามแห่งประเทศไทย'
  },
  {
    key: '15',
    text: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
    value: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)'
  },
  {
    key: '16',
    text: 'ธนาคารไทยเครดิตเพื่อรายย่อย',
    value: 'ธนาคารไทยเครดิตเพื่อรายย่อย'
  },
  {
    key: '17',
    text: 'ธนาคารแลนด์ แอนด์ เฮาส์',
    value: 'ธนาคารแลนด์ แอนด์ เฮาส์'
  },
  { key: '18', text: 'ธนาคารไอซีบีซี (ไทย)', value: 'ธนาคารไอซีบีซี (ไทย)' },
  {
    key: '19',
    text: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย',
    value: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย'
  },
  {
    key: '20',
    text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
    value: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร'
  },
  {
    key: '21',
    text: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย',
    value: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย'
  },
  { key: '22', text: 'ธนาคารอาคารสงเคราะห์', value: 'ธนาคารอาคารสงเคราะห์' }
]

class PageSigner extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      modalAttachment: false,
      onSaveNotSuccess: false,
      onSaveSuccess: false,
      indexDelete: '',
      isLoad: false,
      cidRecipient: ''
    }
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  componentDidMount () {
    let { match, auth } = this.props

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
    this.props.getTypeProject('584ccec0e1e111e889cf8170c54de9cb')
  }

  componentWillReceiveProps (nextProps) {
    let { currentProposal, isLoad } = nextProps
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })
    promise1.then(function (value) {
      gettime = value
    })

    if (currentProposal && !isLoad) {
      if (!currentProposal.authorizePerson) {
        currentProposal.authorizePerson = [
          {
            select: true,
            value: currentProposal.manager,
            name: currentProposal.managerName
          },
          { select: true, value: '', name: '' },
          { select: true, value: '', name: '' }
        ]
      }
    }
  }

  handleUploaded = ({ name, index, file, typeOfAttach, uploaded }) => {
    let { currentProposal } = this.props
    if (!currentProposal[uploaded.name]) currentProposal[uploaded.name] = []
    currentProposal[uploaded.name].push({
      fileName: uploaded.typeOfAttach + '_' + uploaded.file.name,
      typeOfAttach: uploaded.typeOfAttach
    })
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  handleAttachedFileRemove = name => {
    let { currentProposal } = this.props
    currentProposal['attachPromise'].splice(name.index, 1)
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  handleAPEditRow = (idx, e, { value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    data = { ...currentProposal, ...data }

    data.authorizePerson[idx].name = value

    this.setState(data)

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
  }

  handleAPchenge = (idx, e, { value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    data = { ...currentProposal, ...data }

    data.authorizePerson[idx].value = value
    let index = APOptions.findIndex(i => i.value === value)
    data.authorizePerson[idx].name = APOptions[index].text

    this.setState(data)

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
  }

  handleAPSearch = (index, e) => {
    let { data } = this.state
    let { currentProposal } = this.props
    data = { ...currentProposal, ...data }

    if (e.target.value.length >= 3) {
      let query = {}
      data.authorizePerson.forEach((item, idx) => {
        if (item.select) {
          query.$or.push({ _id: item.value })
        }
      })
      let test = e.target.value.split(' ')
      query = {
        'firstname': { $regex: test[0] },
        'lastname': { $regex: test[1] || '' }
      }
      this.props.getPerson_ap(query)
    }
  }

  handleSelectRowAP = (idx, value) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    data = { ...currentProposal, ...data }

    data.authorizePerson[idx].select = value

    this.setState(data)

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
  }

  handleConfirm = () => {
    let { data } = this.state
    let { currentProposal, auth } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        gettime = value
      }
    })
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (this.props.currentProposal) {
      data = { ...currentProposal, ...data }
      data.timestamp = gettime
      this.props.updateProposal(data, data._id)
      this.setState({ onSaveSuccess: true })
      setTimeout(() => {
        this.setState({ onSaveSuccess: false })
      }, 3000)
    }
  }

  handleCloseModal = () => {
    this.setState({
      modalAttachment: false,
      modalPrintout: false
    })
  }

  handleNextPage = () => {
    const { currentProposal } = this.props
    if (this.props.history)
      this.props.history.push(
        '/project/TabProgressProject/' + currentProposal._id
      )
  }

  handleBackPage = () => {
    const { currentProposal } = this.props
    this.props.history.push('/project/pagescreen/' + currentProposal._id)
  }

  close = () =>
    this.setState({ openModalPerson: false, openModalOrganization: false })

  handleComponentOrganization = () => {
    this.setState({ openModalOrganization: true })
  }

  handleComponentPerson = () => {
    this.setState({ openModalPerson: true })
  }

  render () {
    const {
      currentProposal,
      isSaving,
      match,
      individuals_ap,
      role
    } = this.props

    let {
      data,
      validate,
      modalAttachment,
      modalPrintout,
      onSaveSuccess,
      onSaveNotSuccess,
      openModalOrganization,
      openModalPerson
    } = this.state

    let checklock =
      (currentProposal &&
        currentProposal.status !== 'ทำสัญญาโครงการ' &&
        role !== 'Admin') ||
      (currentProposal &&
        currentProposal.status === 'ทำสัญญาโครงการ' &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')

    APOptions =
      individuals_ap &&
      individuals_ap.map((item, idx) => ({
        key: idx,
        text:
          item.title === 'อื่นๆ'
            ? item.otherTitle + item.firstname + ' ' + item.lastname
            : item.title + item.firstname + ' ' + item.lastname,
        value: item._id
      }))

    data = { ...currentProposal, ...data }
    data.authorizePerson &&
      data.authorizePerson.forEach(item => {
        if (item.select && item.value) {
          let index = APOptions.findIndex(i => i.value === item.value)
          if (index === -1) {
            APOptions.push({
              key: APOptions.length,
              value: item.value,
              text: item.name
            })
          }
        }
      })

    return (
      <div style={{ paddingBottom: 100 }}>
        <HeaderContent title={data.nameTh} subtitle='ข้อมูลลงนามสัญญา' />
        <AttachedComponent
          currentProposal={currentProposal}
          namePage='attachPromise'
          modalAttachment={modalAttachment}
          onHandleUpload={this.handleUploaded}
          onHandleRemove={this.handleAttachedFileRemove}
          options={PromiseOptions}
          onClose={this.handleCloseModal}
          checkStatus={data && !data.promiseConfirm}
        />
        <PrintoutComponent
          modalPrintout={modalPrintout}
          numberOfFile={[
            'เอกสารสัญญา',
            'เอกสารแนบท้ายสัญญาการดำเนินงาน',
            'งวดงานงวดเงิน',
            'หนังสือขอเปิดบัญชีธนาคาร'
          ]}
          typeFile={[4, 5, 6, 7]}
          match={match}
          onClose={this.handleCloseModal}
        />
        <Form error={validate.error}>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              as='h5'
              content='ผู้มีอำนาจลงนามสั่งจ่าย (พิมพ์อย่างน้อย 3 ตัวอักษาเพื่อค้นหา)'
            />
            {data.authorizePerson &&
              data.authorizePerson.map((item, idx) => (
                <Fragment key={idx}>
                  <Form.Group>
                    {item.select === false && !checklock && (
                      <Form.Input
                        width={6}
                        onChange={this.handleAPEditRow.bind(this, idx)}
                        value={item.name}
                      />
                    )}
                    {item.select === false && !checklock && (
                      <Form.Button
                        content='ค้นหา'
                        name=''
                        onClick={this.handleSelectRowAP.bind(this, idx, true)}
                      />
                    )}
                    {item.select === true && !checklock && (
                      <Form.Select
                        search={true}
                        width={6}
                        options={APOptions}
                        onChange={this.handleAPchenge.bind(this, idx)}
                        onSearchChange={this.handleAPSearch.bind(this, idx)}
                        value={item.value}
                      />
                    )}
                    {item.select === true && !checklock && (
                      <Form.Button
                        content='ระบุเอง'
                        name=''
                        onClick={this.handleSelectRowAP.bind(this, idx, false)}
                      />
                    )}
                    {checklock && (
                      <Form.Input width={6} value={item.name} readOnly />
                    )}
                  </Form.Group>
                </Fragment>
              ))}
            <Form.Group>
              <Button
                content='เพิ่มบุคคล'
                icon='plus'
                color='green'
                onClick={this.handleComponentPerson}
              />
              <Button
                content='เพิ่มองค์กร'
                icon='plus'
                color='green'
                onClick={this.handleComponentOrganization}
              />
            </Form.Group>
          </Segment>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Button
            color='blue'
            content='บันทึก'
            icon='save'
            type='submit'
            disabled={checklock}
            loading={isSaving}
            onClick={this.handleConfirm}
          />
          {/* <Button
            className='button-back-bottom-right'
            type='button'
            color='blue'
            content='ย้อนกลับ'
            icon='step backward'
            disabled={validate.error}
            onClick={this.handleBackPage}
          />
          <Button
            className='button-bottom-right'
            type='button'
            color='blue'
            content='ถัดไป'
            icon='step forward'
            disabled={validate.error}
            onClick={this.handleNextPage}
          /> */}
        </Form>

        <Modal
          open={openModalOrganization}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon
        >
          <Modal.Content>
            <ComponentOrganization
              openModalOrganization={openModalOrganization}
              onCloseModalPerson={this.close}
            />
          </Modal.Content>
        </Modal>

        <Modal
          open={openModalPerson}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon
        >
          <Modal.Content>
            <ComponentPerson />
            {/* <ComponentOrganization openModalOrganization={openModalOrganization} onCloseModalPerson={this.close} /> */}
          </Modal.Content>
        </Modal>

        <Transition
          visible={onSaveSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle'></Icon>บันทึกสำเร็จ
            </Message.Header>
          </Message>
        </Transition>
        <Transition
          visible={onSaveNotSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message negative>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle'></Icon>จำนวนเงินรวมทั้งหมด
              มากกว่างบประมาณที่เสนอ
            </Message.Header>
          </Message>
        </Transition>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  isSaving: state.projects.isSaving,
  auth: state.auth,
  role: state.role,
  user: state.user.data,
  currentPerson: state.individuals.data,
  counter: state.counter,
  individuals: state.individuals.data,
  individuals_w: state.individuals_w.data,
  individuals_ap: state.individuals_ap.data,
  typeProject: state.typeproject.currentData,
  bankgroup: state.bankgroup.data
})

const mapDispatchToProps = dispatch => ({
  listProject: () => dispatch(torActions.fetchList()),
  listUser: () => dispatch(userActions.fetchList()),
  addProposal: data => dispatch(projectActions.save(data)),
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id)),
  getPerson: query => dispatch(individualsActions.fetchList({ query: query })),
  getPerson_w: query =>
    dispatch(individuals_wActions.fetchList({ query: query, limit: 30 })),
  getPerson_ap: query =>
    dispatch(individuals_apActions.fetchList({ query: query, limit: 30 })),
  genId: name => dispatch(generate(name)),
  getTypeProject: id => dispatch(typeProject.fetchOne(id)),
  getBankBranch: query =>
    dispatch(bankgroupActions.fetchList({ query: query, limit: 30 }))
})

export default connect(mapStateToProps, mapDispatchToProps)(PageSigner)

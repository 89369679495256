import { actions as proposalActions } from '../reducers/projects'

export const login = credential => {
  return { type: 'LOGIN', credential }
}

export const addTodo = data => {
  return { type: 'ADD_TODO', data }
}

export const changePage = page => {
  return { type: 'CHANGE_PAGE', page }
}

export const setProposalQuery = query => {
  return function (dispatch) {
    return dispatch(proposalActions.fetchList({ query: query }));
  }
}

export const addProject = name => {
  return { type: 'ADD_PROJECT', name }
}

export const addInvidual = name => {
  return { type: 'ADD_INDIVIDUAL', name }
}

export const changeSearch = search => {
  return { type: 'CHANGE_SEARCH', search }
}

export const sidebarState = isOpen => {
  return { type: 'SIDEBAR_STATE', isOpen }
}

export const changeRole = role => {
  return { type: 'CHANGE_ROLE', role }
}

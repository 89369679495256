import React, { Component, Fragment } from 'react'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { connect } from 'react-redux'
import { Table, Header, Button, Modal, Form, Dimmer, Loader } from 'semantic-ui-react'

class PageYearBudget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
      value: {}
    }
  }

  componentDidMount() {
    this.props.listYearBudget()
  }

  handleSelectRow = (selected) => {
    this.props.onSelected('year', selected.name, 'group')
    this.props.onSelected('_id', selected.id, 'group')
    localStorage.setItem("_id", selected.id);
    localStorage.setItem("state", "group");
  }

  mapArrayBudget = (data) => {
    let result = []
    if (data.length > 0) {
      result[0] = data[0]
      for (let i = 1; i < data.length; i++) {
        let check = false
        for (let j = 0; j < result.length; j++) {
          if (data[i].name === result[j].name) check = true
        }
        if (!check) result.push(data[i])
      }
    }
    return result
  }

  handleAddRow = () => {
    const { fundings } = this.props
    const liseYear = this.mapArrayBudget(fundings.map((item, idx) => ({
      name: parseInt(item.year),
      value: item
    })));
    const nextYear = (liseYear[liseYear.length - 1].name + 1).toString()
    this.setState({ modal: true, closeOnDimmerClick: false, nextYear })
  }

  handleConfirmModalAdd = () => {
    const { nextYear } = this.state
    let tmp = {}
    tmp['year'] = nextYear
    tmp['version'] = '1'
    tmp['list'] = []
    this.props.addDoc(tmp)
    this.setState({ modal: false, closeOnDimmerClick: true })
  }

  handleCloseModalAdd = () => {
    this.setState({ modal: false, closeOnDimmerClick: true })
  }

  handleChange = (row, e, { checked }) => {
    let data = row.value
    data.used = checked
    console.log(data)
    this.props.updateDoc(data, row.value._id)
  }

  render() {
    let { nextYear } = this.state
    const { fundings, yearLoading } = this.props
    const liseYear = this.mapArrayBudget(fundings.map((item, idx) => ({
      name: item.year,
      id: item._id,
      value: item
    })));

    return (
      <Fragment >
        <Dimmer active={yearLoading} inverted>
          <Loader disabled={!yearLoading} size='large'>โปรดรอสักครู่</Loader>
        </Dimmer>
        <Header content='ปีหมวดงบประมาณทั้งหมดของระบบ' />
        <Table selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content='เปิดใช้' textAlign='center' width={2} />
              <Table.HeaderCell content='ปีหมวดงบประมาณ' textAlign='center' />
              <Table.HeaderCell content='แก้ไขข้อมูลล่าสุด' textAlign='center' />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {liseYear && liseYear.map((row, idx) => (
              <Fragment key={idx}>
                <Table.Row  >
                  <Table.Cell textAlign='center' >
                    <Form.Checkbox toggle checked={row.value.used === true} onChange={this.handleChange.bind(this, row)} />
                  </Table.Cell>
                  <Table.Cell textAlign='center' content={'ปีงบประมาณ : ' + row.value.year} onClick={this.handleSelectRow.bind(this, row)} />
                  <Table.Cell textAlign='center' content='' onClick={this.handleSelectRow.bind(this, row)} />
                </Table.Row>
              </Fragment>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>
                <Button content='เพิ่มปีงบประมาณ' color='blue' onClick={this.handleAddRow} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Modal
          open={this.state.modal}
          onClose={this.handleCloseModalAdd}
          closeOnDimmerClick={this.state.closeOnDimmerClick}
          size='tiny'
          closeIcon>
          <Modal.Header content={'เพิ่มปีงบประมาณ : ' + nextYear} as='h3' />
          <Modal.Actions>
            <Button content='ยินยัน' color='blue' icon='check' onClick={this.handleConfirmModalAdd} />
            <Button content='ยกเลิก' color='red' icon='close' onClick={this.handleCloseModalAdd} />
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}

const mapState = state => ({
  fundings: state.fundings.data,
  yearLoading: state.fundings.listLoading,
})

const mapAction = dispatch => ({
  listYearBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  getDoc: (id) => dispatch(fundingsActions.fetchOne(id)),
  updateDoc: (data, id) => dispatch(fundingsActions.save(data, id, 'POST')),
  deleteDoc: (id) => dispatch(fundingsActions.delete(id)),
  addDoc: (data) => dispatch(fundingsActions.save(data)),
})

export default connect(mapState, mapAction)(PageYearBudget)

import React, { Component, Fragment } from 'react'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { connect } from 'react-redux'
import { Table, Header, Button, Modal, Form, Label, Transition, Icon, Message } from 'semantic-ui-react'

// const abc = [
//   {key:'0', text:'A',value:'A'},
//   {key:'1', text:'B',value:'B'},
//   {key:'2', text:'C',value:'C'},
//   {key:'3', text:'D',value:'D'},
//   {key:'4', text:'E',value:'E'},
//   {key:'5', text:'F',value:'F'},
//   {key:'6', text:'G',value:'G'},
//   {key:'7', text:'H',value:'H'},
//   {key:'8', text:'I',value:'I'},
//   {key:'9', text:'J',value:'J'},
//   {key:'10', text:'K',value:'K'},
//   {key:'11', text:'L',value:'L'},
//   {key:'12', text:'M',value:'M'},
//   {key:'13', text:'N',value:'N'},
//   {key:'14', text:'O',value:'O'},
//   {key:'15', text:'P',value:'P'},
//   {key:'16', text:'Q',value:'Q'},
//   {key:'17', text:'R',value:'R'},
//   {key:'18', text:'S',value:'S'},
//   {key:'19', text:'T',value:'T'},
//   {key:'20', text:'U',value:'U'},
//   {key:'21', text:'V',value:'V'},
//   {key:'22', text:'W',value:'W'},
//   {key:'23', text:'X',value:'X'},
//   {key:'24', text:'Y',value:'Y'},
//   {key:'25', text:'Z',value:'Z'},
// ]
class PageGroupBudget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: '',
      item: {},
      data: {},
      checkValue: { valueGroup: '', group: '' },
      obj: { valueGroup: '', group: '' },
      dataChange: false,
      onSaveSuccess: false,
      error: false
    }
  }

  componentDidMount() {
    const { obj } = this.props
    if (obj._id) {
      this.props.getDoc(obj._id)
    } else {
      const id = localStorage.getItem("_id");
      this.props.getDoc(id)
    }
  }

  componentWillReceiveProps(nextProps) {
    let { data } = this.state
    let tmp = { ...nextProps.fundings }
    if (nextProps.fundings) {
      this.setState({ data: { ...tmp, ...data } })
    }
  }

  handleSelected = (selected) => {
    const { dataChange } = this.state
    if (!dataChange) {
      this.props.onSelected('valueGroup', selected.name, 'plan')
      localStorage.setItem("valueGroup", selected.name);
    } else {
      this.setState({ item: selected })
      this.handleSelectedWithChangeOpen()
    }
  }

  handleSelectedBy = (item) => {
    this.props.onSelected('valueGroup', item.name, 'plan')
    localStorage.setItem("valueGroup", item.name);
  }

  handleSelectedWithChangeNext = () => {
    const { item } = this.state
    this.setState({ modalDataChange: false, closeOnDimmerDataChange: true, dataChange: false, })
    this.handleSelectedBy(item)
  }

  handleSelectedWithChangeOpen = () => {
    this.setState({ modalDataChange: true, closeOnDimmerDataChange: false })
  }

  handleSelectedWithChangeClose = () => {
    this.setState({ modalDataChange: false, closeOnDimmerDataChange: true })
  }

  handleChangeExplain = (e, { name, value }) => {
    let { data, checkValue } = this.state
    data.list.forEach(item => {
      if (item.valueGroup === name) {
        item.group = value
      }
    })
    checkValue.group = value
    this.setState({ data, checkValue, dataChange: true })
  }

  handleSelectRow = (item, selected) => {
    let { checkValue } = this.state
    checkValue.valueGroup = item.name
    checkValue.group = item.value
    if (selected === this.state.selected) selected = null
    this.setState({ checkValue, selected })
  }

  mapArrayBudget = (data) => {
    let result = []
    if (data.length > 0) {
      result[0] = data[0]
      for (let i = 1; i < data.length; i++) {
        let check = false
        for (let j = 0; j < result.length; j++) {
          if (data[i].name === result[j].name) check = true
        }
        if (!check) result.push(data[i])
      }
    }
    return result
  }

  handleAddRow = () => {
    let { obj } = this.state
    obj['group'] = 'รหัสแผน'
    this.setState({ modal: true, closeOnDimmerClick: false, obj })
  }

  handleConfirmModalAdd = () => {
    let { obj, data } = this.state
    const { fundings } = this.props
    if (fundings) {
      data = { ...fundings, ...data }
    }
    var regx = /^[A-Z]{1}[0-9]*$/
    const check = obj.valueGroup.match(regx)
    if (check !== null && obj.valueGroup.length === 3) {
      const groups = data && data.list ?
        this.mapArrayBudget(data.list
          .filter(item => item.valueGroup)
          .map((item, idx) => ({
            name: item.valueGroup,
            value: item.group,
          }))) : []
      const validate = groups.filter(item => item.name === obj.valueGroup)
      if (validate.length > 0) {
        this.setState({ error: true })
        setTimeout(() => {
          this.setState({ error: false });
        }, 3000)
      } else {
        const year = localStorage.getItem("year");
        let s = { 'year': year, 'valueGroup': obj.valueGroup, 'group': obj.group };
        data.list.push(s);
        this.setState({ modal: false, closeOnDimmerClick: true, dataChange: true })
      }
    } else {
      this.setState({ error: true })
      setTimeout(() => {
        this.setState({ error: false });
      }, 3000)
    }

  }

  handleCloseModalAdd = () => {
    this.setState({ modal: false, closeOnDimmerClick: true })
  }

  handleOpenModalSave = () => {
    let { data } = this.state
    const { fundings } = this.props
    if (fundings) {
      data = { ...fundings, ...data }
    }
    this.props.updateDoc(data, data._id);
    this.setState({ onSaveSuccess: true, dataChange: false })
    setTimeout(() => {
      this.setState({ onSaveSuccess: false });
    }, 3000)
  }

  handleChange = (e, { name, value }) => {
    let { obj } = this.state
    if (name === 'valueGroup') {
      if (value.length < 4) {
        obj[name] = value
      }
    } else obj[name] = value
    this.setState(obj)
  }

  render() {
    let { data, selected, checkValue, obj, dataChange, onSaveSuccess, error } = this.state
    const { fundings } = this.props
    if (fundings) {
      data = { ...fundings, ...data }
    }

    const groups = data && data.list ?
      this.mapArrayBudget(data.list
        .filter(item => item.valueGroup)
        .map((item, idx) => ({
          name: item.valueGroup,
          value: item.group,
        }))) : []

    return (
      <Fragment >
        <Header  >
          {'แผนหมวดงบประมาณของปี : ' + data.year + ' '}
        </Header>
        <Table selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content='รหัสแผน' textAlign='center' width={3} />
              <Table.HeaderCell content='คำอธิบาย' textAlign='center' width={13} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {groups.length > 0 && groups.map((row, idx) => (
              <Fragment key={idx}>
                <Table.Row onClick={this.handleSelectRow.bind(this, row, idx)} >
                  {(selected === idx && < Table.Cell >
                    <Label color="green" ribbon size='big' content={'รหัสแผน : ' + row.name} />
                  </Table.Cell>) || <Table.Cell textAlign='center' content={'รหัสแผน : ' + row.name} />}
                  <Table.Cell content={row.value} />
                </Table.Row>
                {selected === idx && (
                  <Table.Row active>
                    <Table.Cell colSpan='2'>
                      <Form>
                        <Form.Group>
                          <Form.Field width={2}>
                            <label>รหัสแผน</label>
                            <Header content={' ' + checkValue.valueGroup} as='h4' style={{ 'marginTop': '15px' }} />
                          </Form.Field>
                          <Form.Input label='คำอธิบาย' value={checkValue.group} width={14}
                            name={checkValue.valueGroup} onChange={this.handleChangeExplain} />
                        </Form.Group>
                        <Form.Group>
                          <Form.Field width={2} />
                          <Button content='เลือกแผน' color='blue' icon='step forward'
                            onClick={this.handleSelected.bind(this, row)} />
                        </Form.Group>
                      </Form>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Fragment>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>
                <Button content='เพิ่มแผน' color='blue' icon='plus' onClick={this.handleAddRow} /></Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Transition visible={onSaveSuccess} animation='horizontal flip' duration={500} >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='check circle'></Icon>บันทึกสำเร็จ</Message.Header>
          </Message>
        </Transition>
        <Button content='บันทึก' color='green' icon='save' disabled={!dataChange}
          onClick={this.handleOpenModalSave} />
        <Modal
          open={this.state.modal}
          onClose={this.handleCloseModalAdd}
          closeOnDimmerClick={this.state.closeOnDimmerClick}
          size='small'
          closeIcon>
          <Modal.Header content={'เพิ่มแผน ของปีงบประมาณ :' + data.year} as='h3' />
          <Modal.Content>
            <Form>
              <Form.Group>
                <Form.Input label='รหัสแผน 3 หลัก' width={4} value={obj.valueGroup} name='valueGroup' onChange={this.handleChange} />
                <Form.Input label='คำอธิบาย' width={12} value={obj.group} name='group' onChange={this.handleChange} />
              </Form.Group>
              <Transition visible={error} animation='horizontal flip' duration={500} >
                <Message negative>
                  <Message.Header style={{ fontFamily: 'supermarket' }}><Icon name='close'></Icon>รหัสแผน ผิดพลาด</Message.Header>
                </Message>
              </Transition>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button content='ยินยัน' color='blue' icon='check' onClick={this.handleConfirmModalAdd} />
            <Button content='ยกเลิก' color='red' icon='close' onClick={this.handleCloseModalAdd} />
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.modalDataChange}
          onClose={this.handleSelectedWithChangeClose}
          closeOnDimmerClick={this.state.closeOnDimmerDataChange}
          size='small'
          closeIcon>
          <Header content={' คำเตือน '} as='h2' icon='warning' />
          <Modal.Content content='ท่านได้เปลี่ยนคำอธิบายแผนงานแต่ยังไม่บันทึก ต้องการดำเนินการต่อหรือไม่ ?' as='h3' />
          <Modal.Actions>
            <Button content='ใช่' color='blue' icon='check' onClick={this.handleSelectedWithChangeNext} />
            <Button content='ไม่' color='red' icon='close' onClick={this.handleSelectedWithChangeClose} />
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}

const mapState = state => ({
  fundings: state.fundings.currentData,
})

const mapAction = dispatch => ({
  updateDoc: (data, id) => dispatch(fundingsActions.save(data, id, 'POST')),
  getDoc: (id) => dispatch(fundingsActions.fetchOne(id)),
})

export default connect(mapState, mapAction)(PageGroupBudget)

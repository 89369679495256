const initialState = {    
    data:null        
}
  
export function changeManager(data) {
    return {
        type:'CHANGE_MANAGER',
        data:data        
    }
}
    
export const managerReducers = (state = initialState, action) => {
    switch (action.type) {
      case 'CHANGE_MANAGER':
        return {
          ...state,
          data:action.data          
        }      
      default:
        return state
    }
}
  
  
  
  
  
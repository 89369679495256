const keyMap = {
  proposal: {
    'targetsCount': 'จำนวนกลุ่มเป้าหมาย *',
    'targetsUnit': 'หน่วยกลุ่มเป้าหมาย *',
    'targetExplan': 'ข้อความกลุ่มเป้าหมาย *',
    'tor_id': 'TOR ของโครงการนี้',
    'sameNameTor': 'ใช้ชื่อเดียวกับ TOR',
    'nameTh': 'ชื่อโครงการ *',
    'projectNo': 'เลขที่โครงการ',
    'projectType': 'ประเภทโครงการ',
    'askBudget': 'งบประมาณ *',
    'otherBudget': 'งบประมาณจากแหล่งอื่น',
    'otherBudgetDropdown': 'การสมทบจากแหล่งอื่น',
    'yearBudget': 'ปีงบประมาณ',
    'activity': 'กลุ่มกิจกรรม',
    'recipientType': 'ประเภทผู้รับทุน *',
    'managerName': 'ผู้รับผิดชอบโครงการ',
    'manager': 'ผู้รับผิดชอบโครงการ * (พิมพ์อย่างน้อย 3 ตัวอักษรเพิ่อค้นหารายชื่อ *ไม่ต้องใส่คำนำหน้า ใส่ชื่อจริงหรือนามสกุล อย่างใดอย่างหนึ่งเท่านั้น* )',
    'recipientPerson': 'ผู้รับทุน * (พิมพ์อย่างน้อย 3 ตัวอักษรเพิ่อค้นหารายชื่อ *ไม่ต้องใส่คำนำหน้า ใส่ชื่อจริงหรือนามสกุล อย่างใดอย่างหนึ่งเท่านั้น* )',
    'contractType': 'รูปแบบสัญญา',
    'typeBudget': 'หมวดงบประมาณ',
    'groupsPlan': 'กลุ่มงาน',
    'valueActivity': 'กลุ่มกิจกรรม *',
    'valueGroup': 'กลุ่มแผนงาน *',
    'valuePlan': 'แผนงาน *',
    'focusPoint': 'จุดเน้น',
    'strategy': 'ยุทธศาสตร์',
    'recipientOrganize': 'องค์กรผู้รับทุน * (พิมพ์อย่างน้อย 3 ตัวอักษรเพิ่อค้นหารายชื่อ)',
    'recipientOrganizeName': 'องค์กรผู้รับทุน',
    'PJManager': 'ผูรับผิดชอบโครงการ',
    'proposerTitle': 'คำนำหน้า',
    'proposerTitleOther': 'คำนำหน้า',
    'proposerFName': 'ผู้รับผิดชอบโครงการ',
    'proposerLName': 'นามสกุล',
    'proposerCid': 'เลขบัตรประจำตัวประชาชน',
    'proposerRank': 'ตำแหน่ง',
    'proposerEmail': 'อีเมลล์',
    'proposerHomePhoneNumber': 'เบอร์โทรศัพท์',
    'proposerPhoneNumber': 'เบอร์มือถือ',
    'proposerAddress': 'ที่อยู่',
    'proposerFax': 'Fax',
    'purpose': 'เป้าประสงค์ *',
    'goal': 'เป้าหมาย',
    'objective': 'วัตถุประสงค์',
    'evaluation': 'การประเมินผล *',
    'result': 'ผลลัพธ์ข้อกำหนด',
    'projectArea': 'พื้นที่ดำเนินการ *',
    'projectGroupArea': 'พื้นที่ดำเนินการ *',
    'withDrawTime': 'งวดงานงวดเงินดำเนินโครงการ',
    'period': 'ระยะเวลาโครงการ',
    'isTimeFollowProposal': 'เวลาดำเนินงานเป็นไปตามข้อเสนอ *',
    'isCompleteInTime': 'ระยะเวลาโครงการจริง',
    'startDate': 'วันเริ่มต้นโครงการ',
    'endDate': 'วันสิ้นสุดโครงการ',
    'extendStartDate': 'การขยายเวลาตั้งแต่วันที่',
    'extendEndDate': 'ถึงวันที่',
    'isSpendAskBudgetFollowProposal': 'การใช้งบประมาณ (เฉพาะที่ได้รับการสนับสนุนจาก กสศ.) *',
    'isSendAskBudgetBack': 'การใช้งบประมาณ',
    'askBudgetDiff': 'เป็นเงิน (บาท)',
    'askBudgetFrom': 'เป็นงบของ',
    'isSpendOtherBudgetFollowProposal': 'การใช้งบประมาณ (แหล่งทุนอื่น) *',
    'isSendOtherBudgetBack': 'การใช้งบประมาณ',
    'otherudgetDiff': 'เป็นเงิน (บาท)',
    'otherBudgetFrom': 'เป็นงบของ',
    'resultOpinion': 'ความคิดเห็น',
    'resultOutsideProposal': 'ผลผลิต ผลลัพธ์ที่เกิดขึ้นแต่ไม่ได้กำหนดไว้ในข้อเสนอโครงการ (ถ้ามี)',
    'resultArea1': 'พื้นที่ดำเนินงานที่ 1',
    'resultArea2': 'พื้นที่ดำเนินงานที่ 2',
    'screen': 'ผู้กลั่นกรอง',
    'noScreen': 'โครงการนี้ไม่ต้องกลั่นกรอง',
    'resultGroup1Activity': 'กิจกรรมสำคัญ',
    'resultGroup1Member': 'จำนวน (คน)',
    'resultGroup1StartDate': 'ตั้งแต่วันที่',
    'resultGroup1EndDate': 'ถึงวันที่',
    'resultGroup2Activity': 'กิจกรรมสำคัญ',
    'resultGroup2Member': 'จำนวน (คน)',
    'resultGroup2StartDate': 'ตั้งแต่วันที่',
    'resultGroup2EndDate': 'ถึงวันที่',
    'codeDisburse': 'เลขที่เบิกจ่าย *',
    'promiseConfirm': 'ยืนยันการทำสัญญา',
    'budget': 'งบประมาณที่ได้รับ',
    'installmentCount': 'เบิกไปแล้ว(งวด) *',
    'totalSpend': 'เป็นจำนวนเงินทั้งสิ้น *',
    'extendTime': 'การขยายเวลา(ถ้ามี)',
    'endingCause': 'สาเหตุของการยุติโครงการ',
    'spending': 'การใช้จ่ายเงิน *',
    'quitProjectAttachmentType': 'ประเภทไฟล์แนบ',
    'quitProjectApprovement': 'เสนอผู้จัดการกสศ เพื่ออนุมัติยุติโครงการ *',
    'quitProjectDescription': 'เงื่อนไข / ไม่อนุมัติเนื่องจาก',
    'endFormConclusion': 'สรุปผลงาน',
    'endFormApprove': 'อนุมัติปิดโครงการ',
    'endFormNote': 'เนื่องจาก (กรณีไม่อนุมัติปิดโครงการ)',
    'descriptionWithdraw': 'การใช้เงิน',
    'comment': 'ความเห็นของผู้ประสานงาน ',
    'submitTime': 'วันกำหนดส่งผลงาน',
    'endFormApprover': 'ผู้อนุมัติปิดโครงการ',
    'approveBudget': 'งบประมาณ',
    'approveBudgetOther': 'งบประมาณจากแหล่งอื่น',
    'approveAlreadyWithdraw': 'เบิกเงินครั้งก่อน(งวด)',
    'approveAlreadyWithdrawAmount': 'จำนวน (บาท)',
    'approveRateProgress': 'ประเมินความก้าวหน้าของโครงการ *',
    'approveRateProgressDesc': 'คำอธิบายเพิ่มเติม (ความก้าวหน้า)',
    'approveResult': 'ผลผลิต/ผลลัพธ์ของโครงการ',
    'approveProblem': 'ปัญหาอุปสรรค *',
    'approveProblemDesc': 'คำอธิบายเพิ่มเติม (ปัญหาอุปสรรค)',
    'approveManage': 'การจัดการให้โครงการบรรลุผล(เพื่อป้องกันความล่าช้า)*',
    'approveManageDesc': 'คำอธิบายเพิ่มเติม (แนวทางการจัดการความล่าช้าของโครงการ)',
    'approveMoneyProblem': 'ปัญหาด้านการเงิน *',
    'approveMoneyProblemDesc': 'คำอธิบายเพิ่มเติม (ปัญหาการเงิน)',
    'approveRateInterest': 'ประเมินความน่าสนใจ',
    'approveRateInterestDesc': 'คำอธิบายเพิ่มเติม (ความน่าสนใจ)',
    'approveUseMoney': 'การใช้จ่ายเงินงวดนี้ *',
    'approveUseMoneyAmount': 'จำนวนเงินการใช้จ่ายเงินงวดนี้ (บาท) *',
    'approveWithdraw': 'การเบิกเงินตามงวด *',
    'approveWithdrawAmount': 'จำนวนเงินการเบิกเงินตามงวด (บาท) *',
    'approveDeadline': 'การเบิกจ่ายตามงวด *',
    'approveDeadlineDesc': 'ความคิดเห็น (การเบิกจ่ายเงิน)',
    'approveDate': 'วันที่',
    'approveDesc': 'คำอธิบายเพิ่มเติม',
    'approveCommentSsk': 'ความคิดเห็นผู้ประสานงาน  กสศ.',
    'approveReportProgress': 'รายงานความก้าวหน้าพร้อมเอกสาร',
    'approveReportMoney': 'รายงานการเงินโครงการ',
    'approveCdData': 'cd ข้อมูล',
    'approveReportOther': 'อื่นๆ',
    'approveReportProgressAmount': 'รายงานความก้าวหน้า จำนวน',
    'approveCdDataAmout': 'cd ข้อมูล จำนวน',
    'approveReportOtherSpecify': 'อื่นๆ ระบุ',
    'withdrawDateOfReceipt': 'วันที่รับผลงาน',
    'withdrawOfProjectPeriod': 'ขอเบิกเงินโครงการงวดที่',
    'withdrawPeriodAmount': 'จำนวนเงินงวดปัจจุบัน',
    'withdrawAlready': 'เบิกมาแล้ว',
    'withdrawBalance': 'คงเหลือ',
    'withdrawMoneyLastPeriod': 'การใช้เงินจากงวดที่แล้ว',
    'withdrawMoneyLastPeriodAmount': 'จำนวนเงินจากงวดที่แล้ว',
    'withdrawCostOfOperation': 'ค่าดำเนินการ *',
    'withdrawServiceFee': 'ค่าธรรมเนียมบริหาร',
    'withdrawCompensationManager': 'ค่าตอบแทนผู้จัดการโครงการ *',
    'withdrawCompensationProject': 'ค่าตอบแทนผู้ร่วมโครงการ',
    'withdrawAmount': 'จำนวนเงินที่เบิก',
    'withdrawPayChequeBy': 'จ่ายเช็คในนาม',
    'withdrawBank': 'รายชื่อธนาคาร *',
    'withdrawBankBranch': 'สาขา *',
    'withdrawAccountNumber': 'เลขบัญชี *',
    'withdrawTaxDeduction': 'รายละเอียดการหักภาษี',
    'withdrawPayment': 'วิธีจ่ายเงิน *',
    'moneyConfirm': 'งบประมาณที่อนุมัติ',
    'typeApprove': 'ประเภทสัญญา/ สัญญา',
    'typePay': 'ระบุประเภทการจ่ายค่าดำเนินการ *',
    'sentFinanceDocTo': 'เอกสารการเงินส่งไปที่ *',
    'sentdocApproveTo': 'สัญญาและเอกสารต่างๆ ส่งไปที่',
    'sendSignedContract': 'การจัดส่งสัญญาให้คู่สัญญาลงนาม *',
    'typeScreen': 'ประเภทการกลั่นกรอง',
    'dateContract': 'วันที่ทำสัญญา',
    'dateScreen': 'วันที่เอกสาร',
    'noAgreement': 'เลขที่สัญญา',
    'codeProject': 'รหัสโครงการ',
    'typeProject': 'ประเภทโครงการ *',
    'witness1': 'พยาน * (พิมพ์อย่างน้อย 3 ตัวอักษรเพื่อค้นหาชื่อ *ไม่ต้องใส่คำนำหน้า ใส่ชื่อจริงหรือนามสกุล อย่างใดอย่างหนึ่งเท่านั้น*)',
    'witness2': 'ผู้รับผิดชอบโครงการ/พยาน * (พิมพ์อย่างน้อย 3 ตัวอักษรเพื่อค้นหาชื่อ *ไม่ต้องใส่คำนำหน้า ใส่ชื่อจริงหรือนามสกุล อย่างใดอย่างหนึ่งเท่านั้น*)',
    'contractForAdmin': 'ชื่อคนทำสัญญา(สำหรับ Admin แก้ไข ถ้าไม่ใช่แอดมินจะเก็บชื่อคนทำสัญญาอัตโนมัติ)',
    'witness': 'พยาน ',
    'witnessOptions1': 'เลือกประเภทพยาน1 ',
    'witnessOptions2': 'เลือกประเภทพยาน2 ',
    'witnessKSS': 'พยาน กสศ',
    'progressWithdrawProblem': 'ปัญหาและอุปสรรคในการดำเนินงานโครงการ(สรุปเป็นข้อๆ)',
    'progressWithdrawFixProblem': 'ข้อเสนอแนะและแนวทางการแก้ไขปัญหาอุปสรรคข้างต้น',
    'progressWithdrawPlanOptions': 'แผนงาน',
    'progressWithdrawVisitOptions': 'โครงการเป็นแหล่งดูงานต้นแบบ',
    'progressWithdrawDeliverResultAmount': '1.ผลงาน/ชิ้นงานที่เกิดจากการดำเนินงานจำนวน',
    'progressWithdrawDeliverPicResAmount': '2.ภาพถ่ายผลงานและกิจกรรมจำนวน',
    'progressWithdrawDeliverPicResComm': '3.ภาพถ่ายสื่อรณรงค์ ประชาสัมพันธ์จำนวน',
    'screensuggestion': 'ความคิดเห็นและข้อเสนอแนะอื่นๆ',
    'screenFeedback': 'สรุปความคิดเห็นจากผู้ทรงคุณวุฒิ *',
    'screenConfirm': 'ยืนยันการกลั่นกรอง',
    'takeTime': 'จำนวนงวดเงินที่เบิกไปแล้ว *',
    'Takemoney': 'เบิกจ่าย *',
    'interest': 'ดอกเบี้ย *',
    'refund': 'เงินคืน / เบิกเพิ่ม *',
    'ActualUsemoney': 'รวมใช้จ่ายจริง *',
    'funding': ' ',
    'funding1': ' ',
    'funding2': ' ',
    'funding3': ' ',
    'funding4': ' ',
    'targetGroup': 'กลุ่มเป้าหมายของโครงการ',
    'product': 'ผลผลิตข้อกำหนด *',
    'withDrawTimeContent': 'ผลงานที่นำส่งกสศ. และเงื่อนไข *',
    'withDrawTimeStartDate': 'วันเริ่มในงวดงาน *',
    'withDrawTimeEndDate': 'วันสิ้นสุดในงวดงาน *',
    'quitProjectDate': 'วันยุติโครงการ',
    'countPerson': 'จำนวนในกลุ่มเป้าหมาย *',
    'withDrawTimeAgreement': 'งวดงานงวดเงินดำเนินโครงการ *',
    'mainCom': 'ค่าตอบแทนบุคลากรหลัก *',
    'manage': 'ค่าดำเนินงาน *',
    'withdrawtimeStartDate': 'วันเริ่มในงวดงาน *',
    'withdrawtimeEndDate': 'วันสิ้นสุดในงวดงาน *',
    'withdrawcontent': 'ผลงานที่นำส่งและเงื่อนไข *',
    'approveReportNoReportProgress': 'จำนวน(ชุด) ',
    'approveReportNoCD': 'จำนวน(ชุด) ',
    'reasonExtend': 'เหตุผลการขอขยายเวลา *',
    'attachmentExtend': 'เอกสารการขอขยายเวลาของผู้รับทุน',
    'otherattachmentExtend': 'อื่นๆ',
    'otherattachmentExtendText': ' ',
    'commentExtend': 'ความเห็นนักวิชาการ *',
    'startDateExtend': 'เวลาเริ่มต้น *',
    'endDateExtend': 'เวลาสิ้นสุด *',
    'typeOffice': 'สำนักงาน *',
    'sendWorkTime': 'วันที่รับผลงาน *',
    'copyright': 'เป็นงานอันมีลิขสิทธิ์ ให้ *',
    'withdrawAccountName': 'ชื่อบัญชี *',
    'attach': 'ไฟล์แนบ',
    'profesAmount': 'จำนวนผู้ทรงคุณวุฒิ',
    'seriesProject': 'รายชื่อชุดโครงการ *',
    'numberOfGroupBudget': 'กลุ่มงบประมาณที่ใช้',
    'withdrawBankID': 'รายชื่อธนาคาร *',
    'withdrawBankBranchID': 'สาขา * (พิมพ์ค้นหาอย่างน้อย 3 ตัวอักษร)',
    'sendtoERF':'ได้รับเอกสารพร้อมลายเซ็น การผูกพันธ์สัญญาแล้ว',
    'seriesConnect' : 'โครงการทุนต่อเนื่องปีที่ 1',
    'seqConnect' : 'ปีที่',
    'endDate_connect': 'วันที่เริ่มต้น (โครงการทุนต่อเนื่อง)',
    'startDate_connect': 'วันที่สิ้นสุด (โครงการทุนต่อเนื่อง)',
    'askBudget_connect': 'งบประมาณ (โครงการทุนต่อเนื่อง)',
  },
  tor: {
    'startDate': 'วันที่เริ่มต้น *',
    'nameTh': 'ชื่อข้อกำหนด (ไทย) ไม่ต้องเติมคำว่า "โครงการ" *',
    'nameEn': 'ชื่อข้อกำหนด (EN) *',
    'noBook': 'เลขที่หนังสือ *',
    'torCode': 'เลขที่ข้อกำหนด *',
    'objective': 'วัตถุประสงค์ *',
    'funding': ' ',
    'product': 'ผลผลิตข้อกำหนด *',
    'result': 'ผลลัพธ์ข้อกำหนด *',
    'timeProcess': 'ระยะเวลาดำเนินการ *',
    'budgetLimit': 'วงเงินงบประมาณ *',
    'academic': 'ผู้ประสานงาน *',
    'status': 'สถานะ',
    'target': 'เป้าหมาย',
    'targets': 'กลุ่มเป้าหมาย *',
    'targetGroup': 'กลุ่มเป้าหมายของโครงการ',
    'scope': 'ขอบเขต *',
    'targetsCount': 'จำนวนในกลุ่มเป้าหมาย *',
    'targetsUnit': 'หน่วยจำนวนในกลุ่มเป้าหมาย *',
    'targetExplan': 'ข้อความในกลุ่มเป้าหมาย *'
  },
  individual: {
    'organization': 'องค์กร (พิมพ์อย่างน้อย 3 ตัว เพื่อค้นหารายชื่อ)',
    'title': 'คำนำหน้าชื่อ',
    'firstname': 'ชื่อ',
    'lastname': 'นามสกุล',
    'cid': 'เลขบัตรประชาชน',
    'rank': 'ตำแหน่งในองค์กร',
    'role': 'บทบาทที่เกี่ยวข้องกับ กสศ',
    'addressNo': 'ที่อยู่',
    'address': 'ตำบล - อำเภอ - จังหวัด - รหัสไปรษณีย์',
    'email': 'E-mail',
    'phoneNumber': 'โทรศัพท์มือถือ',
    'homePhoneNumber': 'โทรศัพท์',
    'fax': 'โทรสาร',
    'otherTitle': 'ระบุคำนำหน้าชื่อ',
    'haveOrganization': 'มีองค์กรอยู่ในระบบ',
    'fullname': 'ชื่อ - นามสกุล'
  },
  organization: {
    'organizationName': 'ชื่อหน่วยงาน',
    'organizationCode': 'รหัสองค์กร',
    'organizationAdressNo': 'ที่อยู่',
    'organizationAdress': 'ตำบล - อำเภอ - จังหวัด - รหัสไปรษณีย์',
    'organizationEmail': 'E-mail',
    'organizationPhone': 'มือถือ',
    'organizationTel': 'โทรศัพท์',
    'organizationFax': 'โทรสาร',
    'organizationBossTitle': 'คำนำหน้า',
    'organizationBossOtherTitle': 'ระบุคำนำหน้าชื่อ',
    'organizationBossName': 'ชื่อ',
    'organizationBossLastname': 'นามสกุล',
    'organizationBossid': 'เลขบัตรประชาชน',
    'organizationBossAdressNo': 'ที่อยู่',
    'organizationBossAdress': 'ตำบล - อำเภอ - จังหวัด - รหัสไปรษณีย์',
    'organizationBossEmail': 'E-mail',
    'organizationBossPhone': 'มือถือ',
    'organizationBossTel': 'โทรศัพท์',
    'organizationBossFax': 'โทรสาร',
    'governmentAgencies':'ประเภทหน่วยงานภาครัฐ/นอกภาครัฐ',
    'legalEntity':'ประเภทเป็นนิติบุคคล/ไม่เป็นนิติบุคคล',
    'cid': 'เลขประจำตัวผู้เสียภาษีอากร'
  },
  sendEmail: {
    'from': 'จาก',
    'to': 'ส่งถึง',
    'subject': 'หัวข้อ',
    'text': 'เนื้อหา',
    'typeSend': 'ส่งแบบ',
    'dateSend': 'เวลา',
    'typeDate': 'รูปแบบเวลา',
  },
  AddGroupFinance: {
    'funding': ' ',
  },
  Borrow: {
    'budget': ' ',
    'burnProject': 'โครงการเผาหัว * (พิมพิ์ 3 ตัวอักษรเพื่อค้นหาโครงการ)',
    'yearBudget': 'ปีงบประมาณ *',
    'valueActivity': 'กลุ่มกิจกรรม *',
    'valueGroup': 'กลุ่มแผนงาน *',
    'valuePlan': 'แผนงาน *',
    'nameAccount': 'ชื่อบัญชี *',
    'bank': 'ธนาคาร *',
    'branch': 'สาขา *',
    'noAccount': 'เลขที่บัญชี *',
    'namePayer': 'ผู้จ่ายเงิน *',
    'typeRecive': 'วิธีจ่ายเงิน *',
    'codeDisburse': 'เลขที่เบิกจ่าย *',
    'place': 'สถานที่ *',
    'startDate': 'วันที่เริ่มต้น *',
    'endDate': 'วันที่สิ้นสุด *',
    'startTime': 'เวลาเริ่มต้น *',
    'endTime': 'เวลาสิ้นสุด *',
    'usingDate': 'เริ่มใช้วันที่ *',
    'transferMoney': 'โอนเงินเข้าบัญชี *',
    'remuneration': 'ค่าตอบแทน *',
    'document': 'จัดส่งเอกสาร *',
    'vehicle': 'พาหนะ *',
    'apartment': 'ที่พัก *',
    'food': 'อาหารและเครื่องดื่ม *',
    'other': 'ค่าใช้จ่ายอื่นๆ *',
    'listBorrow': 'รายละเอียดการยืมเงินทดรองจ่าย',
    'objective': 'วัตถุประสงค์ขอยืมเงิน *',
    'typeOfBorrow': 'ประเภทวัตถุประสงค์*',
    'conclude': 'รายการสรุปค่าใช้จ่าย *',
    'remunerationT': 'ค่าตอบแทน *',
    'documentT': 'จัดส่งเอกสาร *',
    'vehicleT': 'พาหนะ *',
    'apartmentT': 'ที่พัก *',
    'foodT': 'อาหารและเครื่องดื่ม *',
    'otherT': 'ค่าใช้จ่ายอื่นๆ *',
    'noBorrowMoney': 'รายละเอียดการยืมเงินทดรองจ่าย',
    'haveBurnPJ': 'มีโครงการเผาหัว *',
    'refundDate': 'วันที่คืนเงิน *',
    'borrowDate': 'วันที่ยืมเงิน *',
    'academicID': 'รายชื่อบุคลากรในสำนักเดียวกัน *',
    'sendtoERP': 'ส่งข้อมูลไปยังระบบบัญชี *',
    

  },
  refund: {
    'remuneration': 'ค่าตอบแทน',
    'document': 'จัดส่งเอกสาร',
    'vehicle': 'พาหนะ',
    'apartment': 'ที่พัก',
    'food': 'อาหารและเครื่องดื่ม',
    'other': 'ค่าใช้จ่ายอื่นๆ',
  },
  roles: {
    "Academic": "นักวิชาการ",
    "Contract": "ศูนย์สัญญา",
    "Admin": "ผู้ดูแลระบบ",
    "Staff": "เจ้าหน้าที่",
    "Law": "ฝ่ายกฎหมาย",
    "Co-Academic": "ผู้ช่วยนักวิชาการ",
    "auditor": "ผู้ตรวจสอบบัญชี"
  },
  manageUser: {
    "Account": "ข้อมูลส่วนตัว",
    "Settings": "เปลี่ยนรหัสผ่าน",
    "Signout": "ออกจากระบบ",
  },
  changePwd: {
    "oldPwd": "รหัสผ่านเก่า",
    "Pwd": "รหัสผ่านใหม่",
    "pwd": "รหัสผ่านเก่า",
    "confirmPwd": "ยืนยันรหัสผ่านใหม่",
  },
  register: {
    "user": "USERNAME",
    "password": "PASSWORD",
    "title": "คำนำหน้า",
    "titleOther": "คำนำหน้าอื่นๆ",
    "firstname": "ชื่อ",
    "lastname": "นามสกุล",
    "firstnameEN": "ชื่อ(EN)",
    "lastnameEN": "นามสกุล(EN)",
    "tel": "เบอร์โทรศัพท์",
    "email": "E-MAIL",
    "roles": "บทบาทในระบบ",
    "workGroup": "ตำแหน่ง",
    "address": "ที่อยู่",
    "typeInstitution": "สังกัด",
    "addressCity": "ตำบล - อำเภอ - จังหวัด - รหัสไปรษณีย์",
    "fax": "โทรสาร",
    'cid': 'รหัสประจำตัวประชาชน'
  },
  bill: {
    "firstname": "ชื่อ",
    "lastname": "นามสกุล",
    "cid": "เลขประจำตัวประชาชน",
    "billAddressNo": 'ที่อยู่เลขที่',
    "billAddress": 'ที่อยู่',
    "projectName": 'ได้รับเงินจากโครงการ',
    "listBill": "รายการ",
    "title": 'คำนำหน้า',
    "titleOther": 'คำนำหน้าอื่นๆ',
    "billMoney": "จำนวนเงิน"
  },
  typeOffice: {
    "01": 'สำนักบริหารกลาง',
    "02": 'สำนักพัฒนานโยบาย กลยุทธ์ และงบประมาณ',
    "03": 'สำนักบริหารเงินอุดหนุนผู้ขาดแคลนทุนทรัพย์',
    "04": "สำนักพัฒนา นวัตกรรมการเรียนรู้เชิงพื้นที่",
    "05": 'สำนักพัฒนาคุณภาพครู นักเรียนครูและสถานศึกษา',
    "06": 'สถาบันวิจัยพัฒนาองค์ความรู้แบะเทคโนโลยีสารสนเทศเพื่อความเสมอภาคทางการศึกษา',
    "07": "สำนักส่งเสริมการมีส่วนร่มนวัตกรรมและทุนการศึกษา"
  },
  burnApprove: {
    'nameTh': 'ชื่อโครงการ (ไม่ต้องเติมคำว่า "โครงการ") *',
    "plan": "แผน",
    // "burnManager":"ผู้รับผิดชอบโครงการ",
    "burnScreen": 'ผู้กลั่นกรอง',
    "burnEditPJ": 'การปรับข้อเสนอโครงการ (ถ้ามี)',
    "burnDate": 'ระยะเวลาโครงการ',
    "burnHaveAttach": "เอกสารการพิจารณากลั่นกรองโครงการ",
    "academic": 'นักวิชาการ',
    "inspector": 'ผู้ตรวจสอบ',
    'activity': 'กลุ่มกิจกรรม',
    'academicID': 'ผู้รับผิดชอบโครงการ',
    'checkProjectDate': 'วันที่ทบทวนส่งโครงการ'
  },
  burnInfo: {
    "nameTh": 'ชื่อโครงการ (ไม่ต้องเติมคำว่า "โครงการ") *',
    "typeOffice": "สำนักงาน *",
    "startDate": "วันที่เริ่มต้น *",
    "endDate": "วันที่สิ้นสุด *",
    "academicID": "ผู้รับผิดชอบโครงการ *",
    "funding": ' ',
    "principle": 'หลักการและเหตุผล *',
    "operationTime": 'ระยะเวลาดำเนินการ *',
    "budget": "งบประมาณ *",
    'objective': 'วัตถุประสงค์ *',
    'targetGroup': 'กลุ่มเป้าหมาย *',
    'targetsCount': 'จำนวนกลุ่มเป้าหมาย *',
    'targetsUnit': 'หน่วยกลุ่มเป้าหมาย *',
    'operationPlan': 'แผนการดำเนินงาน *',
    'result': 'ผลที่คาดว่าจะได้รับ *',
    'screenProfessional': 'ผู้ทรงคุณวุฒิ *',
    'screenresult': 'ผลการกลั่นกรอง *',
    'budgetDetail': 'งบประมาณโครงการ *',
    'budgetContent': 'รายละเอียดงบประมาณโครงการ *',
    'inspector ': 'ผู้ตรวจสอบ *',
    'groupsPlan': 'กลุ่มงาน',
    'plan': 'แผนงาน',
    'activity': 'กลุ่มกิจกรรม',
    'valueActivity': 'กลุ่มกิจกรรม *',
    'valueGroup': 'กลุ่มแผนงาน *',
    'valuePlan': 'แผนงาน *',
    'profesAmount': 'จำนวนผู้ทรงคุณวุฒิ'
  },
  profes: {
    "title": 'คำนำหน้า',
    "titleOther": "คำนำหน้าอื่นๆ",
    "firstname": "ชื่อ",
    "lastname": "นามสกุล",
    "rank": "ตำแหน่ง",
    "specialization": 'ความชำนาญ',
    "organization": 'องค์กร',
    "type": 'ประเภท',
    "year": 'ปี',
    "addressNo" : "ที่อยู่",
    "cid": "เลขบัตรประจำคัวประชาชน",    
  },
}

export default function (context, name) {
  if (keyMap[context]) {
    return keyMap[context][name] ? keyMap[context][name] : name
  }
  return name;
}
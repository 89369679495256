import React, { Component } from 'react'
import './Search.css'
import jwt from 'jsonwebtoken'
import {
  Button,
  Input,
  Dropdown,
  Menu,
  Popup,
  Modal,
  Icon,
  Form,
  Label
} from 'semantic-ui-react'
import { ComponentAddTOR } from 'Containers/TOR'
import { connect } from 'react-redux'
import { changeSearch } from 'Redux/actions'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as shareuserActions } from 'Redux/reducers/shareuser'
import { DatePicker as DayPicker, UTC } from 'Utils'
import { CloneTOR, ListTORDelete } from 'Utils'

const sortTor = [
  { key: '01', value: 'nameTh', text: 'ชื่อข้อกำหนด' },
  { key: '02', value: 'torCode', text: 'เลขที่ข้อกำหนด' },
  { key: '03', value: 'academicName', text: 'ผู้ประสานงาน' },
  { key: '04', value: 'startDate', text: 'วันเริ่มต้นข้อกำหนด' }
]

const sortProposal = [
  { key: '01', value: 'nameTh', text: 'ชื่อโครงการ' },
  { key: '02', value: 'codeProject', text: 'เลขที่โครงการ' },
  { key: '03', value: 'managerName', text: 'ผู้รับผิดชอบโครงการ' },
  { key: '04', value: 'startDate', text: 'วันเริ่มต้นโครงการ' }
]

const filterTor = [
  { key: '00', value: 'ทั้งหมด', text: 'ทั้งหมด' },
  { key: '01', value: 'อนุมัติแล้ว', text: 'อนุมัติแล้ว' },
  { key: '02', value: 'ยังไม่อนุมัติ', text: 'ยังไม่อนุมัติ' }
]

const filterProposal = [
  { key: '00', value: 'ทั้งหมด', text: 'ทั้งหมด' },
  { key: '01', value: 'ส่งข้อเสนอโครงการ', text: 'ส่งข้อเสนอโครงการ' },
  { key: '02', value: 'กลั่นกรอง', text: 'กลั่นกรอง' },
  { key: '03', value: 'ทำสัญญาโครงการ', text: 'ทำสัญญาโครงการ' },
  {
    key: '04',
    value: 'ประเมินความก้าวหน้าโครงการ',
    text: 'ประเมินความก้าวหน้าโครงการ'
  },
  { key: '05', value: 'ปิดโครงการ', text: 'ปิดโครงการ' },
  { key: '06', value: 'ยุติโครงการ', text: 'ยุติโครงการ' }
]
class Search extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalTorOpen: false,
      inputText: '',
      filter: {},
      isModalOpen: false,
      activeAdvance: false,
      openAddSuccess: false
    }
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  handleOpenModal = () =>
    this.setState({ modalTorOpen: true, modalDimmer: false })
  handleCancleCloneTOR = () => {
    let { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    this.setState({
      modalTorOpen: false,
      modalDimmer: true,
      modalCopyTorOpen: false,
      openAddSuccess: false,
      modalDeleteTorOpen: false
    })
    if (role === 'Academic') {
      let queryTor = {
        sort: { nameTh: 1 },
        limit: 5,
        skip: 0,
        query: {
          academic: user._id,
          delete: { $ne: true }
        }
      }
      this.props.listTor(queryTor)
      // this.props.firstlistTor()
    } else {
      this.props.firstlistTor()
    }
  }

  handleCloseModalAddTOR = () => {
    this.setState({ modalTorOpen: false, modalDimmer: true })
  }

  handleCloseModalCopyTOR = () => {
    this.setState({ modalCopyTorOpen: false, openAddSuccess: true })
    setTimeout(() => {
      this.setState({ openAddSuccess: false })
    }, 1500)
  }

  componentDidMount () {
    let { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    if (role === 'Guest') {
      role = user.roles[0]
    }

    let search = JSON.parse(JSON.stringify(this.props.search))
    if (role === 'Academic') {
      search.filter = { academic: user._id }
      search.type = 'tor'
    } else if (role === 'Co-Academic') {
      search.type = 'proposal'
      this.props.changeSearch(search)
    } else {
      search.filter = {}
      search.type = 'tor'
    }
    search.sortBy = 'nameTh'
    search.sortValue = 'ascendant'

    search.share = false
    this.setState({ inputText: '' })

    let queryTor = {
      sort: { nameTh: 1 },
      limit: 5,
      skip: 0,
      query: {
        academic: user._id,
        delete: { $ne: true }
      }
    }
    if (role === 'Academic') {
      this.props.listTor(queryTor)
      // this.props.firstlistTor()
    } else if (role === 'Co-Academic') {
      this.props.firstlistProject()
    } else {
      this.props.firstlistTor()
    }
    let queryProjectShare = {
      query: {
        listUsers: { $elemMatch: { _id: user._id, checkboxOn: true } }
      }
    }

    this.props.getlistShare(queryProjectShare)
  }

  componentWillReceiveProps = nextProps => {
    const { tors, role, auth } = nextProps
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (tors.params) {
      if (!tors.params.query) {
        let queryTor = {
          sort: { nameTh: 1 },
          limit: 5,
          skip: 0,
          query: {
            academic: user._id,
            delete: { $ne: true }
          }
        }
        if (role === 'Academic') {
          //  this.props.firstlistTor()
          this.props.listTor(queryTor)
        }
      }
    }
  }

  projectShare = () => {
    const { shareuser } = this.props

    let search = JSON.parse(JSON.stringify(this.props.search))
    search.type = 'proposal'
    search.share = true

    this.props.changeSearch(search)

    let listUser = []
    shareuser.forEach(x => {
      listUser.push(x.projectShareId)
    })

    let queryProject = {
      sort: { nameTh: 1 },
      limit: 5,
      skip: 0,
      query: {
        delete: { $ne: true },
        _id: { $in: listUser }
      }
    }

    this.props.listProject(queryProject)
  }

  refreshCard = () => {
    const { tors, role, auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (tors.params) {
      let queryTor = {
        sort: { nameTh: 1 },
        limit: 5,
        skip: 0,
        query: {
          academic: user._id
        }
      }
      if (role === 'Academic') {
        this.props.listTor(queryTor)
        // this.props.firstlistTor()
      }
    }
  }

  toggleSearchType = () => {
    const { auth, role } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let queryProject = {
      sort: { nameTh: 1 },
      limit: 5,
      skip: 0,
      query: {
        delete: { $ne: true },
        academic_id: user._id
      }
    }

    let querySwitchProject = {
      sort: { nameTh: 1 },
      limit: 100,
      skip: 0,
      query: {
        academic_id: user._id
      }
    }

    let queryTor = {
      sort: { nameTh: 1 },
      limit: 5,
      skip: 0,
      query: {
        academic: user._id
      }
    }

    let search = JSON.parse(JSON.stringify(this.props.search))

    search.share = false

    if (role === 'Academic') {
      if (search.type === 'proposal') search.filter = { academic: user._id }
      if (search.type === 'tor') search.filter = { academic_id: user._id }
    } else {
      search.filter = {}
    }

    search.sortBy = 'nameTh'
    search.sortValue = 'ascendant'
    search.type = search.type === 'proposal' ? 'tor' : 'proposal'
    this.setState({ inputText: '' })

    this.props.changeSearch(search)
    if (search.type === 'proposal') {
      if (role === 'Academic') {
        // this.props.firstlistProject()
        this.props.listProject(queryProject)
      } else {
        this.props.firstlistProject()
      }
    } else {
      if (role === 'Academic') {
        // this.props.firstlistTor()
        this.props.listTor(queryTor)
        this.props.listProject(querySwitchProject)
      } else {
        this.props.firstlistTor()
      }
    }
  }

  toggleSortValue = () => {
    const { auth, role, shareuser } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let listUser = []
    shareuser.forEach(x => {
      listUser.push(x.projectShareId)
    })

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (search.share) {
      search.filter = { _id: { $in: listUser } }
    }

    if (Object.keys(search.filter).length === 0) {
      if (role === 'Academic') {
        // search.filter = {}
        if (search.type === 'tor') search.filter = { academic: user._id }
        if (search.type === 'proposal')
          search.filter = { academic_id: user._id }
      } else {
        search.filter = {}
      }
    }

    search.sortValue =
      search.sortValue === 'ascendant' ? 'descendant' : 'ascendant'
    this.onChange(search)
  }

  sortChange = (e, { value }) => {
    const { auth, role, shareuser } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let listUser = []
    shareuser.forEach(x => {
      listUser.push(x.projectShareId)
    })

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (search.share) {
      search.filter = { _id: { $in: listUser } }
    }

    if (Object.keys(search.filter).length === 0) {
      if (role === 'Academic') {
        // search.filter = {}
        if (search.type === 'tor') search.filter = { academic: user._id }
        if (search.type === 'proposal')
          search.filter = { academic_id: user._id }
      } else {
        search.filter = {}
      }
    }

    search.sortBy = value
    this.onChange(search)
  }

  filterChange = (e, { value }) => {
    const { auth, role, shareuser } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let listUser = []
    shareuser.forEach(x => {
      listUser.push(x.projectShareId)
    })

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (search.share) {
      search.filter = { _id: { $in: listUser } }
    }

    if (Object.keys(search.filter).length === 0) {
      if (role === 'Academic') {
        // search.filter = {}
        if (search.type === 'tor') search.filter = { academic: user._id }
        if (search.type === 'proposal')
          search.filter = { academic_id: user._id }
      } else {
        search.filter = {}
      }
    }

    search.filter.status = value
    if (value === 'ทั้งหมด' && search.filter.status) delete search.filter.status
    this.onChange(search)
  }

  onTextChange = (e, { name, value }) => {
    const { auth, role, shareuser } = this.props
    this.setState({ inputText: value })
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let listUser = []
    shareuser.forEach(x => {
      listUser.push(x.projectShareId)
    })

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (search.share) {
      search.filter = { _id: { $in: listUser } }
    }

    if (Object.keys(search.filter).length === 0) {
      if (role === 'Academic') {
        // search.filter = {}
        if (search.type === 'tor') search.filter = { academic: user._id }
        if (search.type === 'proposal') {
          search.filter = { academic_id: user._id }
        }
      } else {
        search.filter = {}
      }
    }

    if (value.length >= 3) {
      if (name === 'torCode') {
        if (value.match(/^[0-9]{3}/) !== null) {
          delete search.filter.nameTh
          search.filter[name] = value
        } else {
          let spacialtext = value
          spacialtext = spacialtext.replaceAll('(', '\\(')
          spacialtext = spacialtext.replaceAll(')', '\\)')
          search.filter.nameTh = spacialtext
        }
        this.onChange(search)
      } else if (name === 'codeProject') {
        if (value.match(/^[0-9]{2}/) !== null) {
          delete search.filter.nameTh
          search.filter[name] = value
        } else {
          let spacialtext = value
          spacialtext = spacialtext.replaceAll('(', '\\(')
          spacialtext = spacialtext.replaceAll(')', '\\)')
          search.filter.nameTh = spacialtext
        }
        this.onChange(search)
      }
    } else if (value.length === 0) {
      delete search.filter.nameTh
      delete search.filter[name]
      this.onChange(search)
    }
  }

  onChange = search => {
    const { tors, proposals, auth } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let tmp = search.type === 'proposal' ? proposals.params : tors.params
    tmp.sort = { [search.sortBy]: search.sortValue === 'ascendant' ? 1 : -1 }
    // filter by statys
    if (search.filter && search.filter.status)
      tmp.query = { $and: [{ status: search.filter.status }] }
    else tmp.query = { $and: [] }
    // filter by another value
    search.filter &&
      Object.keys(search.filter).forEach(key => {
        if (key !== 'status') {
          if (key === 'startDate') {
            tmp.query.$and.push({ [key]: { $gte: search.filter[key] } })
          } else if (key === 'endDate') {
            tmp.query.$and.push({ [key]: { $lte: search.filter[key] } })
          } else if (key === '_id') {
            tmp.query.$and.push({ [key]: search.filter[key] })
          } else if (key === 'targetGroup') {
            tmp.query.$and.push({
              'targetGroup.targetGroup': search.filter[key]
            })
          } else {
            tmp.query.$and.push({
              [key]: { $regex: search.filter[key], $options: 'i' }
            })
          }
        }
      })
    if (tmp.query.$and.length === 0) tmp.query = {}
    // console.log(tmp)
    // fetch data and set property
    if (search.type === 'proposal') {
      tmp.query.delete = { $ne: true }
      this.props.listProject(tmp)
    } else {
      tmp.query.delete = { $ne: true }
      this.props.listTor(tmp)
    }
    this.props.changeSearch(search)
  }

  inputTextChange = (e, { name, value }) => {
    const filter = { ...this.state.filter }
    filter[name] = value
    if (value === 'ทั้งหมด' || value === '') delete filter[name]
    // this.props.changeSearch(search)
    this.setState({ filter })
  }

  handleDayChange = (name, index, date) => {
    const utc7 = UTC.getUTC7(date._d)
    const filter = { ...this.state.filter }
    filter[name] = utc7.getTime()
    this.setState({ filter })
  }

  handleClearSearch = () => {
    const { auth, role, shareuser } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let listUser = []
    shareuser.forEach(x => {
      listUser.push(x.projectShareId)
    })

    let search = JSON.parse(JSON.stringify(this.props.search))

    if (role === 'Academic') {
      // search.filter = {}
      if (search.type === 'proposal') {
        if (search.share) {
          search.filter = { _id: { $in: listUser } }
        } else {
          search.filter = { academic_id: user._id }
        }
      }
      if (search.type === 'tor') search.filter = { academic: user._id }
    } else {
      search.filter = {}
    }

    this.setState({ activeAdvance: false, inputText: '' })
    this.onChange(search)
  }

  advanceSearchProposal = () => {
    const options = [
      { key: '00', value: 'ทั้งหมด', text: 'ทั้งหมด' },
      { key: '01', value: 'โครงการเดี่ยว', text: 'โครงการเดี่ยว' },
      { key: '02', value: 'โครงการเชิงรุก', text: 'โครงการเชิงรุก' }
    ]
    return (
      <Form className='search-form'>
        <Form.Field>
          <Input
            fluid
            labelPosition='left'
            type='text'
            name='managerName'
            onChange={this.inputTextChange}
          >
            <Label basic className='search-modal-label'>
              ชื่อ-นามสกุล ผู้รับผิดชอบโครงการ
            </Label>
            <input />
          </Input>
        </Form.Field>
        <Form.Field>
          <Input
            fluid
            labelPosition='left'
            type='text'
            name='plan'
            onChange={this.inputTextChange}
          >
            <Label basic className='search-modal-label'>
              แผนงบ
            </Label>
            <input />
          </Input>
        </Form.Field>
        <Form.Field>
          <Input
            fluid
            labelPosition='left'
            type='text'
            name='targetGroup'
            onChange={this.inputTextChange}
          >
            <Label basic className='search-modal-label'>
              กลุ่มเป้าหมาย
            </Label>
            <input />
          </Input>
        </Form.Field>
        <Form.Field>
          <Input fluid labelPosition='left' type='text'>
            <Label basic className='search-modal-label'>
              ประเภทโครงการ
            </Label>
            <Form.Select
              options={options}
              placeholder='ประเภทโครงการ'
              name='typeProject'
              onChange={this.inputTextChange}
            />
          </Input>
        </Form.Field>
        <Form.Field>
          <Form.Group inline>
            <label>ช่วงเวลา ณ วันที่</label>
            <DayPicker
              ser={'dateContract'}
              name={'startDate'}
              onDayChange={this.handleDayChange}
              data={this.state.filter.startDate || ''}
            />
            <label> ถึงวันที่</label>
            <DayPicker
              ser={'dateContract'}
              name={'endDate'}
              onDayChange={this.handleDayChange}
              data={this.state.filter.endDate || ''}
            />
          </Form.Group>
        </Form.Field>
      </Form>
    )
  }

  advanceSearchTor = () => {
    const time = Array.from(Array(12).keys()).map((item, idx) => ({
      key: idx + 1,
      value: idx + 1,
      text: idx + 1 + ' เดือน'
    }))
    time.unshift({ key: 0, value: 'ทั้งหมด', text: 'ทั้งหมด' })
    return (
      <Form>
        <Form.Field>
          <Input
            fluid
            labelPosition='left'
            type='text'
            name='targets'
            onChange={this.inputTextChange}
          >
            <Label basic className='search-modal-label'>
              กลุ่มเป้าหมาย
            </Label>
            <input />
          </Input>
        </Form.Field>
        <Form.Field>
          <Input
            fluid
            labelPosition='left'
            type='text'
            name='academicName'
            onChange={this.inputTextChange}
          >
            <Label basic className='search-modal-label'>
              ผู้ประสานงานโครงการ
            </Label>
            <input />
          </Input>
        </Form.Field>
        <Form.Field>
          <Form.Group inline>
            <label>ช่วงเวลา ณ วันที่</label>
            <DayPicker
              ser={'dateContract'}
              name={'startDate'}
              onDayChange={this.handleDayChange}
              data={this.state.filter.startDate || ''}
            />
            <label> ถึงวันที่</label>
            <DayPicker
              ser={'dateContract'}
              name={'endDate'}
              onDayChange={this.handleDayChange}
              data={this.state.filter.endDate || ''}
            />
          </Form.Group>
        </Form.Field>
      </Form>
    )
  }

  onModalClose = () => {
    const { shareuser } = this.props
    let tmp = this.props.search
    let search = JSON.parse(JSON.stringify(tmp))

    let listUser = []
    shareuser.forEach(x => {
      listUser.push(x.projectShareId)
    })

    if (search.share) {
      this.setState({ filter: { _id: { $in: listUser } } })
    }
    this.setState({ filter: {} })
  }

  onSearchClick = () => {
    const { auth, role, shareuser } = this.props
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    let listUser = []
    shareuser.forEach(x => {
      listUser.push(x.projectShareId)
    })

    let tmp = this.props.search
    let search = JSON.parse(JSON.stringify(tmp))
    if (role === 'Academic') {
      // search.filter = {}
      if (search.type === 'tor') search.filter = { academic: user._id }
      if (search.type === 'proposal') {
        if (search.share) {
          search.filter = { _id: { $in: listUser } }
        } else {
          search.filter = { academic_id: user._id }
        }
      }
    } else {
      search.filter = {}
    }

    if (tmp.filter.status) search.filter.status = tmp.filter.status
    const { filter } = this.state
    if (search.type === 'tor') {
      if (filter.targets && filter.targets.length > 0) {
        search.filter['targets.targetGroup'] = filter.targets
      }
      if (filter.academicName && filter.academicName.length > 0) {
        search.filter.academicName = filter.academicName
      }
    } else {
      if (filter.managerName && filter.managerName.length > 0) {
        search.filter.managerName = filter.managerName
      }
      if (filter.plan && filter.plan.length > 0) {
        search.filter.plan = filter.plan
      }
      if (filter.targetGroup && filter.targetGroup.length > 0) {
        search.filter.targetGroup = filter.targetGroup
      }
      if (filter.typeProject && filter.typeProject.length > 0) {
        search.filter.typeProject = filter.typeProject
      }
    }
    if (filter.startDate && filter.startDate !== '') {
      search.filter.startDate = filter.startDate
    }
    if (filter.endDate && filter.endDate !== '') {
      search.filter.endDate = filter.endDate
    }
    this.onChange(search)
    this.setState({ isModalOpen: false, activeAdvance: true })
  }

  openModal = () => this.setState({ isModalOpen: true, filter: {} })
  closeModal = () =>
    this.setState({ isModalOpen: false, activeAdvance: false }, () => {
      const { auth, role, shareuser } = this.props
      let user = auth.isAuthenticated && auth.user && auth.user.user
      if (!user && auth.isAuthenticated) {
        user = this.getLocalUser()
      }

      let listUser = []
      shareuser.forEach(x => {
        listUser.push(x.projectShareId)
      })

      let tmp = this.props.search
      let search = JSON.parse(JSON.stringify(tmp))
      if (role === 'Academic') {
        // search.filter = {}
        if (search.type === 'tor') search.filter = { academic: user._id }
        if (search.type === 'proposal') {
          if (search.share) {
            search.filter = { _id: { $in: listUser } }
          } else {
            search.filter = { academic_id: user._id }
          }
        }
      } else {
        search.filter = {}
      }
      if (tmp.filter.status) search.filter.status = tmp.filter.status
      this.onChange(search)
    })

  handleCopyTOR = () => {
    this.setState({ modalCopyTorOpen: true })
  }

  handleListTORDelete = () => {
    this.setState({ modalDeleteTorOpen: true })
  }

  render () {
    const isOpen = this.props.sidebar.isOpen
    const { search, role } = this.props
    const {
      isModalOpen,
      activeAdvance,
      modalTorOpen,
      modalCopyTorOpen,
      openAddSuccess,
      modalDeleteTorOpen
    } = this.state

    return (
      <div className={'search-filter ' + (isOpen ? '' : 'full')}>
        <div className={'left ' + (isOpen ? '' : 'full')}>
          <Button.Group style={{ marginRight: 5 }}>
            <Button
              active={search.sortValue === 'ascendant'}
              onClick={this.toggleSortValue}
              icon='sort amount down'
            />
            <Button
              active={search.sortValue === 'descendant'}
              onClick={this.toggleSortValue}
              icon='sort amount up'
            />
            {search.type === 'proposal' && (
              <Dropdown
                className='search-dropdown'
                placeholder='เรียงข้อมูล'
                onChange={this.sortChange}
                options={sortProposal}
                button
              />
            )}
            {search.type === 'tor' && (
              <Dropdown
                className='search-dropdown'
                placeholder='เรียงข้อมูล'
                onChange={this.sortChange}
                options={sortTor}
                button
              />
            )}
          </Button.Group>
          {search.type === 'proposal' && (
            <Dropdown
              placeholder='กรองข้อมูล'
              icon='filter'
              labeled
              button
              className='icon search-dropdown'
              onChange={this.filterChange}
              options={filterProposal}
            />
          )}
          {search.type === 'tor' && (
            <Dropdown
              placeholder='กรองข้อมูล'
              icon='filter'
              labeled
              button
              className='icon search-dropdown'
              onChange={this.filterChange}
              options={filterTor}
            />
          )}
          {
            <Popup
              trigger={
                <Button
                  className='button-switch'
                  content={search.type === 'proposal' ? 'โครงการ' : 'ข้อกำหนด'}
                  icon='retweet'
                  onClick={this.toggleSearchType}
                />
              }
              content='เปลี่ยนการค้นหา(โครงการ - ข้อกำหนด)'
            />
          }
          {role !== 'Contract' && (
            <Popup
              trigger={
                <Button
                  className='button-switch'
                  content='โครงการที่แชร์ให้ฉัน'
                  icon='share square'
                  onClick={this.projectShare}
                />
              }
              content='โครงการที่แชร์ให้ฉัน'
            />
          )}
        </div>
        <div className={'right ' + (isOpen ? '' : 'full')}>
          <Menu secondary>
            <Menu.Item fitted>
              <Input
                icon='search'
                value={this.state.inputText}
                name={search.type === 'proposal' ? 'codeProject' : 'torCode'}
                onChange={this.onTextChange}
                placeholder={
                  search.type === 'proposal'
                    ? 'ชื่อหรือรหัสโครงการ...'
                    : 'ชื่อหรือรหัสข้อกำหนด...'
                }
              />
            </Menu.Item>
            <Menu.Item fitted>
              <Modal
                open={isModalOpen}
                size='small'
                trigger={
                  <Popup
                    trigger={
                      <Button
                        toggle
                        active={activeAdvance}
                        onClick={this.openModal}
                        icon='cogs'
                      />
                    }
                    content='ค้นหาขั้นสูง'
                  />
                }
                onClose={this.onModalClose}
              >
                <Modal.Header style={{ fontFamily: 'supermarket' }}>
                  ค้นหา{search.type === 'proposal' ? 'โครงการ' : 'ข้อกำหนด'}
                  ขั้นสูง
                </Modal.Header>
                <Modal.Content>
                  {search.type === 'proposal'
                    ? this.advanceSearchProposal()
                    : this.advanceSearchTor()}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    style={{ fontFamily: 'supermarket' }}
                    color='grey'
                    onClick={this.closeModal}
                  >
                    <Icon name='close' />
                    ยกเลิก
                  </Button>
                  <Button
                    style={{ fontFamily: 'supermarket' }}
                    color='green'
                    onClick={this.onSearchClick}
                  >
                    <Icon name='checkmark' />
                    ค้นหา
                  </Button>
                </Modal.Actions>
              </Modal>

              <span className='padding' />
              <Button
                style={{ fontFamily: 'supermarket' }}
                icon='sync'
                content='ล้างค้นหา'
                onClick={this.handleClearSearch}
              />
            </Menu.Item>
          </Menu>
          <Menu secondary className='search-create-new'>
            {search.type === 'tor' && (
              <Menu.Item fitted>
                <Button
                  icon='plus'
                  className='button'
                  content='สร้างข้อกำหนด'
                  onClick={this.handleOpenModal}
                  disabled={role !== 'Academic' && role !== 'Admin'}
                />
              </Menu.Item>
            )}
            {search.type === 'tor' && (
              <Menu.Item fitted>
                <Button
                  icon='copy'
                  content='คัดลอกข้อกำหนด'
                  onClick={this.handleCopyTOR}
                  disabled={role !== 'Academic' && role !== 'Admin'}
                />
              </Menu.Item>
            )}
            {search.type === 'tor' && (
              <Menu.Item fitted>
                <Button
                  icon='box'
                  content='ข้อกำหนดที่ลบแล้ว'
                  onClick={this.handleListTORDelete}
                  disabled={role !== 'Academic' && role !== 'Admin'}
                />
              </Menu.Item>
            )}
          </Menu>
          {
            <Modal
              style={{ marginTop: 30 }}
              open={modalTorOpen}
              onClose={this.handleCloseModalAddTOR}
              closeOnDimmerClick={this.state.modalDimmer}
              size='small'
              closeIcon
            >
              <Modal.Content>
                <ComponentAddTOR
                  onCloseModalTor={this.handleCloseModalAddTOR}
                  onRefreshCard={this.refreshCard}
                />
              </Modal.Content>
            </Modal>
          }
          {
            <Modal
              style={{ marginTop: 30 }}
              open={modalCopyTorOpen}
              onClose={this.handleCancleCloneTOR}
              closeOnDimmerClick
              size='small'
              closeIcon
            >
              <Modal.Content>
                <CloneTOR onCloseModalProject={this.handleCloseModalCopyTOR} />
              </Modal.Content>
            </Modal>
          }
          {
            <Modal
              style={{ marginTop: 30 }}
              open={modalDeleteTorOpen}
              onClose={this.handleCancleCloneTOR}
              closeOnDimmerClick
              size='small'
              closeIcon
            >
              <Modal.Content>
                <ListTORDelete
                  onCloseModalProject={this.handleCloseModalAddTOR}
                />
              </Modal.Content>
            </Modal>
          }
          <Modal
            open={openAddSuccess}
            // closeIcon
            onClose={this.modalCopyTorOpen}
          >
            {/* <Modal.Header><Icon name='users' />Users Sharing</Modal.Header> */}
            <Modal.Content image>
              <Modal.Description style={{ fontSize: 20 }}>
                <Icon name='check circle'></Icon> คัดลอกข้อกำหนดสำเร็จ
              </Modal.Description>
            </Modal.Content>
            {/* <Modal.Actions>
            <Button primary onClick={this.closeShareModal}>
              <p style={{ fontFamily: 'supermarket' }}>ปิด</p>
            </Button>
          </Modal.Actions> */}
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tors: state.tors,
  proposals: state.projects,
  sidebar: state.sidebar,
  search: state.search,
  role: state.role,
  auth: state.auth,
  shareuser: state.shareuser.data
})

const mapDispatchToProps = dispatch => ({
  firstlistTor: () =>
    dispatch(
      torActions.fetchList({
        query: { delete: { $ne: true } },
        sort: { nameTh: 1 },
        limit: 5,
        skip: 0
      })
    ),
  firstlistProject: () =>
    dispatch(
      projectActions.fetchList({
        query: { delete: { $ne: true } },
        sort: { nameTh: 1 },
        limit: 5,
        skip: 0
      })
    ),
  changeSearch: search => dispatch(changeSearch(search)),
  listProject: query => dispatch(projectActions.fetchList({ ...query })),
  listTor: query => dispatch(torActions.fetchList({ ...query })),
  getlistShare: query => dispatch(shareuserActions.fetchList({ ...query }))
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)

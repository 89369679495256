import React, { Component, Fragment } from 'react'
import {
  Header,
  Form,
  Input,
  Button,
  Table,
  Confirm,
  Label,
  Segment,
  Message,
  Select,
  Grid,
  Checkbox,
  Modal,
  Transition,
  Icon
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import moment from 'moment'
import Cleave from 'cleave.js/react'
import 'react-datepicker/dist/react-datepicker.css'
import { GetServerTime as GetTime } from 'Utils'
import { HeaderContent } from 'Components/Header'
import { constraints as Constraints } from './PromiseConstraints'
import { constraints as PromiseArrayConstraints } from './PromiseConstraints'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as userActions } from 'Redux/reducers/user'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as individuals_wActions } from 'Redux/reducers/individuals_w'
import { actions as individuals_apActions } from 'Redux/reducers/individuals_ap'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { actions as bankgroupActions } from 'Redux/reducers/bankgroup'
import { actions as organizationsActions } from 'Redux/reducers/organizations'
import { generate } from 'Redux/reducers/counting'
import { localTh } from 'Utils'
import { connect } from 'react-redux'
import { DatePicker as DayPicker } from 'Utils'
import { ThaiDate } from 'Utils'
import { AttachedComponent } from 'Utils'
import { PrintoutComponent } from 'Utils'
import { ValidateArray as validateArray } from 'Utils'
import { formatcomma } from 'Utils'
import { ComponentPerson } from 'Utils'
import { ComponentOrganization } from 'Utils'
import jwt from 'jsonwebtoken'

let gettime,
  noAgree,
  noBookInside,
  noBook1,
  noBook2,
  thisYear,
  dataStart,
  dataEnd = ''

let bankBranchText = []

let APOptions = []

const copyrightOptions = [
  { key: '1', text: 'กสศ. เป็นเจ้าของสิทธิ', value: 'กสศ.' },
  { key: '2', text: 'ภาคีร่วมดำเนินงานเป็นเจ้าของสิทธิ', value: 'ภาคีร่วม' },
  {
    key: '3',
    text: 'กสศ.และภาคีร่วมดำเนินงานเป็นเจ้าของสิทธิร่วมกัน',
    value: 'กสศ.และภาคีร่วม'
  }
]

const sendCon = [
  {
    key: '1',
    text: 'ศูนย์สัญญาส่งให้และรับกลับคืน',
    value: 'ศูนย์สัญญาส่งให้และรับกลับคืน'
  },
  {
    key: '2',
    text: 'นักวิชาการดำเนินการส่งเสริมและรับกลับเอง',
    value: 'นักวิชาการจพดำเนินการส่งเสริมและรับกลับเอง'
  }
]
const process = [
  { key: '1', text: 'เหมาจ่าย', value: 'เหมาจ่าย' },
  { key: '2', text: 'จ่ายตามจริง', value: 'จ่ายตามจริง' }
]
// const typeApprove = [
//   { key: '1', text: 'โครงการเดี่ยว', value: 'โครงการเดี่ยว' },
//   { key: '2', text: 'โครงการเชิงรุก', value: 'โครงการเชิงรุก' }
// ]
const PaymentOptions = [
  { key: 'y', text: 'ส่งเช็คทางไปรษณีย์', value: 'delivery' },
  { key: 'n', text: 'โอนเงินเข้าบัญชีธนาคาร', value: 'bank' }
]

const witnessOptions = [
  { key: '1', text: 'พยานจากองค์กรต่างๆ', value: 'normalWitness' },
  { key: '2', text: 'พยานจากกสศ', value: 'kssWitness' }
]

const bankOptions = [
  { key: '1', text: 'ธนาคารกรุงเทพ จำกัด (มหาชน)', value: 'BBL', get: 'BBL' },
  { key: '2', text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร', value: 'BAAC', get: 'BAAC' },
  { key: '3', text: 'ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)', value: 'BAY', get: 'BAY' },
  { key: '4', text: 'ธนาคารซีไอเอ็มบี ไทย จำกัด (มหาชน)', value: 'CIMB', get: 'CIMB' },
  { key: '5', text: 'ธนาคารซิตี้แบงก์', value: 'CITI', get: 'CITI' },
  { key: '6', text: 'ธนาคารดอยซ์แบงก์', value: 'DEUTSHE', get: 'DEUTSHE' },
  { key: '7', text: 'ธนาคารอาคารสงเคราะห์', value: 'GHB', get: 'GHB' },
  { key: '8', text: 'ธนาคารออมสิน', value: 'GSB', get: 'GSB' },
  {
    key: '9',
    text: 'The HongKong and Shanghai Banking Corporation Limited',
    value: 'HSBC', get: 'HSBC'
  },
  { key: '10', text: 'ธนาคารอิสลามแห่งประเทศไทย', value: 'IBANK', get: 'IBANK' },
  { key: '11', text: 'ธนาคารกสิกรไทย จำกัด (มหาชน)', value: 'KBANK', get: 'KBANK' },
  { key: '12', text: 'ธนาคารเกียรตินาคิน จำกัด (มหาชน)', value: 'KK', get: 'KK' },
  { key: '13', text: 'ธนาคารกรุงไทย จำกัด (มหาชน)', value: 'KTB', get: 'KTB' },
  { key: '14', text: 'ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)', value: 'LB', get: 'LB' },
  { key: '15', text: 'ธนาคารไทยพาณิชย์ จำกัด (มหาชน)', value: 'SCB', get: 'SCB' },
  {
    key: '16',
    text: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) จำกัด (มหาชน)',
    value: 'SCBT', get: 'SCBT'
  },
  { key: '17', text: 'ธนาคารธนชาต จำกัด (มหาชน)', value: 'TBANK', get: 'TBANK' },
  { key: '18', text: 'ธนาคารทิสโก้ จำกัด (มหาชน)', value: 'TISCO', get: 'TISCO' },
  { key: '19', text: 'ธนาคารทหารไทย จำกัด (มหาชน)', value: 'TMB', get: 'TMB' },
  { key: '20', text: 'ธนาคารยูโอบี จำกัด (มหาชน)', value: 'UOB', get: 'UOB' },
  { key: '21', text: 'ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)', value: 'LH', get: 'LH' },
  { key: '22', text: 'JPMorgan Chase Bank', value: 'JPMorgan', get: 'JPMorgan' },
  { key: '23', text: 'Standard Chartered Bank', value: 'Standard Chartered Bank', get: 'Standard Chartered Bank' },
  { key: '24', text: 'ธนาคารทหารไทยธนชาต จำกัด (มหาชน) ', value: 'TMB1', get: 'TMB' },
  { key: '25', text: 'Santander Bank', value: 'Santander Bank', get: 'Santander Bank' },
  { key: '26', text: 'Citibank, N.A.', value: 'Citibank, N.A.', get: 'Citibank, N.A.' },
  { key: '26', text: 'JP Morgan AG', value: 'JP Morgan AG', get: 'JP Morgan AG' }
]

var PromiseOptions = [
  { key: '1', text: 'ธนาคารกรุงเทพ', value: 'ธนาคารกรุงเทพ' },
  { key: '2', text: 'ธนาคารออมสิน', value: 'ธนาคารออมสิน' },
  { key: '3', text: 'ธนาคารกรุงไทย', value: 'ธนาคารกรุงไทย' },
  { key: '4', text: 'ธนาคารกสิกร', value: 'ธนาคารกสิกร' },
  { key: '5', text: 'ธนาคารไทยพาณิชย์', value: 'ธนาคารไทยพาณิชย์' },
  { key: '6', text: 'ธนาคารกรุงศรีอยุธยา', value: 'ธนาคารกรุงศรีอยุธยา' },
  { key: '7', text: 'ธนาคารธนชาต', value: 'ธนาคารธนชาต' },
  { key: '8', text: 'ธนาคารทหารไทย', value: 'ธนาคารทหารไทย' },
  { key: '9', text: 'ธนาคารเกียรตินาคิน', value: 'ธนาคารเกียรตินาคิน' },
  { key: '10', text: 'ธนาคารซีไอเอ็มบีไทย', value: 'ธนาคารซีไอเอ็มบีไทย' },
  { key: '11', text: 'ธนาคารทิสโก้', value: 'ธนาคารทิสโก้' },
  { key: '12', text: 'ธนาคารยูโอบี', value: 'ธนาคารยูโอบี' },
  { key: '13', text: 'ธนาคารเพื่อการเกษตร', value: 'ธนาคารเพื่อการเกษตร' },
  {
    key: '14',
    text: 'ธนาคารอิสลามแห่งประเทศไทย',
    value: 'ธนาคารอิสลามแห่งประเทศไทย'
  },
  {
    key: '15',
    text: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
    value: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)'
  },
  {
    key: '16',
    text: 'ธนาคารไทยเครดิตเพื่อรายย่อย',
    value: 'ธนาคารไทยเครดิตเพื่อรายย่อย'
  },
  {
    key: '17',
    text: 'ธนาคารแลนด์ แอนด์ เฮาส์',
    value: 'ธนาคารแลนด์ แอนด์ เฮาส์'
  },
  { key: '18', text: 'ธนาคารไอซีบีซี (ไทย)', value: 'ธนาคารไอซีบีซี (ไทย)' },
  {
    key: '19',
    text: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย',
    value: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย'
  },
  {
    key: '20',
    text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
    value: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร'
  },
  {
    key: '21',
    text: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย',
    value: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย'
  },
  { key: '22', text: 'ธนาคารอาคารสงเคราะห์', value: 'ธนาคารอาคารสงเคราะห์' }
]

class PagePromise extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      openConfirm: false,
      modalAttachment: false,
      modalSummary: false,
      onSaveNotSuccess: false,
      onSaveSuccess: false,
      openConfirmDel: false,
      indexDelete: '',
      isLoad: false,
      cidRecipient: ''
    }
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  componentDidMount() {
    let { match, auth, counter, bankgroup } = this.props

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
    this.props.getTypeProject('584ccec0e1e111e889cf8170c54de9cb')
    this.props.listUser()
    thisYear = undefined
    noBookInside = undefined
    noBook1 = undefined
    noBook2 = undefined

    if (Object.getOwnPropertyNames(counter.counter).length > 0) {
      Object.keys(counter.counter).forEach(key => {
        delete counter.counter[key]
      })
    }

    if (gettime) {
      // let checkYear = new Date(((new Date(gettime)).getFullYear()), 8, 30).getTime()
      // if (gettime > checkYear) {
      //   thisYear = new Date((new Date(gettime)).getFullYear() + 544).getTime()
      // } else {
      thisYear = new Date(new Date(gettime).getFullYear() + 543).getTime()
      // }
    }

    if (bankgroup.length > 0) bankgroup = []
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
      }
    })
    this.setState({ isLoad: false })
  }

  componentWillReceiveProps(nextProps) {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    // console.log(thisYear)

    // if (thisYear === undefined || thisYear === '') {
    //   this.getTimeFuction()
    // }

    let { data, isLoad, cidRecipient } = this.state
    let { currentProposal, counter, individuals, organization } = nextProps
    let tmpWithdraw = currentProposal && currentProposal.withDrawTimeAgreement
    let tmp = { ...nextProps.currentProposal }

    if (currentProposal && !isLoad) {
      if (currentProposal.withdrawBankID) {
        let query = {}
        let tmp_name = currentProposal.withdrawBankBranch
        if (currentProposal.withdrawBankBranchID) {
          query = {
            $and: [
              { BankGroup: currentProposal.withdrawBankID },
              { Name: { $regex: currentProposal.withdrawBankBranch.split('(')[0] } }
            ]
          }
          if (currentProposal.withdrawBankID === 'TMB1') {
            query = {
              $and: [
                { BankGroup: 'TMB' },
                { Name: { $regex: currentProposal.withdrawBankBranch.split('(')[0] } }
              ]
            }
          }
          this.props.getBankBranch(query)
        }
        // this.props.getBankBranch(query)
      }

      if (!currentProposal.authorizePerson) {
        currentProposal.authorizePerson = [
          {
            select: true,
            value: currentProposal.manager,
            name: currentProposal.managerName
          },
          { select: true, value: '', name: '' },
          { select: true, value: '', name: '' }
        ]
      }

      var dayS = new Date(currentProposal.startDate).getDate() - 45
      var monthS = new Date(currentProposal.startDate).getMonth()
      var yearS = new Date(currentProposal.startDate).getFullYear()
      dataStart = new Date(yearS, monthS, dayS)
      var dayE = new Date(currentProposal.endDate).getDate() + 45
      var monthE = new Date(currentProposal.endDate).getMonth()
      var yearE = new Date(currentProposal.endDate).getFullYear()
      dataEnd = new Date(yearE, monthE, dayE)

      if (!cidRecipient) {
        if (currentProposal.recipientType === 'องค์กร' || currentProposal.recipientType === 'คณะบุคคล') {
          this.props.getOrganization(currentProposal.recipientOrganize)
        } else {
          this.props.getPerson({ _id: currentProposal.recipientPerson })
        }
        this.setState({ cidRecipient: 'gotPerson' })
        let person2 = ''
        if (
          (currentProposal.recipientType === 'องค์กร' || currentProposal.recipientType === 'คณะบุคคล') &&
          currentProposal.recipientPerson !== currentProposal.manager
        ) {
          if (!currentProposal.witness2) {
            currentProposal.witness2 = currentProposal.manager
            currentProposal.witness2Name = currentProposal.managerName
          }
          person2 = currentProposal.witness2
          let queryP = { _id: person2 }
          this.props.getPerson_w(queryP)
        } else {
          person2 = currentProposal.witness2
          let queryP = { _id: person2 }
          this.props.getPerson_w(queryP)
        }
        this.setState({ isLoad: true })
      }
    }

    if (currentProposal) {
      if (currentProposal.recipientType === 'องค์กร' || currentProposal.recipientType === 'คณะบุคคล') {
        if (organization) {
          if (organization._id === currentProposal.recipientOrganize) {
            if (organization.cid) {
              this.setState({ cidRecipient: organization.cid })
            } else {
              this.setState({ cidRecipient: 'noCid' })
            }
          }
        }
      } else {
        if (individuals[0]) {
          let name = individuals[0]._id
          if (name === currentProposal.recipientPerson) {
            if (individuals[0].cid) {
              this.setState({ cidRecipient: individuals[0].cid })
            } else {
              this.setState({ cidRecipient: 'noCid' })
            }
          }
        }
      }
    }

    if (counter.counter['noAgreement-' + thisYear]) {
      noAgree =
        parseInt(thisYear) -
        2500 +
        '-' +
        counter.counter['noAgreement-' + thisYear].toString().padStart(4, '0')
      delete counter.counter['noAgreement-' + thisYear]
    }

    if (counter.counter['noBookInside-' + thisYear]) {
      noBookInside =
        'กสศ. น. 19/' +
        counter.counter['noBookInside-' + thisYear]
          .toString()
          .padStart(5, '0') +
        '/' +
        (new Date(gettime).getFullYear() + 543)
      delete counter.counter['noBookInside-' + thisYear]
    }

    if (counter.counter['noBook-' + thisYear]) {
      if (!noBook1) {
        noBook1 = counter.counter['noBook-' + thisYear]
        delete counter.counter['noBook-' + thisYear]
      } else {
        noBook2 = counter.counter['noBook-' + thisYear]
        delete counter.counter['noBook-' + thisYear]
      }
    }

    if (noAgree && noBookInside && noBook1 && noBook2) {
      this.handleConfirm()
    }

    if (nextProps.currentProposal) {
      if (!tmp.budget) {
        tmp.budget = tmp.approveBuget
          ? parseFloat(tmp.approveBuget).toString()
          : parseFloat(tmp.askBudget).toString()
        this.setState({ data: { ...data, ...tmp } })
      }
      if (!data.withDrawTime) {
        data.withDrawTime = tmpWithdraw
        this.setState({ data: { ...data, ...tmp } })
      }
      if (!data.condition) {
        data.condition = []
        this.setState({ data: { ...data, ...tmp } })
      }
      let sd = moment(data.startDate).format('YYYY-MM-DD')
      let ed = moment(data.endDate).format('YYYY-MM-DD')

      // var diff = moment.preciseDiff(sd, ed);
      var diff = moment.preciseDiff(sd + ' 00:00:00', ed + ' 24:00:00')

      let diffyth
      let diffmth
      let diffdth

      let cy = diff.indexOf('years')

      if (cy !== -1) {
        diffyth = diff.replace('years', 'ปี')
      } else {
        diffyth = diff.replace('year', 'ปี')
      }

      let cl = diff.indexOf('months')

      if (cl !== -1) {
        diffmth = diffyth.replace('months', 'เดือน')
      } else {
        diffmth = diffyth.replace('month', 'เดือน')
      }

      let cd = diff.indexOf('days')

      if (cd !== -1) {
        diffdth = diffmth.replace('days', 'วัน')
      } else {
        diffdth = diffmth.replace('day', 'วัน')
      }

      data['operationTime'] = diffdth
      if (!data._id) this.setState({ data: { ...data, ...tmp } })
    }
  }

  editRecipient = () => {
    this.props.getPerson({ _id: this.props.currentProposal.recipientPerson })
    this.setState({ cidRecipient: 'gotPerson' })
  }

  _onUpload = () => {
    this.setState({ modalAttachment: true, modalPrintout: false })
  }

  handleUploaded = ({ name, index, file, typeOfAttach, uploaded }) => {
    let { currentProposal } = this.props
    if (!currentProposal[uploaded.name]) currentProposal[uploaded.name] = []
    currentProposal[uploaded.name].push({
      fileName: uploaded.typeOfAttach + '_' + uploaded.file.name,
      typeOfAttach: uploaded.typeOfAttach
    })
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  handleAttachedFileRemove = name => {
    let { currentProposal } = this.props
    currentProposal['attachPromise'].splice(name.index, 1)
    this.props.updateProposal(currentProposal, currentProposal._id)
  }

  _renderFormField = content => {
    const { validate } = this.state
    let { currentProposal, role } = this.props
    // let user = auth.isAuthenticated && auth.user && auth.user.user
    // if (!user && auth.isAuthenticated) {
    //   user = this.getLocalUser()
    // }
    // const checkRole = user.roles.find(x => x === 'Contract')

    if (currentProposal) {
      if (
        (currentProposal &&
          currentProposal.status !== 'ทำสัญญาโครงการ' &&
          role !== 'Admin') ||
        (currentProposal &&
          currentProposal.status === 'ทำสัญญาโครงการ' &&
          role !== 'Admin' &&
          role !== 'Contract')
      ) {
        if (content.control === Select) {
          // return <Form.Field {...content} error={validate.attrs[content.name]} onKeyPress={this.handleKeyPress} />
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              onKeyPress={this.handleKeyPress}
              disabled
            />
          )
        } else
          return (
            <Form.Field
              {...content}
              error={validate.attrs[content.name]}
              onKeyPress={this.handleKeyPress}
              readOnly
            />
          )
      } else {
        if (content.control === Select) {
          return (
            <Form.Field {...content} error={validate.attrs[content.name]} />
          )
        } else
          return (
            <Form.Field {...content} error={validate.attrs[content.name]} />
          )
      }
    }
  }

  handleSearch = (e, { name }) => {
    let { data } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    if (e.target.value.length >= 3) {
      if (name === 'witness1') {
      } else if (name === 'witness2') {
        let test = e.target.value.split(' ')
        let query = {
          firstname: { $regex: test[0] },
          lastname: { $regex: test[1] || '' }
        }
        this.props.getPerson_w(query)
      } else if (name === 'withdrawBankBranchID') {
        // console.log(data.withdrawBankID, e.target.value)
        if (data.withdrawBankID) {
          let tmp_name = e.target.value
          tmp_name.replace('(', '\\(')
          tmp_name.replace(')', '\\)')
          let query = {
            $and: [
              { BankGroup: data.withdrawBankID },
              { Name: { $regex: tmp_name } }
            ]
          }
          if (data.withdrawBankID === 'TMB1') {
            query = {
              $and: [
                { BankGroup: 'TMB' },
                { Name: { $regex: tmp_name } }
              ]
            }
          }

          this.props.getBankBranch(query)
        }
      }
    }

    this.setState({ data })
  }

  handleChange = (e, { name, value, checked }) => {
    let { data, validate } = this.state
    let { currentProposal, user, individuals_w } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    if (name === 'witness1') {
      data[name] = value
      user.map(r => {
        if (value === r._id) {
          data['witness1Name'] = r.title + r.firstname + ' ' + r.lastname
        }
        return r
      })
    }

    if (name === 'witness2') {
      data[name] = value
      individuals_w.map(r => {
        if (value === r._id) {
          data['witness2Name'] = r.title + r.firstname + ' ' + r.lastname
        }
        return r
      })
    }

    if (name === 'withdrawBankID') {
      // console.log(data[name])
      let valueGet = ''
      data[name] = value
      bankOptions.map(r => {
        if (value === r.value) {
          data['withdrawBank'] = r.text
          valueGet = r.get
        }
        return r
      })
      data.withdrawBankBranchID = ''
      data.withdrawBankBranch = ''

      let query = {
        $and: [
          { BankGroup: valueGet }
        ]
      }
      this.props.getBankBranch(query)
    }

    if (name === 'withdrawBankBranchID') {
      data[name] = value
      bankBranchText.map(r => {
        if (value === r.value) {
          data['withdrawBankBranch'] = r.text.replace('สาขา', '')
        }
        return r
      })
    }

    if (name === 'promiseConfirm') {
      if (!data['promiseConfirm']) {
        data[name] = false
        this.setState({ checkSummary: checked, modalSummary: true })
      } else {
        this.setState({ checkSummary: checked, modalSummary: false })
        data[name] = checked
      }
    } else if (name !== 'witness1' || name !== 'witness2') {
      data[name] = checked || value
    } else {
      data[name] = value
    }

    if (name === 'contractForAdmin') {
      user.map(r => {
        if (value === r._id) {
          data.contractName =
            r.title === 'อื่นๆ'
              ? r.otherTitle + r.firstname + ' ' + r.lastname
              : r.title + r.firstname + ' ' + r.lastname
        }
        return r
      })
      data['contract_id'] = value
    }

    validate['validateError'] = validatejs(data, Constraints.PromiseConstraints)
    validate.attrs = {}
    validate.error = false

    this.setState({ data, validate })
  }

  handleAPEditRow = (idx, e, { value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    data = { ...currentProposal, ...data }

    data.authorizePerson[idx].name = value

    this.setState(data)

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
  }

  handleAPchenge = (idx, e, { value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    data = { ...currentProposal, ...data }

    data.authorizePerson[idx].value = value
    let index = APOptions.findIndex(i => i.value === value)
    data.authorizePerson[idx].name = APOptions[index].text

    this.setState(data)

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
  }

  handleAPSearch = (index, e) => {
    // let { data } = this.state
    // let { currentProposal } = this.props
    // data = { ...currentProposal, ...data }

    if (e.target.value.length >= 3) {
      let query = {}
      // data.authorizePerson.forEach((item, idx) => {
      //   if (item.select) {
      //     query.$or.push({ _id: item.value })
      //   }
      // })
      let test = e.target.value.split(' ')
      query = {
        firstname: { $regex: test[0] },
        lastname: { $regex: test[1] || '' }
      }
      this.props.getPerson_ap(query)
    }
  }

  handleSelectRowAP = (idx, value) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    data = { ...currentProposal, ...data }

    data.authorizePerson[idx].select = value

    this.setState(data)

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
  }

  handleConfirm = () => {
    let { data, validate } = this.state
    let { currentProposal, isSaving, auth, role } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    if (role === 'Contract') {
      data.contract_id = user._id
      data.contractName = user.title + user.firstname + ' ' + user.lastname
    }

    if (this.props.currentProposal) {
      data = { ...currentProposal, ...data }
      if (data.budget === '0') delete data.budget
      // if(data.withDrawTime){
      //   data.withDrawTime.forEach(x => {
      //     if(x.mainCom==='0'){
      //       delete x.mainCom
      //     }
      //     if(x.manage==='0'){
      //       delete x.manage
      //     }
      //   });
      // }

      validate.attrs = {}
      validate.error = false

      if (data.recipientType === 'องค์กร' || data.recipientType === 'คณะบุคคล') {
        Constraints.PromiseConstraints.witness1 = {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
      }
      if (data.promiseConfirm) {
        if (data.promiseConfirm === true) {
          Constraints.PromiseConstraints.withdrawBank = {
            presence: {
              allowEmpty: false,
              message: '^ต้องระบุ'
            }
          }
          Constraints.PromiseConstraints.withdrawBankBranch = {
            presence: {
              allowEmpty: false,
              message: '^ต้องระบุ'
            }
          }
          Constraints.PromiseConstraints.withdrawAccountName = {
            presence: {
              allowEmpty: false,
              message: '^ต้องระบุ'
            }
          }
          Constraints.PromiseConstraints.withdrawAccountNumber = {
            presence: {
              allowEmpty: false,
              message: '^ต้องระบุ'
            },
            numericality: {
              message: '^ต้องเป็นตัวเลข'
            }
          }
        }
      }
      validate['validateError'] = validatejs(
        data,
        Constraints.PromiseConstraints
      )
      if (this.state.cidRecipient === 'noCid') {
        if (!validate['validateError']) validate['validateError'] = {}
        validate['validateError']['เลขประจำตัวประชาชนผู้เสียภาษี'] = [
          ' โปรดระบุ'
        ]
      }
      // validate array
      if (!data.withDrawTime) data.withDrawTime = []
      let validateArrayError = validateArray(
        data.withDrawTime,
        PromiseArrayConstraints.PromiseArrayConstraints
      )
      if (!validate['validateError']) validate['validateError'] = {}
      if (
        Object.keys(validateArrayError).length !== 0 &&
        validateArrayError.constructor === Object
      ) {
        validate.error = true
        Object.keys(validateArrayError).forEach(index => {
          if (validateArrayError[index].content) {
            validate['validateError']['withDrawTimeContent'] =
              validateArrayError[index].content
          }
          if (validateArrayError[index].startDate) {
            validate['validateError']['withDrawTimeStartDate'] =
              validateArrayError[index].startDate
          }
          if (validateArrayError[index].endDate) {
            validate['validateError']['withDrawTimeEndDate'] =
              validateArrayError[index].endDate
          }
          if (validateArrayError[index].mainCom) {
            validate['validateError']['mainCom'] =
              validateArrayError[index].mainCom
          }
          if (validateArrayError[index].manage) {
            validate['validateError']['manage'] =
              validateArrayError[index].manage
          }
          validate.attrs['withDrawTime' + index] = true
        })
      }

      if (!data.withDrawTime) data.withDrawTime = []

      if (data.withdrawAccountNumber) {
        if (data.withdrawAccountNumber.toString().length < 10) {
          validate.error = true
          validate['validateError']['withdrawAccountNumber'] = []
          validate['validateError']['withdrawAccountNumber'].push(
            'ต้องเป็นตัวเลขมากกว่า 10 หลักเท่านั้น'
          )
        }
      }

      if (data.withDrawTime.length === 0) {
        validate.error = true
        validate['validateError']['withDrawTime'] = []
        validate['validateError']['withDrawTime'].push('ต้องระบุ')
      }
      // end validate array
      if (data.witness1 === data.witness2) {
        validate.error = true
        validate['validateError']['witness'] = []
        validate['validateError']['witness'].push('ชื่อพยานซ้ำ')
      }

      if (data.typeProject === 5) {
        let validateSeries = {
          seqConnect: {
            presence: {
              allowEmpty: false,
              message: '^ต้องระบุ'
            }
          }
        }
        if (data.seqConnect !== '1') {
          validateSeries.seriesConnect = {
            presence: {
              allowEmpty: false,
              message: '^ต้องระบุ'
            }
          }
        } else {
          validateSeries.endDate_connect = {
            presence: {
              allowEmpty: false,
              message: '^ต้องระบุ'
            }
          }
          validateSeries.startDate_connect = {
            presence: {
              allowEmpty: false,
              message: '^ต้องระบุ'
            }
          }
          validateSeries.askBudget_connect = {
            presence: {
              allowEmpty: false,
              message: '^ต้องระบุ'
            }
          }
        }
        let validConnect = validatejs(data, validateSeries)

        if (validConnect) {
          if (!validate['validateError']) validate['validateError'] = []
          if (validConnect.seriesConnect)
            validate['validateError']['seriesConnect'] = [' โปรดระบุ']
          if (validConnect.seqConnect)
            validate['validateError']['seqConnect'] = [' โปรดระบุ']
          if (validConnect.endDate_connect)
            validate['validateError']['endDate_connect'] = [' โปรดระบุ']
          if (validConnect.startDate_connect)
            validate['validateError']['startDate_connect'] = [' โปรดระบุ']
          if (validConnect.askBudget_connect)
            validate['validateError']['askBudget_connect'] = [' โปรดระบุ']
        }
      }

      if (
        Object.keys(validate['validateError']).length !== 0 &&
        validate['validateError'].constructor === Object
      ) {
        validate['error'] = true
        Object.keys(validate['validateError']).forEach(item => {
          validate.attrs[item] = true
        })
        this.setState({ validate, openConfirm: false })
      } else {
        if (data.withDrawTime.length !== 0) {
          let checkSum = 0
          let budget = parseFloat(data.budget || '0')
          data.withDrawTime.forEach((item, idx) => {
            checkSum += item.sum
          })
          if (checkSum === budget) {
            this.setState({ openConfirm: false })
            if (
              currentProposal &&
              currentProposal.result &&
              currentProposal.product &&
              !data['concludeResultProgress'] &&
              !data['concludeProductProgress']
            ) {
              for (let i = 0; i < currentProposal.result.length; i++) {
                if (!data['concludeResultProgress']) {
                  data['concludeResultProgress'] = []
                }
                data['concludeResultProgress'].push({
                  concludeResult: '',
                  resultCheck: false
                })
              }
              for (let i = 0; i < currentProposal.product.length; i++) {
                if (!data['concludeProductProgress']) {
                  data['concludeProductProgress'] = []
                }
                data['concludeProductProgress'].push({
                  concludeProduct: '',
                  productCheck: false
                })
              }
            }
            if (data['promiseConfirm']) {
              data['status'] = 'ประเมินความก้าวหน้าโครงการ'
            }

            if (role === 'Admin') {
              data['adminEdit'] = user._id
              data['adminEditTime'] = gettime
            } else {
              data['timestamp'] = gettime
            }

            if (data.sendtoERF) data['sendTimestamp'] = gettime

            // let countWithdraw = data.withDrawTime.length

            // if (data.withdrawForm.length === 0) {
            //   data.withdrawForm = []
            //   data.progressPeriod = []
            //   for (let i = 0; i < countWithdraw; i++) {
            //     // data.withdrawForm[i] = { dateOfReceipt: '' }
            //     data.withdrawForm.push({ dateOfReceipt: '' })
            //   }
            //   for (let i = 0; i < countWithdraw; i++) {
            //     // data.progressPeriod[i] = { }
            //     data.progressPeriod.push({})
            //   }
            // }

            if (!data.noAgreement) {
              if (noAgree && noBookInside && noBook1 && noBook2) {
                data.noAgreement = noAgree
                noAgree = undefined
                data.noBookAgreement = noBookInside
                noBookInside = undefined
                data['noBookSendAgreement'] =
                  'กสศ. 19/' +
                  noBook1.toString().padStart(5, '0') +
                  '/' +
                  thisYear
                noBook1 = undefined
                data['noBookBankaccount'] =
                  'กสศ. 19/' +
                  noBook2.toString().padStart(5, '0') +
                  '/' +
                  thisYear
                noBook2 = undefined
                this.props.updateProposal(data, data._id)
              } else {
                this.props.genId('noAgreement-' + thisYear)
                this.props.genId('noBookInside-' + thisYear)
                this.props.genId('noBook-' + thisYear)
                this.props.genId('noBook-' + thisYear)
              }
            } else {
              this.props.updateProposal(data, data._id)
            }

            if (!isSaving) {
              this.setState({
                onSaveSuccess: true,
                modalSummary: false,
                openConfirm: false
              })
              setTimeout(() => {
                this.setState({ onSaveSuccess: false })
                if (data['promiseConfirm']) {
                  this.props.history.push(
                    '/project/tabWithdrawForm/' + data._id
                  )
                }
              }, 3000)
            }
            // if (this.props.history) this.props.history.push('/project/TabProgressProject/' + data._id);
          } else {
            this.props.updateProposal(data, data._id)
            this.setState({
              onSaveNotSuccess: true,
              modalSummary: false,
              openConfirm: false
            })
            setTimeout(() => {
              this.setState({ onSaveNotSuccess: false })
            }, 3000)
          }
        }
      }
    }
  }

  handleSelectRow = selectedRow => {
    if (selectedRow === this.state.selectedRow) selectedRow = null
    this.setState({ selectedRow, modalAttachment: false })
  }

  handleEditRow = (idx, e, { name, value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props

    let _withDrawTime = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        currentProposal.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      } else {
        data.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      }
    }

    _withDrawTime[idx][name] = value
    _withDrawTime[idx].sum =
      parseFloat(
        (_withDrawTime[idx].mainCom || '').replace(/,/g, '') || 0,
        10
      ) +
      (parseFloat((_withDrawTime[idx].supCom || '').replace(/,/g, ''), 10) ||
        0) +
      (parseFloat((_withDrawTime[idx].manage || '').replace(/,/g, ''), 10) ||
        0) +
      (parseFloat((_withDrawTime[idx].charge || '').replace(/,/g, ''), 10) ||
        0) || 0

    // validate array
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    let tmp = { ...data, withDrawTime: _withDrawTime }

    this.setState({ data: tmp, validate })
  }

  handleAddRow = () => {
    let { data } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }

    let _withDrawTime = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _withDrawTime = currentProposal.withDrawTime.slice()
      } else {
        _withDrawTime = data.withDrawTime.slice()
      }
    }

    if (_withDrawTime.length === 0) {
      _withDrawTime.push({
        content:
          'กสศ. จ่ายเงินสนับสนุนภายใน 15 วัน เมื่อได้รับสัญญาและเมื่อลงนามในสัญญา',
        mainCom: 0,
        supCom: 0,
        manage: 0,
        charge: 0,
        sum: 0,
        take: '',
        transfer: ''
      })
    } else {
      _withDrawTime.push({
        content:
          'กสศ. จะจ่ายเงินอุดหนุนให้ภายใน 15 วันหลักจากได้รับเอกสารและเห็นชอบกับรายการ ดังนี้',
        mainCom: 0,
        supCom: 0,
        manage: 0,
        charge: 0,
        sum: 0,
        take: '',
        transfer: ''
      })
    }

    let tmp = { ...data, withDrawTime: _withDrawTime }

    this.setState({ data: tmp, selectedRow: _withDrawTime.length - 1 })
  }

  handleDeleteRow = () => {
    let { data, indexDelete, validate } = this.state
    let { currentProposal } = this.props

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    // if (currentProposal) data = { ...currentProposal, ...data }

    let _withDrawTime = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _withDrawTime = currentProposal.withDrawTime.slice()
      } else {
        _withDrawTime = data.withDrawTime.slice()
      }
    }

    _withDrawTime.splice(indexDelete, 1)
    let tmp = { ...data, withDrawTime: _withDrawTime }

    this.setState({ data: tmp, openConfirmDel: false, selectedRow: null })
  }

  handleDayChangeRow = (name, index, date) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    let _withDrawTime = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        currentProposal.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      } else {
        data.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      }
    }

    _withDrawTime[index][name] = date._d.getTime()
    let tmp = { ...data, withDrawTime: _withDrawTime }

    this.setState({ data: tmp, validate })
  }

  handleDayChange = (name, index, date) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    data[name] = date._d.getTime()
    if (data.withDrawTime) {
      let len = data.withDrawTime.length
      if (len > 0) {
        if (!data.withDrawTime[0].startDate) data.withDrawTime[0].startDate = data.startDate
        if (!data.withDrawTime[len - 1].endDate) data.withDrawTime[len - 1].endDate = data.endDate
      }
    }

    let sd = moment(data.startDate).format('YYYY-MM-DD')
    let ed = moment(data.endDate).format('YYYY-MM-DD')

    // var diff = moment.preciseDiff(sd, ed);
    var diff = moment.preciseDiff(sd + ' 00:00:00', ed + ' 24:00:00')

    let diffyth
    let diffmth
    let diffdth

    let cy = diff.indexOf('years')

    if (cy !== -1) {
      diffyth = diff.replace('years', 'ปี')
    } else {
      diffyth = diff.replace('year', 'ปี')
    }

    let cl = diff.indexOf('months')

    if (cl !== -1) {
      diffmth = diffyth.replace('months', 'เดือน')
    } else {
      diffmth = diffyth.replace('month', 'เดือน')
    }

    let cd = diff.indexOf('days')

    if (cd !== -1) {
      diffdth = diffmth.replace('days', 'วัน')
    } else {
      diffdth = diffmth.replace('day', 'วัน')
    }

    data['operationTime'] = diffdth

    let sd_connect = moment(data.startDate_connect).format('YYYY-MM-DD')
    let ed_connect = moment(data.endDate_connect).format('YYYY-MM-DD')
    var diff_connect = moment.preciseDiff(sd_connect + ' 00:00:00', ed_connect + ' 24:00:00')

    let diffyth_connect
    let diffmth_connect
    let diffdth_connect

    let cy_connect = diff_connect.indexOf('years')

    if (cy_connect !== -1) {
      diffyth_connect = diff_connect.replace('years', 'ปี')
    } else {
      diffyth_connect = diff_connect.replace('year', 'ปี')
    }

    let cl_connect = diff_connect.indexOf('months')

    if (cl_connect !== -1) {
      diffmth_connect = diffyth_connect.replace('months', 'เดือน')
    } else {
      diffmth_connect = diffyth_connect.replace('month', 'เดือน')
    }

    let cd_connect = diff_connect.indexOf('days')

    if (cd_connect !== -1) {
      diffdth_connect = diffmth_connect.replace('days', 'วัน')
    } else {
      diffdth_connect = diffmth_connect.replace('day', 'วัน')
    }

    data['operationTime_connect'] = diffdth_connect

    this.setState({ data, validate })
  }

  handleCancel = () => this.setState({ openConfirm: false })
  handleSubmit = condition => {
    let { currentProposal } = this.props
    let { data } = this.state

    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    if (data.promiseConfirm) {
      this.setState({ openConfirm: true })
    } else {
      this.handleConfirm(condition)
    }
  }

  summaryRow = object => {
    let result = 0
    var nf = new Intl.NumberFormat()
    if (object) {
      // result = (parseInt(object.mainCom) || 0) + (parseInt(object.supCom) || 0) +
      //   (parseInt(object.manage) || 0) + (parseInt(object.charge) || 0)
      result =
        (parseFloat((object.mainCom || '').replace(/,/g, ''), 10) || 0) +
        (parseFloat((object.supCom || '').replace(/,/g, ''), 10) || 0) +
        (parseFloat((object.manage || '').replace(/,/g, ''), 10) || 0) +
        (parseFloat((object.charge || '').replace(/,/g, ''), 10) || 0) || 0
    }

    return nf.format(result)
  }

  summary = array => {
    let result = 0
    var nf = new Intl.NumberFormat()
    if (array) {
      array.forEach(r => (result += r.sum))
    }
    return nf.format(result)
  }

  _onDownload = () => {
    this.setState({ modalPrintout: true, modalAttachment: false })
  }

  handleCloseModal = () => {
    this.setState({
      modalSendEmail: false,
      modalAttachment: false,
      modalAddGroupFinance: false,
      modalPrintout: false,
      modalSummary: false,
      openConfirmDel: false,
      selectedRow: false
    })
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  handleConfirmSummary = () => {
    const { checkSummary, data } = this.state
    data['promiseConfirm'] = checkSummary

    this.setState({ data, modalSummary: false })
  }

  handleNextPage = () => {
    // const { currentProposal } = this.props
    this.handleSubmit('next')
    // if (this.props.history)
    //   this.props.history.push(
    //     '/project/TabProgressProject/' + currentProposal._id
    //   )
  }

  handleBackPage = () => {
    // const { currentProposal } = this.props
    this.handleSubmit('back')
    // this.props.history.push('/project/pagescreen/' + currentProposal._id)
  }

  openConfirmDel = (e, { name }) => {
    this.setState({ openConfirmDel: true, indexDelete: name })
  }

  onMoneyChange = (idx, e) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) {
    //   data = { ...currentProposal, ...data }
    // }

    let _withDrawTime = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        currentProposal.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      } else {
        data.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      }
    }

    let money = e.target.rawValue.replace('-', '')

    _withDrawTime[idx][e.target.name] = money ? money.toString() : '0'
    _withDrawTime[idx].sum =
      parseFloat(
        (_withDrawTime[idx].mainCom || '').replace(/,/g, '') || 0,
        10
      ) +
      (parseFloat((_withDrawTime[idx].supCom || '').replace(/,/g, ''), 10) ||
        0) +
      (parseFloat((_withDrawTime[idx].manage || '').replace(/,/g, ''), 10) ||
        0) +
      (parseFloat((_withDrawTime[idx].charge || '').replace(/,/g, ''), 10) ||
        0) || 0

    // validate array
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    let tmp = { ...data, withDrawTime: _withDrawTime }

    this.setState({ data: tmp, validate })
  }

  onMoneyBudgetChange = e => {
    const { data, validate } = this.state
    let money = e.target.rawValue.replace('-', '')

    data[e.target.name] = money ? money.toString() : '0'
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
    this.setState({ data, validate })
  }

  onWithdrawNumberChange = e => {
    const { data, validate } = this.state
    data[e.target.name] = e.target.rawValue
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}
    this.setState({ data, validate })
  }

  handleListOrganization = () => {
    const { currentProposal } = this.props
    this.props.history.push('/project/listorganization/' + currentProposal._id)
  }

  close = () =>
    this.setState({
      openModalRecipient: false,
      openModalPerson: false,
      openModalOrganization: false,
      openConfirm: false,
      selectedRow: null,
      typeProjectModal: false
    })

  handleComponentPerson = () => {
    this.setState({ openModalPerson: true })
  }

  handleComponentRecipient = () => {
    this.setState({ openModalRecipient: true })
  }

  handleComponentOrganization = () => {
    this.setState({ openModalOrganization: true })
  }

  handleAddRowCondition = () => {
    let { data } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }

    let _condition = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _condition = currentProposal.condition.slice()
      } else {
        _condition = data.condition.slice()
      }
    }

    _condition.push('')

    let tmp = { ...data, condition: _condition }
    this.setState({ data: tmp, selectCondition: _condition.length - 1 })
  }

  handleSelectRowCondition = selectCondition => {
    if (selectCondition === this.state.selectCondition) selectCondition = null
    this.setState({ selectCondition, modalAttachment: false })
  }

  handleChangeCondition = (e, { index, value }) => {
    let { data } = this.state
    let { currentProposal } = this.props
    data = { ...currentProposal, ...data }

    let _condition = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _condition = currentProposal.condition.slice()
      } else {
        _condition = data.condition.slice()
      }
    }

    _condition[index] = value

    let tmp = { ...data, condition: _condition }

    this.setState({ data: tmp })
  }

  handleDeleteConditions = (e, { index }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    // if (currentProposal) data = { ...currentProposal, ...data }

    let _conditions = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _conditions = currentProposal.condition.slice()
      } else {
        _conditions = data.condition.slice()
      }
    }

    _conditions.splice(index, 1)
    let tmp = { ...data, condition: _conditions }

    this.setState({
      data: tmp,
      openConfirmDelCon: false,
      selectCondition: null
    })
  }

  handleCloseModalCon = () => {
    this.setState({ openConfirmDelCon: false })
  }

  handleOpenModalCon = () => {
    this.setState({ openConfirmDelCon: true })
  }

  mapArrayBudget = data => {
    let result = []
    if (data.length > 0) {
      result[0] = data[0]
      for (let i = 1; i < data.length; i++) {
        let check = false
        for (let j = 0; j < result.length; j++) {
          if (data[i]._id === result[j]._id) check = true
        }
        if (!check) result.push(data[i])
      }
    }
    return result
  }

  render() {
    const {
      currentProposal,
      isSaving,
      match,
      role,
      individuals_w,
      typeProject,
      individuals,
      user,
      bankgroup,
      individuals_ap,
      organization
    } = this.props

    let {
      data,
      validate,
      selectedRow,
      modalAttachment,
      modalPrintout,
      onSaveSuccess,
      onSaveNotSuccess,
      openModalPerson,
      openModalOrganization,
      selectCondition,
      openConfirmDelCon,
      openModalRecipient
    } = this.state
    let personWitnessOptions1 = []
    let personWitnessOptions2 = []

    let bankBranchOptions = bankgroup
      ? bankgroup.map((item, idx) => ({
        key: idx,
        text: item.Name,
        value: item.BankBranch
      }))
      : []

    bankBranchText = bankgroup
      ? bankgroup.map((item, idx) => ({
        key: idx,
        text: item.Name,
        value: item.BankBranch
      }))
      : []

    let typeProjectOption = typeProject
      ? typeProject.name === 'ประเภทโครงการ'
        ? typeProject.type.map((item, idx) => ({
          key: idx,
          text: item.name,
          value: item.value
        }))
        : []
      : []

    let personOptions2 =
      individuals_w &&
      individuals_w.map((item, idx) => ({
        key: idx,
        text:
          item.title === 'อื่นๆ'
            ? item.otherTitle + item.firstname + ' ' + item.lastname
            : item.title + item.firstname + ' ' + item.lastname,
        value: item._id
      }))

    APOptions =
      individuals_ap &&
      individuals_ap.map((item, idx) => ({
        key: idx,
        text:
          item.title === 'อื่นๆ'
            ? item.otherTitle + item.firstname + ' ' + item.lastname
            : item.title + item.firstname + ' ' + item.lastname,
        value: item._id
      }))

    let filterContract = []

    for (let u in user) {
      user[u].roles &&
        user[u].roles.forEach(itm => {
          if (itm === 'Contract') {
            filterContract.push(user[u])
          }
        })
    }

    let contractOptions =
      filterContract &&
      filterContract.map((item, idx) => ({
        key: idx,
        text:
          item.title === 'อื่นๆ'
            ? item.otherTitle + item.firstname + ' ' + item.lastname
            : item.title + item.firstname + ' ' + item.lastname,
        value: item._id
      }))

    let academic = user && user.filter(item => data.academic_id === item._id)

    let personKssFilterOptions = []
    if (academic[0] && academic[0].typeInstitution && user) {
      user.forEach(item => {
        if (item.typeInstitution !== undefined)
          item.typeInstitution.forEach(itm => {
            // let check = academic[0].typeInstitution && academic[0].typeInstitution.findIndex(i => i === itm)
            let check = item.typeInstitution.findIndex(
              i => i === data.typeOffice
            )
            if (check !== -1) {
              personKssFilterOptions.push(item)
            }
          })
      })
    }

    let personKssOptions =
      personKssFilterOptions &&
      this.mapArrayBudget(personKssFilterOptions)
        // .filter(item =>
        //   data.academic_id !== item._id
        // )
        .map((item, idx) => ({
          key: idx,
          text:
            item.title === 'อื่นๆ'
              ? item.otherTitle + item.firstname + ' ' + item.lastname
              : item.title + item.firstname + ' ' + item.lastname,
          value: item._id
        }))

    personWitnessOptions1 = personKssOptions

    personWitnessOptions2 = personOptions2

    const content = {
      noAgreement: {
        control: Input,
        readOnly: true,
        placeholder: 'กรุณาทำการบันทึกข้อมูลก่อน'
      },
      codeProject: { control: Input, readOnly: true },
      typeProject: { control: Select, options: typeProjectOption, width: 8 },
      witness1: {
        control: Select,
        options: personWitnessOptions1,
        search: true
      },
      witness2: {
        control: Select,
        options: personWitnessOptions2,
        search: true,
        readOnly: true
      },
      witnessOptions1: { control: Select, options: witnessOptions },
      witnessOptions2: { control: Select, options: witnessOptions },
      approveBuget: { control: Input, money: 'money' },
      copyright: { control: Select, options: copyrightOptions, width: 8 },
      // 'typeApprove': { control: Select, options: typeApprove },
      typePay: { control: Select, options: process },
      sendSignedContract: { control: Select, options: sendCon },
      withdrawPayment: { control: Select, options: PaymentOptions, width: 8 },
      withdrawPayChequeBy: { control: Input },
      withdrawBank: { control: Select, options: bankOptions, search: true },
      withdrawBankID: { control: Select, options: bankOptions, search: true },
      withdrawBankBranch: { control: Input },
      withdrawBankBranchID: {
        control: Select,
        options: bankBranchOptions,
        search: true
      },
      withdrawAccountNumber: { control: Input },
      withdrawAccountName: { control: Input },
      withdrawTaxDeduction: { control: Input, width: 8 },
      codeDisburse: { control: Input, width: 8 },
      promiseConfirm: { control: Checkbox },
      askBudget: { control: Input, readOnly: true },
      contractForAdmin: { control: Select, options: contractOptions, width: 8 },
      sendtoERF: { control: Checkbox }
    }

    data = { ...currentProposal, ...data }
    console.log('bankBranchOptions', bankBranchOptions)
    data.authorizePerson &&
      data.authorizePerson.forEach(item => {
        if (item.select && item.value) {
          let index = APOptions.findIndex(i => i.value === item.value)
          if (index === -1) {
            APOptions.push({
              key: APOptions.length,
              value: item.value,
              text: item.name
            })
          }
        }
      })
    if (!currentProposal)
      return (
        <HeaderContent title='ข้อมูลลงนามสัญญา' onUpload={this._onUpload} />
      )
    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('proposal', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].control === Input) {
        content[key].value = data[key] || ''
      } else if (content[key].control === Checkbox) {
        content[key].checked = data[key] || false
      } else if (
        key === 'witness1' ||
        key === 'witness2' ||
        key === 'withdrawBankBranchID'
      ) {
        content[key].onSearchChange = this.handleSearch
        content[key].value = data[key] || ''
      } else {
        content[key].value = data[key] || ''
      }
    })

    let checkLock =
      (currentProposal &&
        currentProposal.status !== 'ทำสัญญาโครงการ' &&
        role !== 'Admin') ||
      (currentProposal &&
        currentProposal.status === 'ทำสัญญาโครงการ' &&
        role !== 'Admin' &&
        role !== 'Contract')

    let len = data.withDrawTime ? data.withDrawTime.length : 0

    if (gettime) {
      if (!data.dateContract) data.dateContract = gettime
      if (!data.dateScreen) data.dateScreen = gettime
    }

    return (
      <div style={{ paddingBottom: 100 }}>
        <HeaderContent title={data.nameTh} subtitle='ข้อมูลลงนามสัญญา' />
        <AttachedComponent
          currentProposal={currentProposal}
          namePage='attachPromise'
          modalAttachment={modalAttachment}
          onHandleUpload={this.handleUploaded}
          onHandleRemove={this.handleAttachedFileRemove}
          options={PromiseOptions}
          onClose={this.handleCloseModal}
          checkStatus={data && !data.promiseConfirm}
        />
        <PrintoutComponent
          modalPrintout={modalPrintout}
          numberOfFile={[
            'เอกสารสัญญา',
            'เอกสารแนบท้ายสัญญาการดำเนินงาน',
            'งวดงานงวดเงิน',
            'หนังสือขอเปิดบัญชีธนาคาร'
          ]}
          typeFile={[4, 5, 6, 7]}
          match={match}
          onClose={this.handleCloseModal}
        />
        <Form error={validate.error}>
          <Message color='yellow'>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              หมายเหตุ*
            </Message.Header>
            <p>
              * ผู้ที่จะทำการกรอกข้อมูลทำสัญญาโครงการ ต้องเป็น User
              ที่เป็นศูนย์สัญญาเท่านั้น
            </p>
            <p>* เลขที่สัญญาจะต้องกดบันทึกก่อน ถึงจะแสดงเลขที่สัญญา</p>
            <p>
              * กรุณาติ๊กถูกช่องยืนยันการทำสัญญา ถ้าต้องการทำการทำสัญญา
              ถ้าไม่ติ๊กถูก ข้อมูลจะยังไม่ทำสัญญา แต่จะบันทึกข้อมูลเท่านั้น
            </p>
          </Message>
          <Segment>
            <Form.Group widths='equal'>
              {this._renderFormField(content.noAgreement)}
              {this._renderFormField(content.codeProject)}
            </Form.Group>
            <Form.Group>
              {/* {this._renderFormField(content.typeProject)} */}
              <Form.Field width={4} disabled={checkLock}>
                <label>{'วันที่ทำสัญญา *'}</label>
                <DayPicker
                  ser={'dateContract'}
                  name={'dateContract'}
                  onDayChange={this.handleDayChange}
                  data={data.dateContract || ''}
                />
              </Form.Field>
              <Form.Field width={4} disabled={checkLock}>
                <label>{'ส่งทบทวนโครงการ *'}</label>
                <DayPicker
                  ser={'dateScreen'}
                  name={'dateScreen'}
                  onDayChange={this.handleDayChange}
                  data={data.dateScreen || ''}
                />
              </Form.Field>
            </Form.Group>
          </Segment>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='ข้อมูลสัญญา'
              as='h4'
              icon='book'
            />
            {data.typeProject === 5 && data.seqConnect === '1' &&
              (<Form.Group>
                <Form.Field>
                  <label>{'วันที่เริ่มต้น (ทุนต่อเนื่อง)'}</label>
                  <DayPicker
                    ser={'startDate_connect'}
                    name={'startDate_connect'}
                    max={data.endDate_connect}
                    onDayChange={this.handleDayChange}
                    data={data.startDate_connect || ''}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{'วันที่สิ้นสุด (ทุนต่อเนื่อง)'}</label>
                  <DayPicker
                    ser={'endDate_connect'}
                    name={'endDate_connect'}
                    min={data.startDate_connect}
                    onDayChange={this.handleDayChange}
                    data={data.endDate_connect || ''}
                  />
                </Form.Field>
                <Header style={{ fontFamily: 'supermarket', marginLeft: 20 }}>
                  ระยะเวลาดำเนินการ (ทุนต่อเนื่อง) :{' '}
                  {data['operationTime_connect']
                    ? data['operationTime_connect']
                    : 'ยังไม่ได้ระบุเวลาเริ่มต้น/สิ้นสุด'}
                </Header>
              </Form.Group>)}
            {data.typeProject === 5 && data.seqConnect === '1' &&
              (<Form.Group>
                <Form.Field error={validate.attrs['askBudget_connect']} width={8}>
                  <label>{'งบประมาณ (ทุนต่อเนื่อง)'}</label>
                  <Cleave
                    name='askBudget_connect'
                    maxLength='16'
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand'
                    }}
                    onChange={this.onMoneyChange.bind(this)}
                    value={data.askBudget_connect || ''}
                  />
                </Form.Field>
              </Form.Group>)}
            <Form.Group>
              <Form.Field width={4} disabled={checkLock}>
                <label>{'วันเริ่มต้นโครงการ *'}</label>
                <DayPicker
                  ser={'startDate'}
                  name={'startDate'}
                  max={data.withDrawTime && data.withDrawTime[0].endDate}
                  onDayChange={this.handleDayChange}
                  data={data.startDate || ''}
                />
              </Form.Field>
              <Form.Field width={4} disabled={checkLock}>
                <label>{'วันเสร็จสิ้นโครงการ *'}</label>
                <DayPicker
                  ser={'endDate'}
                  name={'endDate'}
                  min={data.withDrawTime && data.withDrawTime.length > 1 && data.withDrawTime[len - 1].startDate}
                  onDayChange={this.handleDayChange}
                  data={data.endDate || ''}
                />
              </Form.Field>
              <Header style={{ fontFamily: 'supermarket', marginLeft: 20 }}>
                ระยะเวลาดำเนินการ :{' '}
                {data['operationTime']
                  ? data['operationTime']
                  : 'ยังไม่ได้ระบุเวลาเริ่มต้น/สิ้นสุด'}
              </Header>
              {/* {this._renderFormField(content.copyright)} */}
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>{'งบประมาณที่ส่งข้อเสนอ'}</label>
                <Cleave
                  name='askBudget'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  value={
                    parseFloat(data.askBudget) +
                    parseFloat(data.otherBudget ? data.otherBudget : '0') ||
                    '0'
                  }
                  disabled
                />
              </Form.Field>
              <Form.Field>
                <label>{'งบประมาณที่ได้รับ'}</label>
                <Cleave
                  name='budget'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyBudgetChange.bind(this)}
                  value={data.budget || ''}
                  disabled={checkLock}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.typePay)}
              {this._renderFormField(content.sendSignedContract)}
            </Form.Group>
            <Form.Group>
              {this._renderFormField(content.copyright)}
              <Form.Field>
                <Header
                  style={{ fontFamily: 'supermarket' }}
                  content={
                    ' เลขประจำตัวผู้เสียภาษีอากร : ' +
                    (data.recipientType === 'องค์กร' || data.recipientType === 'คณะบุคคล'
                      ? organization && (organization.cid || 'ยังไม่ได้ระบุ')
                      : individuals[0] &&
                      (individuals[0].cid || 'ยังไม่ได้ระบุ'))
                  }
                  as='h4'
                />
                <Button
                  content='ข้อมูลผู้เสียภาษี'
                  color='blue'
                  disabled={checkLock}
                  onClick={this.handleComponentRecipient}
                />
              </Form.Field>
            </Form.Group>
          </Segment>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content=' พยานการทำสัญญา'
              icon='tags'
              as='h5'
            />
            <Form.Group widths='equal'>
              {this._renderFormField(content.witness1)}
              {this._renderFormField(content.witness2)}
            </Form.Group>
            <Button
              disabled={checkLock}
              content='เพิ่มบุคคล'
              icon='plus'
              color='green'
              onClick={this.handleComponentPerson}
            />
            <Button
              disabled={checkLock}
              content='เพิ่มองค์กร'
              icon='plus'
              color='green'
              onClick={this.handleComponentOrganization}
            />
            <Button
              type='button'
              color='green'
              content='รายชื่อองค์กร'
              icon='list'
              disabled={checkLock}
              onClick={this.handleListOrganization}
            />
          </Segment>
          <Header
            style={{ fontFamily: 'supermarket' }}
            content='กำหนดเวลาส่งผลงานและการเบิกจ่ายงวดเงิน'
            as='h5'
            icon='money'
          />
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content='งวดที่' textAlign='center' />
                {/* <Table.HeaderCell content='วันที่นำส่งผลงาน' textAlign='center' /> */}
                <Table.HeaderCell
                  content='ผลงานที่นำส่งกสศ. และเงื่อนไข *'
                  textAlign='center'
                />
                <Table.HeaderCell
                  content='ภายในวงเงิน'
                  textAlign='center'
                  width={2}
                />
                <Table.HeaderCell
                  content='กำหนดส่งงาน'
                  textAlign='center'
                  width={2}
                />
                <Table.HeaderCell content='ลบ' textAlign='center' width={2} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.withDrawTime &&
                data.withDrawTime.map((item, idx) => (
                  <Fragment key={idx}>
                    <Table.Row
                      onClick={this.handleSelectRow.bind(this, idx)}
                      error={validate.attrs['withDrawTime' + idx]}
                    >
                      {(selectedRow === idx && (
                        <Table.Cell>
                          <Label
                            color='green'
                            ribbon
                            content={'งวดที่ ' + (idx + 1)}
                          />
                        </Table.Cell>
                      )) || (
                          <Table.Cell textAlign='center'>
                            {'งวดที่ ' + (idx + 1)}
                            <br />
                            {(ThaiDate('month', item.startDate) ||
                              'ยังไม่กำหนด') +
                              ' - ' +
                              (ThaiDate('month', item.endDate) || 'ยังไม่กำหนด')}
                          </Table.Cell>
                        )}
                      {/* <Table.Cell textAlign='center' content={ThaiDate('month', item.submitTime) || 'ยังไม่ได้กำหนด'} /> */}
                      {/* content={(ThaiDate('month', item.startDate) || 'ยังไม่กำหนด') + ' - ' + (ThaiDate('month', item.endDate) || 'ยังไม่กำหนด')} /> */}
                      <Table.Cell textAlign='center' content={item.content} />
                      <Table.Cell
                        textAlign='center'
                        content={this.summaryRow(item)}
                      />
                      <Table.Cell
                        textAlign='center'
                        content={
                          ThaiDate('month', item.submitTime) || 'ยังไม่กำหนด'
                        }
                      />
                      <Table.Cell textAlign='center'>
                        <Button
                          disabled={checkLock}
                          type='button'
                          name={idx}
                          color='red'
                          icon='close'
                          onClick={this.openConfirmDel}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {selectedRow === idx && (
                      <Table.Row active>
                        <Table.Cell colSpan={6}>
                          <Form.TextArea
                            label='ผลงานที่นำส่ง กสศ. และเงื่อนไข'
                            name='content'
                            value={item.content}
                            onChange={this.handleEditRow.bind(this, idx)}
                          />
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <label>{'ค่าตอบแทนบุคลากรหลัก'}</label>
                              <Cleave
                                name='mainCom'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.mainCom || ''}
                                readOnly={checkLock}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>{'ค่าตอบแทนบุคลากรสนับสนุน'}</label>
                              <Cleave
                                name='supCom'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.supCom || ''}
                                readOnly={checkLock}
                              />
                            </Form.Field>
                          </Form.Group>
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <label>{'ค่าดำเนินงาน'}</label>
                              <Cleave
                                name='manage'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.manage || ''}
                                readOnly={checkLock}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>{'ค่าธรรมเนียม'}</label>
                              <Cleave
                                name='charge'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.charge || ''}
                                readOnly={checkLock}
                              />
                            </Form.Field>
                          </Form.Group>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width={8}>
                                <Form.Group>
                                  <Form.Field disabled={checkLock}>
                                    <label>{'ตั้งแต่วันที่'}</label>
                                    <DayPicker
                                      ser={'startDate'}
                                      name={'startDate'}
                                      // min={data.withDrawTime[idx - 1] && data.withDrawTime[idx - 1].endDate}
                                      // max={item.endDate || data.endDate}
                                      min={dataStart}
                                      max={item.endDate || dataEnd}
                                      index={idx}
                                      onDayChange={this.handleDayChangeRow}
                                      data={item.startDate || ''}
                                    />
                                  </Form.Field>
                                  <Form.Field disabled={checkLock}>
                                    <label>{'ถึงวันที่'}</label>
                                    <DayPicker
                                      ser={'endDate'}
                                      name={'endDate'}
                                      // min={item.startDate || (data.withDrawTime[idx - 1] && data.withDrawTime[idx - 1].endDate) || item.startDate || data.startDate}
                                      // max={data.endDate}
                                      min={item.startDate || dataStart}
                                      max={dataEnd}
                                      index={idx}
                                      onDayChange={this.handleDayChangeRow}
                                      data={item.endDate || ''}
                                    />
                                  </Form.Field>
                                </Form.Group>
                              </Grid.Column>
                              <Grid.Column width={8}>
                                <Form.Field
                                  width={8}
                                  disabled={!item.startDate || !item.endDate}
                                >
                                  <label>{'วันที่นำส่งผลงาน'}</label>
                                  <DayPicker
                                    ser={'submitTime'}
                                    name={'submitTime'}
                                    min={dataStart}
                                    max={dataEnd}
                                    index={idx}
                                    onDayChange={this.handleDayChangeRow}
                                    data={item.submitTime || ''}
                                  />
                                </Form.Field>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Fragment>
                ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan='2'>
                  {
                    <Button
                      type='button'
                      color='green'
                      content='เพิ่ม'
                      onClick={this.handleAddRow}
                      disabled={checkLock}
                    />
                  }
                </Table.HeaderCell>
                <Table.HeaderCell colSpan='5' textAlign='center'>
                  {'รวมเป็นเงินทั้งสิ้น(ปิดโครงการ) : ' +
                    this.summary(data.withDrawTime) +
                    ' บาท'}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='บัญชีธนาคาร'
              as='h5'
              icon='clipboard'
            />
            <Form.Group widths='equal'>
              {this._renderFormField(content.withdrawBankID)}
              {this._renderFormField(content.withdrawBankBranchID)}
            </Form.Group>
            <Form.Group widths='equal'>
              {this._renderFormField(content.withdrawAccountName)}
              <Form.Field
                error={
                  validate.validateError &&
                  validate.validateError.withdrawAccountNumber &&
                  validate.validateError.withdrawAccountNumber.length > 0
                }
              >
                <label>{'เลขบัญชี *'}</label>
                <Cleave
                  name='withdrawAccountNumber'
                  maxLength={15}
                  // options={{
                  //   delimiter: '-',
                  //   blocks: [3, 1, 5, 1],
                  //   uppercase: true
                  // }}
                  onChange={this.onWithdrawNumberChange.bind(this)}
                  value={data.withdrawAccountNumber || ''}
                  readOnly={checkLock}
                />
              </Form.Field>
              {/* {this._renderFormField(content.withdrawAccountNumber)} */}
            </Form.Group>
            {this._renderFormField(content.withdrawTaxDeduction)}

            <Header
              style={{ fontFamily: 'supermarket' }}
              as='h5'
              content='ผู้มีแำนาจลงนามสั่งจ่าย'
            />
            {data.authorizePerson &&
              data.authorizePerson.map((item, idx) => (
                <Fragment key={idx}>
                  <Form.Group>
                    {item.select === false && (
                      <Form.Input
                        width={6}
                        onChange={this.handleAPEditRow.bind(this, idx)}
                        value={item.name}
                      />
                    )}
                    {item.select === false && (
                      <Form.Button
                        content='ค้นหา'
                        name=''
                        onClick={this.handleSelectRowAP.bind(this, idx, true)}
                      />
                    )}
                    {item.select === true && (
                      <Form.Select
                        search={true}
                        width={6}
                        options={APOptions}
                        onChange={this.handleAPchenge.bind(this, idx)}
                        onSearchChange={this.handleAPSearch.bind(this, idx)}
                        value={item.value}
                      />
                    )}
                    {item.select === true && (
                      <Form.Button
                        content='ระบุเอง'
                        name=''
                        onClick={this.handleSelectRowAP.bind(this, idx, false)}
                      />
                    )}
                  </Form.Group>
                </Fragment>
              ))}
          </Segment>

          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='เงื่อนไขและแนวทางอื่นๆ'
              as='h5'
              icon='comment alternate outline'
            />

            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    content='ลำดับ'
                    width={1}
                    textAlign='center'
                  />
                  <Table.HeaderCell
                    content='เงื่อนไขและแนวทาง'
                    width={13}
                    textAlign='center'
                  />
                  <Table.HeaderCell content='ลบ' width={2} textAlign='center' />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.condition &&
                  data.condition.map((item, idx) => (
                    <Fragment key={idx}>
                      <Table.Row
                        onClick={this.handleSelectRowCondition.bind(this, idx)}
                      >
                        {(selectCondition === idx && (
                          <Table.Cell>
                            <Label
                              color='green'
                              ribbon
                              content={'ลำดับที่  ' + (idx + 1)}
                            />
                          </Table.Cell>
                        )) || (
                            <Table.Cell
                              content={idx + 1}
                              textAlign='center'
                              width={1}
                            />
                          )}
                        <Table.Cell content={item} />
                        <Table.Cell textAlign='center'>
                          {' '}
                          <Button
                            color='red'
                            icon='close'
                            index={idx}
                            onClick={this.handleOpenModalCon}
                            disabled={checkLock}
                          />
                        </Table.Cell>
                      </Table.Row>
                      {selectCondition === idx && (
                        <Table.Row active>
                          <Table.Cell colSpan={3}>
                            <Form.TextArea
                              readOnly={checkLock}
                              label='เงื่อนไขและแนวทาง'
                              index={idx}
                              onChange={this.handleChangeCondition}
                              value={item}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  ))}
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan='3'>
                    {
                      <Button
                        type='button'
                        color='green'
                        content='เพิ่ม'
                        onClick={this.handleAddRowCondition}
                        disabled={checkLock}
                      />
                    }
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
          {role === 'Admin' && (
            <Segment>
              <Form.Group widths='equal'>
                {this._renderFormField(content.contractForAdmin)}
              </Form.Group>
            </Segment>
          )}
          <Form.Group>
            {this._renderFormField(content.sendtoERF)}
            {this._renderFormField(content.promiseConfirm)}
          </Form.Group>
          {/* <Form.Group>
            <Form.Field>
              <label style={{ fontSize: 15, color: 'red' }}>*หมายเหตุ </label>
              <label style={{ fontSize: 15, color: 'red' }}> 1. กรุณาติ๊กถูกช่องยืนยันการทำสัญญา ถ้าต้องการทำการทำสัญญา ถ้าไม่ติ๊กถูก ข้อมูลจะยังไม่ทำสัญญา แต่จะบันทึกข้อมูลเท่านั้น </label>
            </Form.Field>
          </Form.Group> */}
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          <Transition
            visible={onSaveSuccess}
            animation='horizontal flip'
            duration={500}
          >
            <Message positive>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                <Icon name='check circle'></Icon>บันทึกสำเร็จ
              </Message.Header>
            </Message>
          </Transition>
          <Transition
            visible={onSaveNotSuccess}
            animation='horizontal flip'
            duration={500}
          >
            <Message negative>
              <Message.Header style={{ fontFamily: 'supermarket' }}>
                <Icon name='check circle'></Icon>
                จำนวนเงินรวมทั้งหมดต้องเท่ากับงบประมาณที่อนุมัติ
              </Message.Header>
            </Message>
          </Transition>
          <Button
            color='blue'
            content='บันทึก'
            icon='save'
            type='submit'
            disabled={checkLock}
            loading={isSaving}
            onClick={this.handleSubmit}
          />
          {/* <Button
            className='button-back-bottom-right'
            type='button'
            color='blue'
            content='ย้อนกลับ'
            icon='step backward'
            disabled={validate.error}
            onClick={this.handleBackPage}
          /> */}
          <Button
            className='button-bottom-right'
            type='button'
            color='blue'
            content='ถัดไป'
            icon='step forward'
            disabled={checkLock}
            // disabled={validate.error}
            onClick={this.handleNextPage}
          />
        </Form>
        <Confirm
          open={this.state.openConfirm}
          header='คำเตือน'
          content='เมื่อกดยืนยันแล้ว โครงการนี้จะมีสถานะเป็นอนุมัติ จะไม่สามารถกลับไปแก้ไขข้อมูลก่อนทำสัญญาได้'
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />
        <Modal
          open={openModalPerson}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon
        >
          {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
          <Modal.Content>
            <ComponentPerson
              openModalPerson={openModalPerson}
              onCloseModalPerson={this.close}
            />
          </Modal.Content>
        </Modal>
        <Modal
          open={openModalRecipient}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon
        >
          {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
          <Modal.Content>
            {data.recipientType === 'บุคคล' && (
              <ComponentPerson
                openModalPerson={false}
                onCloseModalPerson={this.close}
                data_id={data.recipientPerson}
                savedData={this.editRecipient}
              />
            )}
            {(data.recipientType === 'องค์กร' || data.recipientType === 'คณะบุคคล') && (
              <ComponentOrganization
                checkOrganization={true}
                onCloseModalPerson={this.close}
              />
            )}
          </Modal.Content>
        </Modal>
        <Modal
          open={openModalOrganization}
          closeOnDimmerClick={false}
          onClose={this.close}
          closeIcon
        >
          {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
          <Modal.Content>
            <ComponentOrganization
              checkOrganization={false}
              openModalOrganization={openModalOrganization}
              onCloseModalPerson={this.close}
            />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.modalSummary}
          onClose={this.handleCloseModal}
          size='small'
          closeIcon
        >
          <Modal.Header style={{ fontFamily: 'supermarket' }}>
            ยืนยันข้อมูลการทำสัญญาโครงการ
          </Modal.Header>
          <Modal.Content>
            <Header style={{ fontFamily: 'supermarket' }}>ข้อมูลพื้นฐาน</Header>
            <p>ชื่อโครงการ : {currentProposal.nameTh}</p>
            <p>เลขที่โครงการ : {currentProposal.codeProject}</p>
            <p>กลุ่มแผนงาน : {currentProposal.groupsPlan}</p>
            <p>แผนงาน : {currentProposal.plan}</p>
            <p>กิจกรรม : {currentProposal.activity}</p>
            <p>
              งบประมาณที่เสนอ : {formatcomma(currentProposal.askBudget)} บาท ||
              งบประมาณอื่น : {formatcomma(currentProposal.otherBudget)} บาท
            </p>
            <Header style={{ fontFamily: 'supermarket' }}>สัญญา</Header>
            <p>เลขที่สัญญา : {data.noAgreement}</p>
            <p>ประเภทโครงการ : {data.typeProject}</p>
            <p>
              วันที่ทำสัญญา :{' '}
              {new Date(data.dateContract).toLocaleDateString() || 'ไม่ได้ระบุ'}
            </p>
            <p>งบประมาณที่ได้รับ : {formatcomma(data.budget)} บาท</p>
            <p>ประเภทการจ่ายค่าดำเนินการ : {data.typePay}</p>
            <p>การจัดส่งสัญญาให้คู่สัญญาลงนาม : {data.sendSignedContract}</p>
            <p>
              พยาน : {data.witness1Name} || พยาน : {data.witness2Name}
            </p>
            {data &&
              data.withDrawTime &&
              data.withDrawTime.map((item, index) => (
                <p key={index}>
                  งวดที่ {index + 1} : วันที่{' '}
                  {ThaiDate('month', item.startDate) || 'ยังไม่กำหนด'} ถึงวันที่{' '}
                  {ThaiDate('month', item.endDate) || 'ยังไม่กำหนด'} ||
                  ภายในวงเงิน {this.summaryRow(item)} บาท
                </p>
              ))}
            <p>รวมเป็นเงินทั้งสิ้น {this.summary(data.withDrawTime)} บาท</p>
            <Header style={{ fontFamily: 'supermarket' }}>ไฟล์แนบ</Header>
            {currentProposal && !currentProposal.attachPromise && (
              <p>ยังไม่มีไฟล์แนบ</p>
            )}
            {currentProposal &&
              currentProposal.attachPromise &&
              currentProposal.attachPromise.map((item, index) => (
                <p key={index}>
                  ไฟล์แนบที่{index + 1} : {item.fileName}
                </p>
              ))}

            <Header style={{ fontFamily: 'supermarket' }}>
              ผู้รับผิดชอบโครงการ
            </Header>
            <p>ผู้รับผิดชอบโครงการ : {currentProposal.managerName}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.handleConfirmSummary}>
              ยืนยัน
            </Button>
          </Modal.Actions>
        </Modal>
        <Confirm
          open={this.state.openConfirmDel}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.handleCloseModal}
          onConfirm={this.handleDeleteRow}
        />

        <Confirm
          open={openConfirmDelCon}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.handleCloseModalCon}
          onConfirm={this.handleDeleteConditions}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  isSaving: state.projects.isSaving,
  auth: state.auth,
  role: state.role,
  user: state.user.data,
  currentPerson: state.individuals.data,
  counter: state.counter,
  individuals: state.individuals.data,
  individuals_w: state.individuals_w.data,
  individuals_ap: state.individuals_ap.data,
  typeProject: state.typeproject.currentData,
  bankgroup: state.bankgroup.data,
  organization: state.organizations.currentData
})

const mapDispatchToProps = dispatch => ({
  listProject: () => dispatch(torActions.fetchList()),
  listUser: () => dispatch(userActions.fetchList()),
  addProposal: data => dispatch(projectActions.save(data)),
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id)),
  getPerson: query => dispatch(individualsActions.fetchList({ query: query })),
  getPerson_w: query =>
    dispatch(individuals_wActions.fetchList({ query: query, limit: 30 })),
  getPerson_ap: query =>
    dispatch(individuals_apActions.fetchList({ query: query, limit: 30 })),
  genId: name => dispatch(generate(name)),
  getTypeProject: id => dispatch(typeProject.fetchOne(id)),
  getBankBranch: query =>
    dispatch(bankgroupActions.fetchList({ query: query, limit: 30 })),
  getOrganization: id => dispatch(organizationsActions.fetchOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(PagePromise)

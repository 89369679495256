import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Header, Segment } from 'semantic-ui-react'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { ThaiDate } from 'Utils'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

class TableConcludeMoney extends Component {
  constructor (props) {
    super(props)
    this.state = {
      next: 0,
      query: {
        sort: { noAgreement: 1 },
        query: { delete: { $ne: true } },
        projection: {
          typeOffice: 1,
          dateContract: 1,
          withdrawForm: 1
        }
      },
      length: 0,
      nameTh: '',
      recipientOrganizeName: '',
      recipientPersonName: '',
      managerName: '',
      startDate: 0,
      endDate: 0,
      academic: ''
    }
  }

  componentDidMount = () => {
    let { query } = this.state
    this.props.listProposal(query)
    this.props.listBudget()
    this.props.getOffice()
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.countProjects) {
      this.setState({ length: nextProps.countProjects / 200 })
    }
  }

  format (input) {
    if (!input && input === 0) {
      input = ''
    }
    var num = input.replace(/,/g, '')
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.')
        num[0] = num[0]
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1)
        }
        input = num[0] + '.' + num[1]
        return input
      } else {
        input = num
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        return input
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1)
    }
  }

  filterOffice = code => {
    const { typeOffice } = this.props
    let typeOfficeOption = typeOffice[0]
      ? typeOffice[0].typeOffice.map((item, idx) => ({
          key: idx,
          text: item.name,
          value: item.value
        }))
      : []

    let text = typeOfficeOption.filter(x => x.value === code)
    if (text.length > 0) return text[0].text
    else return ''
  }

  render () {
    // let { projectsIn, fundings, typeOffice } = this.props
    let { projectsIn } = this.props
    const {
      next,
      length,
    } = this.state
    let footer = []
    for (let i = 0; i < length; i++) {
      footer.push({})
    }

    projectsIn &&
      projectsIn.forEach((item, idx) => {
        let text = ''
        if (item.screen && item.screen.length > 0) {
          item.screen.forEach((i, index) => {
            if (i.nameProfessional && i.nameProfessional !== '') {
              if (index !== item.screen.length - 1)
                text += (index+1) + '. ' + i.nameProfessional + ',\r\n'
              else text += (index+1) + '. ' + i.nameProfessional
            }
          })
        }
        item.screentext = text

        if (item.status === 'ประเมินความก้าวหน้าโครงการ') {
          item.status = item.status + ' - ยังไม่ได้เบิก'
          item.withdrawForm.forEach((row, index) => {
            if (row.noBookTakeMoney) {
              item.status =
                'ประเมินความก้าวหน้าโครงการ - เบิกงวด ' + (index + 1)
            }
          })
        }
      })

    return (
      <Segment>
        <Header
          as='h1'
          style={{ fontFamily: 'supermarket' }}
          content='รายละเอียดโครงการ'
          textAlign='center'
        />

        <Form>
          <ReactHTMLTableToExcel
            id='test-table-xls-button'
            className='download-table-xls-button'
            table='table-to-xls'
            filename='reportPMStablexls'
            sheet='tablexls'
            buttonText='Download as XLS'
          />
          <Segment
            style={{ overflow: 'auto scroll', width: '100%', height: '500px' }}
          >
            <pre>
              <table id='table-to-xls' border='1px black'>
                <thead>
                  <tr>
                    <th>ลำดับ</th>
                    <th>รหัสโครงการ</th>
                    <th>เลขที่สัญญา</th>
                    <th>ชื่อโครงการ</th>
                    <th>สถานะโครงการ</th>
                    <th>สำนัก/สังกัด</th>
                    <th>ภาคีร่วมดำเนินงาน/บุคคลรับทุน</th>
                    <th>ผู้รับผิดชอบโครงการ</th>
                    <th>นักวิชาการ</th>
                    <th>งบประมาณ (บาท)</th>
                    <th>รหัสงบประมาณ</th>
                    <th>ผู้กลั่นกรองโครงการ</th>
                    <th>วันที่ทำสัญญาโครงการ</th>
                    <th>วันที่เริ่มต้นโครงการ</th>
                    <th>วันที่สิ้นสุดโครงการ</th>
                    <th>วันที่สร้างโครงการเข้าระบบ</th>
                  </tr>
                </thead>
                <tbody>
                  {projectsIn &&
                    projectsIn.map((item, idx) => (
                      // <Fragment key={idx}>
                      <tr key={idx}>
                        <td>{next * 200 + idx + 1}</td>
                        <td>{item.codeProject}</td>
                        <td>{item.noAgreement}</td>
                        <td>{item.nameTh}</td>
                        <td>{item.status}</td>
                        <td>{this.filterOffice(item.typeOffice)}</td>
                        {item.recipientType === 'องค์กร' && (
                          <td>{item.recipientOrganizeName}</td>
                        )}
                        {item.recipientType === 'บุคคล' && (
                          <td>{item.recipientPersonName}</td>
                        )}
                        <td>{item.managerName}</td>
                        <td>{item.academic}</td>
                        <td align='right'>
                          {this.format((item.budget || 0).toString())}
                        </td>
                        <td>{item.codeBudget}</td>
                        <td>{item.screentext}</td>
                        <td>{ThaiDate('month', item.dateContract)}</td>
                        <td>{ThaiDate('month', item.startDate)}</td>
                        <td>{ThaiDate('month', item.endDate)}</td>
                        <td>{ThaiDate('month', item.recDate)}</td>
                      </tr>
                      // </Fragment>
                    ))}
                </tbody>
              </table>
            </pre>
          </Segment>
          {/* <Menu pagination>
            <Menu.Item as='a' content='<' onClick={this.handleSkipBack} />
            {footer.map((item, idx) => (
              <Fragment key={idx}>
                <Menu.Item
                  as='a'
                  content={idx + 1}
                  active={idx === next}
                  name={idx.toString()}
                  onClick={this.handleSkipSelect}
                />
              </Fragment>
            ))}
            <Menu.Item as='a' content='>' onClick={this.handleSkipNext} />
          </Menu> */}
        </Form>
      </Segment>
    )
  }
}
const mapStateToProps = state => ({
  projectsIn: state.projects.data,
  countProjects: state.countproject.data,
  auth: state.auth,
  role: state.role,
  fundings: state.fundings.data,
  typeOffice: state.typeproject.data
})

const mapDispatchToProps = dispatch => ({
  listProposal: query => dispatch(projectActions.fetchList(query)),
  listBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: 1 } })),
  getOffice: () =>
    dispatch(typeProject.fetchList({ query: { name: 'สังกัดสำนัก' } }))
})

export default connect(mapStateToProps, mapDispatchToProps)(TableConcludeMoney)

import React, { Component, Fragment } from 'react';
import {
  Button,
  Form,
  Table,
  Message,
  Select,
  Label,
  Input,
  TextArea,
  Confirm,
  Header
} from 'semantic-ui-react'
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react';
import { GetServerTime as GetTime } from 'Utils'
import { constraints as TorConstraints } from './TORConstraints'
import { constraints as TorArrayConstraints } from './TORConstraints'
import { constraints as TorArrayConstraintsP } from './TORConstraints'
import { actions as userActions } from 'Redux/reducers/user'
import { localTh } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'
import { connect } from 'react-redux'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as alltorActions } from 'Redux/reducers/alltors'

import { ValidateArray as validateArray } from 'Utils'
import { generate } from 'Redux/reducers/counting'
import { formatcomma } from 'Utils'

import jwt from 'jsonwebtoken'

var gettime, noTOR, countNo, thisYear = ''
let checkNo = true
let checkProps = false

const statusProposal = [
  { key: '0', value: 'อนุมัติแล้ว', text: 'อนุมัติแล้ว' },
  { key: '1', value: 'ยังไม่อนุมัติ', text: 'ยังไม่อนุมัติ' },
]

let time = []
for (let i = 0; i < 60; i++) {
  time.push({ key: i.toString(), value: i + 1, text: (i + 1).toString() + ' เดือน' })
}

class ComponentAddTOR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      data: {},
      validate: { error: false, validateError: null, attrs: {} },
      // countNo: '',
      // checkNo: true,
      // checkProps: false,
    };
  }

  format(input) {
    if (!input) {
      input = ''
    }
    var num = input.replace(/,/g, '');
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.');
        num[0] = num[0].toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '');
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1);
        }
        input = num[0] + '.' + num[1];
        return (input)
      } else {
        input = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1,').split('').reverse().join('').replace(/^[,]/, '')
        return (input)
      }
    } else {
      input = input.substring(0, input.length - 1);
    }
  }

  getEndTime = (start, endTime) => {
    var day = ((new Date(start)).getDate());
    var month = ((new Date(start)).getMonth() + endTime);
    var year = ((new Date(start)).getFullYear());
    var date = new Date(year, month, day).getTime();
    return date
  }

  componentDidMount = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
          // thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
          thisYear = new Date((new Date(value)).getFullYear() + 543).getTime()
        // }
      }
    });

    this.props.listAllTor()
    this.props.listUser()

    if (this.props.counter.counter['tor' + thisYear]) {
      checkNo = false
      countNo = this.props.counter.counter['tor' + thisYear]
      checkProps = false
    } else {
      checkNo = true
      countNo = undefined
      checkProps = false
    }
  }

  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
      }
    })
  }

  componentWillReceiveProps = (nextProps) => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime((receive) => {
        gettime = receive
        resolve(gettime)
      })
    });

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
          thisYear = new Date((new Date(value)).getFullYear() + 543).getTime()
        // }
      }
    });

    // if(!gettime && gettime === '' && !thisYear && thisYear === ''&& gettime === undefined && thisYear === undefined) this.getTimeFuction()

    if (checkNo) {
      countNo = nextProps.counter.counter['tor' + thisYear]
      if (countNo !== undefined && countNo !== '') {
        checkProps = true
        noTOR = thisYear + '-T-' + nextProps.counter.counter['tor' + thisYear].toString().padStart(5, '0')
        this.handleUpdate()
      }
    } else {
      let emty = nextProps.counter.counter['tor' + thisYear]
      if (emty !== undefined) {
        if (countNo !== nextProps.counter.counter['tor' + thisYear]) {
          countNo = nextProps.counter.counter['tor' + thisYear]
          checkProps = true
          noTOR = thisYear + '-T-' + nextProps.counter.counter['tor' + thisYear].toString().padStart(5, '0')
          this.handleUpdate()

        }
      }
    }
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state;
    let { users } = this.props;
    if (name === 'academic') {
      data[name] = value;
      users.data.map(r => {
        if (value === r._id) data['academicName'] = r.title + r.firstname + ' ' + r.lastname
        return r
      })
    } else {
      data[name] = value;
    }

    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false

    this.setState({ data, validate });
  }

  handleAddRow = (e, { name }) => {
    let { data } = this.state;
    let indexT, indexP, indexR, indexO, indexS = null

    if (name === 'target') {
      if (!data.targets) data['targets'] = [];
      data.targets.push({ targetGroup: '', detail: ''});
      indexT = data.targets.length - 1
    } else if (name === 'product') {
      if (!data.product) data['product'] = [];
      data.product.push({ product: '' });
      indexP = data.product.length - 1
    } else if (name === 'result') {
      if (!data.result) data['result'] = [];
      data.result.push({ result: '' });
      indexR = data.result.length - 1
    } else if (name === 'objective') {
      if (!data.objective) data['objective'] = [];
      data.objective.push({ objective: '' });
      indexO = data.objective.length - 1
    } else if (name === 'scope') {
      if (!data.scope) data['scope'] = [];
      data.scope.push({ scope: '' });
      indexS = data.scope.length - 1
    }

    this.setState({
      data,
      selectedTargetRow: indexT,
      selectedProductRow: indexP,
      selectedResultRow: indexR,
      selectedObjectiveRow: indexO,
      selectedScopeRow: indexS,
    })
  }

  handleSelectRow = (selectedRow, name) => {
    let { selectedTargetRow,
      selectedProductRow,
      selectedResultRow,
      selectedObjectiveRow,
      selectedScopeRow } = this.state

    if (name === 'target') {
      if (selectedRow === this.state.selectedTargetRow) selectedTargetRow = null;
      else selectedTargetRow = selectedRow
      selectedProductRow = null;
      selectedResultRow = null;
      selectedObjectiveRow = null;
    }
    if (name === 'product') {
      if (selectedRow === this.state.selectedProductRow) selectedProductRow = null;
      else selectedProductRow = selectedRow
      selectedTargetRow = null;
      selectedResultRow = null;
      selectedObjectiveRow = null;
      selectedScopeRow = null;
    }
    if (name === 'result') {
      if (selectedRow === this.state.selectedResultRow) selectedResultRow = null;
      else selectedResultRow = selectedRow
      selectedTargetRow = null;
      selectedProductRow = null;
      selectedObjectiveRow = null;
      selectedScopeRow = null;
    }
    if (name === 'objective') {
      if (selectedRow === this.state.selectedObjectiveRow) selectedObjectiveRow = null;
      else selectedObjectiveRow = selectedRow
      selectedTargetRow = null;
      selectedProductRow = null;
      selectedResultRow = null;
      selectedScopeRow = null;
    }
    if (name === 'scope') {
      if (selectedRow === this.state.selectedScopeRow) selectedScopeRow = null;
      else selectedScopeRow = selectedRow
      selectedTargetRow = null;
      selectedProductRow = null;
      selectedResultRow = null;
      selectedObjectiveRow = null;
    }

    this.setState({ selectedTargetRow, modalAttachment: false, selectedProductRow, selectedResultRow, selectedObjectiveRow, selectedScopeRow })
  }


  handleEditRow = (idx, field, e, { name, value }) => {
    let { data, validate } = this.state;

    validate.validateError = {}
    validate.error = false
    validate.attrs = {}

    if (field === 'target') {
      if (name === 'typeTarget') {
        if (value !== 'อื่นๆ') data.targets[idx]['targetGroup'] = value
        else data.targets[idx]['targetGroup'] = ''
      }
      data.targets[idx][name] = value;
    } else if (field === 'product') {
      data.product[idx][name] = value;
    } else if (field === 'result') {
      data.result[idx][name] = value;
    } else if (field === 'objective') {
      data.objective[idx][name] = value;
    } else if (field === 'scope') {
      data.scope[idx][name] = value;
    }
    this.setState({ data })
  }

  handleDeleteRow = () => {
    let { data, indexDelete, keyDelete, validate } = this.state;

    validate.validateError = {}
    validate.error = false
    validate.attrs = {}

    if (keyDelete === 'targets') {
      if (data.targets) {
        data.targets.splice(indexDelete, 1)
        this.setState({ data, open: false, selectedTargetRow: null })
      }
    } else if (keyDelete === 'product') {
      if (data.product) {
        data.product.splice(indexDelete, 1)
        this.setState({ data, open: false, selectedProductRow: null })
      }
    } else if (keyDelete === 'result') {
      if (data.result) {
        data.result.splice(indexDelete, 1)
        this.setState({ data, open: false, selectedResultRow: null })
      }
    } else if (keyDelete === 'objective') {
      if (data.objective) {
        data.objective.splice(indexDelete, 1)
        this.setState({ data, open: false, selectedObjectiveRow: null })
      }
    }
    else if (keyDelete === 'scope') {
      if (data.scope) {
        data.scope.splice(indexDelete, 1)
        this.setState({ data, open: false, selectedScopeRow: null })
      }
    }
  }

  getLocalUser = () => {
    const token = localStorage.getItem("id_token")
    var content = jwt.decode(token)
    return content
  }

  handleUpdate = () => {
    let { data, validate } = this.state;
    let { tors, auth } = this.props;
    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    // if (counter.counter['noBook' + ((new Date(gettime)).getFullYear() + 543)]) {
    //   data['noBookTor'] = 'กสศ. 19/' + counter.counter['noBook' + ((new Date(gettime)).getFullYear() + 543)].toString().padStart(5, '0')
    // } else {
    //   if (!data['noBookTor']) {
    //     this.props.genId('noBook' + ((new Date(gettime)).getFullYear() + 543))
    //   }
    // }

    if (!data.targets) {
      data.targets = []
    }
    if (!data.product) {
      data.product = []
    }
    if (!data.result) {
      data.result = []
    }
    if (!data.objective) {
      data.objective = []
    }
    if (!data.scope) {
      data.product = []
    }

    if (data.budgetLimit === '0') delete data.budgetLimit
    if (data.targets) {
      data.targets.forEach(x => {
        if (x.targetsCount === '0') {
          delete x.targetsCount
        }
      });
    }

    validate.attrs = {};
    validate['validateError'] = validatejs(data, TorConstraints.TorConstraints)

    let validateArrayError = validateArray(data.targets, TorArrayConstraints.TorArrayConstraints)
    let validateArrayErrorP = validateArray(data.product, TorArrayConstraintsP.TorArrayConstraintsP)
    // let validateArrayErrorR = validateArray(data.result, TorArrayConstraintsP.TorArrayConstraintsR)
    let validateArrayErrorO = validateArray(data.objective, TorArrayConstraintsP.TorArrayConstraintsO)
    let validateArrayErrorS = validateArray(data.scope, TorArrayConstraintsP.TorArrayConstraintsS)

    if (Object.keys(validateArrayError).length !== 0 && validateArrayError.constructor === Object) {
      validate.error = true
      if (!validate['validateError']) validate['validateError'] = {}
      Object.keys(validateArrayError).forEach(index => {
        if (validateArrayError[index].targetGroup) {
          validate['validateError']['targets'] = validateArrayError[index].targetGroup
        }
        if (validateArrayError[index].targetsCount) {
          validate['validateError']['targetsCount'] = validateArrayError[index].targetsCount
        }
        if (validateArrayError[index].targetsUnit) {
          validate['validateError']['targetsUnit'] = validateArrayError[index].targetsUnit
        }
        if (validateArrayError[index].targetExplan) {
          validate['validateError']['targetExplan'] = validateArrayError[index].targetExplan
        }
        validate.attrs['targets' + index] = true;
      })
    }

    if (!validate['validateError']) validate['validateError'] = {}

    if (!data.targets || data.targets.length === 0) {
      validate.error = true
      validate['validateError']['targets'] = []
      validate['validateError']['targets'].push('โปรดระบุกลุ่มเป้าหมาย')
    }
    if (!data.product || data.product.length === 0) {
      validate.error = true
      validate['validateError']['product'] = []
      validate['validateError']['product'].push('โปรดระบุผลผลิต')
    }
    // if (!data.result || data.result.length === 0) {
    //   validate.error = true
    //   validate['validateError']['result'] = []
    //   validate['validateError']['result'].push('โปรดระบุผลลัพธ์')
    // }
    if (!data.objective || data.objective.length === 0) {
      validate.error = true
      validate['validateError']['objective'] = []
      validate['validateError']['objective'].push('โปรดระบุวัตถุประสงค์')
    }
    if (!data.scope || data.scope.length === 0) {
      validate.error = true
      validate['validateError']['scope'] = []
      validate['validateError']['scope'].push('โปรดระบุขอบเขต')
    }


    if (Object.keys(validateArrayErrorP).length !== 0 && validateArrayErrorP.constructor === Object) {
      validate.error = true
      Object.keys(validateArrayErrorP).forEach(index => {
        validate['validateError']['product'] = validateArrayErrorP[index].product
        validate.attrs['product' + index] = true;
      })
    }
    // if (Object.keys(validateArrayErrorR).length !== 0 && validateArrayErrorR.constructor === Object) {
    //   validate.error = true
    //   Object.keys(validateArrayErrorR).forEach(index => {
    //     validate['validateError']['result'] = validateArrayErrorR[index].result
    //     validate.attrs['result' + index] = true;
    //   })
    // }
    if (Object.keys(validateArrayErrorO).length !== 0 && validateArrayErrorO.constructor === Object) {
      validate.error = true
      Object.keys(validateArrayErrorO).forEach(index => {
        validate['validateError']['objective'] = validateArrayErrorO[index].objective
        validate.attrs['objective' + index] = true;
      })
    }
    if (Object.keys(validateArrayErrorS).length !== 0 && validateArrayErrorS.constructor === Object) {
      validate.error = true
      Object.keys(validateArrayErrorS).forEach(index => {
        validate['validateError']['scope'] = validateArrayErrorS[index].scope
        validate.attrs['scope' + index] = true;
      })
    }

    let repeate = tors.filter(x => x.nameTh === data.nameTh).length
    if (validate['validateError'] && repeate !== 0) {
      if (repeate) {
        if (validate.validateError.nameTh) {
          validate.validateError.nameTh.push('ห้ามระบุชื่อซ้ำ')
        } else {
          validate.validateError['nameTh'] = []
          validate.validateError.nameTh.push('ห้ามระบุชื่อซ้ำ')
        }
      }
      validate['error'] = true
      Object.keys(validate['validateError']).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else if (Object.keys(validate['validateError']).length !== 0) {
      validate['error'] = true
      Object.keys(validate.validateError).forEach((item) => {
        validate.attrs[item] = true;
      })
      this.setState({ validate });
    } else {
      if (!data.status) data['status'] = 'ยังไม่อนุมัติ'
      if ((countNo !== undefined && countNo !== '' && checkProps)) {
        checkProps = false
        data['torCode'] = noTOR
        data['recDate'] = gettime
        data['timestamp'] = gettime
        data['endDate'] = this.getEndTime(data.startDate, data.timeProcess)
        data['academic'] = (user._id || '')
        data['academicName'] = (user.title || '') + (user.firstname || '') + ' ' + (user.lastname || '')
        validate['error'] = false
        this.setState({ validate, checkProps });
        this.props.addTor(data);
        if (this.props.onRefreshCard) this.props.onRefreshCard();
        if (this.props.onCloseModalTor) this.props.onCloseModalTor();
        // this.setState({ checkNo: true })
      } else {
        this.props.genId('tor' + thisYear)
      }
    }
  }

  handleSelect = (e, { name }) => {
    this.props.getProposal(name)
    this.props.changePage('MainDetail')
  }

  _renderFormField = (content) => {
    const { validate } = this.state;
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else
      return <Form.Field {...content} error={validate.attrs[content.name]} />
  }


  handleDayChange = (name, index, date) => {
    let { data, validate } = this.state;
    data[name] = date._d.getTime();
    validate.validateError = {}
    validate.error = false
    validate.attrs = {}
    this.setState({ data, validate });
  }

  _onDownload = () => {
    this.setState({ modalPrintout: true, modalAttachment: false })
  }

  open = (idx, obj, e) => {
    this.setState({ open: true, selectedRow: null, indexDelete: idx, keyDelete: obj })
  }
  close = () => this.setState({ open: false })


  onMoneyChange = (e) => {
    let { data, validate } = this.state

    // let money = Math.abs(parseFloat(e.target.rawValue))
    let money = e.target.rawValue.replace('-', '')

    data[e.target.name] = money ? money.toString() : '0'
    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false
    this.setState({ data, validate })
  }

  onMoneyTargetsChange = (idx, e) => {
    let { data, validate } = this.state

    // let money = Math.abs(parseFloat(e.target.rawValue))
    let money = e.target.rawValue.replace('-', '')
    data['targets'][idx][e.target.name] = money ? money.toString() : '0'
    validate['validateError'] = {}
    validate.attrs = {};
    validate.error = false
    this.setState({ data, validate })
  }

  render() {
    let { selectedTargetRow,
      selectedProductRow,
      // selectedResultRow,
      selectedObjectiveRow,
      selectedScopeRow,
      data, validate } = this.state
    let { projects, users, auth } = this.props;

    let userOption = []
    if (users.data.length > 0) {
      userOption = users.data.map((item, idx) => ({
        key: item._id,
        value: item._id,
        text: item.title + item.firstname + ' ' + item.lastname
      }));
    }

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    const optionsTarget = [
      { key: '0', text: 'เด็กและเยาวชนด้อยโอกาส อายุระหว่างแรกเกิด - ๑๘ ปี', value: 'เด็กและเยาวชนด้อยโอกาส อายุระหว่างแรกเกิด - ๑๘ ปี' },
      { key: '1', text: 'นักเรียน/นักศึกษา', value: 'นักเรียน/นักศึกษา' },
      { key: '2', text: 'ครู', value: 'ครู' },
      { key: '3', text: 'สถานศึกษา', value: 'สถานศึกษา' },
      { key: '4', text: 'องค์กร', value: 'องค์กร' },
      { key: '5', text: 'บุคลากรทางการศึกษาอื่นๆ', value: 'บุคลากรทางการศึกษาอื่นๆ' },
    ]

    let content = {
      'noBook': { control: Input, width: 6 },
      'torCode': { control: Input, width: 6 },
      'nameTh': { control: Input },
      'nameEn': { control: Input },
      'objective': { control: TextArea },
      'product': { control: TextArea },
      'result': { control: TextArea },
      'timeProcess': { control: Select, options: time },
      'budgetLimit': { control: Input, money: 'money', maxLength: '16', width: 8 },
      'academic': { control: Select, width: 8, options: userOption },
      'status': { control: Select, options: statusProposal },
    }

    Object.keys(content).forEach((key) => {
      content[key]['label'] = localTh('tor', key);
      content[key].name = key;
      content[key].onChange = this.handleChange;
      if (content[key].control === Input) {
        if (content[key].money === 'money') {
          content[key].value = this.format(data[key] || '') || ''
        }
      }
    });

    if (!data.targets) {
      data.targets = []
    }
    if (!data.product) {
      data.product = []
    }
    if (!data.result) {
      data.result = []
    }
    if (!data.objective) {
      data.objective = []
    }

    if (!data.scope) {
      data.scope = []
    }

    return (
      <Fragment>
        <Form error={validate.error} >
          {this._renderFormField(content.nameTh)}
          {this._renderFormField(content.nameEn)}
          <Header as='h4' style={{ fontFamily: 'supermarket' }} content='วัตถุประสงค์ *' icon='list alternate outline' />
          <Table celled selectable color="olive">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell content='ลำดับ' width={2} />
                <Table.HeaderCell content='วัตถุประสงค์ *' width={12} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.objective && data.objective.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx, 'objective')} error={validate.attrs['objective' + idx]}>
                    {(selectedObjectiveRow === idx && <Table.Cell>
                      <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                    </Table.Cell>) || <Table.Cell textAlign='center'> {(idx + 1)}</Table.Cell>}
                    <Table.Cell content={item.objective} />
                    <Table.Cell textAlign='center'><Button type='button' name={idx} color='red' icon='close' onClick={this.open.bind(this, idx, 'objective')} /></Table.Cell>
                  </Table.Row>
                  {selectedObjectiveRow === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={4}>
                        <Form.TextArea label={'วัตถุประสงค์ที่ ' + (idx + 1)} name='objective'
                          value={item.objective} onChange={this.handleEditRow.bind(this, idx, 'objective')} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button style={{ fontFamily: 'supermarket' }} content='เพิ่ม' color='green' icon='plus' name='objective' onClick={this.handleAddRow} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Header as='h4' style={{ fontFamily: 'supermarket' }} content='ขอบเขต *' icon='list alternate outline' />
          <Table celled selectable color="olive">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell content='ลำดับ' width={2} />
                <Table.HeaderCell content='ขอบเขต *' width={12} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.scope && data.scope.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx, 'scope')} error={validate.attrs['scope' + idx]}>
                    {(selectedScopeRow === idx && <Table.Cell>
                      <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                    </Table.Cell>) || <Table.Cell textAlign='center'> {(idx + 1)}</Table.Cell>}
                    <Table.Cell content={item.scope} />
                    <Table.Cell textAlign='center'><Button type='button' name={idx} color='red' icon='close' onClick={this.open.bind(this, idx, 'scope')} /></Table.Cell>
                  </Table.Row>
                  {selectedScopeRow === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={4}>
                        <Form.TextArea label={'ขอบเขตที่ ' + (idx + 1)} name='scope'
                          value={item.scope} onChange={this.handleEditRow.bind(this, idx, 'scope')} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button style={{ fontFamily: 'supermarket' }} content='เพิ่ม' color='green' icon='plus' name='scope' onClick={this.handleAddRow} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Header style={{ fontFamily: 'supermarket' }} as='h4' content='กลุ่มเป้าหมาย *' icon='target' />
          <Table celled selectable color="olive">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell content='ลำดับ' width={2} />
                <Table.HeaderCell content='กลุ่มเป้าหมาย *' width={12} />
                <Table.HeaderCell content='จำนวน *' textAlign='center' width={3} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={1} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.targets && data.targets.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx, 'target')} error={validate.attrs['targets' + idx]}>
                    {(selectedTargetRow === idx && <Table.Cell>
                      <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                    </Table.Cell>) || <Table.Cell textAlign='center'> {(idx + 1)}</Table.Cell>}
                    <Table.Cell>{item.targetGroup}</Table.Cell>
                    <Table.Cell content={(formatcomma(item.targetsCount) ? formatcomma(item.targetsCount) + ' ' + (item.targetsUnit || '') : ' ')} textAlign='center' />
                    <Table.Cell textAlign='center'><Button type='button' key='targets' name={idx} color='red' icon='close' onClick={this.open.bind(this, idx, 'targets')} /></Table.Cell>
                  </Table.Row>
                  {selectedTargetRow === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={5}>
                        <Form.Group widths='equal'>
                          <Form.Select label='กลุ่มเป้าหมาย' options={optionsTarget} value={item.typeTarget} name='typeTarget'
                            onChange={this.handleEditRow.bind(this, idx, 'target')} />
                          <Form.Input label='รายละเอียด กลุ่มเป้าหมาย' name='targetGroup' 
                            value={item.targetGroup} onChange={this.handleEditRow.bind(this, idx, 'target')} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                          <Form.Input label='หมายเหตุ' name='detail'
                            value={item.detail} onChange={this.handleEditRow.bind(this, idx, 'target')} />
                          <Form.Field >
                            <label>{'จำนวน'}</label>
                            <Cleave name='targetsCount'
                              maxLength='16'
                              options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand'
                              }}
                              onChange={this.onMoneyTargetsChange.bind(this, idx)}
                              value={item.targetsCount || ''} />
                          </Form.Field>
                          <Form.Input label='หน่วย' name='targetsUnit'
                            value={item.targetsUnit || ''} onChange={this.handleEditRow.bind(this, idx, 'target')} />
                        </Form.Group>
                        <Form.TextArea label={'ข้อความของกลุ่มเป้าหมายที่ ' + (idx + 1)} name='targetExplan'
                          value={item.targetExplan || ''} onChange={this.handleEditRow.bind(this, idx, 'target')} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button style={{ fontFamily: 'supermarket' }} content='เพิ่ม' color='green' icon='plus' name='target' onClick={this.handleAddRow} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <Header style={{ fontFamily: 'supermarket' }} as='h4' content='ผลผลิตข้อกำหนด *' icon='list alternate outline' />
          <Table celled selectable color="olive">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell content='ลำดับ' textAlign='center' width={2} />
                <Table.HeaderCell content='ผลผลิตข้อกำหนด *' width={12} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={2} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.product && data.product.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx, 'product')} error={validate.attrs['product' + idx]}>
                    {(selectedProductRow === idx && <Table.Cell>
                      <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                    </Table.Cell>) || <Table.Cell textAlign='center' >{idx + 1}</Table.Cell>}
                    <Table.Cell content={item.product} />
                    <Table.Cell textAlign='center'><Button type='button' key='product'
                      name={idx} color='red' icon='close'
                      onClick={this.open.bind(this, idx, 'product')} /></Table.Cell>
                  </Table.Row>
                  {selectedProductRow === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={4}>
                        <Form.TextArea label={'ผลผลิตที่' + (idx + 1)} name='product'
                          value={item.product} onChange={this.handleEditRow.bind(this, idx, 'product')} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button style={{ fontFamily: 'supermarket' }} content='เพิ่ม' color='green' icon='plus' name='product' onClick={this.handleAddRow} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          {/* <Header style={{ fontFamily: 'supermarket' }} as='h4' content='ผลลัพธ์ข้อกำหนด *' icon='list alternate outline' />
          <Table celled selectable color="olive">
            <Table.Header>
              <Table.Row >
                <Table.HeaderCell content='ลำดับ' textAlign='center' width={2} />
                <Table.HeaderCell content='ผลลัพธ์ข้อกำหนด *' width={12} />
                <Table.HeaderCell content='ลบ' textAlign='center' width={2} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.result && data.result.map((item, idx) => (
                <Fragment key={idx}>
                  <Table.Row onClick={this.handleSelectRow.bind(this, idx, 'result')} error={validate.attrs['result' + idx]}>
                    {(selectedResultRow === idx && <Table.Cell>
                      <Label color="green" ribbon content={'ลำดับที่ ' + (idx + 1)} />
                    </Table.Cell>) || <Table.Cell textAlign='center' >{idx + 1}</Table.Cell>}
                    <Table.Cell content={item.result} />
                    <Table.Cell textAlign='center'><Button type='button' key='result' name={idx} color='red' icon='close' onClick={this.open.bind(this, idx, 'result')} /></Table.Cell>
                  </Table.Row>
                  {selectedResultRow === idx && (
                    <Table.Row active>
                      <Table.Cell colSpan={4}>
                        <Form.TextArea label={'ผลลัพธ์ที่ ' + (idx + 1)} name='result'
                          value={item.result} onChange={this.handleEditRow.bind(this, idx, 'result')} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='5'>
                  <Button style={{ fontFamily: 'supermarket' }} content='เพิ่ม' color='green' icon='plus' name='result' onClick={this.handleAddRow} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table> */}
          <Form.Group >
            <Form.Field width={8} error={validate.attrs['budgetLimit']}>
              <label>{'งบประมาณ'}</label>
              <Cleave name='budgetLimit'
                maxLength='16'
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
                onChange={this.onMoneyChange.bind(this)}
                value={data.budgetLimit || ''} />
            </Form.Field>
            <Form.Field width={4} >
              <label>{'วันที่เริ่มต้น *'}</label>
              <DayPicker ser={'startDate'} name={'startDate'}
                onDayChange={this.handleDayChange}
                data={data.startDate || ''} />
            </Form.Field>
            {this._renderFormField(content.timeProcess)}
            {/* {this._renderFormField(content.academic)} */}
          </Form.Group>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>กรอกข้อมูลไม่ครบถ้วน</Message.Header>
            <Message.List>
              {validate.error && Object.keys(validate.validateError).map((key, idx) => (
                <Fragment key={idx}>
                  {validate.validateError[key].map((item, idx) => (
                    <Message.Item key={idx}> {localTh('tor', key)} - {item} </Message.Item>
                  ))}
                </Fragment>
              ))}
            </Message.List>
          </Message>
          <Button
            style={{ fontFamily: 'supermarket' }}
            color='blue'
            icon='save'
            disabled={validate.error}
            loading={projects.isSaving}
            onClick={this.handleUpdate} content="บันทึก" />
        </Form>
        <Confirm
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          open={this.state.open}
          onCancel={this.close}
          onConfirm={this.handleDeleteRow} />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  projects: state.tors,
  selectedProject: state.tors.currentData,
  tors: state.alltors.data,
  counter: state.counter,
  auth: state.auth,
  users: state.user
})

const mapDispatchToProps = dispatch => ({
  listAllTor: () => dispatch(alltorActions.fetchList()),
  addTor: (data) => dispatch(torActions.save(data)),
  listUser: () => dispatch(userActions.fetchList()),
  genId: (name) => dispatch(generate(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentAddTOR);

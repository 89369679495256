import React, { Component, Fragment } from 'react'
import {
  Form,
  Input,
  Select,
  Button,
  Header,
  Message,
  Segment,
  Checkbox,
  Modal
} from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css'
import validatejs from 'validate.js'
import Cleave from 'cleave.js/react'
import moment from 'moment'
import 'moment-precise-range-plugin'
import { connect } from 'react-redux'
import { GetServerTime as GetTime } from 'Utils'
import { HeaderContent } from 'Components/Header'
import { constraints as InfoConstraints } from './RegisConstraints'
import { actions as torActions } from 'Redux/reducers/tors'
import { actions as projectActions } from 'Redux/reducers/projects'
import { actions as organizations_wActions } from 'Redux/reducers/organizations'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as individuals_wActions } from 'Redux/reducers/individuals_w'
import { actions as fundingsActions } from 'Redux/reducers/fundings'
import { actions as typeProject } from 'Redux/reducers/typeproject'
import { generate } from 'Redux/reducers/counting'
import ComponentSetBudget from './ComponentSetBudget'
import { localTh } from 'Utils'
import { FundingFields } from 'Utils'
import { PersonDropdown } from 'Utils'
import { ComponentPerson, ListTypeProject, ListSeriesProject } from 'Utils'
import { ComponentOrganization } from 'Utils'
import { ModalListOrganization } from 'Utils'
import { ModalListIndividuals } from 'Utils'
import { DatePicker as DayPicker } from 'Utils'
import jwt from 'jsonwebtoken'

// import { object } from 'prop-types';

var gettime,
  noProject,
  countNo,
  attr,
  oldNoBookIn,
  noBookIn,
  thisYear = ''
let checkNo = true
let checkProps = false
let personReceiveOptions = {}

const organize = [
  { key: '1', value: 'องค์กร', text: 'องค์กร' },
  { key: '2', value: 'บุคคล', text: 'บุคคล' }
]

const contract = [
  { key: '1', text: 'โครงการเดี่ยว', value: 'โครงการเดี่ยว' },
  { key: '2', text: 'โครงการเชิงรุก', value: 'โครงการเชิงรุก' }
]

const otherBudget = [
  { key: '1', text: 'งบประมาน', value: 'งบประมาน' },
  { key: '2', text: 'สิ่งของอื่นๆ', value: 'สิ่งของอื่นๆ' },
  { key: '3', text: 'ไม่มี', value: 'ไม่มี' }
]

const seriesContinueProjeect = [
  { key: '1', value: '1', text: 'ปีที่ 1' },
  { key: '2', value: '2', text: 'ปีที่ 2' },
  { key: '3', value: '3', text: 'ปีที่ 3' },
  { key: '4', value: '4', text: 'ปีที่ 4' },
  { key: '5', value: '5', text: 'ปีที่ 5' }
]

let organization

class ComponentAddProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileUpload: [],
      tmp: '',
      Oganize: 'องค์กร',
      modalOpen1: false,
      modalOpen2: false,
      data: {},
      typeProjectModal: false,
      SeriesProjectModal: false,
      addPerson: false,
      addOrganization: false,
      validate: { error: false, validateError: {}, attrs: {} },
      checkReadOnly: false,
      checkOffice: '',
      checkType: '',
      checkYear: '',
      organizationId: '',
      openModalPerson: false,
      openModalOrganization: false,
      listProjectConnectSeq1: []
    }
  }

  componentDidMount() {
    const { tors, projects } = this.props

    let { data, listProjectConnectSeq1 } = this.state
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    this.props.getTor(tors.currentData._id)
    this.props.listBudget()
    this.props.queryTors()
    this.props.getTypeProject('584ccec0e1e111e889cf8170c54de9cb')
    let listProjectConnect = projects.filter(
      item =>
        item.typeProject === 5 &&
        item.seqConnect === '1' &&
        (item.status === 'ประเมินความก้าวหน้าโครงการ' || item.status === 'ปิดโครงการ' || item.status === 'ยุติโครงการ')
    )
    // let listProjectConnect = projects
    if (listProjectConnect.length > 0) {
      listProjectConnect.forEach((item, idx) => {
        listProjectConnectSeq1.push({
          key: idx,
          value: item._id,
          text: item.nameTh
        })
      })
    }
    this.setState(listProjectConnectSeq1)
    // this.props.getListProposal(queryObj)
    let counterProject = false
    checkNo = true
    countNo = ''
    checkProps = false
    oldNoBookIn = ''
    noBookIn = ''
    if (Object.getOwnPropertyNames(this.props.counter.counter).length > 0) {
      Object.keys(this.props.counter.counter).forEach(key => {
        delete this.props.counter.counter[key]
      })
    }

    if (Object.getOwnPropertyNames(this.props.counter.counter).length > 0) {
      Object.keys(this.props.counter.counter).forEach(key => {
        counterProject = key.includes('project')
        let noBook = key.includes('noBookInside')
        if (counterProject) {
          attr = key
          checkNo = false
          countNo = this.props.counter.counter[key]
          checkProps = false
        }
        if (noBook) {
          oldNoBookIn = this.props.counter.counter[key]
        }
      })
    }
    this.props.getSeriesProject()
    data.numberOfGroupBudget = 1
    this.setState(data)
  }


  getTimeFuction = () => {
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
      }
    })
  }

  componentWillReceiveProps = nextProps => {
    const { tors } = nextProps
    const { data } = this.state

    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })

    // if (!gettime && gettime === '' && !thisYear && thisYear === '' && gettime === undefined && thisYear === undefined) this.getTimeFuction()


    if (nextProps.counter.counter['noBookInside-' + thisYear]) {
      if (oldNoBookIn !== '') {
        if (oldNoBookIn !== nextProps.counter.counter['noBookInside-' + thisYear]) {
          noBookIn = 'กสศ. น. 19/' + nextProps.counter.counter['noBookInside-' + thisYear].toString().padStart(5, '0') + '/' + thisYear
        }
      } else {
        noBookIn = 'กสศ. น. 19/' + nextProps.counter.counter['noBookInside-' + thisYear].toString().padStart(5, '0') + '/' + thisYear
      }
    }

    if (checkNo) {
      countNo =
        nextProps.counter.counter[
        'project-' + thisYear + '-' + data.typeOffice + '-' + data.typeProject
        ]
      if (countNo !== undefined && countNo !== '') {
        checkProps = true
        noProject =
          thisYear.toString().replace('25', '') +
          '-' +
          data.typeOffice +
          data.typeProject +
          '-' +
          nextProps.counter.counter[
            'project-' +
            thisYear +
            '-' +
            data.typeOffice +
            '-' +
            data.typeProject
          ]
            .toString()
            .padStart(5, '0')
      }
    } else {
      let emty =
        nextProps.counter.counter[
        'project-' + thisYear + '-' + data.typeOffice + '-' + data.typeProject
        ]
      if (emty !== undefined) {
        if (
          attr ===
          'project-' + thisYear + '-' + data.typeOffice + '-' + data.typeProject
        ) {
          if (
            countNo !==
            nextProps.counter.counter[
            'project-' +
            thisYear +
            '-' +
            data.typeOffice +
            '-' +
            data.typeProject
            ]
          ) {
            countNo =
              nextProps.counter.counter[
              'project-' +
              thisYear +
              '-' +
              data.typeOffice +
              '-' +
              data.typeProject
              ]
            checkProps = true
            noProject =
              thisYear.toString().replace('25', '') +
              '-' +
              data.typeOffice +
              data.typeProject +
              '-' +
              nextProps.counter.counter[
                'project-' +
                thisYear +
                '-' +
                data.typeOffice +
                '-' +
                data.typeProject
              ]
                .toString()
                .padStart(5, '0')
          }
        } else {
          countNo =
            nextProps.counter.counter[
            'project-' +
            thisYear +
            '-' +
            data.typeOffice +
            '-' +
            data.typeProject
            ]
          checkProps = true
          noProject =
            thisYear.toString().replace('25', '') +
            '-' +
            data.typeOffice +
            data.typeProject +
            '-' +
            nextProps.counter.counter[
              'project-' +
              thisYear +
              '-' +
              data.typeOffice +
              '-' +
              data.typeProject
            ]
              .toString()
              .padStart(5, '0')
        }
      }
    }

    if (checkProps && noBookIn !== '') {
      this.handleSubmit()
    }

    if (!data.nameTh && tors.data) {
      // let index = tors.data.findIndex(x => x._id === tors.);
      // if (index !== -1) {
      data['nameTh'] = ''
      data['tor_id'] = tors.currentData._id
      data['startDate'] = tors.currentData.startDate
      data['endDate'] = tors.currentData.endDate

      let sd = moment(tors.currentData.startDate).format('YYYY-MM-DD')
      let ed = moment(tors.currentData.endDate).format('YYYY-MM-DD')

      // var diff = moment.preciseDiff(sd, ed);
      var diff = moment.preciseDiff(sd + ' 00:00:00', ed + ' 24:00:00')
      let diffyth
      let diffmth
      let diffdth

      let cy = diff.indexOf('years')

      if (cy !== -1) {
        diffyth = diff.replace('years', 'ปี')
      } else {
        diffyth = diff.replace('year', 'ปี')
      }

      let cl = diff.indexOf('months')

      if (cl !== -1) {
        diffmth = diffyth.replace('months', 'เดือน')
      } else {
        diffmth = diffyth.replace('month', 'เดือน')
      }

      let cd = diff.indexOf('days')

      if (cd !== -1) {
        diffdth = diffmth.replace('days', 'วัน')
      } else {
        diffdth = diffmth.replace('day', 'วัน')
      }

      data['operationTime'] = diffdth

      this.setState(data)
    }
    // }
  }

  handleAddMoreFile = () => {
    let { fileUpload } = this.state
    fileUpload.push({ name: 'เอกสารแนบ' })
    this.setState({ fileUpload })
  }

  format(input) {
    if (!input) {
      input = ''
    }
    var num = input.replace(/,/g, '')
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.')
        num[0] = num[0]
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1)
        }
        input = num[0] + '.' + num[1]
        return input
      } else {
        input = num
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        return input
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1)
    }
  }

  handleChange = (e, { name, value, checked, manager }) => {
    const { tors } = this.props
    let { data, validate, tmp } = this.state

    if (name === 'askBudget' || name === 'otherBudget') {
      let money = this.format(value)
      data[name] = money
    } else if (name === 'funding') {
      data['yearBudget'] = value.value.year
      data['groupsPlan'] = value.value.group
      data['plan'] = value.value.plan
      data['activity'] = value.value.activity
      data['valueGroup'] = value.value.valueGroup
      data['valuePlan'] = value.value.valuePlan
      data['valueActivity'] = value.value.valueActivity
      data['codeBudget'] =
        parseInt(data['yearBudget'] - 2500) + value.value.valueActivity
    } else if (name === 'funding1') {
      data['yearBudget1'] = value.value.year
      data['groupsPlan1'] = value.value.group
      data['plan1'] = value.value.plan
      data['activity1'] = value.value.activity
      data['valueGroup1'] = value.value.valueGroup
      data['valuePlan1'] = value.value.valuePlan
      data['valueActivity1'] = value.value.valueActivity
      data['codeBudget1'] =
        parseInt(data['yearBudget1']) + value.value.valueActivity
    } else if (name === 'funding2') {
      data['yearBudget2'] = value.value.year
      data['groupsPlan2'] = value.value.group
      data['plan2'] = value.value.plan
      data['activity2'] = value.value.activity
      data['valueGroup2'] = value.value.valueGroup
      data['valuePlan2'] = value.value.valuePlan
      data['valueActivity2'] = value.value.valueActivity
      data['codeBudget2'] =
        parseInt(data['yearBudget2']) + value.value.valueActivity
    } else if (name === 'funding3') {
      data['yearBudget3'] = value.value.year
      data['groupsPlan3'] = value.value.group
      data['plan3'] = value.value.plan
      data['activity3'] = value.value.activity
      data['valueGroup3'] = value.value.valueGroup
      data['valuePlan3'] = value.value.valuePlan
      data['valueActivity3'] = value.value.valueActivity
      data['codeBudget3'] =
        parseInt(data['yearBudget3']) + value.value.valueActivity
    } else if (name === 'funding4') {
      data['yearBudget4'] = value.value.year
      data['groupsPlan4'] = value.value.group
      data['plan4'] = value.value.plan
      data['activity4'] = value.value.activity
      data['valueGroup4'] = value.value.valueGroup
      data['valuePlan4'] = value.value.valuePlan
      data['valueActivity4'] = value.value.valueActivity
      data['codeBudget4'] =
        parseInt(data['yearBudget4']) + value.value.valueActivity
    } else if (name === 'typeOffice' || name === 'typeProject') {
      data[name] = value
    } else if (name === 'sameNameTor') {
      if (checked) {
        let index = tors.data.findIndex(x => x._id === data.tor_id)
        if (index === -1) {
          data['nameTh'] = tors.currentData.nameTh
          data['tor_id'] = tors.currentData._id
        } else {
          let tor = tors.data
          data['nameTh'] = tor[index].nameTh
        }
        this.setState({ checkReadOnly: true })
      } else {
        data['nameTh'] = ''
        this.setState({ checkReadOnly: false })
      }
    } else if (name === 'recipientOrganize') {
      if (value.length > 3) {
        this.props.listPersonByOrganization(value)
        this.props.listPersonReceive(value)
      }
      data[name] = value
      organization.map(r => {
        if (value === r.id) data['recipientOrganizeName'] = r.name
        return r
      })
    } else if (name === 'manager') {
      data[name] = value
      manager.map(r => {
        if (value === r.id) data['managerName'] = r.name
        return r
      })
    } else if (name === 'recipientPerson') {
      data[name] = value
      personReceiveOptions.map(r => {
        if (value === r.value) data['recipientPersonName'] = r.text
        return r
      })
    } else if (name === 'recipientType') {
      data[name] = value
      let query = {
        _id: '0'
      }
      this.props.listPersonFreelance(query)
      this.props.listPersonFreelanceReceive(query)
      this.props.listOrganizations(query)
      if (value === 'บุคคล') {
        if (data.manager || data.recipientPerson) {
          delete data.manager
          delete data.recipientPerson
        }
        delete data.recipientOrganize
        delete data.recipientOrganizeName
        // let query = { _id: data.manager }
        // this.props.listPersonFreelance(query)
      }
    } else {
      data[name] = value
    }

    data['tor_id'] = tors.currentData._id
    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false
    this.setState({ data, validate, tmp })
  }

  handleSearch = (e, { name }) => {
    let { data } = this.state
    if (name === 'recipientOrganize') {
      if (e.target.value.length >= 3) {
        let query = {
          $or: [{ organizationName: { $regex: e.target.value } }]
        }
        this.props.listOrganizations(query)
      }
    } else if (name === 'manager') {
      if (e.target.value.length >= 3) {
        let test = e.target.value.split(' ')
        test.forEach((item, idx) => {
          if (item.length === 0) {
            test.splice(idx, 1)
          }
        })
        let query = {
          firstname: { $regex: test[0] || '' },
          lastname: { $regex: test[1] || '' }
        }
        this.props.listPersonFreelance(query)
      }
    } else if (name === 'recipientPerson') {
      if (data.recipientType === 'บุคคล') {
        if (e.target.value.length >= 3) {
          let test = e.target.value.split(' ')
          test.forEach((item, idx) => {
            if (item.length === 0) {
              test.splice(idx, 1)
            }
          })
          let query = {
            firstname: { $regex: test[0] || '' },
            lastname: { $regex: test[1] || '' }
          }
          this.props.listPersonFreelanceReceive(query)
        }
      }
    }
  }

  handleSubmit = () => {
    let { data, validate } = this.state
    const { tors, projects } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      if (value) {
        // let checkYear = new Date(((new Date(value)).getFullYear()), 8, 30).getTime()
        // if (value > checkYear) {
        //   thisYear = new Date((new Date(value)).getFullYear() + 544).getTime()
        // } else {
        thisYear = new Date(new Date(value).getFullYear() + 543).getTime()
        // }
      }
    })
    if (data.askBudget === '0') delete data.askBudget

    data['status'] = 'ส่งข้อเสนอโครงการ'
    validate.attrs = {}
    validate['validateError'] = validatejs(
      data,
      InfoConstraints.InfoConstraints
    )
    if (data.typeProject === 3) {
      let validateSeries = {
        seriesProject: {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
      }
      let validSeries = validatejs(data, validateSeries)
      if (validSeries) {
        validate['validateError']['seriesProject'] = [' โปรดระบุ']
      }
    }
    if (data.typeProject === 5) {
      let validateSeries = {
        seqConnect: {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
      }
      if (data.seqConnect !== '1') {
        validateSeries.seriesConnect = {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
      } else {
        validateSeries.endDate_connect = {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
        validateSeries.startDate_connect = {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
        validateSeries.askBudget_connect = {
          presence: {
            allowEmpty: false,
            message: '^ต้องระบุ'
          }
        }
      }
      let validConnect = validatejs(data, validateSeries)

      if (validConnect) {
        if (!validate['validateError']) validate['validateError'] = []
        if (validConnect.seriesConnect)
          validate['validateError']['seriesConnect'] = [' โปรดระบุ']
        if (validConnect.seqConnect)
          validate['validateError']['seqConnect'] = [' โปรดระบุ']
        if (validConnect.endDate_connect)
          validate['validateError']['endDate_connect'] = [' โปรดระบุ']
        if (validConnect.startDate_connect)
          validate['validateError']['startDate_connect'] = [' โปรดระบุ']
        if (validConnect.askBudget_connect)
          validate['validateError']['askBudget_connect'] = [' โปรดระบุ']
      }
    }

    if (validate['validateError']) {
      validate['error'] = true
      Object.keys(validate['validateError']).forEach(item => {
        validate.attrs[item] = true
      })
      this.setState({ validate })
    } else {
      if (countNo !== '' && checkProps && noBookIn) {
        validate['error'] = false
        data['withdrawForm'] = []
        data['recDate'] = gettime
        data['timestamp'] = gettime
        // data['codeBudget'] = data.yearBudget + data.valueActivity
        data['targetGroup'] = tors.currentData.targets
        data['product'] = tors.currentData.product
        data['result'] = tors.currentData.result
        data['objective'] = tors.currentData.objective
        data['scope'] = tors.currentData.scope
        data['academic'] = tors.currentData.academicName
        data['academic_id'] = tors.currentData.academic
        data['codeProject'] = noProject
        if (data.typeProject === 5 && data.seqConnect !== '1') {
          let firstProject = projects.filter(
            item => item._id === data.seriesConnect
          )
          data.purpose = firstProject[0].purpose
          data.evaluation = firstProject[0].evaluation
          data.objective = firstProject[0].objective
          data.product = firstProject[0].product
          data.result = firstProject[0].result
          data.targetGroup = firstProject[0].targetGroup
          data.screen = firstProject[0].screen
          data.projectGroupArea = firstProject[0].projectGroupArea
          data.noAgreement = firstProject[0].noAgreement
          data.noBookAgreement = noBookIn
          data.noBookSendAgreement = firstProject[0].noBookSendAgreement
          data.noBookBankaccount = firstProject[0].noBookBankaccount
          data.dateContract = firstProject[0].dateContract
          data.witness1 = firstProject[0].witness1
          data.witness1Name = firstProject[0].witness1Name
          data.witness2 = firstProject[0].witness2
          data.witness2Name = firstProject[0].witness2Name
          data.copyright = firstProject[0].copyright
          data.typePay = firstProject[0].typePay
          data.sendSignedContract = firstProject[0].sendSignedContract
          data.withdrawBank = firstProject[0].withdrawBank
          data.withdrawBankBranch = firstProject[0].withdrawBankBranch
          data.withdrawAccountName = firstProject[0].withdrawAccountName
          data.withdrawAccountNumber = firstProject[0].withdrawAccountNumber
          data.withdrawBankID = firstProject[0].withdrawBankID
          data.withdrawBankBranchID = firstProject[0].withdrawBankBranchID
        }

        this.setState({ validate })
        this.props.addProposal(data)
        countNo = ''
        if (this.props.onCloseModalProject) this.props.onCloseModalProject()
      } else {
        this.props.genId(
          'project-' + thisYear + '-' + data.typeOffice + '-' + data.typeProject
        )
        if (data.typeProject === 5 && data.seqConnect !== '1') this.props.genId('noBookInside-' + thisYear)
        else noBookIn = 'no'
      }

      // data['startDate'] = tors.currentData.Date
      // data['endDate'] = this.getEndTime(tors.currentData.Date, tors.currentData.timeProcess)
      // this.setState({ validate });
      // this.props.addProposal(data)
      // if (this.props.onCloseModalProject) this.props.onCloseModalProject();
    }
  }

  getEndTime = (start, endTime) => {
    var day = new Date(start).getDate()
    var month = new Date(start).getMonth() + endTime
    var year = new Date(start).getFullYear()
    var date = new Date(year, month, day).getTime()
    return date
  }

  _renderFormField = content => {
    const { validate } = this.state
    if (content.control === Select) {
      return <Form.Field {...content} error={validate.attrs[content.name]} />
    } else
      return <Form.Field {...content} error={validate.attrs[content.name]} />
  }

  handleDayChange = (name, index, date) => {
    let { data, validate } = this.state

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    data[name] = date._d.getTime()

    let sd = moment(data.startDate).format('YYYY-MM-DD')
    let ed = moment(data.endDate).format('YYYY-MM-DD')
    var diff = moment.preciseDiff(sd + ' 00:00:00', ed + ' 24:00:00')

    let diffyth
    let diffmth
    let diffdth

    let cy = diff.indexOf('years')

    if (cy !== -1) {
      diffyth = diff.replace('years', 'ปี')
    } else {
      diffyth = diff.replace('year', 'ปี')
    }

    let cl = diff.indexOf('months')

    if (cl !== -1) {
      diffmth = diffyth.replace('months', 'เดือน')
    } else {
      diffmth = diffyth.replace('month', 'เดือน')
    }

    let cd = diff.indexOf('days')

    if (cd !== -1) {
      diffdth = diffmth.replace('days', 'วัน')
    } else {
      diffdth = diffmth.replace('day', 'วัน')
    }

    data['operationTime'] = diffdth

    let sd_connect = moment(data.startDate_connect).format('YYYY-MM-DD')
    let ed_connect = moment(data.endDate_connect).format('YYYY-MM-DD')
    var diff_connect = moment.preciseDiff(sd_connect + ' 00:00:00', ed_connect + ' 24:00:00')

    let diffyth_connect
    let diffmth_connect
    let diffdth_connect

    let cy_connect = diff_connect.indexOf('years')

    if (cy_connect !== -1) {
      diffyth_connect = diff_connect.replace('years', 'ปี')
    } else {
      diffyth_connect = diff_connect.replace('year', 'ปี')
    }

    let cl_connect = diff_connect.indexOf('months')

    if (cl_connect !== -1) {
      diffmth_connect = diffyth_connect.replace('months', 'เดือน')
    } else {
      diffmth_connect = diffyth_connect.replace('month', 'เดือน')
    }

    let cd_connect = diff_connect.indexOf('days')

    if (cd_connect !== -1) {
      diffdth_connect = diffmth_connect.replace('days', 'วัน')
    } else {
      diffdth_connect = diffmth_connect.replace('day', 'วัน')
    }

    data['operationTime_connect'] = diffdth_connect

    this.setState({ data, validate })
  }

  handleSelectRow = selectedRow => {
    if (selectedRow === this.state.selectedRow) selectedRow = null
    this.setState({ selectedRow })
  }

  handleComponentPerson = () => {
    this.setState({ openModalPerson: true })
  }

  handleComponentOrganization = () => {
    this.setState({ openModalOrganization: true })
  }

  handleListOrganization = () => {
    this.setState({ openModalListOrganization: true })
  }

  close = () => {
    this.props.getTypeProject('584ccec0e1e111e889cf8170c54de9cb')
    this.setState({
      openModalPerson: false,
      openModalOrganization: false,
      typeProjectModal: false,
      openModalListOrganization: false,
      openModalListIndividual: false,
      seriesProjectModal: false
    })
  }

  onMoneyChange = e => {
    let { data, validate } = this.state
    let money = e.target.rawValue.replace('-', '')

    data[e.target.name] = money

    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false
    this.setState({ data, validate })
  }
  handleModalTypeProject = () => {
    this.setState({ typeProjectModal: true })
  }

  handleModalSeriesProject = () => {
    this.setState({ seriesProjectModal: true })
  }

  handleModalListIndividuals = id => {
    this.setState({ organizationId: id, openModalListIndividual: true })
  }

  closeModalIndividuals = () => {
    this.setState({ openModalListIndividual: false })
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  render() {
    const {
      tors,
      organizations,
      typeProject,
      role,
      individuals,
      auth
    } = this.props

    let {
      data,
      validate,
      checkReadOnly,
      openModalPerson,
      openModalOrganization,
      organizationId,
      listProjectConnectSeq1
    } = this.state

    let user = auth.isAuthenticated && auth.user && auth.user.user
    if (!user && auth.isAuthenticated) {
      user = this.getLocalUser()
    }

    const torOption = tors.data.map((item, idx) => ({
      key: item._id,
      value: item._id,
      text: item.nameTh
    }))

    let organizationsOptions = organizations.data.map((item, idx) => ({
      text: item.organizationName,
      value: item._id
    }))

    personReceiveOptions = individuals.data.map((item, idx) => ({
      key: idx,
      text:
        item.title === 'อื่นๆ'
          ? item.otherTitle + item.firstname + ' ' + item.lastname
          : item.title + item.firstname + ' ' + item.lastname,
      value: item._id
    }))

    let typeProjectOption = typeProject.currentData
      ? typeProject.currentData.name === 'ประเภทโครงการ'
        ? typeProject.currentData.type.map((item, idx) => ({
          key: idx,
          text: item.name,
          value: item.value
        }))
        : []
      : []

    let typeSeriesProject =
      typeProject.data.length > 0
        ? typeProject.data.filter(
          item => item.type === 'ชุดโครงการ' && item.year === thisYear
        )
        : []

    let typeSeriesProjectOption =
      typeSeriesProject.length > 0
        ? typeSeriesProject[0].list.map((item, idx) => ({
          key: idx,
          text: item.name,
          value: item.code
        }))
        : []

    organization = organizations.data.map((item, idx) => ({
      id: item._id,
      name: item.organizationName
    }))

    let typeOffice =
      typeProject.data.length > 0
        ? typeProject.data.filter(item => item.name === 'สังกัดสำนัก')
        : []
    let typeOfficeOption = []
    let officeUser = user.typeInstitution ? user.typeInstitution : []
    officeUser.forEach((item, idx) => {
      let name =
        typeOffice.length > 0
          ? typeOffice[0].typeOffice.filter(row => row.value === item)
          : []
      typeOfficeOption[idx] = {
        key: idx,
        text: name[0] ? name[0].name : '',
        value: item
      }
    })

    let optionGroup5 = [
      { key: 0, value: 1, text: 1 },
      { key: 1, value: 2, text: 2 },
      { key: 2, value: 3, text: 3 },
      { key: 3, value: 4, text: 4 },
      { key: 4, value: 5, text: 5 }
    ]

    let content = {
      tor_id: {
        control: Select,
        options: torOption,
        search: true,
        selection: true,
        disabled: true
      },
      sameNameTor: { control: Checkbox },
      nameTh: { control: Input },
      askBudget: { control: Input, money: 'money', maxLength: '16' },
      otherBudgetItem: { control: Input, width: 8 },
      otherBudgetDropdown: { control: Select, options: otherBudget },
      contractType: { control: Select, options: contract },
      recipientType: { control: Select, options: organize, width: 8 },
      recipientOrganize: {
        control: Select,
        width: 8,
        options: organizationsOptions,
        selection: true,
        search: true
      },
      funding: { control: FundingFields, width: 16 },
      funding1: { control: FundingFields, width: 16 },
      funding2: { control: FundingFields, width: 16 },
      funding3: { control: FundingFields, width: 16 },
      funding4: { control: FundingFields, width: 16 },
      manager: {
        control: PersonDropdown,
        width: 8,
        disabled:
          !data.recipientOrganize &&
          (!data.recipientType || data.recipientType === 'องค์กร')
      },
      recipientPerson: {
        control: Select,
        width: 8,
        options: personReceiveOptions,
        search: true,
        disabled:
          !data.recipientOrganize &&
          (!data.recipientType || data.recipientType === 'องค์กร')
      },
      typeOffice: { control: Select, options: typeOfficeOption, width: 8 },
      typeProject: { control: Select, options: typeProjectOption, width: 5 },
      seriesProject: {
        control: Select,
        options: typeSeriesProjectOption,
        width: 13
      },
      numberOfGroupBudget: { control: Select, options: optionGroup5, width: 8 },
      seriesConnect: {
        control: Select,
        options: listProjectConnectSeq1,
        width: 11,
        disabled: !data.seqConnect || data.seqConnect === '1'
      },
      seqConnect: { control: Select, options: seriesContinueProjeect, width: 5 }
    }

    Object.keys(content).forEach(key => {
      content[key]['label'] = localTh('proposal', key)
      content[key].name = key
      content[key].onChange = this.handleChange
      if (content[key].control === Input && content[key].name === 'nameTh') {
        content[key].value = data[key] || ''
      } else if (content[key].name === 'funding') {
        content[key].value = {
          year: data['yearBudget'],
          group: data['groupsPlan'],
          plan: data['plan'],
          activity: data['activity'],
          valueGroup: data['valueGroup'],
          valuePlan: data['valuePlan'],
          valueActivity: data['valueActivity']
        }
      } else if (content[key].name === 'funding1') {
        content[key].value = {
          year: data['yearBudget1'],
          group: data['groupsPlan1'],
          plan: data['plan1'],
          activity: data['activity1'],
          valueGroup: data['valueGroup1'],
          valuePlan: data['valuePlan1'],
          valueActivity: data['valueActivity1']
        }
      } else if (content[key].name === 'funding2') {
        content[key].value = {
          year: data['yearBudget2'],
          group: data['groupsPlan2'],
          plan: data['plan2'],
          activity: data['activity2'],
          valueGroup: data['valueGroup2'],
          valuePlan: data['valuePlan2'],
          valueActivity: data['valueActivity2']
        }
      } else if (content[key].name === 'funding3') {
        content[key].value = {
          year: data['yearBudget3'],
          group: data['groupsPlan3'],
          plan: data['plan3'],
          activity: data['activity3'],
          valueGroup: data['valueGroup3'],
          valuePlan: data['valuePlan3'],
          valueActivity: data['valueActivity3']
        }
      } else if (content[key].name === 'funding4') {
        content[key].value = {
          year: data['yearBudget4'],
          group: data['groupsPlan4'],
          plan: data['plan4'],
          activity: data['activity4'],
          valueGroup: data['valueGroup4'],
          valuePlan: data['valuePlan4'],
          valueActivity: data['valueActivity4']
        }
      } else if (
        key === 'manager' ||
        key === 'recipientOrganize' ||
        key === 'recipientPerson'
      ) {
        content[key].onSearchChange = this.handleSearch
        content[key].value = data[key] || ''
      } else {
        content[key].value = data[key] || ''
      }
      if (tors && tors.currentData) {
        if (content[key].control === Select && content[key].name === 'tor_id') {
          content[key].value = tors.currentData._id || ''
        }
      }
    })

    return (
      <Fragment>
        <HeaderContent title={data.nameTh} subtitle='ข้อมูลพื้นฐานโครงการ' />
        <Form error={validate.error}>
          {this._renderFormField(content.tor_id)}
          <Checkbox
            name='sameNameTor'
            label='ใช้ชื่อเดียวกับ TOR'
            onChange={this.handleChange}
          />
          <Form.Input
            name='nameTh'
            label='ชื่อโครงการ (ไม่ต้องเติมคำว่า "โครงการ")*'
            onChange={this.handleChange}
            value={data.nameTh || ''}
            readOnly={checkReadOnly}
            error={validate.attrs['nameTh']}
          />
          <Form.Group>
            {this._renderFormField(content.typeOffice)}
            {this._renderFormField(content.typeProject)}
            {role === 'Admin' && <Form.Field width={3}>
              <label>เพิ่มประเภทโครงการ</label>
              <Button
                content='เพิ่ม'
                color='green'
                icon='plus'
                onClick={this.handleModalTypeProject}
                disabled={role !== 'Admin'}
              />
            </Form.Field>}
          </Form.Group>
          <Form.Group>
            {data.typeProject === 3 &&
              this._renderFormField(content.seriesProject)}
            {data.typeProject === 3 && (
              <Form.Field width={3}>
                <label>เพิ่มชุดโครงการ</label>
                <Button
                  content='เพิ่ม'
                  color='green'
                  icon='plus'
                  onClick={this.handleModalSeriesProject}
                  disabled={role !== 'Admin'}
                />
              </Form.Field>
            )}
            {data.typeProject === 5 &&
              this._renderFormField(content.seqConnect)}
            {data.typeProject === 5 &&
              this._renderFormField(content.seriesConnect)}
          </Form.Group>
          {data.typeProject === 5 && data.seqConnect === '1' &&
            (<Form.Group>
              <Form.Field>
                <label>{'วันที่เริ่มต้น (ทุนต่อเนื่อง)'}</label>
                <DayPicker
                  ser={'startDate_connect'}
                  name={'startDate_connect'}
                  max={data.endDate_connect}
                  onDayChange={this.handleDayChange}
                  data={data.startDate_connect || ''}
                />
              </Form.Field>
              <Form.Field>
                <label>{'วันที่สิ้นสุด (ทุนต่อเนื่อง)'}</label>
                <DayPicker
                  ser={'endDate_connect'}
                  name={'endDate_connect'}
                  min={data.startDate_connect}
                  onDayChange={this.handleDayChange}
                  data={data.endDate_connect || ''}
                />
              </Form.Field>
              <Header style={{ fontFamily: 'supermarket', marginLeft: 20 }}>
                ระยะเวลาดำเนินการ (ทุนต่อเนื่อง) :{' '}
                {data['operationTime_connect']
                  ? data['operationTime_connect']
                  : 'ยังไม่ได้ระบุเวลาเริ่มต้น/สิ้นสุด'}
              </Header>
            </Form.Group>)}
          {data.typeProject === 5 && data.seqConnect === '1' &&
            (<Form.Group>
              <Form.Field error={validate.attrs['askBudget_connect']} width={8}>
                <label>{'งบประมาณ (ทุนต่อเนื่อง)'}</label>
                <Cleave
                  name='askBudget_connect'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.askBudget_connect || ''}
                />
              </Form.Field>
            </Form.Group>)}
          <Form.Group>
            <Form.Field>
              <label>{'วันที่เริ่มต้น'}</label>
              <DayPicker
                ser={'startDate'}
                name={'startDate'}
                max={data.endDate}
                onDayChange={this.handleDayChange}
                data={data.startDate || tors.currentData.startDate || ''}
              />
            </Form.Field>
            <Form.Field>
              <label>{'วันที่สิ้นสุด'}</label>
              <DayPicker
                ser={'endDate'}
                name={'endDate'}
                min={data.startDate}
                onDayChange={this.handleDayChange}
                data={data.endDate || tors.currentData.endDate || ''}
              />
            </Form.Field>
            <Header style={{ fontFamily: 'supermarket', marginLeft: 20 }}>
              ระยะเวลาดำเนินการ :{' '}
              {data['operationTime']
                ? data['operationTime']
                : 'ยังไม่ได้ระบุเวลาเริ่มต้น/สิ้นสุด'}
            </Header>
          </Form.Group>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content={'หมวดงบประมาณโครงการ 1'}
              as='h4'
              icon='money'
            />
            <Form.Group widths='equal'>
              {this._renderFormField(content.funding)}
            </Form.Group>
            <Form.Group>
              <Form.Field error={validate.attrs['budget']} width={8}>
                <label>{'งบประมาณ'}</label>
                <Cleave
                  name='askBudget'
                  maxLength='16'
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  onChange={this.onMoneyChange.bind(this)}
                  value={data.budget || ''}
                />
              </Form.Field>
            </Form.Group>
          </Segment>

          {data.numberOfGroupBudget > 1 && (
            <Segment>
              <Header
                style={{ fontFamily: 'supermarket' }}
                content={'หมวดงบประมาณโครงการ 2'}
                as='h4'
                icon='money'
              />
              <Form.Group widths='equal'>
                {this._renderFormField(content.funding1)}
              </Form.Group>
              <ComponentSetBudget
                askBudget={data.askBudget1}
                otherBudgetDropdown={data.otherBudgetDropdown1}
                nameAskBudget='askBudget1'
                nameotherDropdown='otherBudgetDropdown1'
                nameOtherBudget='otherBudget1'
                nameOtherBudgetItem='otherBudgetItem1'
                otherBudget={data.otherBudget1}
                otherBudgetItem={data.otherBudgetItem1}
                onDatatoChange={this.handleChange}
                onMoneyToChange={this.onMoneyChange}
              />
            </Segment>
          )}
          {data.numberOfGroupBudget > 2 && (
            <Segment>
              <Header
                style={{ fontFamily: 'supermarket' }}
                content={'หมวดงบประมาณโครงการ 3'}
                as='h4'
                icon='money'
              />
              <Form.Group widths='equal'>
                {this._renderFormField(content.funding2)}
              </Form.Group>
              <ComponentSetBudget
                askBudget={data.askBudget2}
                otherBudgetDropdown={data.otherBudgetDropdown2}
                nameAskBudget='askBudget2'
                nameotherDropdown='otherBudgetDropdown2'
                nameOtherBudget='otherBudget2'
                nameOtherBudgetItem='otherBudgetItem2'
                otherBudget={data.otherBudget2}
                otherBudgetItem={data.otherBudgetItem2}
                onDatatoChange={this.handleChange}
                onMoneyToChange={this.onMoneyChange}
              />
            </Segment>
          )}
          {data.numberOfGroupBudget > 3 && (
            <Segment>
              <Header
                style={{ fontFamily: 'supermarket' }}
                content={'หมวดงบประมาณโครงการ 4'}
                as='h4'
                icon='money'
              />
              <Form.Group widths='equal'>
                {this._renderFormField(content.funding3)}
              </Form.Group>
              <ComponentSetBudget
                askBudget={data.askBudget3}
                otherBudgetDropdown={data.otherBudgetDropdown3}
                nameAskBudget='askBudget3'
                nameotherDropdown='otherBudgetDropdown3'
                nameOtherBudget='otherBudget3'
                nameOtherBudgetItem='otherBudgetItem3'
                otherBudget={data.otherBudget3}
                otherBudgetItem={data.otherBudgetItem3}
                onDatatoChange={this.handleChange}
                onMoneyToChange={this.onMoneyChange}
              />
            </Segment>
          )}
          {data.numberOfGroupBudget > 4 && (
            <Segment>
              <Header
                style={{ fontFamily: 'supermarket' }}
                content={'หมวดงบประมาณโครงการ 5'}
                as='h4'
                icon='money'
              />
              <Form.Group widths='equal'>
                {this._renderFormField(content.funding4)}
              </Form.Group>
              <ComponentSetBudget
                askBudget={data.askBudget4}
                otherBudgetDropdown={data.otherBudgetDropdown4}
                nameAskBudget='askBudget4'
                nameotherDropdown='otherBudgetDropdown4'
                nameOtherBudget='otherBudget4'
                nameOtherBudgetItem='otherBudgetItem4'
                otherBudget={data.otherBudget4}
                otherBudgetItem={data.otherBudgetItem4}
                onDatatoChange={this.handleChange}
                onMoneyToChange={this.onMoneyChange}
              />
            </Segment>
          )}
          <Modal
            style={{ marginTop: 80 }}
            open={openModalPerson}
            // closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon
          >
            {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
            <Modal.Content>
              <ComponentPerson
                onCloseModalPerson={this.close}
                openModalPerson={openModalPerson}
              />
            </Modal.Content>
          </Modal>
          <Modal
            style={{ marginTop: 50 }}
            open={openModalOrganization}
            closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon
          >
            <Modal.Content>
              <ComponentOrganization
                onCloseModalPerson={this.close}
                openModalOrganization={true}
              />
            </Modal.Content>
          </Modal>
          <Modal
            style={{ marginTop: 50 }}
            open={this.state.typeProjectModal}
            closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon
          >
            <Modal.Content>
              <ListTypeProject onClose={this.close} />
            </Modal.Content>
          </Modal>
          <Modal
            style={{ marginTop: 50 }}
            open={this.state.seriesProjectModal}
            closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon
          >
            <Modal.Content>
              <ListSeriesProject onClose={this.close} year={thisYear} />
            </Modal.Content>
          </Modal>
          <Modal
            style={{ marginTop: 50 }}
            open={this.state.openModalListOrganization}
            closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon
          >
            <Modal.Content>
              <ModalListOrganization
                onClose={this.close}
                onListIndividual={this.handleModalListIndividuals}
              />
            </Modal.Content>
          </Modal>
          <Modal
            style={{ marginTop: 50 }}
            open={this.state.openModalListIndividual}
            closeOnDimmerClick={false}
            onClose={this.closeModalIndividuals}
            closeIcon
          >
            <Modal.Content>
              <ModalListIndividuals
                onClose={this.closeModalIndividuals}
                organizationId={organizationId}
              />
            </Modal.Content>
          </Modal>
          <Segment>
            <Header
              style={{ fontFamily: 'supermarket' }}
              content='ภาคีร่วมดำเนินงาน'
              as='h4'
              icon='user'
            />
            <Form.Group>
              {this._renderFormField(content.recipientType)}
              {data.recipientType === 'องค์กร' &&
                this._renderFormField(content.recipientOrganize)}
            </Form.Group>
            <Form.Group>
              {this._renderFormField(content.recipientPerson)}
              {this._renderFormField(content.manager)}
            </Form.Group>
            <Button
              style={{ fontFamily: 'supermarket' }}
              type='button'
              color='green'
              content='บุคลากร'
              icon='plus'
              onClick={this.handleComponentPerson}
            />
            <Button
              style={{ fontFamily: 'supermarket' }}
              type='button'
              color='green'
              content='หน่วยงาน'
              icon='plus'
              onClick={this.handleComponentOrganization}
            />
            <Button
              style={{ fontFamily: 'supermarket' }}
              type='button'
              color='green'
              content='รายชื่อองค์กร'
              icon='list'
              onClick={this.handleListOrganization}
            />
          </Segment>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
          {
            <Button
              style={{ fontFamily: 'supermarket' }}
              color='blue'
              content='บันทึก'
              icon='save'
              // disabled={validate.error} loading={currentState.isSaving}
              onClick={this.handleSubmit}
            />
          }
        </Form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  form: state.proposalForm,
  currentState: state.projects,
  projects: state.projects.data,
  tors: state.tors,
  organizations: state.organizations,
  individuals: state.individuals,
  personReceive: state.individuals_w,
  counter: state.counter,
  role: state.role,
  auth: state.auth,
  typeProject: state.typeproject
})

const mapDispatchToProps = dispatch => ({
  listOrganizations: query =>
    dispatch(organizations_wActions.fetchList({ query: query, limit: 20 })),
  listPersonReceive: id =>
    dispatch(individualsActions.fetchList({ query: { organization: id } })),
  listPersonByOrganization: id =>
    dispatch(individuals_wActions.fetchList({ query: { organization: id } })),
  listPersonFreelance: query =>
    dispatch(individuals_wActions.fetchList({ query: query, limit: 20 })),
  listPersonFreelanceReceive: query =>
    dispatch(individualsActions.fetchList({ query: query, limit: 20 })),
  queryTors: () =>
    dispatch(torActions.fetchList({ query: { status: 'อนุมัติแล้ว' } })),
  addProposal: data => dispatch(projectActions.save(data)),
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: query => dispatch(projectActions.fetchList(query)),
  getListProposal: id => dispatch(projectActions.fetchOne(id)),
  listBudget: () => dispatch(fundingsActions.fetchList({ sort: { year: -1 } })),
  genId: name => dispatch(generate(name)),
  getTor: id => dispatch(torActions.fetchOne(id)),
  getSeriesProject: () => dispatch(typeProject.fetchList()),
  getTypeProject: id => dispatch(typeProject.fetchOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentAddProject)
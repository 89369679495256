import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Header,
  Segment,
  Button,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import { actions as senPOToAXActions } from 'Redux/reducers/send_PO_AX'
// import { actions as senVendorToAXActions } from 'Redux/reducers/send_vendor_AX'
// import { DatePicker as DayPicker } from 'Utils'
import { ThaiDate } from 'Utils'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

class TableProject extends Component {
  constructor (props) {
    super(props)
    this.state = {
      next: 0,
      query: {
        sort: { creatdate: 1 },
        query: {},
        limit: 10000,
        skip: 0
      },
      length: 0,
      nameTh: '',
      recipientOrganizeName: '',
      recipientPersonName: '',
      managerName: '',
      startDate: 0,
      endDate: 0,
      academic: '',
      checkQueryInvidual: false
    }
  }

  componentDidMount = () => {
    let { query } = this.state
    this.props.allProject(query)
  }

  componentWillReceiveProps = nextProps => {}

  format (input) {
    if (!input && input === 0) {
      input = ''
    }
    var num = input.replace(/,/g, '')
    if (!isNaN(num)) {
      if (num.indexOf('.') > -1) {
        num = num.split('.')
        num[0] = num[0]
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        if (num[1].length > 2) {
          // alert('You may only enter two decimals!');
          num[1] = num[1].substring(0, num[1].length - 1)
        }
        input = num[0] + '.' + num[1]
        return input
      } else {
        input = num
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
          .split('')
          .reverse()
          .join('')
          .replace(/^[,]/, '')
        return input
      }
    } else {
      // alert('You may enter only numbers in this field!');
      return input.substring(0, input.length - 1)
    }
  }

  handleDayChange = (name, index, date) => {
    let { query, startDate, endDate } = this.state
    if (name === 'startDate') {
      startDate = date._d.getTime()
      query.query.startDate = { $gte: startDate }
      this.setState({ query, startDate })
    } else if (name === 'endDate') {
      endDate = date._d.getTime()
      query.query.endDate = { $lte: endDate }
      this.setState({ query, endDate })
    }
  }

  handleChange = (e, { name, value }) => {
    let {
      query,
      nameTh,
      recipientOrganizeName,
      recipientPersonName,
      managerName,
      startDate,
      endDate,
      academic
    } = this.state

    if (
      name === 'budget' ||
      name === 'recipientType' ||
      name === 'typeOffice' ||
      name === 'yearBudget' ||
      name === 'status'
    ) {
      query.query[name] = value
      if (query.query.recipientType === 'บุคคล') {
        delete query.query.recipientOrganizeName
      }
      this.setState({ query })
    } else if (name === 'nameTh') {
      nameTh = value
      query.query.nameTh = { $regex: value }
      this.setState({ query, nameTh })
    } else if (name === 'managerName') {
      managerName = value
      query.query.managerName = { $regex: value }
      this.setState({ query, managerName })
    } else if (name === 'recipientOrganizeName') {
      recipientOrganizeName = value
      query.query.recipientOrganizeName = { $regex: value }
      this.setState({ query, recipientOrganizeName })
    } else if (name === 'recipientPersonName') {
      recipientPersonName = value
      query.query.recipientPersonName = { $regex: value }
      this.setState({ query, recipientPersonName })
    } else if (name === 'startDate') {
      startDate = value
      query.query.startDate = { $gte: value }
      this.setState({ query, startDate })
    } else if (name === 'endDate') {
      endDate = value
      query.query.endDate = { $lte: value }
      this.setState({ query, endDate })
    } else if (name === 'academic') {
      academic = value
      query.query.academic = { $regex: value }
      this.setState({ query, academic })
    }
  }

  handleSearch = () => {}

  handleReset = () => {
    let { query } = this.state
    query = {
      sort: {},
      query: {},
      limit: 10000,
      skip: 0
    }
    this.setState({
      query
    })
  }

  render () {
    // let { projectsIn, fundings, typeOffice } = this.props
    let { projectsIn } = this.props
    const {
      length,
      academic
    } = this.state
    let footer = []
    for (let i = 0; i < length; i++) {
      footer.push({})
    }

    projectsIn &&
      projectsIn.forEach((item, idx) => {
        let text = ''
        if (item.screen && item.screen.length > 0) {
          item.screen.forEach((i, index) => {
            if (i.nameProfessional && i.nameProfessional !== '') {
              if (index !== item.screen.length - 1)
                text += index + 1 + '. ' + i.nameProfessional + ',\r\n'
              else text += index + 1 + '. ' + i.nameProfessional
            }
          })
        }
        item.screentext = text
        let textList = ''
        if (item.withDrawTime && item.withDrawTime.length > 0) {
          item.withDrawTime.forEach((i, index) => {
            if (i.submitTime) {
              if (index !== item.withDrawTime.length - 1)
                textList +=
                  index + 1 + '. ' + ThaiDate('month', i.submitTime) + '\r\n'
              else
                textList += index + 1 + '. ' + ThaiDate('month', i.submitTime)
            }
          })
        }
        item.withDrawTimetext = textList
      })

    return (
      <Segment>
        <Header
          as='h1'
          style={{ fontFamily: 'supermarket' }}
          content='รายละเอียดโครงการ'
          textAlign='center'
        />
        <Dimmer active={projectsIn.length === 0}>
          <Loader />
        </Dimmer>

        <Form>
          <Form.Group>
          <Form.Input
              label='นักวิชาการ/ผู้ปฏิบัติงาน'
              width={4}
              name='academic'
              onChange={this.handleChange}
              value={academic}
            />
            <Form.Input
              label='เลขที่สัญญา'
              width={4}
              name='academic'
              onChange={this.handleChange}
              value={academic}
            />
          </Form.Group>
          <Form.Field style={{ textAlign: 'center' }}>
            <Button
              content='ล้างการค้นหา'
              icon='sync'
              size='big'
              onClick={this.handleReset}
              style={{ fontFamily: 'supermarket' }}
            />
            <Button
              color='green'
              content='ค้นหา'
              icon='search'
              size='big'
              onClick={this.handleSearch}
              style={{ fontFamily: 'supermarket' }}
            />
          </Form.Field>
          {projectsIn.length > 0 && (
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='table-to-xls'
              filename='reportPMStablexls'
              sheet='tablexls'
              buttonText='Download as XLS'
            />
          )}
          <Segment
            style={{ overflow: 'auto scroll', width: '100%', height: '500px' }}
          >
            <pre>
              <table id='table-to-xls' border='1px black'>
                <thead>
                  <tr>
                    <th>ลำดับงวด</th>
                    <th>ชื่อนักวิชาการ</th>
                    <th>เลขที่สัญญา</th>
                    <th>วันที่ทำสัญญา</th>
                    <th>เลขประจำตัวผู้เสียภาษี</th>
                    <th>คำอธิบาย</th>
                    <th>จำนวนเงิน</th>
                    <th>รหัสงบประมาณ</th>
                    <th>วันที่ลงบันทึกการส่งข้อมูล</th>
                  </tr>
                </thead>
                <tbody>
                  {projectsIn.length > 0 &&
                    projectsIn.map((item, idx) => (
                      // <Fragment key={idx}>
                      <tr key={idx}>
                        <td>{item.index}</td>
                        <td>{item.academic}</td>
                        <td>{item.noAgreement}</td>
                        <td>{item.accountingDate}</td>
                        <td>{item.cid}</td>
                        <td>{item.content}</td>
                        <td align='right'>
                          {this.format((item.unitprice || 0).toString())}
                        </td>
                        <td>{item.codeBudget}</td>
                        <td>{item.creatdate}</td>
                      </tr>
                      // </Fragment>
                    ))}
                </tbody>
              </table>
            </pre>
          </Segment>
        </Form>
      </Segment>
    )
  }
}
const mapStateToProps = state => ({
  projectsIn: state.send_PO_AX.data,
  countProjects: state.countproject.data,
  auth: state.auth,
  role: state.role,
  fundings: state.fundings.data,
  typeOffice: state.typeproject.data,
  individuals: state.individuals.data,
  organizations: state.organizations.data
})

const mapDispatchToProps = dispatch => ({
  allProject: query => dispatch(senPOToAXActions.fetchList({ ...query }))
})

export default connect(mapStateToProps, mapDispatchToProps)(TableProject)

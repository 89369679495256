import React, { Component, Fragment } from 'react'
import {
  Form,
  Header,
  Table,
  Button,
  Label,
  Modal,
  Confirm,
  Transition,
  Icon,
  Message
} from 'semantic-ui-react'
import Cleave from 'cleave.js/react'
import { GetServerTime as GetTime } from 'Utils'
import { ComponentPerson, ComponentOrganization } from 'Utils'
import { connect } from 'react-redux'
import { actions as projectActions } from 'Redux/reducers/projects'
import { HeaderContent } from 'Components/Header'
import { ValidateArray as validateArray } from 'Utils'
import { constraints as WithdrawTimeArrayConstraints } from './RegisConstraints'
import { constraints as WithdrawTimeConstraints } from './RegisConstraints'
import { DatePicker as DayPicker } from 'Utils'
import { ThaiDate } from 'Utils'
import { localTh } from 'Utils'
import { formatcomma } from 'Utils'
import validatejs from 'validate.js'
import SendProposal from './SendProposal'
import { generate } from 'Redux/reducers/counting'
import { actions as individualsActions } from 'Redux/reducers/individuals'
import { actions as organizationsActions } from 'Redux/reducers/organizations'
import jwt from 'jsonwebtoken'

let gettime

let dataStart

let dataEnd

let budget = ''
// let otherbudget = ''

class WithdrawTime extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedRow: null,
      openConfirm: false,
      data: {},
      validate: { error: false, validateError: {}, attrs: {} },
      onSaveSuccess: false,
      openConfirmDel: false,
      onSaveNotSuccess: false,
      indexDelete: '',
      countNoAgree: '',
      checkNoAgree: false,
      notAttach: false,
      sendProposal: false
    }
  }

  componentDidMount () {
    const { match } = this.props
    var promise1 = new Promise(function (resolve, reject) {
      GetTime(receive => {
        gettime = receive
        resolve(gettime)
      })
    })

    promise1.then(function (value) {
      gettime = value
    })

    if (match.params.id) {
      this.props.getProposal(match.params.id)
    }
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps (nextProps) {
    let { individuals, organization } = nextProps
    if (nextProps.currentProposal) {
      let tmp = { ...nextProps.currentProposal }
      let { data, countNoAgree } = this.state

      var dayS = new Date(tmp.startDate).getDate() - 45
      var monthS = new Date(tmp.startDate).getMonth()
      var yearS = new Date(tmp.startDate).getFullYear()
      dataStart = new Date(yearS, monthS, dayS)
      var dayE = new Date(tmp.endDate).getDate() + 45
      var monthE = new Date(tmp.endDate).getMonth()
      var yearE = new Date(tmp.endDate).getFullYear()
      dataEnd = new Date(yearE, monthE, dayE)
      if (!tmp.withDrawTime) {
        tmp.withDrawTime = []
        tmp.withDrawTime.push({
          sum: 0,
          startDate: tmp.startDate,
          endDate: tmp.endDate,
          content:
            'กสศ. จ่ายเงินสนับสนุนภายใน 15 วัน เมื่อได้รับสัญญาและเมื่อลงนามในสัญญา'
        })
      }

      if (tmp.numberOfGroupBudget === 1) {
        budget = parseFloat(tmp.askBudget.replace(/,/g, ''), 10)
      } else if (tmp.numberOfGroupBudget === 2) {
        budget =
          parseInt(tmp.askBudget.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget1.replace(/,/g, ''), 10)
      } else if (tmp.numberOfGroupBudget === 3) {
        budget =
          parseInt(tmp.askBudget.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget1.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget2.replace(/,/g, ''), 10)
      } else if (tmp.numberOfGroupBudget === 4) {
        budget =
          parseInt(tmp.askBudget.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget1.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget2.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget3.replace(/,/g, ''), 10)
      } else if (tmp.numberOfGroupBudget === 5) {
        budget =
          parseInt(tmp.askBudget.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget1.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget2.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget3.replace(/,/g, ''), 10) +
          parseInt(tmp.askBudget4.replace(/,/g, ''), 10)
      } else {
        budget = parseFloat(tmp.askBudget.replace(/,/g, ''), 10)
      }

      tmp.budget = budget.toString()

      if (!tmp.approveBuget) tmp.approveBuget = tmp.budget
      else tmp.budget = tmp.approveBuget

      if (tmp.recipientType === 'องค์กร' || tmp.recipientType === 'คณะบุคคล') {
        if (organization) {
          if (organization._id !== tmp.recipientOrganize) {
            this.props.getOrganization(tmp.recipientOrganize)
          }
        } else {
          this.props.getOrganization(tmp.recipientOrganize)
        }
      } else {
        if (individuals[0]) {
          if (individuals[0]._id !== tmp.recipientPerson) {
            this.props.getPerson({ _id: tmp.recipientPerson })
          }
        } else {
          this.props.getPerson({ _id: tmp.recipientPerson })
        }
      }
      this.setState({ data: { ...tmp, ...data }, countNoAgree })
    }
  }

  handleSelectRow = selectedRow => {
    // let { currentProposal } = this.props

    if (selectedRow === this.state.selectedRow) selectedRow = null
    this.setState({ selectedRow, modalAttachment: false })
  }

  handleEditRow = (idx, e, { name, value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) {
    //   data = { ...currentProposal, ...data }
    // }

    let _withDrawTime = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        currentProposal.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      } else {
        data.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      }
    }

    _withDrawTime[idx][name] = value
    _withDrawTime[idx].sum =
      parseFloat(
        (_withDrawTime[idx].mainCom || '').replace(/,/g, '') || 0,
        10
      ) +
        (parseFloat((_withDrawTime[idx].supCom || '').replace(/,/g, ''), 10) ||
          0) +
        (parseFloat((_withDrawTime[idx].manage || '').replace(/,/g, ''), 10) ||
          0) +
        (parseFloat((_withDrawTime[idx].charge || '').replace(/,/g, ''), 10) ||
          0) || 0

    // validate array
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    let tmp = { ...data, withDrawTime: _withDrawTime }

    this.setState({ data: tmp, validate })
  }

  handleAddRow = () => {
    let { data } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) data = { ...currentProposal, ...data }

    // if (!currentProposal.withDrawTime) data['withDrawTime'] = [];

    let _withDrawTime = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _withDrawTime = currentProposal.withDrawTime.slice()
      } else {
        _withDrawTime = data.withDrawTime.slice()
      }
    }

    if (_withDrawTime.length === 0) {
      _withDrawTime.push({
        content:
          'กสศ. จ่ายเงินสนับสนุนภายใน 15 วัน เมื่อได้รับสัญญาและเมื่อลงนามในสัญญา',
        mainCom: 0,
        supCom: 0,
        manage: 0,
        charge: 0,
        sum: 0,
        take: '',
        transfer: ''
      })
    } else {
      _withDrawTime.push({
        content:
          'กสศ. จะจ่ายเงินอุดหนุนให้ภายใน 15 วันหลักจากได้รับเอกสารและเห็นชอบกับรายการ ดังนี้',
        mainCom: 0,
        supCom: 0,
        manage: 0,
        charge: 0,
        sum: 0,
        take: '',
        transfer: ''
      })
    }

    let tmp = { ...data, withDrawTime: _withDrawTime }
    this.setState({ data: tmp, selectedRow: _withDrawTime.length - 1 })

    // data.withDrawTimeAgreement.push({ sum: 0, content: 'กสศ. จะจ่ายเงินอุดหนุนให้ภายใน 15 วันหลักจากได้รับเอกสารและเห็นชอบกับรายการ ดังนี้' });
    // this.setState({ data })
  }

  handleDeleteRow = () => {
    let { data, indexDelete, validate } = this.state
    let { currentProposal } = this.props

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    let _withDrawTime = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        _withDrawTime = currentProposal.withDrawTime.slice()
      } else {
        _withDrawTime = data.withDrawTime.slice()
      }
    }

    _withDrawTime.splice(indexDelete, 1)
    let tmp = { ...data, withDrawTime: _withDrawTime }
    this.setState({ data: tmp, openConfirmDel: false, selectedRow: null })
  }

  handleDayChangeRow = (name, index, date) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) {
    //   data = { ...currentProposal, ...data }
    // }

    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    let _withDrawTime = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        currentProposal.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      } else {
        data.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      }
    }

    data.withDrawTime[index][name] = date._d.getTime()
    this.setState({ data, validate })
  }

  getLocalUser = () => {
    const token = localStorage.getItem('id_token')
    var content = jwt.decode(token)
    return content
  }

  handleConfirm = condition => {
    let { data, validate } = this.state
    let { currentProposal, role, auth } = this.props
    if (this.props.currentProposal) {
      data = { ...currentProposal, ...data }
      validate.attrs = {}
      validate.error = false

      // if (data.withDrawTimeAgreement) {
      //   data.withDrawTimeAgreement.forEach(x => {
      //     if (x.mainCom === '0') delete x.mainCom
      //     if (x.manage === '0') delete x.manage
      //     if (x.charge === '0') delete x.charge
      //     if (x.supCom === '0') delete x.supCom
      //   });
      // }
      var promise1 = new Promise(function (resolve, reject) {
        GetTime(receive => {
          gettime = receive
          resolve(gettime)
        })
      })

      promise1.then(function (value) {
        if (value) {
          gettime = value
        }
      })

      let user = auth.isAuthenticated && auth.user && auth.user.user
      if (!user && auth.isAuthenticated) {
        user = this.getLocalUser()
      }

      if (role === 'Admin') {
        data['adminEdit'] = user._id
        data['adminEditTime'] = gettime
      }

      validate['validateError'] = validatejs(
        data,
        WithdrawTimeConstraints.WithdrawTimeConstraints
      )

      let validateArrayError = validateArray(
        data.withDrawTime,
        WithdrawTimeArrayConstraints.WithdrawTimeArrayConstraints
      )

      if (!validateArrayError) validateArrayError = {}

      validate['validateError'] = {}

      if (
        Object.keys(validateArrayError).length !== 0 &&
        validateArrayError.constructor === Object
      ) {
        validate.error = true
        Object.keys(validateArrayError).forEach(index => {
          if (validateArrayError[index].content) {
            validate['validateError']['withdrawcontent'] =
              validateArrayError[index].content
          }
          if (validateArrayError[index].mainCom) {
            validate['validateError']['mainCom'] =
              validateArrayError[index].mainCom
          }
          if (validateArrayError[index].manage) {
            validate['validateError']['manage'] =
              validateArrayError[index].manage
          }
          if (validateArrayError[index].startDate) {
            validate['validateError']['withdrawtimeStartDate'] =
              validateArrayError[index].startDate
          }
          if (validateArrayError[index].endDate) {
            validate['validateError']['withdrawtimeEndDate'] =
              validateArrayError[index].endDate
          }
          if (validateArrayError[index].submitTime) {
            validate['validateError']['submitTime'] =
              validateArrayError[index].submitTime
          }
          validate.attrs['withDrawTime' + index] = true
        })
      }

      if (!data.withDrawTime) data.withDrawTime = []
      if (data.withDrawTime.length === 0) {
        validate.error = true
        validate['validateError']['withDrawTimeAgreement'] = []
        validate['validateError']['withDrawTimeAgreement'].push(
          'โปรดระบุอย่างน้อย 1งวด'
        )
      }

      // if(!data.purpose)
      // if(!data.evaluation)
      if (!data.objective) data.objective = []
      if (!data.product) data.product = []
      if (!data.projectGroupArea) data.projectGroupArea = []

      if (data.product.length === 0) {
        validate.error = true
        validate['validateError']['product'] = []
        validate['validateError']['product'].push(
          'คุณยังไม่ได้ระบุ ผลผลิตข้อกำหนด โปรดกลับไปที่เมนู เป้าประสงค์'
        )
      }

      if (data.objective.length === 0) {
        validate.error = true
        validate['validateError']['objective'] = []
        validate['validateError']['objective'].push(
          'คุณยังไม่ได้ระบุ วัตถุประสงค์ โปรดกลับไปที่เมนู เป้าประสงค์'
        )
      }

      if (data.projectGroupArea.length === 0) {
        validate.error = true
        validate['validateError']['projectGroupArea'] = []
        validate['validateError']['projectGroupArea'].push(
          'คุณยังไม่ได้ระบุ พื้นที่ดำเนินการ โปรดกลับไปที่เมนู พื้นที่ดำเนินการ'
        )
      }

      if (!data.purpose) {
        validate.error = true
        validate['validateError']['purpose'] = []
        validate['validateError']['purpose'].push(
          'คุณยังไม่ได้ระบุ เป้าประสงค์ โปรดกลับไปที่เมนู เป้าประสงค์'
        )
      }

      if (!data.evaluation) {
        validate.error = true
        validate['validateError']['evaluation'] = []
        validate['validateError']['evaluation'].push(
          'คุณยังไม่ได้ระบุ การประเมินผล โปรดกลับไปที่เมนู เป้าประสงค์'
        )
      }

      if (
        Object.keys(validate['validateError']).length !== 0 &&
        validate['validateError'].constructor === Object
      ) {
        validate['error'] = true
        Object.keys(validate['validateError']).forEach(item => {
          validate.attrs[item] = true
        })
        this.setState({ validate, openConfirm: false })
      } else {
        let checkSum = 0
        let budget =
          parseFloat((data.approveBuget || '').replace(/,/g, ''), 10) || 0

        data.withDrawTime.forEach((item, idx) => {
          checkSum += item.sum
        })
        if (checkSum === budget) {
          validate.error = false
          validate['validateError'] = {}
          if (role !== 'Admin') data['status'] = 'กลั่นกรอง'
          if (role === 'Admin') {
            data['adminEdit'] = user._id
            data['adminEditTime'] = gettime
          } else {
            data['timestamp'] = gettime
          }
          data['haveWithDrawTime'] = true
          console.log(sessionStorage.getItem('attachSendProposal'))
          this.props.updateProposal(data, data._id)
          this.setState({ onSaveSuccess: true, openConfirm: false })
          setTimeout(() => {
            this.setState({ onSaveSuccess: false })
            if (condition === 'next') {
              this.props.history.push('/project/pagePromise/' + data._id)
            } else if (condition === 'back') {
              this.props.history.push('/project/pageScreen/' + data._id)
            }
          }, 3000)
        } else {
          this.setState({ onSaveNotSuccess: true, openConfirm: false })
          setTimeout(() => {
            this.setState({ onSaveNotSuccess: false })
          }, 5000)
        }
      }
    }
  }

  handleCancel = () => this.setState({ openConfirm: false })
  // handleSubmit = () => this.setState({ openConfirm: true })

  summaryRow = object => {
    let result = 0
    var nf = new Intl.NumberFormat()
    if (object) {
      result =
        (parseFloat((object.mainCom || '').replace(/,/g, ''), 10) || 0) +
          (parseFloat((object.supCom || '').replace(/,/g, ''), 10) || 0) +
          (parseFloat((object.manage || '').replace(/,/g, ''), 10) || 0) +
          (parseFloat((object.charge || '').replace(/,/g, ''), 10) || 0) || 0
    }
    return nf.format(result)
  }

  summary = array => {
    let result = 0
    var nf = new Intl.NumberFormat()
    if (array !== undefined) {
      array.forEach(r => (result += r.sum))
    }
    return nf.format(result)
  }

  handleNextPage = () => {
    this.handleConfirm('next')
  }

  handleBackPage = () => {
    this.handleConfirm('back')
  }

  getDate = date => {
    const thaiMount = [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน ',
      'ธันวาคม'
    ]
    var day = new Date(date).getDate()
    var month = new Date(date).getMonth()
    var year = new Date(date).getFullYear() + 543
    return day + ' ' + thaiMount[month] + ' พ.ศ. ' + year
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  openConfirm = (e, { name }) => {
    this.setState({ openConfirmDel: true, indexDelete: name })
  }

  close = () => this.setState({ openConfirmDel: false, selectedRow: null })

  approveBugetChange = e => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }

    let money = e.target.rawValue.replace('-', '')

    data[e.target.name] = money ? money.toString() : '0'
    data.budget = money ? money.toString() : '0'

    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false
    this.setState({ data, validate })
  }

  handleChange = (e, { name, value }) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (currentProposal) {
      data = { ...currentProposal, ...data }
    }
    data[name] = value

    validate['validateError'] = {}
    validate.attrs = {}
    validate.error = false
    this.setState({ data, validate })
  }

  onMoneyChange = (idx, e) => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    // if (currentProposal) {
    //   data = { ...currentProposal, ...data }
    // }
    let money = e.target.rawValue.replace('-', '')

    let _withDrawTime = []
    if (currentProposal) {
      if (Object.keys(data).length === 0) {
        currentProposal.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      } else {
        data.withDrawTime.forEach(item => {
          var tmp = { ...item }
          _withDrawTime.push(tmp)
        })
      }
    }

    _withDrawTime[idx][e.target.name] = money ? money.toString() : '0'

    _withDrawTime[idx].sum =
      parseFloat(
        (_withDrawTime[idx].mainCom || '').replace(/,/g, '') || 0,
        10
      ) +
        (parseFloat((_withDrawTime[idx].supCom || '').replace(/,/g, ''), 10) ||
          0) +
        (parseFloat((_withDrawTime[idx].manage || '').replace(/,/g, ''), 10) ||
          0) +
        (parseFloat((_withDrawTime[idx].charge || '').replace(/,/g, ''), 10) ||
          0) || 0

    // validate array
    validate.error = false
    validate.validateError = {}
    validate.attrs = {}

    let tmp = { ...data, withDrawTime: _withDrawTime }

    this.setState({ data: tmp, validate })
  }

  handleSendProposal = () => {
    this.setState({ openConfirm: true })
  }

  handleConfirmSubmitSend = () => {
    let { data, validate } = this.state
    let { currentProposal } = this.props
    if (this.props.currentProposal) {
      data = { ...currentProposal, ...data }
    }
    validate.attrs = {}
    validate.error = false
    // if (!data.noAgreement || data.noAgreement !== noAgreement) {
    //   data.noAgreement = noAgreement
    // }

    let sendProposalOptions = []
    let attachSendProposal = []

    sendProposalOptions.push(
      'แนวทางและข้อคิดเห็นของผู้ทรงคุณวุฒิ',
      'ไฟล์ผู้ทรงคุณวุฒิ (พร้อมลายเซ็น)',
      'เอกสารโครงการ',
      'เอกสารโครงการฉบับสมบูรณ์'
    )

    if (!data.attachSendProposal) data.attachSendProposal = []
    data.attachSendProposal.forEach(x => {
      attachSendProposal.push(x.typeOfAttach)
    })
    let checkAttach = false

    if (data.attachSendProposal.length === 0) {
      checkAttach = true
      // checkAttach = false
    } else {
      let compareAttach = sendProposalOptions.every(e =>
        attachSendProposal.includes(e)
      )
      if (compareAttach) {
        checkAttach = false
      } else {
        checkAttach = true
        // checkAttach = false
      }
    }

    if (checkAttach) {
      this.setState({ notAttach: true, openConfirm: false })
      setTimeout(() => {
        this.setState({ notAttach: false })
      }, 5000)
    } else {
      data.status = 'ทำสัญญาโครงการ'
      data['sentProposalTime'] = gettime
      // if(JSON.parse(sessionStorage.getItem('attachSendProposal'))) {
      //   data['attachSendProposal'] = JSON.parse(sessionStorage.getItem('attachSendProposal'))
      //   sessionStorage.setItem('attachSendProposal', null)
      // }
      this.props.updateProposal(data, data._id)
      this.setState({ sendProposal: true, openConfirm: false })
      setTimeout(() => {
        this.setState({ sendProposal: false })
      }, 3000)
    }
  }

  handleComponentRecipient = () => {
    this.setState({ openModalRecipient: true })
  }

  close = () => {
    this.setState({ openModalRecipient: false })
  }

  render () {
    let {
      selectedRow,
      data,
      openConfirm,
      validate,
      onSaveSuccess,
      onSaveNotSuccess,
      notAttach,
      sendProposal,
      openModalRecipient
    } = this.state
    const {
      currentProposal,
      currentState,
      role,
      individuals,
      organization
    } = this.props
    data = { ...currentProposal, ...data }

    let checkLockScreen =
      (currentProposal &&
        currentProposal.status !== 'ส่งข้อเสนอโครงการ' &&
        currentProposal.status !== 'กลั่นกรอง' &&
        role !== 'Admin') ||
      (currentProposal &&
        (currentProposal.status === 'ส่งข้อเสนอโครงการ' ||
          currentProposal.status === 'กลั่นกรอง') &&
        role !== 'Admin' &&
        role !== 'Academic' &&
        role !== 'Co-Academic')

    return (
      <Fragment>
        <HeaderContent
          title={data.nameTh}
          subtitle='ข้อมูลงวดงานงวดเงินโครงการ'
        />
        <Form error={validate.error}>
          <Header
            style={{ fontFamily: 'supermarket' }}
            as='h5'
            content='งวดงานงวดเงินดำเนินโครงการ '
            icon='money'
          />
          <Header
            style={{ fontFamily: 'supermarket' }}
            as='h5'
            content={
              'วันเริ่มต้นโครงการ : ' +
              this.getDate(data.startDate) +
              ' - วันสิ้นสุดโครงการ : ' +
              this.getDate(data.endDate) +
              ' งบประมาณที่เสนอ : ' +
              formatcomma(parseFloat(data.askBudget)) +
              ' บาท'
            }
          />
          <Form.Group inline>
            <Form.Field disabled={checkLockScreen}>
              <label>{'งบประมาณที่อนุมัติ'}</label>
              <Cleave
                name='approveBuget'
                maxLength='16'
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
                onChange={this.approveBugetChange.bind(this)}
                value={data.approveBuget || ''}
              />
            </Form.Field>
            <Form.Field disabled={checkLockScreen}>
              <label>
                {'เลขประจำตัวผู้เสียภาษี : ' +
                  ((data.recipientType === 'องค์กร' || data.recipientType === 'คณะบุคคล')
                    ? organization && (organization.cid || ' ยังไม่ได้ระบุ ')
                    : individuals[0] &&
                      (individuals[0].cid || ' ยังไม่ได้ระบุ '))}
              </label>
            </Form.Field>
            <Button
              content='ข้อมูลผู้เสียภาษี'
              color='blue'
              disabled={checkLockScreen}
              onClick={this.handleComponentRecipient}
            />
          </Form.Group>

          {/* <Header style={{ fontFamily: 'supermarket' }}
            as='h5' content='งวดงานงวดเงินที่ต้องการแบ่งจ่ายเงินเป็นระยะ' />
          <Form.Group>
            {data.withDrawTime && data.withDrawTime.map((item, idx) => (
              <Fragment key={idx}>
                <Form.Checkbox radio
                  label={'งวดที่ ' + (idx + 1)}
                  name='indexOfEdit'
                  value={idx}
                  checked={data.indexOfEdit === idx}
                  onChange={this.handleChange} />
              </Fragment>
            ))}
            <Form.Checkbox radio
              label={'ไม่ต้องการ'}
              name='indexOfEdit'
              value={'no'}
              checked={data.indexOfEdit === 'no'}
              onChange={this.handleChange} />
          </Form.Group> */}

          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  content='งวดที่'
                  textAlign='center'
                  width={3}
                />
                <Table.HeaderCell
                  content='ผลงานที่นำส่งและเงื่อนไข'
                  textAlign='center'
                  width={5}
                />
                <Table.HeaderCell
                  content='จำนวนเงิน'
                  textAlign='center'
                  width={2}
                />
                <Table.HeaderCell
                  content='กำหนดส่งงาน'
                  textAlign='center'
                  width={2}
                />
                <Table.HeaderCell content='ลบ' textAlign='center' width={2} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.withDrawTime &&
                data.withDrawTime.map((item, idx) => (
                  <Fragment key={idx}>
                    <Table.Row
                      onClick={this.handleSelectRow.bind(this, idx)}
                      error={validate.attrs['withDrawTime' + idx]}
                    >
                      {(selectedRow === idx && (
                        <Table.Cell>
                          <Label
                            color='green'
                            ribbon
                            content={'งวดที่ ' + (idx + 1)}
                          />
                        </Table.Cell>
                      )) || (
                        <Table.Cell textAlign='center'>
                          {'งวดที่ ' + (idx + 1)}
                          <br />
                          {(ThaiDate('month', item.startDate) ||
                            'ยังไม่กำหนด') +
                            ' - ' +
                            (ThaiDate('month', item.endDate) || 'ยังไม่กำหนด')}
                        </Table.Cell>
                      )}
                      <Table.Cell content={item.content} />
                      <Table.Cell
                        textAlign='center'
                        content={this.summaryRow(item)}
                      />
                      <Table.Cell
                        textAlign='center'
                        content={
                          ThaiDate('month', item.submitTime) || 'ยังไม่กำหนด'
                        }
                      />
                      <Table.Cell textAlign='center'>
                        <Button
                          disabled={checkLockScreen}
                          type='button'
                          name={idx}
                          color='red'
                          icon='close'
                          onClick={this.openConfirm}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {selectedRow === idx && (
                      <Table.Row active>
                        <Table.Cell colSpan={5}>
                          <Form.TextArea
                            disabled={checkLockScreen}
                            label='ผลงานที่นำส่งและเงื่อนไข'
                            name='content'
                            placeholder='กรุณาใส่ผลงาน'
                            value={item.content}
                            onChange={this.handleEditRow.bind(this, idx)}
                          />
                          <Form.Group widths='equal'>
                            <Form.Field disabled={checkLockScreen}>
                              <label>{'ค่าตอบแทนบุคลากรหลัก'}</label>
                              <Cleave
                                name='mainCom'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.mainCom || ''}
                                // readOnly={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') ||
                                //   (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                              />
                            </Form.Field>
                            <Form.Field disabled={checkLockScreen}>
                              <label>{'ค่าตอบแทนบุคลากรสนับสนุน'}</label>
                              <Cleave
                                name='supCom'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.supCom || ''}
                                // readOnly={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') ||
                                //   (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                              />
                            </Form.Field>
                          </Form.Group>
                          <Form.Group widths='equal'>
                            <Form.Field disabled={checkLockScreen}>
                              <label>{'ค่าดำเนินงาน'}</label>
                              <Cleave
                                name='manage'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.manage || ''}
                                // readOnly={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') ||
                                //   (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                              />
                            </Form.Field>
                            <Form.Field disabled={checkLockScreen}>
                              <label>{'ค่าธรรมเนียม'}</label>
                              <Cleave
                                name='charge'
                                maxLength='16'
                                options={{
                                  numeral: true,
                                  numeralThousandsGroupStyle: 'thousand'
                                }}
                                onChange={this.onMoneyChange.bind(this, idx)}
                                value={item.charge || ''}
                                // readOnly={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') ||
                                //   (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                              />
                            </Form.Field>
                          </Form.Group>
                          <Form.Group>
                            <Form.Field
                              disabled={checkLockScreen}
                              // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                            >
                              <label>{'ตั้งแต่วันที่'}</label>
                              <DayPicker
                                ser={'startDate'}
                                name={'startDate'}
                                // min={(data.withDrawTime[idx - 1] && data.withDrawTime[idx - 1].endDate) || dataStart}
                                min={dataStart}
                                max={item.endDate || dataEnd}
                                index={idx}
                                onDayChange={this.handleDayChangeRow}
                                data={item.startDate || ''}
                              />
                            </Form.Field>
                            <Form.Field
                              disabled={checkLockScreen}
                              // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                            >
                              <label>{'ถึงวันที่'}</label>
                              <DayPicker
                                ser={'endDate'}
                                name={'endDate'}
                                // min={item.startDate || (data.withDrawTime[idx - 1] && data.withDrawTime[idx - 1].endDate) || dataStart}
                                min={item.startDate || dataStart}
                                max={dataEnd}
                                index={idx}
                                onDayChange={this.handleDayChangeRow}
                                data={item.endDate || ''}
                              />
                            </Form.Field>
                            <Form.Field
                              disabled={checkLockScreen}
                              // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                            >
                              <label>{'กำหนดส่งผลงาน'}</label>
                              <DayPicker
                                ser={'submitTime'}
                                name={'submitTime'}
                                min={dataStart}
                                max={dataEnd}
                                index={idx}
                                onDayChange={this.handleDayChangeRow}
                                data={item.submitTime || ''}
                              />
                            </Form.Field>
                          </Form.Group>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Fragment>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='2'>
                  <Button
                    // disabled={(currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
                    color='green'
                    content='เพิ่ม'
                    onClick={this.handleAddRow}
                    type='button'
                  />
                </Table.HeaderCell>
                <Table.HeaderCell
                  colSpan='4'
                  content={
                    'จำนวนเงินรวมทุกงวด : ' +
                    this.summary(data.withDrawTime) +
                    ' บาท'
                  }
                />
              </Table.Row>
            </Table.Footer>
          </Table>
          {/* </Segment> */}
          <Button
            color='blue'
            content='บันทึกร่าง'
            icon='save'
            type='submit'
            disabled={!data.dateScreen || checkLockScreen}
            // disabled={(currentProposal && currentProposal.withDrawTimeAgreement && currentProposal.withDrawTimeAgreement.length === 0) ||
            //   (currentProposal && currentProposal.status !== 'ส่งข้อเสนอโครงการ' && role !== 'Admin') || (currentProposal && currentProposal.status === 'ส่งข้อเสนอโครงการ' && role !== 'Admin' && role !== 'Academic')}
            loading={currentState.isSaving}
            onClick={this.handleConfirm}
          />
          <Button
            type='button'
            color='blue'
            content='ส่งข้อเสนอโครงการ'
            icon='send'
            disabled={!data.haveWithDrawTime || checkLockScreen}
            onClick={this.handleSendProposal}
          />
          {/* <Confirm
            open={openConfirm}
            header='คำเตือน'
            content='เมื่อกดยืนยันแล้ว โครงการนี้ จะไม่สามารถกลับไปแก้ไขข้อมูลที่อยู่ในขั้นตอน ส่งข้อเสนอโครงการได้'
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirmSubmitSend}
          /> */}
          {openConfirm && (
            <SendProposal
              status={openConfirm}
              confirm={this.handleConfirmSubmitSend}
              cancel={this.handleCancel}
              year={data.yearBudget}
            />
          )}

          <Button
            className='button-bottom-right'
            type='button'
            color='blue'
            content='ย้อนกลับ'
            icon='step backward'
            // disabled={checkLockScreen}
            onClick={this.handleBackPage}
            disabled={!data.dateScreen || checkLockScreen}
          />
          {/* <Button
            className='button-bottom-right'
            type='button'
            color='blue'
            content='ถัดไป'
            icon='step forward'
            onClick={this.handleNextPage}
          /> */}
          <Modal
            open={openModalRecipient}
            closeOnDimmerClick={false}
            onClose={this.close}
            closeIcon
          >
            {/* <Modal.Header >เพิ่ม บุคลากร</Modal.Header> */}
            <Modal.Content>
              {data.recipientType == 'บุคคล' && (
                <ComponentPerson
                  openModalPerson={false}
                  onCloseModalPerson={this.close}
                  data_id={data.recipientPerson}
                  savedData={this.editRecipient}
                />
              )}
              {(data.recipientType === 'องค์กร' || data.recipientType === 'คณะบุคคล') && (
                <ComponentOrganization
                  checkOrganization={true}
                  onCloseModalPerson={this.close}
                />
              )}
            </Modal.Content>
          </Modal>
          <Message error>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              กรอกข้อมูลไม่ครบถ้วน
            </Message.Header>
            <Message.List>
              {validate.error &&
                Object.keys(validate.validateError).map((key, idx) => (
                  <Fragment key={idx}>
                    {validate.validateError[key].map((item, idx) => (
                      <Message.Item key={idx}>
                        {' '}
                        {localTh('proposal', key)} - {item}{' '}
                      </Message.Item>
                    ))}
                  </Fragment>
                ))}
            </Message.List>
          </Message>
        </Form>
        {/* } */}
        <Confirm
          open={this.state.openConfirmDel}
          content='ต้องการลบใช่หรือไม่'
          cancelButton='ยกเลิก'
          confirmButton='ยืนยัน'
          onCancel={this.close}
          onConfirm={this.handleDeleteRow}
        />
        <Transition
          visible={onSaveSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle' />
              บันทึกสำเร็จ
            </Message.Header>
          </Message>
        </Transition>
        <Transition
          visible={onSaveNotSuccess}
          animation='horizontal flip'
          duration={500}
        >
          <Message negative>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='close' />
              บันทึกไม่สำเร็จ จำนวนเงินรวมทั้งหมด ต้องเท่ากับงบประมาณที่อนุมัติ
            </Message.Header>
          </Message>
        </Transition>
        <Transition
          visible={notAttach}
          animation='horizontal flip'
          duration={500}
        >
          <Message negative>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='close' />
              ส่งข้อเสนอไม่สำเร็จ กรุณาแนบไฟล์ให้ครบ ดังนี้
              <br />
              -แนวทางและข้อคิดเห็นของผู้ทรงคุณวุฒิ
              <br />
              -ไฟล์ผู้ทรงคุณวุฒิ
              <br />
              -เอกสารโครงการ
              <br />
              -เอกสารโครงการฉบับสมบูณณ์
              <br />
            </Message.Header>
          </Message>
        </Transition>
        <Transition
          visible={sendProposal}
          animation='horizontal flip'
          duration={500}
        >
          <Message positive>
            <Message.Header style={{ fontFamily: 'supermarket' }}>
              <Icon name='check circle' />
              ส่งข้อเสนอสำเร็จ
            </Message.Header>
          </Message>
        </Transition>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  currentProposal: state.projects.currentData,
  currentState: state.projects,
  role: state.role,
  counter: state.counter,
  individuals: state.individuals.data,
  auth: state.auth,
  organization: state.organizations.currentData
})

const mapDispatchToProps = dispatch => ({
  updateProposal: (data, id) => dispatch(projectActions.save(data, id, 'POST')),
  getProposal: id => dispatch(projectActions.fetchOne(id)),
  getPerson: query => dispatch(individualsActions.fetchList({ query: query })),
  genId: name => dispatch(generate(name)),
  getOrganization: id => dispatch(organizationsActions.fetchOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawTime)
